import type { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

interface IReportContentSectionProps {
  title: string;
}
export function ReportContentSection({
  children,
  title,
}: PropsWithChildren<IReportContentSectionProps>) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        minWidth: 0,
      }}
    >
      <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
        {title}
      </Typography>
      {children}
    </Box>
  );
}
