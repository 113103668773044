import { useMutation } from '@tanstack/react-query';
import { AuthService } from '@api/services';
import { AUTH_KEY } from '@constants/query';

export default function useConfirmVerification() {
  return useMutation({
    mutationKey: AUTH_KEY.CONFIRM_VERIFICATION,
    mutationFn: (v: ConfirmVerificationRequest) =>
      AuthService.confirmVerification(v),
  });
}
