import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardService } from '@api/services';
import { DASHBOARD_KEY } from '@constants/query';

export default function useGetTop10Customer(type: 'paid' | 'stacked') {
  return useSuspenseQuery({
    queryKey: [...DASHBOARD_KEY.GET_TOP10_CUSTOMER, type],
    queryFn: () => DashboardService.getTop10Customer(type),
  });
}
