import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Tile } from '@heka/theme';
import { useGetOrganizationForUI } from '@api/queries/organization';
import AWSAccountTable from './AWSAccountTable';
import NCPAccountTable from './NCPAccountTable';
import NHNAccountTable from './NHNAccountTable';

type Props = Pick<
  ReturnType<
    typeof useFormik<Omit<CreateContract, 'CSP'> & { CSP: CloudProvider | '' }>
  >,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
>;

export default function CustomerContractForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contract' });
  const handleDateChange = useCallback(
    (key: string) => (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setFieldValue(key, newValue.toDate());
      }
    },
    [setFieldValue],
  );
  const [currentCSP, setCurrentCSP] = useState(values.CSP);
  const handleCSP = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const csp = e.target.value as CloudProvider | '';
      setCurrentCSP((prev) => {
        if (_.isEmpty(prev) || prev === csp) {
          setFieldValue('AccountIdList', []);
        }
        return csp;
      });
      setFieldValue('CSP', csp);
    },
    [setFieldValue],
  );
  useEffect(() => {
    if (values.CSP) {
      setCurrentCSP(values.CSP);
    }
  }, [values.CSP]);
  return (
    <Grid container rowSpacing="16px" columnSpacing="24px">
      <Grid xs={12}>
        <InputLabel>{t('label.csp')}</InputLabel>
        <RadioGroup
          name="CSP"
          value={currentCSP}
          onChange={handleCSP}
          onBlur={handleBlur}
          row
          sx={{ gap: '8px' }}
        >
          <FormControlLabel control={<Radio />} value="aws" label="AWS" />
          <FormControlLabel
            control={<Radio />}
            value="ncp"
            label="Naver Cloud"
          />
          <FormControlLabel control={<Radio />} value="nhn" label="NHN Cloud" />
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <DesktopDatePicker
          value={dayjs(values.StartDate)}
          onChange={handleDateChange('StartDate')}
          slotProps={{
            textField: {
              label: t('label.startDate'),
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid xs={12}>
        <DesktopDatePicker
          value={dayjs(values.EndDate)}
          onChange={handleDateChange('EndDate')}
          slotProps={{
            textField: {
              label: t('label.endDate'),
              fullWidth: true,
            },
          }}
        />
      </Grid>
      {!_.isEmpty(values.CSP) && (
        <Grid xs={12}>
          <InputLabel>{t('label.account')}</InputLabel>
          {values.CSP === 'aws' && (
            <AWSAccountTable values={values} setFieldValue={setFieldValue} />
          )}
          {values.CSP === 'ncp' && (
            <NCPAccountTable values={values} setFieldValue={setFieldValue} />
          )}
          {values.CSP === 'nhn' && (
            <NHNAccountTable values={values} setFieldValue={setFieldValue} />
          )}
        </Grid>
      )}
      {/*{values.CSP === 'nhn' && (*/}
      {/*  <Grid xs={12}>*/}
      {/*    <TextField*/}
      {/*      name="DiscountRate"*/}
      {/*      value={values.DiscountRate}*/}
      {/*      onChange={handleChange}*/}
      {/*      onBlur={handleBlur}*/}
      {/*      label={t('label.discountRate')}*/}
      {/*      placeholder={t('holder.discountRate')}*/}
      {/*      error={Boolean(errors.DiscountRate && touched.DiscountRate)}*/}
      {/*      helperText={*/}
      {/*        Boolean(errors.DiscountRate && touched.DiscountRate) &&*/}
      {/*        String(errors.DiscountRate)*/}
      {/*      }*/}
      {/*      InputProps={{*/}
      {/*        endAdornment: <InputAdornment position="end">%</InputAdornment>,*/}
      {/*      }}*/}
      {/*      type="number"*/}
      {/*      inputMode="numeric"*/}
      {/*      fullWidth*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <Grid xs={12}>
        <TextField
          name="Memo"
          value={values.Memo}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.memo')}
          placeholder={t('holder.memo')}
          minRows={4}
          multiline
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <FormControlLabel
          name="EnableNotification"
          value={values.EnableNotification}
          onChange={handleChange}
          onBlur={handleBlur}
          control={<Checkbox checked={values.EnableNotification} />}
          label={t('label.enableNotification')}
        />
        {values.EnableNotification && (
          <Box sx={{ mt: '8px' }}>
            <FormControlLabel
              name="EnableEmail"
              value={values.EnableEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              control={<Checkbox checked={values.EnableEmail} />}
              label={t('label.enableEmail')}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                mt: '16px',
              }}
            >
              <TextField
                type="number"
                name="ReminderDays"
                value={values.ReminderDays}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ width: '80px' }}
              />
              <Typography>일 전 알림</Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
