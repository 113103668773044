import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

type MemberLinkProps = BoxProps & {
  href: LinkProps['to'];
  name: string;
  email: string;
};

export const MemberLink = styled(
  ({ name, email, href }: MemberLinkProps) => (
    <Box
      component={Link}
      to={href}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      <Typography textTransform="capitalize">{name}</Typography>
      <Typography color="textSecondary" fontSize="14px" lineHeight="120%">
        {email}
      </Typography>
    </Box>
  ),
  {
    skipVariantsResolver: true,
    skipSx: true,
  },
)<MemberLinkProps>();
