import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useNHNUserForm(): UseFormValidation<NHNUserInfo> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      TenantId: '',
      Username: '',
      Password: '',
    },
    validationSchema: Yup.object()
      .shape({
        TenantId: Yup.string()
          .matches(/^[a-zA-Z0-9]{32}$/, t('onboard.nhn.tenantId'))
          .defined(t('common.required.base')),
        Username: Yup.string()
          .email(t('auth.email'))
          .defined(t('common.required.base')),
        Password: Yup.string().defined(t('common.required.base')),
      })
      .defined(),
  };
}

export function useNHNAccountForm(): UseFormValidation<CreateNHNAccount> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      APIPrefix: '',
      AccessKey: '',
      SecretKey: '',
      HmacSecretKey: '',
      TempUID: '',
      AccountName: '',
    },
    validationSchema: Yup.object().shape({
      APIPrefix: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+-[a-zA-Z0-9]{10}-[a-zA-Z0-9]+$/,
          t('onboard.nhn.apiPrefix'),
        )
        .defined(t('common.required.base')),
      HmacSecretKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{16}$/, t('onboard.nhn.hmacSecretKey'))
        .defined(t('common.required.base')),
      AccessKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{20}$/, t('onboard.nhn.accessKey'))
        .defined(t('common.required.base')),
      SecretKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{16}$/, t('onboard.nhn.secretKey'))
        .defined(t('common.required.base')),
      TempUID: Yup.string().defined(),
      AccountName: Yup.string(),
    }),
  };
}
