import { Suspense } from 'react';
import { Paper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import {
  ErrorBoundary,
  SuspenseFallback,
  SuspenseFallbackProps,
} from '../styled';

type SuspensePaperProps = PaperProps & {
  FallbackProps?: SuspenseFallbackProps;
};

export default function SuspensePaper({
  children,
  FallbackProps,
  ...props
}: SuspensePaperProps) {
  return (
    <Paper {...props}>
      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback {...FallbackProps} />}>
          {children}
        </Suspense>
      </ErrorBoundary>
    </Paper>
  );
}
