import { Suspense } from 'react';
import { Box, BoxProps } from '@mui/material';
import {
  ErrorBoundary,
  SuspenseFallback,
  SuspenseFallbackProps,
} from '../styled';

type SuspenseBoxProps = BoxProps & {
  FallbackProps?: SuspenseFallbackProps;
};

export default function SuspenseBox({
  children,
  FallbackProps,
  ...props
}: SuspenseBoxProps) {
  return (
    <Box {...props}>
      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback {...FallbackProps} />}>
          {children}
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
}
