import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  calculateDataMax,
  formatChartAxis,
  formatChartLabel,
} from '@utils/chart';
import { formatDate } from '@utils/formatter';

type Props = {
  data?: Array<CustomerTrend>;
};
export default function DashboardCustomerChart({ data = [] }: Props) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} barSize={32}>
        <XAxis
          dataKey="Month"
          stroke="none"
          strokeWidth={0}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={16}
          tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
          height={26}
          padding={{ left: 16, right: 16 }}
        />
        <YAxis
          type="number"
          scale="linear"
          stroke="none"
          strokeWidth={0}
          width={32}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={8}
          tickFormatter={formatChartAxis}
          domain={[0, calculateDataMax]}
          padding={{ top: 8, bottom: 8 }}
        />
        <defs>
          <linearGradient
            id="colorDashboardCustomerGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#7590EF" />
            <stop offset="1" stopColor="#78C1EB" />
          </linearGradient>
        </defs>
        <Bar
          dataKey="NumberOfCompany"
          fill="url(#colorDashboardCustomerGradient)"
          radius={8}
        >
          <LabelList
            dataKey="NumberOfCompany"
            position="top"
            fontSize={14}
            fill="#777B86"
            formatter={formatChartLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
