import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { formatKRW } from '@utils/formatter';

export default function CumulativeDiscount() {
  const { t } = useTranslation('customer', { keyPrefix: 'text' });
  const { data: response } = useGetOrganizationForUI();
  return (
    <>
      <Typography color="textSecondary" letterSpacing="0.32px">
        {t('cumulativeDiscount')}
      </Typography>
      <Typography fontSize="32px" mt="16px">
        {formatKRW(response?.extras?.TotalDiscountAmount ?? 0)}
      </Typography>
    </>
  );
}
