import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PermissionSwitch } from '@components/styled/switch';

type Props = {
  canInvite?: boolean;
  canEdit?: boolean;
  openMargin?: boolean;
};
export default function SettingsRolePermissions({
  canInvite,
  canEdit,
  openMargin,
}: Props) {
  const { t } = useTranslation('settings');
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
      }}
    >
      <Box sx={{ flex: 1, borderRight: '1px solid #E0E0E0' }}>
        <Box sx={{ background: '#E0E0E033', p: '8px 16px' }}>
          <Typography
            color="textSecondary"
            fontSize="14px"
            letterSpacing="0.56px"
            align="center"
          >
            {t('text.invitePermission')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: '16px' }}>
          <PermissionSwitch checked={canInvite} />
        </Box>
      </Box>
      <Box sx={{ flex: 1, borderRight: '1px solid #E0E0E0' }}>
        <Box sx={{ background: '#E0E0E033', p: '8px 16px' }}>
          <Typography
            color="textSecondary"
            fontSize="14px"
            letterSpacing="0.56px"
            align="center"
          >
            {t('text.editPermission')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: '16px' }}>
          <PermissionSwitch checked={canEdit} />
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ background: '#E0E0E033', p: '8px 16px' }}>
          <Typography
            color="textSecondary"
            fontSize="14px"
            letterSpacing="0.56px"
            align="center"
          >
            {t('text.marginPermission')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: '16px' }}>
          <PermissionSwitch checked={openMargin} />
        </Box>
      </Box>
    </Box>
  );
}
