import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/organization' as const;

async function getOrganizations() {
  const { data } = await axios.get<MultipleResponse<Organization>>(PREFIX);
  return data;
}

async function getOrganizationList(filter: OrganizationFilter) {
  const { data } = await axios.post<
    MultipleResponse<
      OrganizationList,
      {
        Count: {
          [k in OrganizationStatus]: number;
        };
      }
    >
  >(`${PREFIX}/list`, {
    ...filter,
    Organizations: filter.Organizations?.map((v) => v.UID),
  });
  return data;
}

async function getOrganizationById(UID: string) {
  const { data } = await axios.get<
    SingleResponse<
      Organization,
      {
        TotalBillingAmount: number;
        TotalDiscountAmount: number;
        LastInvoicedAmount: number;
        DepositBalance: number;
      }
    >
  >(`${PREFIX}/${UID}`);
  return data;
}

async function getOrganizationSummary() {
  const { data } = await axios.get<SingleResponse<OrganizationSummary>>(
    `${PREFIX}/summary`,
  );
  return data;
}

async function getOrganizationAutoComplete() {
  const { data } = await axios.get<SingleResponse<OrganizationAutoComplete>>(
    `${PREFIX}/acdata`,
  );
  return data;
}

async function createOrganization(body: CreateOrganization) {
  const { data } = await axios.post<SingleResponse<Organization>>(PREFIX, body);
  return data;
}

async function updateOrganization(UID: string, body: UpdateOrganization) {
  const { data } = await axios.put<SingleResponse<Organization>>(
    `${PREFIX}/${UID}`,
    body,
  );
  return data;
}

async function deleteOrganization(UID: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}`);
  return data;
}

async function updateBillingCustom(UID: string, body: UpdateBillingCustom) {
  const { data } = await axios.put<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/billing_custom`,
    body,
  );
  return data;
}

async function addDepositDetail(UID: string, body: CreateDepositDetail) {
  const { data } = await axios.post<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/deposit`,
    body,
  );
  return data;
}

async function updateDepositDetail(UID: string, body: UpdateDepositDetail) {
  const { data } = await axios.put<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/deposit/${body.UID}`,
    body,
  );
  return data;
}

async function addContact(UID: string, body: CreateContact) {
  const { data } = await axios.post<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/contact`,
    body,
  );
  return data;
}

async function updateContact(UID: string, body: UpdateContact) {
  const { data } = await axios.put<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/contact/${body.UID}`,
    body,
  );
  return data;
}

async function deleteContact(UID: string, ContactId: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}/contact/${ContactId}`);
  return data;
}

async function addCloudAccount(
  UID: string,
  CSP: CloudProvider,
  body: CreateCloudAccount,
) {
  const { data } = await axios.post<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/account`,
    {
      CSP,
      ...body,
    },
  );
  return data;
}

async function bulkAddCloudAccount(
  UID: string,
  CSP: CloudProvider,
  BulkAccounts: Array<CreateCloudAccount>,
  DiscountRate?: number,
) {
  const { data } = await axios.post<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/account/${CSP}/bulk`,
    {
      BulkAccounts: BulkAccounts.map((v) => ({ CSP, ...v })),
      DiscountRate,
    },
  );
  return data;
}

async function updateCloudAccount(
  UID: string,
  CSP: CloudProvider,
  body: UpdateCloudAccount,
) {
  const { data } = await axios.put<SingleResponse<Organization>>(
    `${PREFIX}/${UID}/account`,
    {
      CSP,
      ...body,
    },
  );
  return data;
}

async function deleteCloudAccount(
  UID: string,
  CSP: CloudProvider,
  AccountUID: string,
) {
  const { data } = await axios.delete(`${PREFIX}/${UID}/account/${AccountUID}`);
  return data;
}

export default {
  getOrganizations,
  getOrganizationList,
  getOrganizationById,
  getOrganizationSummary,
  getOrganizationAutoComplete,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  updateBillingCustom,
  addDepositDetail,
  updateDepositDetail,
  addContact,
  updateContact,
  deleteContact,
  addCloudAccount,
  bulkAddCloudAccount,
  updateCloudAccount,
  deleteCloudAccount,
};
