import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useBulkAddCloudAccount } from '@api/queries/organization';
import CustomerAWSAccountForm, {
  useCustomerAWSAccountForm,
} from '@features/customer/form/account/aws';
import LargeModal from '@components/modal/large';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

export default function AddAWSAccountModal({ open, onClose }: ModalProps) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.aws.add',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useBulkAddCloudAccount();
  const { initialValues, validationSchema } = useCustomerAWSAccountForm();
  const { handleSubmit, resetForm, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (UID) {
        try {
          await mutateAsync({
            OrganizationId: UID,
            CSP: 'aws',
            Accounts: v.AccountIdList.map((k) => ({
              AccountId: k,
              Memo: '',
              Options: {
                ExcludePayerDiscount: false,
              },
            })),
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <LargeModal
      open={open}
      onClose={onClose}
      title={t('title')}
      type="form"
      onSubmit={handleSubmit}
      maxWidth="800px"
      minHeight={0}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!UID || !formik.values.AccountIdList?.length}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerAWSAccountForm {...formik} />
    </LargeModal>
  );
}
