import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { AdminService } from '@api/services';
import { useGetTokenData } from '@api/queries/token';
import { ADMIN_KEY } from '@constants/query';

export default function useGetAllUsers(suspense?: boolean) {
  const { data } = useGetTokenData();
  const useQueryFn = suspense ? useSuspenseQuery : useQuery;
  return useQueryFn({
    queryKey: [...ADMIN_KEY.GET_ALL_USERS, data?.scope],
    queryFn: ({ queryKey }) =>
      queryKey[2]?.includes('ROOT') ? AdminService.getAllUsers() : null,
  });
}
