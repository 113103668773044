import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useGetNCPAccountRatio } from '@api/queries/dashboard';
import { DashboardNotFound } from '@features/dashboard/styled';
import DashboardCloudTypeChart from '@components/chart/dashboard/cloudType';

const ENTERPRISE_COLOR = '#CECFDE' as const;
const PUBLIC_COLOR = '#58A3CE' as const;
const FINANCE_COLOR = '#E3755D' as const;

type ChartItem = {
  color: string;
  type: NCPAccountType;
  amount: number;
  percent: number;
};

export default function CloudTypeChart() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetNCPAccountRatio();
  const isDataEmpty = useMemo(() => {
    return (
      !response?.data.Enterprise &&
      !response?.data.Public &&
      !response?.data.Finance
    );
  }, [response?.data]);
  const items = useMemo<Array<ChartItem>>(() => {
    if (!response?.data) {
      return [
        { color: ENTERPRISE_COLOR, type: 'enterprise', amount: 0, percent: 0 },
        { color: PUBLIC_COLOR, type: 'public', amount: 0, percent: 0 },
        { color: FINANCE_COLOR, type: 'finance', amount: 0, percent: 0 },
      ];
    }
    const total =
      response.data.Enterprise + response.data.Public + response.data.Finance;
    const items: Array<ChartItem> = [
      {
        color: ENTERPRISE_COLOR,
        type: 'enterprise',
        amount: response.data.Enterprise,
        percent: total > 0 ? (response.data.Enterprise / total) * 100.0 : 0,
      },
      {
        color: PUBLIC_COLOR,
        type: 'public',
        amount: response.data.Public,
        percent: total > 0 ? (response.data.Public / total) * 100.0 : 0,
      },
      {
        color: FINANCE_COLOR,
        type: 'finance',
        amount: response.data.Finance,
        percent: total > 0 ? (response.data.Finance / total) * 100.0 : 0,
      },
    ];
    return sortBy(items, 'amount').reverse();
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.ncpCloudType')}
      </Typography>
      {!isDataEmpty ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DashboardCloudTypeChart items={items} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {items.map((item) => (
              <Box
                key={`cloud-type-chart-item-${item.type}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Box
                    sx={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '999px',
                      background: item.color,
                    }}
                  />
                  <Typography color="#362F26" fontSize="14px">
                    {globalT(`ncp.cloudType.${item.type}`)}
                  </Typography>
                </Box>
                <Typography color="#362F26" fontSize="14px">
                  {globalT('format.number.quantity', {
                    value: item.amount,
                  })}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
