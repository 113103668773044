import type { Dispatch, MouseEvent, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  TextField,
  RadioGroup,
  ClickAwayListener,
  FormControlLabel,
  Radio,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { getOutlinedInputUtilityClass } from '@mui/material/OutlinedInput';
import { getInputAdornmentUtilityClass } from '@mui/material/InputAdornment';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import _ from 'lodash';
import { useGetAWSCostAutocomplete } from '@api/queries/aws/cost';
import { AWS_COST_FILTER_TAG_STATE } from '@atoms/page/filter';
import { SuspenseBox } from '@components/suspense';
import { Popper } from '../styled';
import TagKey from './TagKey';
import TagValue from './TagValue';

type TagFilterProps = {
  filter: AWSCEUsageRequestInternal;
  setFilter: Dispatch<SetStateAction<AWSCEUsageRequestInternal>>;
};

export default function TagFilter({ filter, setFilter }: TagFilterProps) {
  const { t } = useTranslation('cost');
  const [isExclude, setIsExclude] = useState(false);
  const [iseKeySelected, setIsKeySelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? 'cost-filter-popper-tag' : undefined;
  const [pendingKeys, setPendingKeys] = useState<Array<string>>([]);
  const [pendingValues, setPendingValues] = useState<
    Array<CEUsageTagItemInternal>
  >([]);
  const setTagKeys = useSetRecoilState(AWS_COST_FILTER_TAG_STATE);
  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      setPendingKeys(
        filter.TagsExclude?.map((v) => v.TagKey) ??
          filter.Tags?.map((v) => v.TagKey) ??
          [],
      );
      setPendingValues(
        _.flattenDeep(
          filter.TagsExclude?.map((v) =>
            v.Items.map((k) => ({ TagKey: v.TagKey, Value: k })),
          ) ??
            filter.Tags?.map((v) =>
              v.Items.map((k) => ({ TagKey: v.TagKey, Value: k })),
            ) ??
            [],
        ),
      );
      setIsExclude(typeof filter.TagsExclude !== 'undefined');
      setAnchorEl(e.currentTarget);
    },
    [filter.Tags, filter.TagsExclude],
  );
  const handleClose = useCallback(() => {
    setIsKeySelected(false);
    setAnchorEl(null);
  }, []);
  const handleTagKeysConfirm = useCallback(() => {
    setTagKeys(pendingKeys);
    setIsKeySelected(true);
  }, [pendingKeys, setTagKeys]);
  const handleTagValueChange = useCallback(
    (values: Array<CEUsageTagItemInternal>) => {
      setPendingValues(values);
      const items: Array<CEUsageTagItem> = [];
      values.forEach((v) => {
        let idx = items.findIndex((k) => k.TagKey === v.TagKey);
        if (idx < 0) {
          items.push({ TagKey: v.TagKey, Items: [] });
          idx = items.length - 1;
        }
        items[idx].Items.push(v.Value);
      });
      setFilter((v) => ({
        ...v,
        ...(!isExclude && { Tags: !_.isEmpty(items) ? items : undefined }),
        ...(isExclude && {
          TagsExclude: !_.isEmpty(items) ? items : undefined,
        }),
      }));
    },
    [isExclude, setFilter],
  );
  const { data: response } = useGetAWSCostAutocomplete();
  const values = useMemo(() => {
    return filter.TagsExclude ?? filter.Tags ?? [];
  }, [filter.TagsExclude, filter.Tags]);
  const setValues = useCallback(
    (newValue: Array<CEUsageTagItem>, manualExclude?: boolean) => {
      const exclusion = manualExclude ?? isExclude;
      setFilter((v) => ({
        ...v,
        ...(!exclusion && { Tags: newValue }),
        ...(exclusion && { TagsExclude: newValue }),
      }));
    },
    [isExclude, setFilter],
  );
  const optionsOrigin = useMemo(() => {
    if (response?.data) {
      return _.uniq(response.data.TagKeys ?? []);
    }
    return [];
  }, [response?.data]);
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return t('filter.text.disabled', { name: t('filter.label.tag') });
    }
    if (values.length) {
      return t(`text.${isExclude ? 'excluded' : 'included'}`, {
        count: _.flattenDeep(values.map((v) => v.Items)).length,
      });
    }
    return t('filter.text.allSelected', { name: t('filter.label.tag') });
  }, [isExclude, optionsOrigin, values, t]);
  const handleChangeExclusion = useCallback(
    (v: boolean) => {
      setIsExclude(v);
      setValues(values, v);
    },
    [values, setValues],
  );
  return (
    <Box>
      <InputLabel>{t('filter.label.tagFilter')}</InputLabel>
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick: handleOpen,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ '> svg': { color: '#323232 !important' } }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={(theme) => ({
          [`> .${getOutlinedInputUtilityClass('root')}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${getOutlinedInputUtilityClass('input')}`]: {
              cursor: 'pointer',
              userSelect: 'none',
            },
            [`> .${getInputAdornmentUtilityClass('root')} > svg`]: {
              width: '24px',
              height: '24px',
              color: 'text.secondary',
            },
          },
        })}
      />
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ p: '16px', borderBottom: '0.5px solid #E0E0E0' }}>
              <RadioGroup
                value={isExclude}
                onChange={(e, v) => handleChangeExclusion(v === 'true')}
                row
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('filter.text.include')}
                  sx={{ ml: 0 }}
                />
                <FormControlLabel
                  value
                  control={<Radio />}
                  label={t('filter.text.exclude')}
                  sx={{ ml: 0 }}
                />
              </RadioGroup>
            </Box>
            <SuspenseBox>
              {iseKeySelected ? (
                <TagValue
                  onClose={handleClose}
                  values={pendingValues}
                  setValues={handleTagValueChange}
                />
              ) : (
                <TagKey
                  onClose={handleClose}
                  onConfirm={handleTagKeysConfirm}
                  values={pendingKeys}
                  setValues={setPendingKeys}
                />
              )}
            </SuspenseBox>
          </div>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
