import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Typography, Box } from '@mui/material';
import _ from 'lodash';
import SimpleTable from '@components/table/simple';
import { formatKRW, formatUSD } from '@utils/formatter';

type TableForm = {
  Type: CloudProvider | 'Total';
  CloudUsage: number;
  AdditionalService: number;
  Discount: number;
  TotalAmount: number;
};

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceSummary({ Invoices }: Props) {
  const { t } = useTranslation('table', { keyPrefix: 'invoice' });
  const { t: invoiceT } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<TableForm>();
    return [
      columnHelper.accessor('Type', {
        id: 'Type',
        header: t('csp'),
        cell: (v) => {
          switch (v.getValue()) {
            case 'aws':
              return 'AWS';
            case 'ncp':
              return 'Naver Cloud';
            case 'nhn':
              return 'NHN Cloud';
            case 'Total':
              return invoiceT('text.grandTotal');
          }
        },
      }),
      columnHelper.accessor('CloudUsage', {
        id: 'CloudUsage',
        header: t('cloudUsage'),
        cell: (v) =>
          v.row.original.Type !== 'aws'
            ? formatKRW(v.getValue())
            : formatUSD(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('AdditionalService', {
        id: 'AdditionalService',
        header: t('serviceCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('Discount', {
        id: 'Discount',
        header: t('discountCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalAmount', {
        id: 'TotalAmount',
        header: t('totalCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t, invoiceT]);
  const tableData = useMemo(() => {
    const result: Array<TableForm> = [
      {
        Type: 'aws',
        CloudUsage: 0,
        AdditionalService: 0,
        Discount: 0,
        TotalAmount: 0,
      },
      {
        Type: 'ncp',
        CloudUsage: 0,
        AdditionalService: 0,
        Discount: 0,
        TotalAmount: 0,
      },
      {
        Type: 'nhn',
        CloudUsage: 0,
        AdditionalService: 0,
        Discount: 0,
        TotalAmount: 0,
      },
      {
        Type: 'Total',
        CloudUsage: 0,
        AdditionalService: 0,
        Discount: 0,
        TotalAmount: 0,
      },
    ];
    Invoices.forEach((invoice) => {
      if (invoice.CSP === 'aws') {
        result[3].CloudUsage += invoice.CloudCost.KRW;
        result[0].CloudUsage += invoice.CloudCost.USD;
        result[0].AdditionalService += invoice.AdditionalCost.KRW;
        result[0].Discount += invoice.DiscountCost.KRW;
        result[0].Discount += _.sumBy(
          _.filter(_.uniqBy(Invoices, 'csp'), { csp: 'aws' }),
          'OrgDiscountCost.KRW',
        );
        result[0].TotalAmount += invoice.TotalCost.KRW;
      } else if (invoice.CSP === 'ncp') {
        result[1].CloudUsage += invoice.CloudCost.KRW;
        result[1].AdditionalService += invoice.AdditionalCost.KRW;
        result[1].Discount += invoice.DiscountCost.KRW;
        result[1].TotalAmount += invoice.TotalCost.KRW;
      } else if (invoice.CSP === 'nhn') {
        result[2].CloudUsage += invoice.CloudCost.KRW;
        result[2].AdditionalService += invoice.AdditionalCost.KRW;
        result[2].Discount += invoice.DiscountCost.KRW;
        result[2].TotalAmount += invoice.TotalCost.KRW;
      }
    });
    const uniqueInvoices = _.uniqBy(Invoices, 'CSP');
    ['aws', 'ncp', 'nhn'].forEach((CSP, i) => {
      const additional = _.sumBy(
        _.filter(uniqueInvoices, { CSP }),
        'OrgAdditionalCost.KRW',
      );
      const discount = _.sumBy(
        _.filter(uniqueInvoices, { CSP }),
        'OrgDiscountCost.KRW',
      );
      result[i].AdditionalService += additional;
      result[i].Discount += discount;
      result[i].TotalAmount += additional;
      result[i].TotalAmount -= discount;
    });
    result[3].CloudUsage += result[1].CloudUsage;
    result[3].CloudUsage += result[2].CloudUsage;
    result[3].AdditionalService += result[0].AdditionalService;
    result[3].AdditionalService += result[1].AdditionalService;
    result[3].AdditionalService += result[2].AdditionalService;
    result[3].Discount += result[0].Discount;
    result[3].Discount += result[1].Discount;
    result[3].Discount += result[2].Discount;
    result[3].TotalAmount += result[0].TotalAmount;
    result[3].TotalAmount += result[1].TotalAmount;
    result[3].TotalAmount += result[2].TotalAmount;
    const hasAWS = Invoices.some((v) => v.CSP === 'aws');
    const hasNCP = Invoices.some((v) => v.CSP === 'ncp');
    const hasNHN = Invoices.some((v) => v.CSP === 'nhn');
    if (!hasAWS) {
      result.splice(0, 1);
    }
    if (!hasNCP) {
      result.splice(result.length - 3, 1);
    }
    if (!hasNHN) {
      result.splice(result.length - 2, 1);
    }
    return result;
  }, [Invoices]);
  return (
    <>
      <Box>
        <Typography color="textSecondary" mb="16px">
          {invoiceT('text.totalCharge')}
        </Typography>
        <Typography color="text.main" fontSize="24px" fontWeight={500}>
          {globalT('format.currency.krw', {
            value: tableData[tableData.length - 1].TotalAmount,
          })}
        </Typography>
      </Box>
      <SimpleTable columns={columns} data={tableData} />
    </>
  );
}
