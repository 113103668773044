import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@mui/material';
import { Tabs } from '@heka/theme/src';
import { SuspensePaper } from '@components/suspense';
import { useCurrentInfo } from '@hooks';

export default function SettingsAccountPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasAWS, hasNCP, hasNHN } = useCurrentInfo();
  const csp = useMemo(() => {
    return pathname.replace('/settings/account', '').replace('/', '').trim();
  }, [pathname]);
  useEffect(() => {
    const csp = pathname
      .replace('/settings/account', '')
      .replace('/', '')
      .trim();
    if (!csp.length) {
      if (hasAWS) {
        navigate('./aws', { replace: true });
      } else if (hasNCP) {
        navigate('./ncp', { replace: true });
      } else if (hasNHN) {
        navigate('./nhn', { replace: true });
      } else {
        navigate('./aws', { replace: true });
      }
    }
  }, [hasAWS, hasNCP, hasNHN, navigate, pathname]);
  if (!csp.length) {
    return null;
  }
  return (
    <>
      <Tabs variant="square" value={csp} sx={{ mb: '-2px' }}>
        <Tab value="aws" label="AWS" href="/settings/account/aws" />
        <Tab value="ncp" label="Naver Cloud" href="/settings/account/ncp" />
        <Tab value="nhn" label="NHN Cloud" href="/settings/account/nhn" />
      </Tabs>
      <SuspensePaper sx={{ flex: 1 }}>
        <Outlet />
      </SuspensePaper>
    </>
  );
}
