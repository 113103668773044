import CryptoJS from 'crypto-js';

export function encryption(original: string): string {
  return CryptoJS.AES.encrypt(
    original,
    CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  ).toString();
}

export function decryption(cipher: string): string {
  return CryptoJS.AES.decrypt(
    cipher,
    CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    },
  ).toString(CryptoJS.enc.Utf8);
}

export function encryptLocalStorage(key: string, value: string): void {
  const cipher = encryption(value);
  localStorage.setItem(key, cipher);
}

export function decryptLocalStorage(key: string): string | null {
  const cipher = localStorage.getItem(key);
  if (cipher) {
    return decryption(cipher);
  }
  return null;
}
