import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import ReportSingleChart from '@components/chart/report/simple';
import ReportTable from '@components/table/report';
import { formatDate, formatKRW, formatLocaleString } from '@utils/formatter';
import { ReportContentSection } from './styled';
import { ReportProviderChip } from '@components/styled/chip';
import ReportMultipleChart from '@components/chart/report/multiple';
import { useRecoilValue } from 'recoil';
import { REPORT_FILTER_STATE } from '@atoms/page/filter';
import _ from 'lodash';

const DUMMY = [
  {
    CSP: 'aws',
    '2323-01': 5,
    '2323-02': 5,
    '2323-03': 5,
    '2323-04': 5,
    '2323-05': 5,
    '2323-06': 5,
  },
  {
    CSP: 'ncp',
    '2323-01': 5,
    '2323-02': 5,
    '2323-03': 5,
    '2323-04': 5,
    '2323-05': 5,
    '2323-06': 5,
  },
];

export default function ReportCustomerAccountSection({
  Summary,
}: Pick<ReportContent, 'Summary'>) {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const reportSettings = useRecoilValue(REPORT_FILTER_STATE);
  const pinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, string | number>>();
    return [
      columnHelper.accessor('CSP', {
        id: 'CSP',
        header: reportSettings.DisplayOptions.includes('BY_CSP') ? 'CSP' : '',
        cell: (v) => {
          if (!reportSettings.DisplayOptions.includes('BY_CSP')) {
            return '계정 수';
          }
          return (
            <ReportProviderChip provider={v.getValue() as CloudProvider} />
          );
        },
        size: 150,
        minSize: 150,
        maxSize: 150,
        meta: {
          align: reportSettings.DisplayOptions.includes('BY_CSP')
            ? 'left'
            : 'right',
        },
      }),
    ];
  }, [reportSettings.DisplayOptions]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, string | number>>();
    return Summary.map((item) =>
      columnHelper.accessor(formatDate(item.Date, 'YYYY-MM'), {
        id: formatDate(item.Date, 'YYYY-MM'),
        header: formatDate(item.Date, 'YYYY.MM'),
        cell: (v) => formatLocaleString(v.getValue()),
        size: 140,
        minSize: 140,
        meta: {
          align: 'right',
        },
      }),
    );
  }, [Summary]);
  const tableData = useMemo(() => {
    if (reportSettings.DisplayOptions.includes('BY_CSP')) {
      const awsObj: Record<string, string | number> = {
        CSP: 'aws',
      };
      const ncpObj: Record<string, string | number> = {
        CSP: 'ncp',
      };

      Summary.forEach((item) => {
        if (_.has(item.Account.CSPList, 'aws')) {
          awsObj[formatDate(item.Date, 'YYYY-MM')] = Number(
            item.Account.CSPList.aws.Amount,
          );
        }
        if (_.has(item.Account.CSPList, 'ncp')) {
          ncpObj[formatDate(item.Date, 'YYYY-MM')] = Number(
            item.Account.CSPList.ncp.Amount,
          );
        }
      });
      return [awsObj, ncpObj];
    } else {
      const obj: Record<string, string | number> = {};
      Summary.forEach((item) => {
        obj[formatDate(item.Date, 'YYYY-MM')] = item.Account.Total;
      });
      return [obj];
    }
  }, [Summary, reportSettings.DisplayOptions]);
  return (
    <ReportContentSection title={reportT('title.customerAccountTrend')}>
      {reportSettings.DisplayOptions.includes('BY_CSP') ? (
        <ReportMultipleChart
          data={[
            { Date: new Date(), aws: 123123, ncp: 123123 },
            { Date: new Date(), aws: 123123, ncp: 123123 },
            { Date: new Date(), aws: 123123, ncp: 123123 },
            { Date: new Date(), aws: 123123, ncp: 123123 },
            { Date: new Date(), aws: 123123, ncp: 123123 },
            { Date: new Date(), aws: 123123, ncp: 123123 },
          ]}
          dateKey="Date"
          labelList={[
            {
              label: 'AWS',
              valueKey: 'Account.CSPList.aws.Amount',
              color: 'url(#colorReportCustomerAccountAWSGradient)',
              gradient: 'linear-gradient(180deg, #E17A00 0%, #EBC781 100%)',
            },
            {
              label: 'Naver Cloud',
              valueKey: 'Account.CSPList.ncp.Amount',
              color: 'url(#colorReportCustomerAccountNCPGradient)',
              gradient: 'linear-gradient(180deg, #2783A0 0%, #7DB7BE 100%)',
            },
          ]}
          Gradient={
            <defs>
              <linearGradient
                id="colorReportCustomerAccountAWSGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
              >
                <stop offset="0" stopColor="#E17A00" />
                <stop offset="1" stopColor="#EBC781" />
              </linearGradient>
              <linearGradient
                id="colorReportCustomerAccountNCPGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
              >
                <stop offset="0" stopColor="#2783A0" />
                <stop offset="1" stopColor="#7DB7BE" />
              </linearGradient>
            </defs>
          }
        />
      ) : (
        <ReportSingleChart
          data={Summary}
          dateKey="Date"
          valueKey="Account.Total"
          color="url(#colorReportCustomerAccountGradient)"
          Gradient={
            <defs>
              <linearGradient
                id="colorReportCustomerAccountGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
              >
                <stop offset="0" stopColor="#464646" />
                <stop offset="1" stopColor="#929292" />
              </linearGradient>
            </defs>
          }
        />
      )}
      <ReportTable
        data={tableData}
        pinnedColumns={pinnedColumns}
        columns={columns}
        disableHighlight
      />
    </ReportContentSection>
  );
}
