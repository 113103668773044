import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  RadioGroup,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { Tile } from '@heka/theme';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetNHNAssignedProjectList } from '@api/queries/nhn/account';
import SimpleTable from '@components/table/simple';
import { useRowSelect } from '@hooks';
import { InvoiceChip } from '@components/styled/chip';

type Props = Pick<
  ReturnType<
    typeof useFormik<AdditionalService & { ApplyToInvoice?: boolean }>
  >,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
> & {
  isDiscount?: boolean;
  isApplicable?: boolean;
};
type AccountTableData = {
  UID: string;
  CSP: CloudProvider;
  AccountId: string;
  AccountName: string;
};
export default function CustomerServiceForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  isDiscount,
  isApplicable,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.service' });
  const { t: tableT } = useTranslation('table');
  const { data: response } = useGetOrganizationForUI();
  const { data: projects } = useGetNHNAssignedProjectList();
  const [billingFixed, setBillingFixed] = useState(true);
  const accountColumns = useMemo(() => {
    const columnHelper = createColumnHelper<AccountTableData>();
    return [
      columnHelper.display({
        id: 'Select',
        header: tableT('text.select'),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('AccountName', {
        id: 'AccountName',
        header: t('label.accountId'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t, tableT]);
  const handleCloudChange = useCallback(
    (value: CloudProvider) => () => {
      const providers = new Set(values.Providers);
      if (providers.has(value)) {
        providers.delete(value);
      } else {
        providers.add(value);
      }
      setFieldValue('Providers', Array.from(providers).sort());
    },
    [setFieldValue, values.Providers],
  );
  const accountData = useMemo<Array<AccountTableData>>(() => {
    if (values.ApplyLevel === 'Company') {
      return [];
    }
    return (
      response?.data?.CloudAccounts?.filter((v) =>
        values.Providers.includes(v.CSP),
      )?.map((v) => {
        if (v.CSP === 'nhn') {
          let accName: string | undefined = undefined;
          const projectInfo = (
            projects?.data?.filter((v) => v.OrgUID === response?.data?.UID) ??
            []
          ).find((k) => k.ProjectInfo.ProjectId === v.AccountId);
          if (projectInfo) {
            accName = projectInfo.ProjectInfo.AccountName;
          }
          return {
            UID: v.UID,
            CSP: v.CSP,
            AccountId: v.AccountId,
            AccountName: accName ? `${accName} (${v.AccountId})` : v.AccountId,
          };
        }
        return {
          UID: v.UID,
          CSP: v.CSP,
          AccountId: v.AccountId,
          AccountName: v.AccountId,
        };
      }) ?? []
    );
  }, [
    projects?.data,
    response?.data?.CloudAccounts,
    response?.data?.UID,
    values.ApplyLevel,
    values.Providers,
  ]);
  const [selectedRows, onRowSelected, setSelectedRows] = useRowSelect(
    (value) => {
      const selected = accountData
        .filter((v, i) => value[i.toString()])
        .map((v) => v.AccountId);
      setFieldValue('ApplyAccount', selected);
    },
  );
  useEffect(() => {
    setSelectedRows(
      accountData.reduce(
        (prev, current, idx) =>
          Object.assign(prev, {
            [idx]: values.ApplyAccount?.includes(current.AccountId) ?? false,
          }),
        {},
      ) ?? {},
    );
  }, [accountData, setSelectedRows, values.ApplyAccount]);
  return (
    <Grid container rowSpacing="16px" columnSpacing="24px">
      <Grid xs={12}>
        <TextField
          name="ServiceName"
          value={values.ServiceName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={isDiscount ? t('label.discountName') : t('label.serviceName')}
          placeholder={
            isDiscount ? t('holder.discountName') : t('holder.serviceName')
          }
          error={Boolean(errors.ServiceName && touched.ServiceName)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <InputLabel>{t('label.applyLevel')}</InputLabel>
        <RadioGroup
          value={values.ApplyLevel}
          onChange={(e, v) => setFieldValue('ApplyLevel', v)}
          row
          sx={{ gap: '8px' }}
        >
          <Tile
            value="Company"
            description={t('label.companyLevel')}
            sx={{ flex: 1, p: '16px' }}
          />
          <Tile
            value="Account"
            description={t('label.accountLevel')}
            sx={{ flex: 1, p: '16px' }}
          />
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <InputLabel>{t('label.applyCloud')}</InputLabel>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <FormControlLabel
            value="aws"
            onChange={handleCloudChange('aws')}
            control={<Checkbox checked={values.Providers.includes('aws')} />}
            label="AWS"
          />
          <FormControlLabel
            value="ncp"
            onChange={handleCloudChange('ncp')}
            control={<Checkbox checked={values.Providers.includes('ncp')} />}
            label="Naver Cloud"
          />
          <FormControlLabel
            value="nhn"
            onChange={handleCloudChange('nhn')}
            control={<Checkbox checked={values.Providers.includes('nhn')} />}
            label="NHN Cloud"
          />
        </Box>
      </Grid>
      {values.ApplyLevel === 'Account' && (
        <Grid xs={12}>
          <InputLabel>{t('label.accountList')}</InputLabel>
          <SimpleTable
            data={accountData}
            columns={accountColumns}
            maxHeight={145}
            rowSelection={{
              selectedRows,
              onRowSelected,
            }}
          />
        </Grid>
      )}
      <Grid xs={12}>
        <InputLabel>{t('label.billingMethod')}</InputLabel>
        <RadioGroup
          value={billingFixed ? 'Fixed' : 'Ratio'}
          onChange={(e, v) => {
            setBillingFixed(v === 'Fixed');
            if (v === 'Fixed') setFieldValue('PricingUnit', 'KRW');
            if (v === 'Ratio') setFieldValue('PricingUnit', 'PCT');
          }}
          row
          sx={{ gap: '8px' }}
        >
          <Tile
            value="Fixed"
            description={t('label.billingFixed')}
            sx={{ flex: 1, p: '16px' }}
          />
          <Tile
            value="Ratio"
            description={t('label.billingRatio')}
            sx={{ flex: 1, p: '16px' }}
          />
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Cost"
          type="number"
          inputMode="decimal"
          value={values.Cost}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.cost')}
          placeholder={t('holder.cost')}
          error={Boolean(errors.Cost && touched.Cost)}
          InputProps={{
            ...(values.PricingUnit === 'PCT' && {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }),
          }}
          fullWidth
        />
      </Grid>
      {billingFixed && (
        <Grid xs={12}>
          <InputLabel>{t('label.pricingUnit')}</InputLabel>
          <RadioGroup
            value={values.PricingUnit}
            onChange={(e, v) => setFieldValue('PricingUnit', v)}
            row
            sx={{ gap: '8px' }}
          >
            <Tile value="KRW" description="KRW" sx={{ flex: 1, p: '16px' }} />
            <Tile value="USD" description="USD" sx={{ flex: 1, p: '16px' }} />
          </RadioGroup>
        </Grid>
      )}
      <Grid xs={12}>
        <TextField
          name="UsageAmount"
          type="number"
          inputMode="decimal"
          value={values.UsageAmount}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.usageAmount')}
          placeholder={t('holder.usageAmount')}
          error={Boolean(errors.UsageAmount && touched.UsageAmount)}
          fullWidth
        />
      </Grid>
      {!!isApplicable && (
        <Grid xs={12}>
          <InputLabel>{t('label.invoiceOption')}</InputLabel>
          <FormControlLabel
            name="ApplyToInvoice"
            control={
              <Checkbox
                checked={values.ApplyToInvoice ?? false}
                onChange={(e, v) => setFieldValue('ApplyToInvoice', v)}
              />
            }
            label={
              <>
                <InvoiceChip status="Unissued" />
                <span> 청구서에 즉시 적용</span>
              </>
            }
          />
        </Grid>
      )}
    </Grid>
  );
}
