import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateCloudAccount } from '@api/queries/organization';
import FormModal from '@components/modal/form';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

export default function EditNHNAccountModal({
  open,
  onClose,
  item,
}: ModalProps & { item?: NHNAccount }) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.nhn.edit',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useUpdateCloudAccount();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<CreateNHNAccount>({
    initialValues: {
      AccountId: '',
      ParentUID: '',
      DiscountRate: 0,
      Memo: '',
      Options: {
        OrgId: '',
      },
    },
    validationSchema: Yup.object()
      .shape({
        AccountId: Yup.string().defined(),
        ParentUID: Yup.string().defined(),
        DiscountRate: Yup.string().defined(),
        Memo: Yup.string(),
        Options: Yup.object()
          .shape({
            OrgId: Yup.string().defined(),
          })
          .defined(),
      })
      .defined(),
    onSubmit: async (v) => {
      if (UID && item) {
        try {
          await mutateAsync({
            OrganizationId: UID,
            CSP: 'nhn',
            UID: item.UID,
            ...v,
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: {
          AccountId: item.AccountId,
          ParentUID: item.ParentUID,
          DiscountRate: item.DiscountRate,
          Memo: item.Memo,
          Options: {
            OrgId: item.Options.OrgId,
          },
        },
      });
    }
  }, [item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      minHeight={480}
      onSubmit={handleSubmit}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="DiscountRate"
          value={values.DiscountRate}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.DiscountRate && touched.DiscountRate)}
          label="할인율"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          inputMode="decimal"
          autoComplete="off"
          fullWidth
        />
      </Box>
    </FormModal>
  );
}
