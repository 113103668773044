import * as Yup from 'yup';

export function useCustomerContactForm(): UseFormValidation<CreateContact> {
  return {
    initialValues: {
      Name: '',
      Email: '',
      Title: '',
      Department: '',
      Team: '',
      Notes: '',
      Birthday: new Date(),
      PhoneNumber: '',
      Providers: [],
    },
    validationSchema: Yup.object()
      .shape({
        Name: Yup.string().defined(),
        Email: Yup.string().email().defined(),
        Title: Yup.string(),
        Department: Yup.string(),
        Team: Yup.string(),
        Notes: Yup.string(),
        Birthday: Yup.date(),
        PhoneNumber: Yup.string(),
        Providers: Yup.array(Yup.mixed().oneOf(['aws', 'ncp']).defined()),
      })
      .defined(),
  };
}
