import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGetOrganizationSummary } from '@api/queries/organization';
import { CustomerChip } from '@components/styled/chip';

type Props = {
  status: OrganizationStatus;
};

export default function OrgCountByStatus({ status }: Props) {
  const { t } = useTranslation('customer');
  const { data: response } = useGetOrganizationSummary();
  const totalCount = useMemo(() => {
    if (response?.data) {
      return response.data.ByStatus[status];
    }
    return 0;
  }, [response?.data, status]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '16px',
        minHeight: '94px',
      }}
    >
      <CustomerChip status={status} />
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {t('text.numberOfCustomer', { count: totalCount })}
      </Typography>
    </Box>
  );
}
