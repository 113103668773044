import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useCreateCustomerForm(): UseFormValidation<CreateOrganization> {
  const { t: validationT } = useTranslation('validation', {
    keyPrefix: 'settings.company.registrationNumber',
  });
  return {
    initialValues: {
      Name: '',
      Representative: '',
      RegistrationNumber: '',
      BusinessLocation: '',
      Telephone: '',
      Website: '',
      TypeOfBusiness: '',
      ItemOfBusiness: '',
      HeadOfficeLocation: '',
      AWSAccounts: [],
      NCPAccounts: [],
    },
    validationSchema: Yup.object()
      .shape({
        Name: Yup.string().defined(),
        Representative: Yup.string().defined(),
        RegistrationNumber: Yup.string()
          .matches(/^[1-9][0-9-]+[0-9]$/, validationT('length'))
          // .test('validation', validationT('invalid'), (value) => {
          //   if (value) {
          //     const numberMap = value
          //       .replace(/-/gi, '')
          //       .split('')
          //       .map((v) => parseInt(v, 10));
          //     if (numberMap.length === 10) {
          //       const validArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
          //       let chk = 0;
          //       validArr.forEach((v, i) => {
          //         chk += v * numberMap[i];
          //       });
          //       chk += Math.floor((validArr[8] * numberMap[8]) / 10);
          //       return Math.floor(numberMap[9]) === 10 - (chk % 10);
          //     }
          //     return false;
          //   }
          //   return true;
          // })
          .defined(),
        BusinessLocation: Yup.string().defined(),
        Telephone: Yup.string(),
        Website: Yup.string(),
        TypeOfBusiness: Yup.string(),
        ItemOfBusiness: Yup.string(),
        HeadOfficeLocation: Yup.string(),
        AWSAccounts: Yup.array(Yup.string().defined()),
        NCPAccounts: Yup.array(
          Yup.object().shape({
            AccountId: Yup.string().defined(),
            CloudType: Yup.mixed()
              .oneOf(['public', 'finance', 'enterprise'])
              .defined(),
            Memo: Yup.string(),
          }),
        ),
      })
      .defined(),
  };
}
