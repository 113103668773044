import { styled } from '@mui/material/styles';
import { Popper } from '@mui/material';

export const FilterPopper = styled(Popper)(({ theme }) => ({
  borderRadius: '16px',
  minWidth: '300px',
  zIndex: theme.zIndex.modal,
  background: 'rgba(255, 255, 255, 0.98)',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
  backdropFilter: 'blur(3px)',
}));
