import AxiosClient from '@api/client';
import { formatDate } from '@utils/formatter';
import { getFilenameFromHeader } from '@utils/blob';

const axios = AxiosClient.getInstance();
const PREFIX = '/report' as const;

async function getReportList() {
  const { data } = await axios.get<MultipleResponse<ReportSettings>>(
    `${PREFIX}/settings/list`,
  );
  return data;
}

async function getReportById(UID: string) {
  const { data } = await axios.get<SingleResponse<ReportContent>>(
    `${PREFIX}/${UID}`,
  );
  return data;
}

async function getReportSettingsById(UID: string) {
  const { data } = await axios.get<SingleResponse<ReportSettings>>(
    `${PREFIX}/settings/${UID}`,
  );
  return data;
}

async function createReport({
  ChartType,
  Name,
  Memo,
  Providers,
  StartMonth,
  EndMonth,
  Organizations,
  InvoiceStatus,
  OrganizationStatus,
  DisplayOptions,
  NCPSettings,
}: CreateReport) {
  const { data } = await axios.post<SingleResponse<ReportSettings>>(
    `${PREFIX}/settings`,
    {
      ChartType,
      Name,
      Memo,
      Providers,
      StartMonth: formatDate(StartMonth, 'YYYY-MM-01T00:00:00'),
      EndMonth: formatDate(EndMonth, 'YYYY-MM-01T00:00:00'),
      Organizations,
      InvoiceStatus,
      OrganizationStatus,
      DisplayOptions,
      NCPSettings,
    },
  );
  return data;
}

async function updateReport(
  UID: string,
  {
    ChartType,
    Name,
    Memo,
    Providers,
    StartMonth,
    EndMonth,
    Organizations,
    InvoiceStatus,
    OrganizationStatus,
    DisplayOptions,
    NCPSettings,
  }: CreateReport,
) {
  const { data } = await axios.put<SingleResponse<ReportSettings>>(
    `${PREFIX}/settings/${UID}`,
    {
      ChartType,
      Name,
      Memo,
      Providers,
      StartMonth: formatDate(StartMonth, 'YYYY-MM-01T00:00:00'),
      EndMonth: formatDate(EndMonth, 'YYYY-MM-01T00:00:00'),
      Organizations,
      InvoiceStatus,
      OrganizationStatus,
      DisplayOptions,
      NCPSettings,
    },
  );
  return data;
}

async function deleteReport(UID: string) {
  const { data } = await axios.delete<void>(`${PREFIX}/settings/${UID}`);
  return data;
}

async function downloadReportAsExcel({
  UID,
  Options,
}: {
  UID: string;
  Options: Array<ReportDownloadOption>;
}) {
  const { data, headers } = await axios.post(
    `${PREFIX}/${UID}/excel`,
    {
      ReportSetting: UID,
      Options,
    },
    {
      responseType: 'blob',
    },
  );
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

export default {
  getReportList,
  getReportById,
  getReportSettingsById,
  createReport,
  updateReport,
  deleteReport,
  downloadReportAsExcel,
};
