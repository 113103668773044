import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClickAwayListener,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateInvoiceStatus } from '@api/queries/invoice';
import { useToggle } from '@hooks';
import { InvoiceChip } from '@components/styled/chip';
import { INVOICE_PUBLISH_STATE } from '@features/invoice/detail/atom';

type Props = Pick<Invoice, 'Status' | 'InvoiceId'>;
export default function InvoiceStatusControl({ Status, InvoiceId }: Props) {
  const { t } = useTranslation('invoice');
  const [openPopper, togglePopper] = useToggle();
  const setPublishState = useSetRecoilState(INVOICE_PUBLISH_STATE);
  const ref = useRef<HTMLDivElement | null>(null);
  const { mutateAsync, isPending } = useUpdateInvoiceStatus();
  const { values, isValid, dirty, handleSubmit, setFieldValue, resetForm } =
    useFormik<UpdateInvoiceStatus>({
      initialValues: {
        Status: 'Unissued',
      },
      validationSchema: Yup.object().shape({
        Status: Yup.mixed().oneOf(['Unissued', 'Invoiced', 'Paid', 'Overdue']),
      }),
      onSubmit: async (v) => {
        if (Status !== 'Unissued') {
          try {
            await mutateAsync({ ...v, InvoiceId });
            togglePopper();
          } catch (e) {
            console.error(e);
          }
        } else {
          setPublishState((k) => ({ ...k, open: true, Status: v.Status }));
          togglePopper();
        }
      },
    });
  useEffect(() => {
    resetForm({
      values: {
        Status,
      },
    });
  }, [Status, resetForm]);
  useEffect(() => {
    if (!openPopper) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [openPopper, resetForm]);
  return (
    <Box ref={ref} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography color="textSecondary">{t('text.invoiceStatus')}</Typography>
      <InvoiceChip onClick={togglePopper} status={Status} />
      <Popper
        open={openPopper}
        anchorEl={ref.current}
        modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={() => togglePopper()}>
          <Paper component="form" onSubmit={handleSubmit}>
            <RadioGroup
              value={values.Status}
              onChange={(e, v) => setFieldValue('Status', v as InvoiceStatus)}
              sx={{ gap: '16px', pl: '11px', mb: '56px' }}
            >
              {Status === 'Unissued' && (
                <FormControlLabel
                  value="Unissued"
                  control={<Radio />}
                  label={<InvoiceChip status="Unissued" />}
                />
              )}
              <FormControlLabel
                value="Invoiced"
                control={<Radio />}
                label={<InvoiceChip status="Invoiced" />}
              />
              {Status !== 'Unissued' && (
                <FormControlLabel
                  value="Paid"
                  control={<Radio />}
                  label={<InvoiceChip status="Paid" />}
                />
              )}
              {Status !== 'Unissued' && (
                <FormControlLabel
                  value="Overdue"
                  control={<Radio />}
                  label={<InvoiceChip status="Overdue" />}
                />
              )}
            </RadioGroup>
            <LoadingButton
              type="submit"
              size="small"
              color="emphasis1"
              loading={isPending}
              disabled={!isValid || !dirty}
              fullWidth
            >
              {t('button.changeStatus')}
            </LoadingButton>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
