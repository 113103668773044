import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { sortBy, sumBy } from 'lodash';
import { useGetCustomerTrend } from '@api/queries/dashboard';
import { DashboardNotFound } from '@features/dashboard/styled';
import DashboardCustomerChart from '@components/chart/dashboard/customer';

export default function CustomerChart() {
  const { t } = useTranslation('dashboard');
  const { data: response } = useGetCustomerTrend();
  const isDataEmpty = useMemo(() => {
    return !sumBy(response?.data, 'NumberOfCompany');
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.customerTrend')}
      </Typography>
      {!isDataEmpty ? (
        <DashboardCustomerChart
          data={sortBy(response?.data, 'Month').slice(-6)}
        />
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
