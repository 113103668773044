import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NCPAccountService } from '@api/services';
import { NCP_ACCOUNT_KEY } from '@constants/query';

export default function useUpdateAccessAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: NCP_ACCOUNT_KEY.UPDATE_ACCESS_ACCOUNT,
    mutationFn: (v: UpdateAccessAccount) =>
      NCPAccountService.updateAccessAccount(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['NCP_ACCOUNT'] });
    },
  });
}
