import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiAvatar from '@mui/material/Avatar';

const Wrapper = styled('div', {
  name: 'HekaProfile',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})({});

export type ProfileProps = {
  name?: string;
  org?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & (
  | {
      disableAvatar?: false | undefined;
      imageSrc?: string;
    }
  | { disableAvatar?: true; imageSrc?: undefined }
);

const Profile: StyledComponent<ProfileProps> = styled(
  ({ name, org, onClick, imageSrc, disableAvatar }: ProfileProps) => (
    <Wrapper
      className="HekaProfile-root"
      onClick={onClick}
      sx={{
        ...(Boolean(onClick) && {
          cursor: 'pointer',
        }),
      }}
    >
      {Boolean(!disableAvatar) && (
        <MuiAvatar
          alt={name}
          src={imageSrc}
          sx={{ width: '40px', height: '40px' }}
        />
      )}
      <div className="HekaProfile-info">
        {Boolean(name) && <p className="HekaProfile-name">{name}</p>}
        {Boolean(org) && <p className="HekaProfile-org">{org}</p>}
      </div>
    </Wrapper>
  ),
  {
    skipVariantsResolver: true,
    skipSx: true,
  },
)<ProfileProps>();
Profile.displayName = 'HekaProfile';

export default Profile;
