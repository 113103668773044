import { useMutation } from '@tanstack/react-query';
import { AWSCostService } from '@api/services';
import { AWS_COST_KEY } from '@constants/query';

export default function useDownloadAWSCostAsCsv() {
  return useMutation({
    mutationKey: AWS_COST_KEY.DOWNLOAD_COST_AS_CSV,
    mutationFn: (v: AWSCEUsageRequest) => AWSCostService.downloadCostAsCsv(v),
  });
}
