import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Typography,
  MenuList,
  MenuItem as MuiMenuItem,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import PeopleOutline from '@mui/icons-material/PeopleOutline';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Settings from '@mui/icons-material/Settings';
import WorkOutline from '@mui/icons-material/WorkOutline';
import { Profile } from '@heka/theme';
import { useGetCurrentUser } from '@api/queries/user';
import { useGetCompany } from '@api/queries/company';
import { useLogout } from '@api/queries/auth';
import { useCurrentInfo } from '@hooks';

// function MenuItem({}: PropsWithChildren<Pick<MenuItemProps>>)

interface MenuItemProps extends MuiMenuItemProps {
  href?: string;
}
const MenuItem = styled(({ href, ...props }: MenuItemProps) => {
  if (href) {
    return <MuiMenuItem component={Link} to={href} {...props} />;
  }
  return <MuiMenuItem {...props} />;
})<MenuItemProps>({
  color: '#1A1818',
  gap: '8px',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '32px',
  paddingRight: '32px',
  '> svg': {
    color: '#323232',
    width: '24px',
    height: '24px',
  },
  '> a': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#1A1818 !important',
    textDecoration: 'none',
    '> svg': {
      color: '#323232',
      width: '24px',
      height: '24px',
    },
  },
});

export default function UserProfile() {
  const { t } = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { hasRole } = useCurrentInfo();
  const { data: user } = useGetCurrentUser();
  const { mutateAsync } = useLogout();
  const doLogout = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      try {
        await mutateAsync();
      } catch (e) {
        console.error(e);
      }
      setAnchorEl(null);
    },
    [mutateAsync],
  );
  return (
    <Box>
      <Profile
        name={user?.data.FirstName ?? 'Name'}
        onClick={handleOpen}
        disableAvatar
      />
      <Popper
        id={open ? 'global-menu-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 2,
        })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              minWidth: '320px',
              border: 'none',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
              backdropFilter: 'blur(3px)',
              p: 0,
            }}
          >
            <Box sx={{ p: '32px', pb: '24px' }}>
              <Typography color="text.main" fontSize="20px" fontWeight={700}>
                {user?.data.FirstName}
              </Typography>
              <Typography mt="8px">{user?.data.Email}</Typography>
            </Box>
            <MenuList sx={{ pb: '24px' }}>
              <MenuItem href="/settings/profile" onClick={handleClose}>
                <AccountCircleOutlined shapeRendering="geometricPrecision" />
                {t('topBar.profile')}
              </MenuItem>
              {hasRole('ADMIN') && (
                <MenuItem href="/settings/permission" onClick={handleClose}>
                  <PeopleOutline shapeRendering="geometricPrecision" />
                  {t('topBar.permission')}
                </MenuItem>
              )}
              {hasRole('MANAGER') && (
                <MenuItem href="/settings/customer" onClick={handleClose}>
                  <WorkOutline shapeRendering="geometricPrecision" />
                  {t('topBar.account')}
                </MenuItem>
              )}
              {hasRole('ADMIN') && (
                <MenuItem href="/settings/account" onClick={handleClose}>
                  <Settings shapeRendering="geometricPrecision" />
                  {t('topBar.settings')}
                </MenuItem>
              )}
            </MenuList>
            <Box sx={{ pb: '24px' }}>
              <MenuItem
                onClick={doLogout}
                sx={{ color: '#C8003C', '&:hover': { background: 'none' } }}
              >
                <PowerSettingsNew shapeRendering="geometricPrecision" />
                {t('topBar.logout')}
              </MenuItem>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
