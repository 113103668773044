import { useSuspenseQuery } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useGetUsers(isClient: boolean = false) {
  const queryKey = isClient
    ? [...USER_KEY.GET_CLIENT_USERS, isClient]
    : [...USER_KEY.GET_USERS, isClient];
  return useSuspenseQuery({
    queryKey,
    queryFn: ({ queryKey }) => UserService.getUsers(queryKey[2] as boolean),
  });
}
