import { Box } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface IProps {
  items: Array<{
    color: string;
    provider: string;
    amount: number;
  }>;
}
export default function DashboardRevenueChart({ items }: IProps) {
  return (
    <Box sx={{ minHeight: '100%' }}>
      <ResponsiveContainer height={300} aspect={1} style={{ padding: '50px' }}>
        <PieChart width={200} height={200}>
          <Pie
            data={items}
            dataKey="amount"
            cx="50%"
            cy="50%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-270}
            blendStroke
          >
            {items.map((entry, index) => (
              <Cell
                key={`dashboard_revenue_pie_${entry.provider}_${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
