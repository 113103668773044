import { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { BANNER_HEIGHT_STATE } from '@atoms/global/banner';
import { CloudUsageBanner, useCloudUsageBanner } from './components';

export default function Banners() {
  const [showCloudUsage, closeCloudUsage] = useCloudUsageBanner();
  const setBannerHeight = useSetRecoilState(BANNER_HEIGHT_STATE);
  const bannerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setTimeout(() => {
      setBannerHeight(bannerRef.current?.clientHeight ?? 0);
    }, 5);
  }, [setBannerHeight, showCloudUsage]);
  return (
    <Stack ref={bannerRef}>
      <CloudUsageBanner open={showCloudUsage} onClose={closeCloudUsage} />
    </Stack>
  );
}
