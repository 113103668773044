import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import AddNCPAccountModal from '@features/customer/modal/account/ncp/add';
import EditNCPAccountModal from '@features/customer/modal/account/ncp/edit';
import DeleteNCPAccountModal from '@features/customer/modal/account/ncp/delete';
import { useToggle } from '@hooks';
import SimpleTable from '@components/table/simple';
import { CloudTypeChip } from '@components/styled/chip';

export default function CustomerNCPAccount() {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const { data: response } = useGetOrganizationForUI();
  const [openAdd, toggleAdd] = useToggle();
  const [toEdit, setToEdit] = useState<NCPAccount | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  // const tableData = useMemo(() => {
  //   return (
  //     linked?.data
  //       .filter((v) =>
  //         Boolean(customer?.data.AccountIdList.includes(v.LinkedAccountId)),
  //       )
  //       .map((v) => ({
  //         ...v,
  //         LinkedAccountAlias: getAccountAlias(v.LinkedAccountId),
  //         PayerAccountAlias: getAccountAlias(v.PayerAccountId),
  //       })) ?? []
  //   );
  // }, [getAccountAlias, linked?.data, customer?.data]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<NCPAccount>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('customer.memberId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Options.CloudType', {
        id: 'CloudType',
        header: t('invoice.ncp.cloudType'),
        cell: (v) => <CloudTypeChip type={v.getValue() ?? 'enterprise'} />,
      }),
      columnHelper.accessor('Memo', {
        id: 'Memo',
        header: '메모',
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'Delete',
        header: '계정 연동 해제',
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data.CloudAccounts.filter((v) => v.CSP === 'ncp')}
        title={customerT('title.ncpAccount')}
        enableGlobalFilter
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addAccount')}
        </Button>
      </SimpleTable>
      <AddNCPAccountModal open={openAdd} onClose={toggleAdd} />
      <EditNCPAccountModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={toEdit}
      />
      <DeleteNCPAccountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        AccountUID={toDelete}
      />
    </>
  );
}
