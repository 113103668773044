import { useSuspenseQuery } from '@tanstack/react-query';
import { AWSCostService } from '@api/services';
import { AWS_COST_KEY } from '@constants/query';

export default function useGetAWSCostAutocomplete() {
  return useSuspenseQuery({
    queryKey: AWS_COST_KEY.GET_COST_AUTOCOMPLETE,
    queryFn: () => AWSCostService.getCostAutoComplete(),
  });
}
