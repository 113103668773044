import { styled } from '@mui/material/styles';
import MuiSwitch, {
  getSwitchUtilityClass,
  SwitchProps as MuiSwitchProps,
} from '@mui/material/Switch';

interface SwitchProps extends MuiSwitchProps {
  limit?: boolean;
}
export const PermissionSwitch = styled(
  ({ classes, limit, ...props }: SwitchProps) => (
    <MuiSwitch classes={classes} {...props} />
  ),
)<SwitchProps>(({ limit }) => ({
  width: '60px',
  height: '20px',
  padding: 0,
  [`> .${getSwitchUtilityClass('switchBase')}`]: {
    // width: '18px',
    // height: '18px',
    padding: 0,
    color: '#D22E5F',
    ...(limit && { color: '#F7B83D' }),
    '&.Mui-checked': {
      color: '#66BE21',
      transform: 'translateX(40px)',
      [`+.${getSwitchUtilityClass('track')}`]: {
        background: '#66BE2133',
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="0" dy="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
            '#448016',
          )}">허용</text></svg>')`,
          left: 10,
        },
      },
      '&.Mui-disabled': {
        [`+.${getSwitchUtilityClass('track')}`]: {
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="0" dy="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
              '#B8B8B8',
            )}">허용</text></svg>')`,
          },
        },
      },
    },
    '&:not(.Mui-checked)': {
      [`+.${getSwitchUtilityClass('track')}`]: {
        '&:after': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
            '#D22E5F',
          )}">금지</text></svg>')`,
          ...(limit && {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
              '#F7B83D',
            )}">제한</text></svg>')`,
          }),
          right: 10,
        },
      },
      '&.Mui-disabled': {
        [`+.${getSwitchUtilityClass('track')}`]: {
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
              '#B8B8B8',
            )}">금지</text></svg>')`,
            ...(limit && {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="26" viewBox="0 0 26 14"><text x="0" y="12" font-size="14" font-family="Noto Sans KR" textLength="26" fill="${encodeURIComponent(
                '#B8B8B8',
              )}">제한</text></svg>')`,
            }),
          },
        },
      },
    },
    '&.Mui-disabled': {
      color: '#B8B8B8',
      [`+.${getSwitchUtilityClass('track')}`]: {
        background: '#B8B8B866',
        opacity: 1,
      },
    },
  },
  [`> .${getSwitchUtilityClass('track')}`]: {
    opacity: 1,
    background: '#D22E5F33',
    ...(limit && { background: '#F7B83D33' }),
    borderRadius: '40px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 26,
      height: 14,
    },
  },
  [`& .${getSwitchUtilityClass('thumb')}`]: {
    boxShadow: 'none',
    width: '16px',
    height: '16px',
    margin: '2px',
  },
}));
