import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputAdornment,
  InputLabel,
  RadioGroup,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { Tile } from '@heka/theme';

type Props = Pick<
  ReturnType<typeof useFormik>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
> & {
  discount?: boolean;
  scopeOnly?: boolean;
  csp?: CloudProvider;
};

export default function InvoiceServiceForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  discount,
  scopeOnly,
  csp,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.service' });
  const fixedMethod = useMemo(() => {
    return values.PricingUnit !== 'PCT';
  }, [values.PricingUnit]);
  return (
    <Grid container rowSpacing="16px">
      <Grid xs={12}>
        <TextField
          name="ServiceName"
          value={values.ServiceName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={discount ? t('label.discountName') : t('label.serviceName')}
          placeholder={
            discount ? t('holder.discountName') : t('holder.serviceName')
          }
          error={Boolean(errors.ServiceName && touched.ServiceName)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <InputLabel>{t('label.applyLevel')}</InputLabel>
        <RadioGroup
          value={values.ApplyLevel}
          onChange={(e, v) => setFieldValue('ApplyLevel', v)}
          row
          sx={{ gap: '8px' }}
        >
          {(!scopeOnly || values.ApplyLevel === 'Company') && (
            <Tile
              value="Company"
              description={t('label.companyLevel')}
              sx={{ flex: 1, p: '16px' }}
            />
          )}
          {(!scopeOnly || values.ApplyLevel === 'Account') && (
            <Tile
              value="Account"
              description={t('label.accountLevel')}
              sx={{ flex: 1, p: '16px' }}
            />
          )}
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <InputLabel>{t('label.billingMethod')}</InputLabel>
        <RadioGroup
          value={fixedMethod ? 'Fixed' : 'Ratio'}
          onChange={(e, v) => {
            if (v === 'Fixed') setFieldValue('PricingUnit', 'KRW');
            if (v === 'Ratio') setFieldValue('PricingUnit', 'PCT');
          }}
          row
          sx={{ gap: '8px' }}
        >
          <Tile
            value="Fixed"
            description={t('label.billingFixed')}
            sx={{ flex: 1, p: '16px' }}
          />
          <Tile
            value="Ratio"
            description={t('label.billingRatio')}
            sx={{ flex: 1, p: '16px' }}
          />
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Cost"
          type="number"
          inputMode="decimal"
          value={values.Cost}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.cost')}
          placeholder={t('holder.cost')}
          error={Boolean(errors.Cost && touched.Cost)}
          InputProps={{
            ...(values.PricingUnit === 'PCT' && {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }),
          }}
          fullWidth
        />
      </Grid>
      {values.PricingUnit !== 'PCT' && (
        <Grid xs={12}>
          <InputLabel>{t('label.billingMethod')}</InputLabel>
          <RadioGroup
            value={values.PricingUnit}
            onChange={(e, v) => setFieldValue('PricingUnit', v)}
            row
            sx={{ gap: '8px' }}
          >
            <Tile value="KRW" description="KRW" sx={{ flex: 1, p: '16px' }} />
            {csp !== 'ncp' && (
              <Tile value="USD" description="USD" sx={{ flex: 1, p: '16px' }} />
            )}
          </RadioGroup>
        </Grid>
      )}
      <Grid xs={12}>
        <TextField
          name="UsageAmount"
          type="number"
          inputMode="decimal"
          value={values.UsageAmount}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.usageAmount')}
          placeholder={t('holder.usageAmount')}
          error={Boolean(errors.UsageAmount && touched.UsageAmount)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
