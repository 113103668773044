import { useQuery } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useGetCompanyExtraInfo() {
  return useQuery({
    queryKey: COMPANY_KEY.GET_COMPANY_EXTRA_INFO,
    queryFn: () => CompanyService.getExtraInfo(),
    throwOnError: false,
  });
}
