import dayjs from 'dayjs';

function isNull(value: any): boolean {
  return value === null || value === undefined;
}

function isNumber(value: any): value is number {
  if (isNull(value)) {
    return false;
  }
  return !Number.isNaN(Number(value));
}

function formatCurrency(
  value: any,
  currency: 'USD' | 'KRW' = 'USD',
  minimumFraction = 2,
  maximumFraction = 2,
): string | undefined | null {
  if (isNumber(value)) {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: minimumFraction,
      maximumFractionDigits: maximumFraction,
    });
  }
  return value;
}

export function formatKRW(value: any) {
  return formatCurrency(value, 'KRW', 0, 0);
}

export function formatUSD(
  value: any,
  minimumFraction = 2,
  maximumFraction = 2,
) {
  return formatCurrency(value, 'USD', minimumFraction, maximumFraction);
}

export function formatLocaleString(
  value: any,
  minFraction: number | undefined = undefined,
  maxFraction: number | undefined = undefined,
): string | undefined | null {
  if (isNumber(value)) {
    const minimum = minFraction ?? 0;
    const maximum = maxFraction ?? minimum;
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: minimum,
      maximumFractionDigits: maximum,
    });
  }
  return value;
}

export function formatDate(value: any, format = 'YYYY.MM.DD', isUTC = false) {
  if (isNull(value)) {
    return value;
  }
  const date = isUTC ? dayjs.utc(value).local() : dayjs(value);
  return date.format(format);
}

export function checkZeroNumber(value: any, fraction: number = 0): value is 0 {
  if (!isNumber(value)) {
    return true;
  }
  return !Math.trunc(value * Math.pow(10, fraction));
}
