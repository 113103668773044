import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { SupportSection } from '../styled';

type Props = Pick<
  ReturnType<typeof useFormik>,
  'values' | 'errors' | 'touched' | 'handleChange' | 'handleBlur'
>;
export default function SupportForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: Props) {
  const { t } = useTranslation('global');
  return (
    <SupportSection>
      <Typography fontSize="14px" lineHeight="150%" color="text.main">
        {t('modal.support.form.description')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mt: '24px',
        }}
      >
        <TextField
          name="Title"
          value={values.Title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.Title && touched.Title)}
          label={t('modal.support.form.label.subject')}
          placeholder={t('modal.support.form.holder.subject')}
          fullWidth
        />
        <TextField
          name="Detail"
          value={values.Detail}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.Detail && touched.Detail)}
          label={t('modal.support.form.label.content')}
          placeholder={t('modal.support.form.holder.content')}
          minRows={9}
          maxRows={9}
          multiline
          fullWidth
        />
      </Box>
    </SupportSection>
  );
}
