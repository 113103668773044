import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
} from '@mui/material';
import { ListProps as MuiListProps } from '@mui/material/List';
import { useCurrentInfo } from '@hooks';

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  margin: 0,
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

type NavListProps = Pick<MuiListProps, 'classes' | 'children'>;
export const NavList = styled((props) => (
  <MuiList {...props} disablePadding />
))<NavListProps>({
  boxShadow: 'none',
  '> li:not(:first-of-type)': {
    marginTop: '12px',
  },
});

type NavLinkProps = {
  href: string;
  label: string;
  targetRole?: UserRole;
};
export function NavLink({ href, label, targetRole }: NavLinkProps) {
  const { pathname } = useLocation();
  const { hasRole } = useCurrentInfo();
  const isActive = useMemo(() => {
    return pathname.startsWith(href);
  }, [pathname, href]);
  if (!!targetRole && !hasRole(targetRole)) {
    return null;
  }
  return (
    <MuiListItem disablePadding>
      <MuiListItemButton
        component={Link}
        to={href}
        disableRipple
        disableTouchRipple
        sx={{
          px: '16px',
          py: '8px',
          borderRadius: '16px',
          '&:hover': {
            background: 'rgba(206, 207, 222, 0.30)',
          },
          ...(isActive && {
            background: 'rgba(206, 207, 222, 0.30)',
          }),
        }}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            color: '#3A4050',
            letterSpacing: 0,
          }}
        />
      </MuiListItemButton>
    </MuiListItem>
  );
}
