import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import { useGetOrganizationForUI } from '@api/queries/organization';
import SimpleTable from '@components/table/simple';
import { useAWSHelper, useRowSelect } from '@hooks';

type Props = Pick<
  ReturnType<
    typeof useFormik<Omit<CreateContract, 'CSP'> & { CSP: CloudProvider | '' }>
  >,
  'values' | 'setFieldValue'
>;

export default function AWSAccountTable({ values, setFieldValue }: Props) {
  const { t } = useTranslation('table');
  const { data: response } = useGetOrganizationForUI();
  const { getAccountAlias } = useAWSHelper();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CloudAccount>();
    return [
      columnHelper.display({
        id: 'Select',
        header: t('text.select'),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('customer.accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Memo', {
        id: 'Memo',
        header: t('customer.accountAlias'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t]);
  const tableData = useMemo(() => {
    return (
      response?.data.CloudAccounts.filter((v) => v.CSP === 'aws').map((v) => ({
        ...v,
        Memo: getAccountAlias(v.AccountId),
      })) ?? []
    );
  }, [response?.data, getAccountAlias]);
  const [selectedRows, onRowSelected, setSelectedRows] = useRowSelect(
    (value) => {
      const selected = tableData
        .filter((v, i) => value[i.toString()])
        .map((v) => v.AccountId);
      setFieldValue('AccountIdList', selected);
    },
  );
  useEffect(() => {
    setSelectedRows(
      tableData.reduce(
        (prev, current, idx) =>
          Object.assign(prev, {
            [idx]: values.AccountIdList.includes(current.AccountId),
          }),
        {},
      ) ?? {},
    );
  }, [setSelectedRows, tableData, values.AccountIdList]);
  return (
    <SimpleTable
      size="wide"
      columns={columns}
      data={tableData}
      rowSelection={{
        selectedRows,
        onRowSelected,
      }}
    />
  );
}
