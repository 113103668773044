import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { StatusPaper } from './styled';

export default function CustomerStatus() {
  const { t } = useTranslation('dashboard');
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.customerStatus')}
      </Typography>
      <Grid container spacing="16px">
        <Grid xs={6}>
          <StatusPaper status="in-use" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="suspended" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="poc" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="canceled" />
        </Grid>
      </Grid>
    </>
  );
}
