import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Typography, IconButton, Divider, Button } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import {
  useGetLinkedAccounts,
  useGetPayerAccounts,
} from '@api/queries/aws/account';
import SimpleTable from '@components/table/simple';
import { useAWSHelper } from '@hooks';
import {
  DeletePayerAccountModal,
  EditPayerAccountModal,
} from '@features/settings/account/aws';

type PayerAccountTableData = PayerAccount & {
  Alias?: string;
};
type LinkedAccountTableData = AWSLinkedAccount & {
  PayerAccountAlias?: string;
  LinkedAccountAlias?: string;
};

export default function SettingsAWSAccountPage() {
  const { t } = useTranslation('table');
  const { t: settingsT } = useTranslation('settings');
  const [toEdit, setToEdit] = useState<PayerAccount | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { getAccountAlias } = useAWSHelper();
  const { data: payerAccounts } = useGetPayerAccounts();
  const { data: linkedAccounts } = useGetLinkedAccounts();
  const payerData = useMemo<Array<PayerAccountTableData>>(() => {
    return (
      payerAccounts?.data.map((acc) => ({
        ...acc,
        Alias: getAccountAlias(acc.AccountId),
      })) ?? []
    );
  }, [payerAccounts?.data, getAccountAlias]);
  const linkedData = useMemo<Array<LinkedAccountTableData>>(() => {
    return (
      linkedAccounts?.data.map((acc) => ({
        ...acc,
        PayerAccountAlias: getAccountAlias(acc.PayerAccountId),
        LinkedAccountAlias: getAccountAlias(acc.LinkedAccountId),
      })) ?? []
    );
  }, [linkedAccounts?.data, getAccountAlias]);
  const payerColumns = useMemo(() => {
    const columnHelper = createColumnHelper<PayerAccountTableData>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('settings.accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Alias', {
        id: 'Alias',
        header: t('settings.accountAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ARN', {
        id: 'ARN',
        header: t('settings.awsARN'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ReportName', {
        id: 'ReportName',
        header: t('settings.awsCurName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('settings.deleteAccount'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  const linkedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<LinkedAccountTableData>();
    return [
      columnHelper.accessor('PayerAccountId', {
        id: 'PayerAccountId',
        header: t('settings.payerId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('PayerAccountAlias', {
        id: 'PayerAccountAlias',
        header: t('settings.payerAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('LinkedAccountId', {
        id: 'LinkedAccountId',
        header: t('settings.memberId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('LinkedAccountAlias', {
        id: 'LinkedAccountAlias',
        header: t('settings.memberAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrganizationName', {
        id: 'OrganizationName',
        header: t('customer.name'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t]);
  return (
    <Box>
      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="24px"
      >
        {settingsT('title.account.aws')}
      </Typography>
      <SimpleTable
        columns={payerColumns}
        data={payerData}
        title={t('title.settings.account.management')}
        enableGlobalFilter
        enablePagination
      >
        <Button href="/onboard/integrate/aws" startIcon={<Add />}>
          {settingsT('button.addAccount')}
        </Button>
      </SimpleTable>
      <Divider sx={{ mt: '64px', mb: '40px' }} />
      <SimpleTable
        columns={linkedColumns}
        data={linkedData}
        title={t('title.settings.account.member')}
        enableGlobalFilter
        enablePagination
      />
      <EditPayerAccountModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={toEdit}
      />
      <DeletePayerAccountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </Box>
  );
}
