import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { SuspensePaper } from '@components/suspense';
import {
  SettingsPermissionMemberShortTable,
  SettingsPermissionUserDetail,
} from '@features/settings/permission';

export default function SettingsPermissionDetailPage() {
  const { uid } = useParams();
  if (typeof uid === 'undefined') {
    return null;
  }
  return (
    <Box sx={{ flex: 1, display: 'flex', gap: '16px' }}>
      <SuspensePaper
        sx={{ flex: '0 0 424px', px: '24px' }}
        FallbackProps={{ size: 72, sx: { flex: '0 0 312px' } }}
      >
        <SettingsPermissionMemberShortTable />
      </SuspensePaper>
      <SuspensePaper sx={{ flex: 1 }}>
        <SettingsPermissionUserDetail UID={uid} />
      </SuspensePaper>
    </Box>
  );
}
