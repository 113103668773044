import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps as MuiChipProps } from '@mui/material/Chip';

interface CloudProviderChipProps extends Pick<MuiChipProps, 'classes'> {
  provider?: CloudProvider;
}
const CloudProviderChip = styled(
  ({ provider = 'aws', ...props }: CloudProviderChipProps) => {
    const label = useMemo(() => {
      switch (provider) {
        case 'aws':
          return 'AWS';
        case 'ncp':
          return 'Naver Cloud';
        case 'nhn':
          return 'NHN Cloud';
        default:
          return '';
      }
    }, [provider]);
    return (
      <MuiChip {...props} variant="filled" size="selected" label={label} />
    );
  },
)<CloudProviderChipProps>(({ provider = 'aws' }) => ({
  color: '#1A1818',
  ...(provider === 'aws' && {
    color: '#F7B83D',
    background: '#F7B83D33',
  }),
  ...(provider === 'ncp' && {
    color: '#448016',
    background: '#67BE2033',
  }),
  ...(provider === 'nhn' && {
    color: '#1353E2',
    background: '#1353E233',
  }),
}));

export default CloudProviderChip;
