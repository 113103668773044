import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateRange } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useUpdateInvoiceCustom } from '@api/queries/invoice';
import { useGetExchangeRatesByInvoiceId } from '@api/queries/exchange';

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceExchangeRate({ Invoices }: Props) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { data: exchangeRates, setId: setExhcnageInvoiceId } =
    useGetExchangeRatesByInvoiceId(Invoices[0].InvoiceId);
  const { mutateAsync: updateExchangeRate, isPending } =
    useUpdateInvoiceCustom();
  const {
    values,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<
    Pick<
      UpdateInvoiceCustom,
      'ExchangeRateId' | 'ExchangeRateValue' | 'ExchangeRateSource'
    >
  >({
    initialValues: {
      ExchangeRateId: Invoices[0].ExchangeRateId,
      ExchangeRateValue: Invoices[0].ExchangeRateValue,
      ExchangeRateSource: Invoices[0].ExchangeRateSource ?? '',
    },
    validationSchema: Yup.object().shape({
      ExchangeRateId: Yup.string(),
      ExchangeRateValue: Yup.number(),
      ExchangeRateSource: Yup.string(),
    }),
    onSubmit: async (v, helpers) => {
      try {
        const { data } = await updateExchangeRate({
          InvoiceId: Invoices[0].InvoiceId,
          ExchangeRateId: v.ExchangeRateId,
          ExchangeRateValue: Number(v.ExchangeRateValue),
          ExchangeRateSource: v.ExchangeRateSource,
        });
        if (data.length) {
          helpers.resetForm({
            values: {
              ExchangeRateId: data[0].ExchangeRateId,
              ExchangeRateValue: data[0].ExchangeRateValue,
              ExchangeRateSource: data[0].ExchangeRateSource ?? '',
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    validateOnMount: true,
  });
  useEffect(() => {
    if (Invoices.length) {
      setExhcnageInvoiceId(Invoices[0].InvoiceId);
    }
  }, [Invoices, setExhcnageInvoiceId]);
  const handleDateChange = useCallback(
    async (newValue: dayjs.Dayjs | null) => {
      if (newValue && exchangeRates?.data) {
        const matched = exchangeRates.data.find((v) =>
          dayjs(v.UpdatedAt).isSame(newValue, 'date'),
        );
        if (matched) {
          await setFieldValue('ExchangeRateId', matched.UID);
          await setFieldValue('ExchangeRateValue', matched.BuyPrice);
        }
      }
    },
    [exchangeRates?.data, setFieldValue],
  );
  const shouldDisableDate = useCallback(
    (newValue: dayjs.Dayjs): boolean => {
      if (exchangeRates?.data) {
        return !exchangeRates.data.find((v) =>
          dayjs(v.UpdatedAt).isSame(newValue, 'date'),
        );
      }
      return false;
    },
    [exchangeRates?.data],
  );
  const currentDate = useMemo(() => {
    if (exchangeRates?.data?.length) {
      const matched = exchangeRates.data.find(
        (v) => v.UID === values.ExchangeRateId,
      );
      if (matched) {
        return dayjs(matched.UpdatedAt);
      }
      resetForm({
        values: {
          ExchangeRateId: exchangeRates.data[0].UID,
          ExchangeRateValue: exchangeRates.data[0].BuyPrice,
        },
      });
      return dayjs(exchangeRates.data[0].UpdatedAt);
    }
    return dayjs();
  }, [exchangeRates?.data, resetForm, values.ExchangeRateId]);
  const minDate = useMemo(() => {
    if (exchangeRates?.data?.length) {
      return dayjs(
        _.orderBy(exchangeRates.data, 'UpdatedAt', 'asc')[0].UpdatedAt,
      );
    }
    return dayjs().startOf('months');
  }, [exchangeRates?.data]);
  const maxDate = useMemo(() => {
    if (exchangeRates?.data?.length) {
      return dayjs(
        _.orderBy(exchangeRates.data, 'UpdatedAt', 'desc')[0].UpdatedAt,
      );
    }
    return dayjs().endOf('months');
  }, [exchangeRates?.data]);
  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      <Box
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography color="textSecondary" mt="24px">
          {t('text.currencySettings')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <DesktopDatePicker
            value={currentDate}
            onChange={handleDateChange}
            views={['day']}
            format="YYYY.MM.DD"
            slots={{
              openPickerIcon: DateRange,
            }}
            slotProps={{
              textField: {
                label: t('form.label.exchangeRateDate'),
                InputProps: {
                  readOnly: true,
                },
                fullWidth: true,
              },
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 8],
                    },
                  },
                ],
              },
              previousIconButton: {
                sx: {
                  display: 'none',
                },
              },
              nextIconButton: {
                sx: {
                  display: 'none',
                },
              },
            }}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={shouldDisableDate}
          />
          <TextField
            name="ExchangeRateValue"
            value={values.ExchangeRateValue}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('form.label.exchangeRate')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">KRW</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">/ USD 1</InputAdornment>
              ),
            }}
            type="number"
            inputMode="decimal"
            fullWidth
          />
          <TextField
            name="ExchangeRateSource"
            value={values.ExchangeRateSource}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('form.label.exchangeRateSource')}
            placeholder="한국수출입은행"
            type="text"
            inputMode="text"
            autoComplete="off"
            fullWidth
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
        <Button color="sub" onClick={() => resetForm()}>
          {t('button.reset')}
        </Button>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {globalT('button.apply')}
        </LoadingButton>
      </Box>
    </Paper>
  );
}
