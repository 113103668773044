import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { AWSCostService } from '@api/services';
import { AWS_COST_FILTER_STATE } from '@atoms/page/filter';
import { AWS_COST_KEY } from '@constants/query';

export default function useGetAWSCostUsage() {
  const filterValue = useRecoilValue(AWS_COST_FILTER_STATE);
  const rawValue = useMemo<AWSCEUsageRequest>(() => {
    return {
      ...filterValue,
      FromDate: dayjs(filterValue.FromDate).format('YYYY-MM-DDT00:00:00'),
      ToDate: dayjs(filterValue.ToDate).format('YYYY-MM-DDT23:59:59'),
    };
  }, [filterValue]);
  return useSuspenseQuery({
    queryKey: [...AWS_COST_KEY.GET_COST_USAGE, rawValue],
    queryFn: ({ queryKey }) => {
      const value = queryKey[2] as AWSCEUsageRequest;
      if (value.GroupBy === 'TAG' && isEmpty(value.TagKey?.trim())) {
        return null;
      }
      return AWSCostService.getCostUsage(queryKey[2] as AWSCEUsageRequest);
    },
  });
}
