import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { sortBy, sumBy } from 'lodash';
import { useGetAdditionalServiceTrend } from '@api/queries/dashboard';
import { useGetOrganizations } from '@api/queries/organization';
import { DashboardNotFound } from '@features/dashboard/styled';
import DashboardAdditionalServiceChart from '@components/chart/dashboard/additionalService';

export default function ServiceUsageChart() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const theme = useTheme();
  const isFull = useMediaQuery(theme.breakpoints.up('full'));
  const { data: response } = useGetAdditionalServiceTrend();
  const { data: organizations } = useGetOrganizations();
  const isDataEmpty = useMemo(() => {
    return (
      !sumBy(response?.data, 'UseCompany') &&
      !sumBy(response?.data, 'NotUseCompany')
    );
  }, [response?.data]);
  const recentUsage = useMemo(() => {
    const items = sortBy(response?.data, 'Month').slice(-1);
    if (items.length) {
      return items[0].UseCompany;
    }
    return 0;
  }, [response?.data]);
  const totalCustomers = useMemo(() => {
    return Number(organizations?.data?.length);
  }, [organizations?.data]);
  const ratio = useMemo(() => {
    if (totalCustomers > 0) {
      return (recentUsage / totalCustomers) * 100.0;
    }
    return 0;
  }, [recentUsage, totalCustomers]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.additionalServiceAmount')}
      </Typography>
      {!isDataEmpty ? (
        <Grid container columnSpacing={{ full: '40px', zero: '48px' }}>
          <Grid zero={8}>
            <DashboardAdditionalServiceChart
              data={sortBy(response?.data, 'Month').slice(isFull ? -6 : -9)}
            />
          </Grid>
          <Grid
            zero={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography color="textSecondary" fontSize="14px" mb="12px">
                {t('text.additionalServiceAmount.additionalService')}
              </Typography>
              <Typography
                color="#362F26"
                fontSize="28px"
                fontWeight={500}
                mb="8px"
              >
                {globalT('format.number.quantity', { value: recentUsage })}
              </Typography>
              <Typography>
                {t('text.additionalServiceAmount.usagePercent', {
                  value: totalCustomers,
                  ratio: ratio,
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
