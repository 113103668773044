import { ChangeEvent, useCallback } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Switch,
  Typography,
} from '@mui/material';
import {
  useGetOrganizationForUI,
  useUpdateCloudAccount,
  useUpdateOrganization,
} from '@api/queries/organization';
import SimpleTable from '@components/table/simple';
import { Note } from '@heka/theme';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type IProps = {
  UID: string;
};
export default function CustomerEtc({ UID }: IProps) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.etc' });
  const { t: globalT } = useTranslation('global');
  const { data: response, isFetching: isOrgFetching } =
    useGetOrganizationForUI();
  const { mutateAsync: updateOrg, isPending: isOrgLoading } =
    useUpdateOrganization();
  const { mutateAsync: updateAccount, isPending: isAccountLoading } =
    useUpdateCloudAccount();
  const handlePayerDiscountChange = useCallback(
    (AccountUID: string) =>
      async (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        try {
          await updateAccount({
            OrganizationId: UID,
            UID: AccountUID,
            CSP: 'aws',
            Options: {
              ExcludePayerDiscount: checked,
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
    [UID, updateAccount],
  );
  const {
    values,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<OrganizationOptions>({
    initialValues: {
      InvoiceCreation: true,
      AutoStatusChange: false,
    },
    validationSchema: Yup.object().shape({
      InvoiceCreation: Yup.bool(),
      AutoStatusChange: Yup.bool(),
    }),
    onSubmit: async (v) => {
      if (response?.data.Options) {
        try {
          await updateOrg({
            UID,
            Options: {
              ...response.data.Options,
              ...v,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  const tableData = useMemo(() => {
    return response?.data.CloudAccounts.filter((v) => v.CSP === 'aws') ?? [];
  }, [response?.data.CloudAccounts]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AWSAccount>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('text.accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Options.ExcludePayerDiscount', {
        id: 'ExcludePayerDiscount',
        header: t('text.payerDiscountExcluded'),
        cell: (v) => (
          <Switch
            checked={v.getValue()}
            onChange={handlePayerDiscountChange(v.row.original.UID)}
            disabled={isAccountLoading || isOrgFetching}
          />
        ),
      }),
    ];
  }, [t, handlePayerDiscountChange, isOrgFetching, isAccountLoading]);
  useEffect(() => {
    if (response?.data.Options) {
      resetForm({
        values: {
          InvoiceCreation: response.data.Options.InvoiceCreation,
          AutoStatusChange: response.data.Options.AutoStatusChange,
        },
      });
    }
  }, [response?.data.Options, resetForm]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '807px',
        gap: '40px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography>{t('text.invoiceSettings')}</Typography>
        <FormControlLabel
          name="InvoiceCreation"
          value={values.InvoiceCreation}
          onChange={handleChange}
          onBlur={handleBlur}
          control={<Checkbox checked={values.InvoiceCreation} />}
          label={t('label.invoiceCreation')}
          sx={{ alignSelf: 'flex-start' }}
        />
        {!!tableData.length && (
          <Box>
            <InputLabel sx={{ mb: '16px' }}>
              {t('label.excludePayerDiscount')}
            </InputLabel>
            <SimpleTable columns={columns} data={tableData} />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography>{t('text.customerSettings')}</Typography>
        <FormControlLabel
          name="AutoStatusChange"
          value={values.AutoStatusChange}
          onChange={handleChange}
          onBlur={handleBlur}
          control={<Checkbox checked={values.AutoStatusChange} />}
          label={t('label.autoStatusChange')}
          sx={{ alignSelf: 'flex-start' }}
        />
        <Note
          title={t('note.customerStatus.title')}
          content={t('note.customerStatus.content')}
          defaultOpen
          disableCollapse
        />
      </Box>
      <LoadingButton
        type="submit"
        loading={isOrgLoading}
        disabled={!isValid || !dirty}
        sx={{ alignSelf: 'flex-start' }}
      >
        {globalT('button.save')}
      </LoadingButton>
    </Box>
  );
}
