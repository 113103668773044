import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationService } from '@api/services';
import { NOTIFICATION_KEY } from '@constants/query';

export default function useUpdateNotification() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: NOTIFICATION_KEY.UPDATE_NOTIFICATION,
    mutationFn: (v: UpdateNotification) =>
      NotificationService.updateNotification(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['NOTIFICATION'] });
    },
  });
}
