import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useAddDepositDetail() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.ADD_DEPOSIT_DETAIL,
    mutationFn: ({ UID, ...v }: CreateDepositDetail & { UID: string }) =>
      OrganizationService.addDepositDetail(UID, v),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
    },
  });
}
