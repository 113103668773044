import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGetNHNSalesGraph } from '@api/queries/nhn/sales';
import SalesMonthlyChart from '@components/chart/sales/monthly';

export default function NHNSalesManagementChart() {
  const { t } = useTranslation('sales', { keyPrefix: 'nhn' });
  const { data: response } = useGetNHNSalesGraph();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '24px',
        }}
      >
        <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
          {t('title.chart')}
        </Typography>
      </Box>
      <SalesMonthlyChart csp="nhn" data={response?.data} />
    </>
  );
}
