import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import FormModal from '@components/modal/form';
import CustomerDepositForm, {
  useCustomerDepositForm,
} from '@features/customer/form/deposit';
import { useAddDepositDetail } from '@api/queries/organization';

type Props = {
  UID: string;
};
export default function AddDepositModal({
  open,
  onClose,
  UID,
}: ModalProps & Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.deposit.add' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useAddDepositDetail();
  const { initialValues, validationSchema } = useCustomerDepositForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          ...v,
          UID,
          Amount: Number(v.Amount),
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      minHeight="560px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerDepositForm {...formik} />
    </FormModal>
  );
}
