import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Typography, IconButton } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Link } from '@heka/theme';
import SimpleTable from '@components/table/simple';
import { TableEmptyState } from '@components/styled/state';

type Props = {
  UID: string;
};
export default function CustomerManager({ UID }: Props) {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const { t: globalT } = useTranslation('global');
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<User>();
    return [
      columnHelper.accessor('FirstName', {
        id: 'FirstName',
        header: t('general.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Email', {
        id: 'Email',
        header: t('general.email'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('LastLogin', {
        id: 'LastLogin',
        header: t('settings.lastLogin'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Role', {
        id: 'Role',
        header: t('general.role'),
        cell: (v) => globalT(`role.${v.getValue()}`),
      }),
      columnHelper.display({
        id: 'Details',
        header: t('general.details'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            href={`/settings/permission/${v.row.original.UID}`}
          >
            <KeyboardArrowRight />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t, globalT]);
  return (
    <>
      <Box sx={{ flex: 1 }}>
        <SimpleTable
          columns={columns}
          data={[]}
          title={customerT('title.manager')}
          emptyState={
            <TableEmptyState sx={{ gap: '20px' }}>
              <Typography
                color="text.main"
                align="center"
                dangerouslySetInnerHTML={{
                  __html: customerT('table.empty.manager'),
                }}
              />
              <Link size="comfort" href="/settings/permission">
                {customerT('table.link.manager')}
              </Link>
            </TableEmptyState>
          }
          enableGlobalFilter
        />
      </Box>
      {/*{Boolean(response?.data?.length) && (*/}
      {/*  <Box sx={{ textAlign: 'right' }}>*/}
      {/*    <Link size="comfort" color="calm" href="/settings/permission">*/}
      {/*      팀 멤버 초대 및 관리*/}
      {/*    </Link>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </>
  );
}
