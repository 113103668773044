import * as Yup from 'yup';

export function useSupportForm(): UseFormValidation<SupportEmail> {
  return {
    initialValues: {
      Title: '',
      Detail: '',
      PageUrl: '',
    },
    validationSchema: Yup.object().shape({
      Title: Yup.string().defined(),
      Detail: Yup.string(),
      PageUrl: Yup.string().defined(),
    }),
  };
}
