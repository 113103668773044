import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate } from '@utils/formatter';
import { calculateDataMax, formatChartAxis } from '@utils/chart';

function TooltipItem({
  label,
  value,
  background,
}: {
  label: string;
  value: number;
  background: string;
}) {
  const { t } = useTranslation('global', { keyPrefix: 'format' });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
        <Box
          sx={{
            display: 'block',
            width: '12px',
            height: '12px',
            borderRadius: '999px',
            mr: '8px',
            background,
          }}
        />
        <Typography color="textSecondary" fontSize="14px">
          {label}
        </Typography>
      </Box>
      <Typography fontSize="14px" fontWeight={500}>
        {t('currency.krw', { value: value })}
      </Typography>
    </Box>
  );
}

interface IProps {
  csp: 'nhn' | 'ncp';
  data?: Array<SalesGraphItem>;
  disableStroke?: boolean;
  height?: number;
}

export default function SalesMonthlyChart({
  csp,
  data = [],
  disableStroke = false,
  height,
}: IProps) {
  const { t } = useTranslation('sales');
  return (
    <Box>
      <ResponsiveContainer width="100%" height={height ?? 421}>
        <BarChart data={data} barSize={32}>
          <XAxis
            dataKey="Month"
            stroke="#CECFDE"
            strokeWidth={disableStroke ? 0 : 0.5}
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
          />
          <YAxis
            scale="linear"
            stroke="#CECFDE"
            strokeWidth={disableStroke ? 0 : 0.5}
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={8}
            tickFormatter={formatChartAxis}
            domain={[0, calculateDataMax]}
          />
          {!disableStroke && (
            <CartesianGrid
              vertical={false}
              stroke="#E0E0E0"
              strokeWidth={0.5}
            />
          )}
          <defs>
            <linearGradient
              id="colorSalesPurchaseGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#7590EF" />
              <stop offset="1" stopColor="#78C1EB" />
            </linearGradient>
            <linearGradient
              id="colorSalesSupplyGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#F26B94" />
              <stop offset="1" stopColor="#E9A2D9" />
            </linearGradient>
            <linearGradient
              id="colorSalesMarginGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#B9DEAC" />
              <stop offset="1" stopColor="#DBE9A6" />
            </linearGradient>
          </defs>
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => {
              if (!active || !payload?.length) {
                return null;
              }
              const purchase = Number(
                payload.find((v) => v.dataKey === 'TotalPurchaseAmount')?.value,
              );
              const supply = Number(
                payload.find((v) => v.dataKey === 'TotalSupplyAmount')?.value,
              );
              const margin = Number(
                payload.find((v) => v.dataKey === 'TotalMarginAmount')?.value,
              );
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    borderRadius: '24px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                    backdropFilter: 'blur(3px)',
                    p: '24px',
                  }}
                >
                  <Typography color="textSecondary" fontSize="14px" mb="8px">
                    {formatDate(label, 'YYYY.MM')}
                  </Typography>
                  {csp === 'nhn' && (
                    <TooltipItem
                      label={t(`${csp}.text.purchase`)}
                      value={purchase}
                      background="linear-gradient(180deg, #7590EF 0%, #78C1EB 100%)"
                    />
                  )}
                  <TooltipItem
                    label={t(`${csp}.text.supply`)}
                    value={supply}
                    background="linear-gradient(180deg, #F26B94 0%, #E9A2D9 100%)"
                  />
                  <TooltipItem
                    label={t(`${csp}.text.margin`)}
                    value={margin}
                    background="linear-gradient(180deg, #B9DEAC 0%, #DBE9A6 100%)"
                  />
                </Box>
              );
            }}
          />
          {csp === 'nhn' && (
            <Bar
              dataKey="TotalPurchaseAmount"
              fill="url(#colorSalesPurchaseGradient)"
              radius={8}
            />
          )}
          <Bar
            dataKey="TotalSupplyAmount"
            fill="url(#colorSalesSupplyGradient)"
            radius={8}
          />
          <Bar
            dataKey="TotalMarginAmount"
            fill="url(#colorSalesMarginGradient)"
            radius={8}
          />
        </BarChart>
      </ResponsiveContainer>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '16px',
          rowGap: '8px',
          mt: '40px',
          ml: '64px',
        }}
      >
        {csp === 'nhn' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Box
              sx={{
                display: 'block',
                width: '12px',
                height: '12px',
                borderRadius: '999px',
                background: 'linear-gradient(180deg, #7590EF 0%, #78C1EB 100%)',
              }}
            />
            <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
              {t(`${csp}.text.purchase`)}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'block',
              width: '12px',
              height: '12px',
              borderRadius: '999px',
              background: 'linear-gradient(180deg, #F26B94 0%, #E9A2D9 100%)',
            }}
          />
          <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
            {t(`${csp}.text.supply`)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'block',
              width: '12px',
              height: '12px',
              borderRadius: '999px',
              background: 'linear-gradient(180deg, #B9DEAC 0%, #DBE9A6 100%)',
            }}
          />
          <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
            {t(`${csp}.text.margin`)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
