import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { Box, Typography, IconButton } from '@mui/material';
import { Edit, MonetizationOn, Payment } from '@mui/icons-material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link, Search } from '@heka/theme/src';
import DepositInvoiceModal from '@features/customer/modal/deposit/invoice';
import EditDepositModal from '@features/customer/modal/deposit/edit';
import { useToggle } from '@hooks';

function DepositItem({
  history,
  details,
  enableEdit,
  OrganizationId,
}: {
  history: DepositHistory;
  details?: Array<DepositDetail>;
  enableEdit?: boolean;
  OrganizationId?: string;
}) {
  const { t } = useTranslation('table');
  const [openInvoice, toggleInvoice] = useToggle();
  const [selectedDetail, setSelectedDetail] = useState<
    DepositDetail | undefined
  >(undefined);
  const findDepositDetail = useCallback(
    (DepositId?: string) => {
      if (DepositId && details?.length) {
        return details.find((v) => v.UID === DepositId);
      }
      return undefined;
    },
    [details],
  );
  const handleDepositDetail = useCallback(
    (DepositId?: string) => {
      if (DepositId) {
        setSelectedDetail(findDepositDetail(DepositId));
      }
    },
    [findDepositDetail],
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: '16px', py: '24px' }}>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '999px',
          color: 'text.secondary',
          background: 'rgba(206, 207, 222, 0.20)',
          p: '8px',
        }}
      >
        {history.ItemType === 'Deposit' ? (
          <Payment sx={{ width: '24px', height: '24px' }} />
        ) : (
          <MonetizationOn sx={{ width: '24px', height: '24px' }} />
        )}
      </Box>
      <Typography color="text.main" px="16px">
        {history.ItemType === 'Deposit'
          ? findDepositDetail(history.DepositId)?.Name ?? '선납금 입금'
          : '선납금 사용'}
      </Typography>
      <Box sx={{ flex: 1, px: '16px' }}>
        {history.ItemType === 'Used' && (
          <Link color="calm" href={`/invoice/${history.InvoiceId}`}>
            {t('customer.invoiceLink', { invoiceId: history.InvoiceId })}
          </Link>
        )}
      </Box>
      <Box sx={{ px: '16px' }}>
        <Typography
          color={history.ItemType === 'Deposit' ? '#0983C7' : '#C8003C'}
          fontWeight={500}
          align="right"
          mb="12px"
        >
          {history.ItemType === 'Deposit' &&
            t('customer.depositPlusValue', { value: history.Amount })}
          {history.ItemType === 'Used' &&
            t('customer.depositMinusValue', { value: history.Amount })}
        </Typography>
        <Typography color="textSecondary" fontSize="14px" align="right">
          {t('customer.depositValue', { value: history.Remaining })}
        </Typography>
      </Box>
      {Boolean(enableEdit) && (
        <>
          <IconButton
            color="calm"
            onClick={
              history.ItemType === 'Deposit'
                ? () => handleDepositDetail(history.DepositId)
                : toggleInvoice
            }
            sx={{ mx: '4px' }}
          >
            <Edit />
          </IconButton>
          <EditDepositModal
            open={typeof selectedDetail !== 'undefined'}
            onClose={() => setSelectedDetail(undefined)}
            detail={selectedDetail}
            OrganizationId={OrganizationId}
          />
          <DepositInvoiceModal
            open={openInvoice}
            onClose={toggleInvoice}
            history={history}
          />
        </>
      )}
    </Box>
  );
}

export default function DepositTable({
  children,
  columns,
  data,
  emptyState,
  enableEdit = false,
  DepositDetail = [],
  OrganizationId,
}: PropsWithChildren<DepositTableProps<DepositHistory>>) {
  const { t } = useTranslation('table');
  const [filterValue, setFilterValue] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  useEffect(() => {
    const timeout = setTimeout(() => {
      setGlobalFilter(filterValue);
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);
  const fuzzyFilter: FilterFn<any> = useCallback(
    (row, columnId, filterValue, addMeta) => {
      const itemRank = rankItem(row.getValue(columnId), filterValue);
      addMeta({ itemRank });
      return itemRank.passed;
    },
    [],
  );
  const { getRowModel } = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  });
  const rows = getRowModel().rows;
  const tableData = useMemo<
    Record<string, Record<string, DepositHistory[]>>
  >(() => {
    const filtered: Record<string, any> = {};
    const years: Record<string, any> = rows.reduce(
      (arg: Record<string, any>, row) => {
        const year = dayjs(row.original.UsageDate).format('YYYY');
        if (!arg[year]) {
          arg[year] = [];
        }
        arg[year].push(row.original);
        return arg;
      },
      {},
    );
    Object.keys(years).forEach((year) => {
      const date_list = years[year].reduce((dates: any, row: any) => {
        const date = dayjs(row.UsageDate).format('MM.DD');
        if (!dates[date]) {
          dates[date] = [];
        }
        dates[date].push(row);
        return dates;
      }, {});
      filtered[year] = _(date_list)
        .toPairs()
        .sortBy(0)
        .reverse()
        .fromPairs()
        .value();
    });
    return filtered;
  }, [rows]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: '8px',
        }}
      >
        <Search
          value={filterValue ?? ''}
          onChange={(e) => setFilterValue(e.target.value)}
          placeholder={t('holder.search')}
          type="search"
          inputMode="text"
          autoComplete="off"
        />
        {Boolean(children) && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {children}
          </Box>
        )}
      </Box>
      {(Boolean(data?.length) || Boolean(!emptyState)) &&
        Object.keys(tableData)
          .reverse()
          .map((year) => (
            <Box key={`deposit-table-item-year-${year}`}>
              <Typography color="text.main" p="16px">
                {year}
              </Typography>
              {Object.keys(tableData[year]).map((date) => (
                <Box key={`deposit-table-item-date-${date}`}>
                  <Typography
                    color="textSecondary"
                    fontSize="14px"
                    p="16px"
                    sx={{ background: 'rgba(224, 224, 224, 0.10)' }}
                  >
                    {date}
                  </Typography>
                  {tableData[year][date].map((history) => (
                    <DepositItem
                      key={`deposit-history-item-${year}-${date}-${
                        history.ItemType === 'Used'
                          ? history.InvoiceId
                          : history.DepositId
                      }`}
                      history={history}
                      details={DepositDetail}
                      enableEdit={enableEdit}
                      OrganizationId={OrganizationId}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          ))}
      {Boolean(!data?.length) && Boolean(emptyState) && (
        <Box sx={{ mt: '16px' }}>{emptyState}</Box>
      )}
    </Box>
  );
}
