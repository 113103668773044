import { useMutation } from '@tanstack/react-query';
import { ReportService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useDownloadReportAsExcel() {
  return useMutation({
    mutationKey: INVOICE_KEY.DOWNLOAD_INVOICE_AS_EXCEL,
    mutationFn: (v: { UID: string; Options: Array<ReportDownloadOption> }) =>
      ReportService.downloadReportAsExcel(v),
  });
}
