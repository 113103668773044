import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateAccessAccount } from '@api/queries/ncp/account';
import FormModal from '@components/modal/form';

type Props = {
  item?: AccessAccount;
};
export default function EditAccessAccountModal({
  open,
  onClose,
  item,
}: ModalProps & Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.account.ncp.edit',
  });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateAccessAccount();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdateAccessAccount>({
    initialValues: {
      UID: '',
      AccountName: '',
      AccountId: '',
      CloudType: 'enterprise',
      AccessKey: '',
      SecretKey: '',
    },
    validationSchema: Yup.object().shape({
      UID: Yup.string().defined(),
      AccessKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{20}$/)
        .defined(),
      SecretKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{40}$/)
        .defined(),
    }),
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: item,
      });
    }
  }, [item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!item || !isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="AccessKey"
          value={values.AccessKey}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.AccessKey && touched.AccessKey)}
          label="Access Key"
          type="password"
          inputMode="text"
          autoComplete="one-time-code"
        />
        <TextField
          name="SecretKey"
          value={values.SecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.SecretKey && touched.SecretKey)}
          label="Secret Key"
          type="password"
          inputMode="text"
          autoComplete="one-time-code"
        />
      </Box>
    </FormModal>
  );
}
