import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Typography, IconButton, Checkbox } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import SimpleTable from '@components/table/simple';
import { TableEmptyState } from '@components/styled/state';
import AddContactModal from '@features/customer/modal/contact/add';
import { useToggle } from '@hooks';
import { useGetOrganizationForUI } from '@api/queries/organization';
import EditContactModal from '@features/customer/modal/contact/edit';
import DeleteContactModal from '@features/customer/modal/contact/delete';

type Props = {
  UID: string;
};
export default function CustomerContact({ UID }: Props) {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const { data: response } = useGetOrganizationForUI();
  const [addOpen, toggleAdd] = useToggle();
  const [toEdit, setToEdit] = useState<Contact | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Contact>();
    return [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('general.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Department', {
        id: 'Department',
        header: t('customer.department'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Title', {
        id: 'Title',
        header: t('customer.title'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Email', {
        id: 'Email',
        header: t('general.email'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('PhoneNumber', {
        id: 'PhoneNumber',
        header: t('general.phone'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Notes', {
        id: 'Notes',
        header: t('general.memo'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'AWS',
        header: 'AWS',
        cell: (v) => (
          <Checkbox
            checked={v.row.original.Providers.includes('aws')}
            disabled
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'NCP',
        header: 'Naver Cloud',
        cell: (v) => (
          <Checkbox
            checked={v.row.original.Providers.includes('ncp')}
            disabled
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('general.delete'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, []);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data.Contact}
        title={customerT('title.contact')}
        emptyState={
          <TableEmptyState sx={{ gap: '32px' }}>
            <Typography color="text.main">
              {customerT('table.empty.contact')}
            </Typography>
            <Button color="sub" onClick={toggleAdd} startIcon={<Add />}>
              {customerT('button.addContact')}
            </Button>
          </TableEmptyState>
        }
        enableGlobalFilter
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addContact')}
        </Button>
      </SimpleTable>
      <AddContactModal open={addOpen} onClose={toggleAdd} UID={UID} />
      <EditContactModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={toEdit}
      />
      <DeleteContactModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </>
  );
}
