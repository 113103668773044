import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { NHNSalesService } from '@api/services';
import { NHN_SALES_FILTER_STATE } from '@atoms/page/filter';
import { NHN_SALES_KEY } from '@constants/query';

export default function useDownloadNHNSalesAsExcel() {
  const filter = useRecoilValue(NHN_SALES_FILTER_STATE);
  return useMutation({
    mutationKey: NHN_SALES_KEY.DOWNLOAD_SALES_AS_EXCEL,
    mutationFn: () => NHNSalesService.downloadNHNSalesAsExcel(filter),
  });
}
