import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useUpdateInvoiceCloudUsage() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: INVOICE_KEY.UPDATE_INVOICE_CLOUD_USAGE,
    mutationFn: ({
      InvoiceId,
      CSP,
      ...v
    }: UpdateInvoiceCloudUsage & { InvoiceId: string; CSP: CloudProvider }) =>
      InvoiceService.updateInvoiceCloudUsage(InvoiceId, CSP, v),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: INVOICE_KEY.GET_INVOICES });
      queryClient.invalidateQueries({
        queryKey: INVOICE_KEY.GET_INVOICE_LIST_BY_COMPANY_ID,
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...INVOICE_KEY.GET_INVOICE_DETAIL,
          variables.InvoiceId,
        ],
      });queryClient.invalidateQueries({
        queryKey: [
          ...INVOICE_KEY.GET_INVOICES_BY_INVOICE_ID,
          variables.InvoiceId,
        ],
      });
      variables.CloudUsage.forEach((v) =>
        queryClient.invalidateQueries({
          queryKey: [
            ...INVOICE_KEY.GET_INVOICE_BY_ACCOUNT_ID,
            variables.InvoiceId,
            v.AccountId,
          ],
        }),
      );
    },
  });
}
