import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export function useCustomerContractForm(): UseFormValidation<
  Omit<CreateContract, 'CSP'> & { CSP: CloudProvider | '' }
> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      CSP: '',
      StartDate: new Date(),
      EndDate: new Date(),
      AccountIdList: [],
      DiscountRate: 0,
      Memo: '',
      EnableNotification: false,
      EnableEmail: false,
      ReminderDays: 30,
    },
    validationSchema: Yup.object()
      .shape({
        CSP: Yup.mixed().oneOf(['aws', 'ncp', 'nhn']).defined(),
        StartDate: Yup.date().defined(),
        EndDate: Yup.date().defined(),
        AccountIdList: Yup.array(Yup.string().defined()),
        DiscountRate: Yup.number()
          .integer()
          .min(0, t('common.format.percent'))
          .max(100, t('common.format.percent'))
          .defined(t('common.required.base')),
        Memo: Yup.string(),
        EnableNotification: Yup.bool(),
        EnableEmail: Yup.bool(),
        ReminderDays: Yup.number(),
      })
      .defined(),
  };
}
