import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useUploadStamp() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: COMPANY_KEY.UPLOAD_COMPANY_EXTRA_INFO,
    mutationFn: (v: File) => CompanyService.uploadExtraInfo(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['COMPANY'] });
    },
  });
}
