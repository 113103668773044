import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useGetCurrentUser(suspense?: boolean) {
  const useQueryFn = suspense ? useSuspenseQuery : useQuery;
  return useQueryFn({
    queryKey: USER_KEY.GET_CURRENT_USER,
    queryFn: () => UserService.getCurrentUser(),
    staleTime: 5000,
  });
}
