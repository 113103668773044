import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { styled, SxProps, Theme } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { Add, Restore } from '@mui/icons-material';
import { formatKRW } from '@utils/formatter';
import { INVOICE_EDIT_STATE } from './atom';
import { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion/Accordion';
import { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary/AccordionSummary';
import { AccordionDetailsProps as MuiAccordionDetailsProps } from '@mui/material/AccordionDetails/AccordionDetails';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { CloudProviderChip } from '@components/styled/chip';

export const Section = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '56px',
  // '&:not(:last-of-type)': {
  //   borderBottom: '1px solid #CECFDE',
  // },
});

type InvoiceSectionProps = {
  id?: string;
  label?: TypographyProps['children'];
  extra?: ReactNode;
  sx?: SxProps<Theme>;
};

export function InvoiceSection({
  children,
  id,
  label,
  extra,
  sx,
}: PropsWithChildren<InvoiceSectionProps>) {
  return (
    <Section id={id} sx={sx}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          ml: '-8px',
        }}
      >
        <Typography
          component="h2"
          sx={{
            display: 'inline-block',
            fontSize: '14px',
            color: 'text.main',
            px: '16px',
            py: '10px',
            background: '#FFEF5D',
            borderRadius: '40px',
          }}
        >
          {label}
        </Typography>
        {!!extra && extra}
      </Box>
      {children}
    </Section>
  );
}

interface InvoiceSectionHeaderProps {
  title: ReactNode;
  onRestore?: () => void;
}
export function InvoiceSectionHeader({
  title,
  onRestore,
}: InvoiceSectionHeaderProps) {
  const { t } = useTranslation('global');
  const isEdit = useRecoilValue(INVOICE_EDIT_STATE);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '32px',
        mb: '24px',
      }}
    >
      <Typography fontSize="18px" fontWeight={500}>
        {title}
      </Typography>
      {isEdit && typeof onRestore !== 'undefined' && (
        <Button color="sub" onClick={onRestore} startIcon={<Restore />}>
          {t('button.restore')}
        </Button>
      )}
    </Box>
  );
}

interface InvoiceAccountHeaderProps {
  accountId: string;
  totalCost?: number;
  onEdit?: () => void;
}
export function InvoiceAccountHeader({
  accountId,
  totalCost,
  onEdit,
}: InvoiceAccountHeaderProps) {
  const { t } = useTranslation('global');
  const isEdit = useRecoilValue(INVOICE_EDIT_STATE);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Typography
          sx={{
            background: '#E0E0E0',
            borderRadius: '8px',
            p: '8px',
          }}
        >
          {accountId}
        </Typography>
        {typeof totalCost !== 'undefined' && (
          <Typography color="textSecondary">{formatKRW(totalCost)}</Typography>
        )}
      </Box>
      {isEdit && typeof onEdit !== 'undefined' && (
        <Button color="emphasis1" onClick={onEdit}>
          {t('button.edit')}
        </Button>
      )}
    </Box>
  );
}

type AccordionDetailsProps = MuiAccordionDetailsProps;
export const AccordionDetails = styled(
  MuiAccordionDetails,
)<AccordionDetailsProps>({
  padding: 0,
});

type ServiceAccordionWrapperProps = Pick<
  MuiAccordionProps,
  'children' | 'classes' | 'expanded'
>;
const ServiceAccordionWrapper = styled(
  ({ children, expanded, ...props }: ServiceAccordionWrapperProps) => (
    <MuiAccordion expanded={expanded} elevation={0} disableGutters {...props}>
      {children}
    </MuiAccordion>
  ),
)<ServiceAccordionWrapperProps>(({ expanded }) => ({
  padding: 0,
  '&::before': {
    height: 0,
  },
  border: '1px solid #00000000',
  borderRadius: 0,
  ...(Boolean(expanded) && {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  }),
}));

type ServiceAccordionSummaryProps = Pick<
  MuiAccordionSummaryProps,
  'classes' | 'children'
>;
const ServiceAccordionSummary = styled(
  MuiAccordionSummary,
)<ServiceAccordionSummaryProps>(() => ({
  // flexDirection: 'row-reverse',
  gap: '40px',
  background: '#E0E0E033',
  padding: '16px',
  cursor: 'default !important',
  [`> .${accordionSummaryClasses.content}`]: {
    margin: '4px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '48px',
  },
}));

type ServiceAccordionProps = {
  csp: CloudProvider;
  accountId: string;
  cost: number;
  count: number;
  onAdd: () => void;
};
export function ServiceAccordion({
  csp,
  accountId,
  cost,
  count,
  onAdd,
  children,
}: PropsWithChildren<ServiceAccordionProps>) {
  const { t } = useTranslation('invoice');
  const accountName = useMemo(() => {
    if (accountId === 'COMMON') {
      return t('text.invoiceCommonTitle');
    }
    return csp !== 'nhn' ? t('text.accountId', { accountId }) : accountId;
  }, [csp, accountId]);
  return (
    <ServiceAccordionWrapper expanded={Boolean(count)}>
      <ServiceAccordionSummary>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <CloudProviderChip provider={csp} />
          <Typography>{accountName}</Typography>
          <Typography color="textSecondary" ml="8px">
            {formatKRW(cost)}
          </Typography>
        </Box>
        <IconButton size="small" color="sub" onClick={onAdd}>
          <Add />
        </IconButton>
      </ServiceAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </ServiceAccordionWrapper>
  );
}
