import type { ReactNode } from 'react';
import { Box, Typography, Dialog } from '@mui/material';
import { getDialogUtilityClass } from '@mui/material/Dialog';

interface SimpleModalProps extends ModalProps {
  title: string;
  Actions: ReactNode;
  description?: string;
  maxWidth?: string | number;
  minHeight?: string | number;
  enableBorder?: boolean;
}

export default function SimpleModal({
  open,
  onClose,
  title,
  Actions,
  description,
  maxWidth,
  minHeight,
  enableBorder,
}: SimpleModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        [`> .${getDialogUtilityClass('container')} > .${getDialogUtilityClass(
          'paper',
        )}`]: {
          maxWidth: maxWidth ?? '352px',
          minHeight: minHeight ?? '320px',
          ...(enableBorder && {
            border: '1px solid #C8003C',
          }),
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '40px',
        }}
      >
        <Typography
          color="text.main"
          fontSize="16px"
          fontWeight={500}
          letterSpacing="0.64px"
        >
          {title}
        </Typography>
        {typeof description !== 'undefined' && (
          <Typography
            color="text.main"
            fontSize="14px"
            lineHeight="150%"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <Box sx={{ display: 'flex', gap: '16px', '> *': { flex: 1 } }}>
          {Actions}
        </Box>
      </Box>
    </Dialog>
  );
}
