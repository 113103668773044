import * as Yup from 'yup';

export type CreateUserForm = {
  Type: 'single' | 'multiple' | '';
  Users: Array<Pick<CreateUser, 'Email' | 'FirstName'>>;
  Role: UserRole | '';
  Organizations: User['Organizations'];
};

export function useCreateUserForm(
  isClient = false,
): UseFormValidation<CreateUserForm> {
  return {
    initialValues: {
      Type: '',
      Users: [
        {
          Email: '',
          FirstName: '',
        },
      ],
      Role: isClient ? 'CLIENT_USER' : '',
      Organizations: [],
    },
    validationSchema: Yup.object().shape({
      Type: Yup.mixed().oneOf(['single', 'multiple']).defined(),
      Users: Yup.array()
        .of(
          Yup.object()
            .shape({
              Email: Yup.string().email().defined(),
              FirstName: Yup.string().defined(),
            })
            .defined(),
        )
        .min(1)
        .defined(),
      Role: Yup.mixed<UserRole>()
        .oneOf(['ADMIN', 'MANAGER', 'CLIENT_USER'])
        .defined(),
      Organizations: Yup.array().of(Yup.string()).defined(),
    }),
  };
}
