import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useUserInitialPasswordForm(): UseFormValidation<
  InitialPasswordRequest & { Confirm: string }
> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      UID: '',
      Token: '',
      Email: '',
      Password: '',
      Confirm: '',
    },
    validationSchema: Yup.object().shape({
      UID: Yup.string().defined(),
      Token: Yup.string().defined(),
      Email: Yup.string()
        .email(t('auth.email'))
        .defined(t('common.required.base')),
      Password: Yup.string()
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          t('auth.password.special'),
        )
        .min(8, t('auth.password.length'))
        .defined(t('common.required.base')),
      Confirm: Yup.string()
        .oneOf([Yup.ref('Password')], t('auth.confirm'))
        .defined(t('common.required.base')),
    }),
  };
}

export function useUserInitialInfoForm(): UseFormValidation<UpdateUser> {
  return {
    initialValues: {
      FirstName: '',
      PhoneNumber: '',
    },
    validationSchema: Yup.object().shape({
      FirstName: Yup.string().defined(),
      PhoneNumber: Yup.string(),
    }),
  };
}
