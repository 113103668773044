import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Button, Typography } from '@mui/material';
import { Add, MonetizationOn } from '@mui/icons-material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { useToggle } from '@hooks';
import AddDepositModal from '@features/customer/modal/deposit/add';
import DepositTable from '@components/table/deposit';
import { TableEmptyState } from '@components/styled/state';

type Props = {
  UID: string;
};
export default function CustomerDeposit({ UID }: Props) {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const [openAdd, toggleAdd] = useToggle();
  const { data: response } = useGetOrganizationForUI();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DepositHistory>();
    return [
      columnHelper.accessor('Amount', {
        id: 'Amount',
      }),
      columnHelper.accessor('UsageDate', {
        id: 'UsageDate',
      }),
      columnHelper.accessor('Remaining', {
        id: 'Remaining',
      }),
    ];
  }, []);
  return (
    <Box
      sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '48px' }}
    >
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {customerT('title.deposit')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography color="textSecondary" mb="16px">
            {customerT('text.currentDepositBalance')}
          </Typography>
          <Typography color="text.main" fontSize="24px" fontWeight={500}>
            {customerT('text.depositValue', {
              value: response?.extras.DepositBalance ?? 0,
            })}
          </Typography>
        </Box>
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addDeposit')}
        </Button>
      </Box>
      <DepositTable
        columns={columns}
        data={response?.data?.BillingCustom.DepositInfo.DepositHistory}
        DepositDetail={response?.data?.BillingCustom.DepositInfo.DepositDetail}
        OrganizationId={UID}
        emptyState={
          <TableEmptyState sx={{ gap: '16px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  color: 'text.secondary',
                  background: 'rgba(206, 207, 222, 0.20)',
                  borderRadius: '999px',
                  p: '8px',
                }}
              >
                <MonetizationOn sx={{ width: '24px', height: '24px' }} />
              </Box>
              <Typography color="text.main">
                {customerT('table.empty.deposit')}
              </Typography>
            </Box>
            <Button color="sub" onClick={toggleAdd} startIcon={<Add />}>
              {customerT('button.addDeposit')}
            </Button>
          </TableEmptyState>
        }
        enableEdit
      />
      <AddDepositModal open={openAdd} onClose={toggleAdd} UID={UID} />
    </Box>
  );
}
