export const GET_DASHBOARD_TREND = ['DASHBOARD', 'GET_DASHBOARD_TREND'];
export const GET_REVENUE_TREND = ['DASHBOARD', 'GET_REVENUE_TREND'];
export const GET_CUSTOMER_TREND = ['DASHBOARD', 'GET_CUSTOMER_TREND'];
export const GET_TOP10_CUSTOMER = ['DASHBOARD', 'GET_TOP10_CUSTOMER'];
export const GET_ADDITIONAL_SERVICE_TREND = [
  'DASHBOARD',
  'GET_ADDITIONAL_SERVICE_TREND',
];
export const GET_NCP_ACCOUNT_RATIO = ['DASHBOARD', 'GET_NCP_ACCOUNT_RATIO'];
export const GET_SALES_TREND = ['DASHBOARD', 'GET_SALES_TREND'];
