import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab } from '@mui/material';
import { Tabs } from '@heka/theme/src';

const SECTION_VALUE_LIST = [
  'info',
  'contact',
  'manager',
  'aws',
  'ncp',
  'nhn',
  'service',
  'discount',
  'deposit',
  'contract',
  'etc',
];

export default function CustomerDetailTabs() {
  const { t } = useTranslation('customer');
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const section = searchParams.get('section');
    if (!section || !SECTION_VALUE_LIST.includes(section)) {
      searchParams.set('section', 'info');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);
  return (
    <Tabs
      variant="square"
      value={searchParams.get('section')}
      onChange={(e, v) => setSearchParams({ section: v })}
      sx={{ mb: '-2px' }}
    >
      <Tab value="info" label={t('tab.detail.info')} />
      <Tab value="contact" label={t('tab.detail.contact')} />
      {/*<Tab value="user" label={t('tab.detail.user')} />*/}
      <Tab value="manager" label={t('tab.detail.manager')} />
      <Tab value="aws" label={t('tab.detail.awsAccount')} />
      <Tab value="ncp" label={t('tab.detail.ncpAccount')} />
      <Tab value="nhn" label={t('tab.detail.nhnAccount')} />
      <Tab value="service" label={t('tab.detail.additionalService')} />
      <Tab value="discount" label={t('tab.detail.discount')} />
      <Tab value="deposit" label={t('tab.detail.deposit')} />
      <Tab value="contract" label={t('tab.detail.contract')} />
      <Tab value="etc" label={t('tab.detail.etc')} />
    </Tabs>
  );
}
