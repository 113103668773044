import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useGetCURList(UID?: string) {
  const [Id, setUID] = useState(UID ?? '');
  const query = useSuspenseQuery({
    queryKey: [...AWS_ACCOUNT_KEY.GET_CUR_LIST, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) ? AWSAccountService.getCURList(queryKey[2]) : null,
  });
  return { ...query, setUID };
}
