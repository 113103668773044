import { useCallback } from 'react';
import { useGetOrganizationList } from '@api/queries/organization';

export default function useOrganizationHelper() {
  const { data: response } = useGetOrganizationList();
  const getOrganizationNameById = useCallback(
    (UID: string) => {
      return response?.data.find((v) => v.UID === UID)?.Name;
    },
    [response?.data],
  );
  return { getOrganizationNameById };
}
