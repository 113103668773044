import { useMutation } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { AUTH_KEY } from '@constants/query';

export default function useResetPassword() {
  return useMutation({
    mutationKey: AUTH_KEY.RESET_PASSWORD,
    mutationFn: (v: ResetPassword) => UserService.confirmResetPassword(v),
  });
}
