import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { AWSCostService } from '@api/services';
import { AWS_COST_FILTER_TAG_STATE } from '@atoms/page/filter';
import { AWS_COST_KEY } from '@constants/query';

export default function useGetAWSCostTagAutocomplete() {
  const keys = useRecoilValue(AWS_COST_FILTER_TAG_STATE);
  return useSuspenseQuery({
    queryKey: [...AWS_COST_KEY.GET_COST_TAG_AUTOCOMPLETE, keys],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? AWSCostService.getCostTagAutoComplete({
            TagKeys: queryKey[2] as string[],
          })
        : null,
  });
}
