import type { CSSProperties, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';

type BannerProps = {
  open: boolean;
  onClose: () => void;
  color?: CSSProperties['color'];
  background?: CSSProperties['background'];
};
export default function Banner({
  open,
  onClose,
  color,
  background,
  children,
}: PropsWithChildren<BannerProps>) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        px: '16px',
        py: '12px',
        color,
        background,
      }}
    >
      {children}
      <Close
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 'calc(50% - 10px)',
          right: '16px',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
        }}
      />
    </Box>
  );
}
