import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { orderBy, reduce, sumBy } from 'lodash';
import { useGetInvoices } from '@api/queries/invoice';
import {
  DashboardLegendItem,
  DashboardNotFound,
} from '@features/dashboard/styled';
import DashboardRevenueChart from '@components/chart/dashboard/revenue';

const AWS_COLOR = '#C4E3AD' as const;
const NCP_COLOR = '#66BEC3' as const;
const NHN_COLOR = '#348BA6' as const;

type ChartItem = {
  color: string;
  provider: string;
  amount: number;
};

export default function RevenueChart() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetInvoices();
  const totalCost = useMemo(() => {
    return sumBy(response?.data, 'TotalCost.KRW');
  }, [response?.data]);
  const isDataEmpty = useMemo(() => {
    return !totalCost;
  }, [totalCost]);
  const awsTotal = useMemo(() => {
    if (response?.data?.length) {
      return reduce(
        response.data.filter((v) => v.CSP === 'aws'),
        (sum, v) => sum + v.TotalCost.KRW,
        0,
      );
    }
    return 0;
  }, [response?.data]);
  const ncpTotal = useMemo(() => {
    if (response?.data?.length) {
      return reduce(
        response.data.filter((v) => v.CSP === 'ncp'),
        (sum, v) => sum + v.TotalCost.KRW,
        0,
      );
    }
    return 0;
  }, [response?.data]);
  const nhnTotal = useMemo(() => {
    if (response?.data?.length) {
      return reduce(
        response.data.filter((v) => v.CSP === 'nhn'),
        (sum, v) => sum + v.TotalCost.KRW,
        0,
      );
    }
    return 0;
  }, [response?.data]);
  const items = useMemo<[ChartItem, ChartItem, ChartItem]>(() => {
    const items: [ChartItem, ChartItem, ChartItem] = [
      {
        color: AWS_COLOR,
        provider: 'AWS',
        amount: awsTotal,
      },
      {
        color: NCP_COLOR,
        provider: 'Naver Cloud',
        amount: ncpTotal,
      },
      {
        color: NHN_COLOR,
        provider: 'NHN Cloud',
        amount: nhnTotal,
      },
    ];
    return orderBy(items, ['amount', 'provider'], ['desc', 'asc']) as [
      ChartItem,
      ChartItem,
      ChartItem,
    ];
  }, [awsTotal, ncpTotal, nhnTotal]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.cloudUsageAmount')}
      </Typography>
      {!isDataEmpty ? (
        <Grid
          container
          rowSpacing={{ full: 0, zero: '56px' }}
          columnSpacing={{ full: '56px', zero: 0 }}
          sx={{ flex: 1 }}
        >
          <Grid tablet={7} zero={12}>
            <DashboardRevenueChart items={items} />
          </Grid>
          <Grid
            tablet={5}
            zero={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: '24px',
              }}
            >
              <Typography color="#362F26" fontSize="24px" fontWeight={500}>
                {globalT('format.currency.krw', {
                  value: totalCost,
                })}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  gap: '16px',
                  maxWidth: '360px',
                }}
              >
                <DashboardLegendItem
                  color={items[0].color}
                  label={items[0].provider}
                  value={globalT('format.currency.krw', {
                    value: items[0].amount,
                  })}
                />
                <DashboardLegendItem
                  color={items[1].color}
                  label={items[1].provider}
                  value={globalT('format.currency.krw', {
                    value: items[1].amount,
                  })}
                />
                <DashboardLegendItem
                  color={items[2].color}
                  label={items[2].provider}
                  value={globalT('format.currency.krw', {
                    value: items[2].amount,
                  })}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
