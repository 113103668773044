import { useSuspenseQuery } from '@tanstack/react-query';
import { NHNAccountService } from '@api/services';
import { NHN_ACCOUNT_KEY } from '@constants/query';

export default function useGetNHNAccounts() {
  return useSuspenseQuery({
    queryKey: NHN_ACCOUNT_KEY.GET_ACCOUNTS,
    queryFn: () => NHNAccountService.getNHNAccounts(),
  });
}
