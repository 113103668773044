import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useResetPassword } from '@api/queries/auth';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

function usePassowrdResetForm(): UseFormValidation<
  ResetPasswordRequest & { Confirm: string }
> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Token: '',
      Email: '',
      Password: '',
      Confirm: '',
    },
    validationSchema: Yup.object().shape({
      Token: Yup.string().defined(t('common.required.base')),
      Email: Yup.string()
        .email(t('auth.email'))
        .defined(t('common.required.base')),
      Password: Yup.string()
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          t('auth.password.special'),
        )
        .min(8, t('auth.password.length'))
        .defined(t('common.required.base')),
      Confirm: Yup.string()
        .oneOf([Yup.ref('Password')], t('auth.confirm'))
        .defined(t('common.required.base')),
    }),
  };
}

export default function PasswordResetPage() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const { token } = useParams();
  const { mutateAsync, isPending } = useResetPassword();
  const { initialValues, validationSchema } = usePassowrdResetForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        navigate('/login');
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (typeof token === 'string') {
      setFieldValue('Token', token);
    }
  }, [setFieldValue, token]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('reset.title')}
          description={t('reset.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              py: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <TextField
                name="Email"
                value={values.Email}
                error={Boolean(errors.Email && touched.Email)}
                helperText={
                  Boolean(errors.Email && touched.Email) && String(errors.Email)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.email')}
                placeholder={t('common.form.holder.email')}
                type="email"
                inputMode="email"
                autoComplete="username"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="Password"
                value={values.Password}
                error={Boolean(errors.Password && touched.Password)}
                helperText={
                  Boolean(errors.Password && touched.Password) &&
                  String(errors.Password)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.passwordNew')}
                placeholder={t('common.form.holder.passwordNew')}
                type="password"
                inputMode="text"
                autoComplete="new-password"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="Confirm"
                value={values.Confirm}
                error={Boolean(errors.Confirm && touched.Confirm)}
                helperText={
                  Boolean(errors.Confirm && touched.Confirm) &&
                  String(errors.Confirm)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.confirmNew')}
                placeholder={t('common.form.holder.confirmNew')}
                type="password"
                inputMode="text"
                autoComplete="new-password"
                autoCapitalize="off"
                fullWidth
              />
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {t('reset.cta')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
