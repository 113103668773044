import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { useGetTop10Customer } from '@api/queries/dashboard';
import { DashboardNotFound } from '@features/dashboard/styled';
import { RankItem } from '../styled';

export default function InvoiceRank() {
  const { t } = useTranslation('dashboard');
  const { data: response } = useGetTop10Customer('stacked');
  const isDataEmpty = useMemo(() => {
    return !response?.data.length;
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.accumulatedTop10')}
      </Typography>
      {!isDataEmpty ? (
        <Stack
          spacing="8px"
          sx={{ overflowY: 'auto', maxHeight: { xl: '344px', xs: '212px' } }}
        >
          {response?.data.map((customer, idx) => (
            <RankItem
              key={`invoice-rank-item-${customer.OrganizationUID}-${idx}`}
              index={idx + 1}
              name={customer.Name}
              amount={customer.Amount}
              href={`/customer`}
            />
          ))}
        </Stack>
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
