import { useSuspenseQuery } from '@tanstack/react-query';
import { NotificationService } from '@api/services';
import { NOTIFICATION_KEY } from '@constants/query';

export default function useGetNotifications() {
  return useSuspenseQuery({
    queryKey: NOTIFICATION_KEY.GET_NOTIFICATIONS,
    queryFn: () => NotificationService.getNotifications(),
    staleTime: 5000,
    refetchInterval: 60000,
  });
}
