/* eslint-disable react-hooks/rules-of-hooks */
import type { ComponentType } from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import { useGetTokenData } from '@api/queries/token';

const withToken = <P extends object>(
  Component: ComponentType<P>,
): React.FC<P> => {
  return function ComponentWithToken(props) {
    const [isVerified, setIsVerified] = useState(false);
    const { data: token } = useGetTokenData();
    const navigate = useNavigate();
    const { pathname, search, hash } = useLocation();
    useEffect(() => {
      if (token === null) {
        const queryString = QueryString.stringify({
          nextUrl: pathname + search + hash,
        });
        navigate(`/login?${queryString}`);
      } else {
        setIsVerified(true);
      }
    }, [token, navigate, pathname, search, hash]);
    if (isVerified) {
      return <Component {...props} />;
    }
    return null;
  };
};

export default withToken;
