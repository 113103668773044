import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useCreateBulkUsers, useCreateUser } from '@api/queries/user';
import LargeModal from '@components/modal/large';
import { useCreateUserForm } from './form';
import { ChooseType, UserInfo, UserPermission, UserRole } from './steps';

export default function SettingsMemberCreateModal({
  open,
  onClose,
  isClient = false,
}: ModalProps & { isClient?: boolean }) {
  const { t } = useTranslation('settings');
  const { t: globalT } = useTranslation('global');
  const [activeStep, setActiveStep] = useState<
    'CHOOSE' | 'INFO' | 'ROLE' | 'PERMISSION'
  >('CHOOSE');
  const { mutateAsync: createUser, isPending: isSingleCreating } =
    useCreateUser();
  const { mutateAsync: createBulkUsers, isPending: isBulkCreating } =
    useCreateBulkUsers();
  const { initialValues, validationSchema } = useCreateUserForm(isClient);
  const { isValid, dirty, handleSubmit, resetForm, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (v.Type === 'single') {
        try {
          await createUser({
            Email: v.Users[0].Email,
            FirstName: v.Users[0].FirstName,
            Role: v.Role as UserRole,
            ...(v.Role !== 'ADMIN' && {
              Organizations: v.Organizations,
            }),
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await createBulkUsers({
            BulkUsers: v.Users,
            Role: v.Role as UserRole,
            ...(v.Role !== 'ADMIN' && {
              Organizations: v.Organizations,
            }),
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  const description = useMemo(() => {
    if (activeStep === 'CHOOSE') {
      return (
        <Trans i18nKey="settings:modal.permission.create.description.initial" />
      );
    }
    return undefined;
  }, [activeStep]);
  const isSubmitStep = useMemo(() => {
    if (formik.values.Type === 'single') {
      if (formik.values.Role === 'ADMIN') {
        return activeStep === 'ROLE';
      }
      return activeStep === 'PERMISSION';
    }
    return activeStep === 'INFO';
  }, [activeStep, formik.values.Type, formik.values.Role]);
  const disabled = useMemo(() => {
    if (isSubmitStep) {
      return !isValid || !dirty;
    }
    if (activeStep === 'CHOOSE' && !_.isEmpty(formik.values.Type)) {
      return false;
    }
    if (activeStep === 'INFO') {
      if (formik.values.Type === 'single') {
        return Boolean(
          _.get(formik.errors, 'Users.0.Email') &&
            _.get(formik.errors, 'Users.0.FirstName'),
        );
      }
    }
    if (activeStep === 'ROLE' && !formik.errors.Role) {
      return false;
    }
    if (activeStep === 'PERMISSION') {
      return false;
    }
    return true;
  }, [
    activeStep,
    isSubmitStep,
    isValid,
    dirty,
    formik.values.Type,
    formik.errors,
  ]);
  const handlePrevStep = useCallback(() => {
    if (activeStep === 'CHOOSE') {
      onClose();
      return;
    }
    setActiveStep((v) => {
      switch (v) {
        case 'INFO':
          return formik.values.Type === 'single'
            ? 'CHOOSE'
            : formik.values.Role === 'ADMIN'
              ? 'ROLE'
              : 'PERMISSION';
        case 'ROLE':
          return formik.values.Type === 'single' ? 'INFO' : 'CHOOSE';
        case 'PERMISSION':
          return isClient
            ? formik.values.Type === 'single'
              ? 'INFO'
              : 'CHOOSE'
            : 'ROLE';
        default:
          return 'CHOOSE';
      }
    });
  }, [activeStep, onClose, formik.values.Type, isClient]);
  const handleNextStep = useCallback(() => {
    if (!isSubmitStep) {
      setActiveStep((v) => {
        switch (v) {
          case 'CHOOSE':
            return formik.values.Type === 'single'
              ? 'INFO'
              : isClient
                ? 'PERMISSION'
                : 'ROLE';
          case 'INFO':
            return formik.values.Type === 'single'
              ? isClient
                ? 'PERMISSION'
                : 'ROLE'
              : 'INFO';
          case 'ROLE':
            return formik.values.Role === 'ADMIN' ? 'INFO' : 'PERMISSION';
          case 'PERMISSION':
            return formik.values.Type === 'single' ? 'PERMISSION' : 'INFO';
          default:
            return 'CHOOSE';
        }
      });
    }
  }, [isClient, isSubmitStep, formik.values.Type, formik.values.Role]);
  useEffect(() => {
    if (
      activeStep !== 'CHOOSE' &&
      formik.values.Type === 'single' &&
      formik.values.Users.length > 1
    ) {
      formik.setFieldValue('Users', [formik.values.Users[0]]);
    }
  }, [activeStep, formik, formik.setFieldValue, formik.values]);
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
        setActiveStep('CHOOSE');
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('modal.permission.create.title')}
      description={description}
      Actions={
        <>
          <Button type="button" color="cancel" onClick={handlePrevStep}>
            {activeStep !== 'CHOOSE'
              ? globalT('button.prev')
              : globalT('button.goBack')}
          </Button>
          {isSubmitStep ? (
            <LoadingButton
              type="submit"
              color="main"
              loading={isSingleCreating || isBulkCreating}
              disabled={disabled}
            >
              {t('button.invite')}
            </LoadingButton>
          ) : (
            <Button
              type="button"
              color="emphasis1"
              onClick={handleNextStep}
              disabled={disabled}
            >
              {globalT('button.next')}
            </Button>
          )}
        </>
      }
    >
      {activeStep === 'CHOOSE' && <ChooseType {...formik} />}
      {activeStep === 'INFO' && <UserInfo {...formik} />}
      {activeStep === 'ROLE' && <UserRole {...formik} />}
      {activeStep === 'PERMISSION' && <UserPermission {...formik} />}
    </LargeModal>
  );
}
