import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Unstable_Grid2 as Grid,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useChangePassword, useCheckPassword } from '@api/queries/user';

export default function SettingsProfilePassword() {
  const { t } = useTranslation('settings');
  const { t: validationT } = useTranslation('validation');
  const { mutateAsync, isPending } = useChangePassword();
  const { mutateAsync: validatePassword, isPending: isValidating } =
    useCheckPassword();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      CurrentPassword: '',
      NewPassword: '',
      NewPasswordConfirm: '',
      Checked: false,
    },
    validationSchema: Yup.object().shape({
      CurrentPassword: Yup.string()
        .matches(/^.*[A-Z]+.*$/, validationT('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, validationT('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, validationT('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          validationT('auth.password.special'),
        )
        .min(8, validationT('auth.password.length'))
        .defined(validationT('common.required.base')),
      NewPassword: Yup.string()
        .notOneOf(
          [Yup.ref('CurrentPassword')],
          validationT('auth.password.notCurrent'),
        )
        .matches(/^.*[A-Z]+.*$/, validationT('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, validationT('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, validationT('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          validationT('auth.password.special'),
        )
        .min(8, validationT('auth.password.length'))
        .defined(validationT('common.required.base')),
      NewPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('NewPassword')], validationT('auth.confirm'))
        .defined(validationT('common.required.base')),
      Checked: Yup.bool().oneOf([true]).defined(),
    }),
    onSubmit: async (v, helpers) => {
      try {
        await mutateAsync({
          Password: v.NewPassword,
        });
        helpers.resetForm();
      } catch (e) {
        console.error(e);
      }
    },
  });
  const checkPassword = useCallback(async () => {
    try {
      await validatePassword({ Password: values.CurrentPassword });
      await setFieldValue('Checked', true);
    } catch (e) {
      setFieldError('CurrentPassword', validationT('auth.password.current'));
    }
  }, [
    values.CurrentPassword,
    validationT,
    validatePassword,
    setFieldValue,
    setFieldError,
  ]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
        maxWidth: '608px',
      }}
    >
      <Typography color="textSecondary" fontSize="14px" letterSpacing="0.56px">
        {t('title.profile.password')}
      </Typography>
      <Grid container spacing="24px">
        <Grid container alignItems="flex-end" xs={12}>
          <Grid xs>
            <TextField
              name="CurrentPassword"
              value={values.CurrentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.CurrentPassword && touched.CurrentPassword)}
              helperText={
                Boolean(errors.CurrentPassword && touched.CurrentPassword) &&
                String(errors.CurrentPassword)
              }
              label={t('form.label.currentPassword')}
              placeholder="현재 비밀번호를 입력해주세요"
              disabled={values.Checked}
              type="password"
              inputMode="text"
              autoComplete="current-password"
              fullWidth
            />
          </Grid>
          <Grid xs={false}>
            <Button
              type="button"
              color="sub"
              onClick={checkPassword}
              disabled={values.Checked}
            >
              {t('button.checkPassword')}
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <TextField
            name="NewPassword"
            value={values.NewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.NewPassword && touched.NewPassword)}
            helperText={
              Boolean(errors.NewPassword && touched.NewPassword) &&
              String(errors.NewPassword)
            }
            label={t('form.label.newPassword')}
            placeholder="새로운 비밀번호를 입력해주세요"
            disabled={!values.Checked}
            type="password"
            inputMode="text"
            autoComplete="new-password"
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            name="NewPasswordConfirm"
            value={values.NewPasswordConfirm}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              errors.NewPasswordConfirm && touched.NewPasswordConfirm,
            )}
            helperText={
              Boolean(
                errors.NewPasswordConfirm && touched.NewPasswordConfirm,
              ) && String(errors.NewPasswordConfirm)
            }
            label={t('form.label.newPasswordConfirm')}
            placeholder="새로운 비밀번호를 확인해주세요"
            disabled={!values.Checked}
            type="password"
            inputMode="text"
            autoComplete="new-password"
            fullWidth
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        color="emphasis1"
        loading={isPending}
        disabled={!isValid || !dirty}
      >
        {t('button.saveChanges')}
      </LoadingButton>
    </Box>
  );
}
