import { useMutation } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useDownloadInvoiceListAsCsv() {
  return useMutation({
    mutationKey: INVOICE_KEY.DOWNLOAD_INVOICE_LIST_AS_CSV,
    mutationFn: (v: string) => InvoiceService.downloadInvoiceListAsCsv(v),
  });
}
