import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useGetReportById } from '@api/queries/report';
import { REPORT_FILTER_STATE } from '@atoms/page/filter';
import DownloadReportModal from '@features/report/detail/modal/download';
import { useToggle } from '@hooks';
import {
  ReportBillingSection,
  ReportBillingStatusSection,
  ReportCommissionSection,
  ReportCustomerAccountSection,
  ReportCustomerTrendSection,
  ReportMarginByCloudTypeSection,
  ReportSalesSection,
  ReportSummarySection,
} from './components';

interface IProps {
  UID: string;
}
export default function ReportContent({ UID }: IProps) {
  const { t: globalT } = useTranslation('global');
  const [openDownload, toggleDownload] = useToggle();
  const { data: response, setId } = useGetReportById(UID);
  const reportSettings = useRecoilValue(REPORT_FILTER_STATE);
  useEffect(() => {
    setId(UID);
  }, [UID, setId]);
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
          {reportSettings.Name}
        </Typography>
        <Button onClick={toggleDownload} startIcon={<Download />}>
          {globalT('button.download')}
        </Button>
      </Box>
      <Box
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
      >
        <ReportSummarySection
          Summary={response?.data.Summary}
          includeMargin={reportSettings.DisplayOptions.includes(
            'INCLUDE_MARGIN',
          )}
        />
        <ReportBillingSection
          InvoiceAmount={response?.data.InvoiceAmount ?? {}}
        />
        {reportSettings.DisplayOptions.includes('ACCOUNT_TREND') && (
          <ReportCustomerAccountSection
            Summary={response?.data.Summary ?? []}
          />
        )}
        {reportSettings.DisplayOptions.includes('CUSTOMER_TREND') && (
          <ReportCustomerTrendSection Summary={response?.data.Summary ?? []} />
        )}
        {/*<ReportBillingStatusSection />*/}
        {reportSettings.DisplayOptions.includes('INCLUDE_MARGIN') && (
          <ReportSalesSection
            SalesPurchase={response?.data.SalesPurchase ?? {}}
            SalesSupply={response?.data.SalesSupply ?? {}}
            SalesMargin={response?.data.SalesMargin ?? {}}
          />
        )}
        {/*<ReportMarginByCloudTypeSection type="enterprise" />*/}
        {/*<ReportMarginByCloudTypeSection type="public" />*/}
        {/*<ReportMarginByCloudTypeSection type="finance" />*/}
        {/*<ReportCommissionSection />*/}
      </Box>
      <DownloadReportModal
        open={openDownload}
        onClose={toggleDownload}
        UID={UID}
      />
    </Box>
  );
}
