import { useSuspenseQueries } from '@tanstack/react-query';
import {
  CompanyService,
  UserService,
  NotificationService,
} from '@api/services';
import { COMPANY_KEY, USER_KEY, NOTIFICATION_KEY } from '@constants/query';

export default function useGetInitialDataSuspense() {
  const [
    { data: company },
    { data: user },
    { data: logo },
    { data: notifications },
  ] = useSuspenseQueries({
    queries: [
      {
        queryKey: COMPANY_KEY.GET_COMPANY,
        queryFn: () => CompanyService.getCompany(),
        staleTime: 5000,
      },
      {
        queryKey: USER_KEY.GET_CURRENT_USER,
        queryFn: () => UserService.getCurrentUser(),
        staleTime: 5000,
      },
      {
        queryKey: COMPANY_KEY.GET_LOGO,
        queryFn: () => CompanyService.getLogo(),
        staleTime: 5000,
      },
      {
        queryKey: NOTIFICATION_KEY.GET_NOTIFICATIONS,
        queryFn: () => NotificationService.getNotifications(),
        staleTime: 5000,
      },
    ],
  });
  return { company, user, logo, notifications };
}
