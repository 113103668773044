import { useCallback } from 'react';
import _ from 'lodash';
import { useGetPayerAccounts } from '@api/queries/aws/account';

export default function useAWSHelper() {
  const { data: accounts } = useGetPayerAccounts();
  const getAccountAlias = useCallback(
    (AccountId: string) => {
      if (accounts?.data?.length) {
        const account = accounts.data.find((v) =>
          _.has(v.AliasAccount, AccountId),
        );
        if (account) {
          return _.get(account.AliasAccount, AccountId);
        }
      }
      return undefined;
    },
    [accounts?.data],
  );

  return {
    getAccountAlias,
  };
}
