import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NHNAccountService } from '@api/services';
import { NHN_ACCOUNT_KEY } from '@constants/query';

export default function useVerifyNHNUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: NHN_ACCOUNT_KEY.VERIFY_USER,
    mutationFn: (v: NHNUserInfo) => NHNAccountService.verifyNHNUser(v),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...NHN_ACCOUNT_KEY.GET_VERIFIED_USER, res.data.TempUID],
      });
    },
  });
}
