import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useVerifyNHNUser } from '@api/queries/nhn/account';
import { NHNOnboardUserInfo, useNHNUserForm } from '@features/onboard/nhn';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function OnboardVerifyNHNUserPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('onboard', { keyPrefix: 'integrateNHN' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useVerifyNHNUser();
  const { initialValues, validationSchema } = useNHNUserForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        const { data } = await mutateAsync(v);
        navigate(`/onboard/integrate/nhn/${data.TempUID}`);
      } catch (e) {
        console.error(e);
      }
    },
  });
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <PanelContainer disableOverflow>
        <LeftPanel title={t('title')} description={t('description')} />
        <MainPanel>
          <NHNOnboardUserInfo
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </MainPanel>
      </PanelContainer>
      <BottomActions enableSupport>
        <Button type="button" color="cancel" onClick={() => navigate(-1)}>
          {globalT('button.goBack')}
        </Button>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {globalT('button.next')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
