import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useCustomerInformationForm(): UseFormValidation<UpdateOrganization> {
  const { t: validationT } = useTranslation('validation', {
    keyPrefix: 'settings.company.registrationNumber',
  });
  return {
    initialValues: {
      UID: '',
      Name: '',
      Representative: '',
      RegistrationNumber: '',
      BusinessLocation: '',
      Telephone: '',
      Website: '',
      TypeOfBusiness: '',
      ItemOfBusiness: '',
      HeadOfficeLocation: '',
    },
    validationSchema: Yup.object()
      .shape({
        UID: Yup.string().defined(),
        Name: Yup.string().defined(),
        Representative: Yup.string().defined(),
        RegistrationNumber: Yup.string()
          .matches(/^[1-9][0-9-]+[0-9]$/, validationT('length'))
          // .test('validation', validationT('invalid'), (value) => {
          //   if (value) {
          //     const numberMap = value
          //       .replace(/-/gi, '')
          //       .split('')
          //       .map((v) => parseInt(v, 10));
          //     if (numberMap.length === 10) {
          //       const validArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
          //       let chk = 0;
          //       validArr.forEach((v, i) => {
          //         chk += v * numberMap[i];
          //       });
          //       chk += Math.floor((validArr[8] * numberMap[8]) / 10);
          //       return Math.floor(numberMap[9]) === 10 - (chk % 10);
          //     }
          //     return false;
          //   }
          //   return true;
          // })
          .defined(),
        BusinessLocation: Yup.string().defined(),
        Telephone: Yup.string(),
        Website: Yup.string(),
        TypeOfBusiness: Yup.string(),
        ItemOfBusiness: Yup.string(),
        HeadOfficeLocation: Yup.string(),
      })
      .defined(),
  };
}
