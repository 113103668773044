import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export type LinkProps = Omit<
  MuiLinkProps,
  'variant' | 'color' | 'underline' | 'ref'
> & {
  variant?: 'default' | 'inline';
  color?: 'main' | 'calm';
  size?: 'default' | 'comfort';
  inverse?: boolean;
};

const Link: StyledComponent<LinkProps> = styled(
  (
    {
      variant = 'default',
      color = 'main',
      size = 'default',
      inverse = false,
      children,
      ...rest
    }: LinkProps,
    // ref,
  ) => (
    <MuiLink
      {...rest}
      // ref={ref}
      {...(variant === 'inline' && { underline: 'always' })}
    >
      {children}
      {variant === 'default' && <KeyboardArrowRight />}
    </MuiLink>
  ),
  { name: 'HekaLink', skipVariantsResolver: true },
)<LinkProps>(
  ({
    theme,
    variant = 'default',
    color = 'main',
    size = 'default',
    inverse = false,
  }) => ({
    letterSpacing: 0,
    ...(color === 'main' && {
      color: theme.palette.component.link.main.default,
      ...(inverse && {
        color: theme.palette.component.link.mainInverse.default,
      }),
    }),
    ...(color === 'calm' && {
      color: theme.palette.component.link.calm.default,
      ...(inverse && {
        color: theme.palette.component.link.calmInverse.default,
      }),
    }),
    '&:hover': {
      ...(color === 'main' && {
        color: theme.palette.component.link.main.hover,
        ...(inverse && {
          color: theme.palette.component.link.mainInverse.hover,
        }),
      }),
      ...(color === 'calm' && {
        color: theme.palette.component.link.calm.hover,
        ...(inverse && {
          color: theme.palette.component.link.calmInverse.hover,
        }),
      }),
    },
    '&:active': {
      ...(color === 'main' && {
        color: theme.palette.component.link.main.active,
        ...(inverse && {
          color: theme.palette.component.link.mainInverse.active,
        }),
      }),
      ...(color === 'calm' && {
        color: theme.palette.component.link.calm.active,
        ...(inverse && {
          color: theme.palette.component.link.calmInverse.active,
        }),
      }),
    },
    '&:visited': {
      textDecoration: 'none !important',
      ...(color === 'main' && {
        color: theme.palette.component.link.main.visited,
        ...(inverse && {
          color: theme.palette.component.link.mainInverse.visited,
        }),
      }),
      ...(color === 'calm' && {
        color: theme.palette.component.link.calm.visited,
        ...(inverse && {
          color: theme.palette.component.link.calmInverse.visited,
        }),
      }),
    },
    '&.Mui-disabled': {
      textDecoration: 'none !important',
      ...(color === 'main' && {
        color: theme.palette.component.link.main.disabled,
        ...(inverse && {
          color: theme.palette.component.link.mainInverse.disabled,
        }),
      }),
      ...(color === 'calm' && {
        color: theme.palette.component.link.calm.disabled,
        ...(inverse && {
          color: theme.palette.component.link.calmInverse.disabled,
        }),
      }),
    },
    ...(size === 'default' && {
      fontSize: '14px',
      lineHeight: '120%',
      // TODO: Check Browser Support
      // leadingTrim: 'both',
      // textEdge: 'cap alphabetic',
      // textBoxTrim: 'both',
      // textBoxEdge: 'cap alphabetic',
    }),
    ...(size === 'comfort' && {
      fontSize: '16px',
      lineHeight: '120%',
      // TODO: Check Browser Support
      // leadingTrim: 'both',
      // textEdge: 'cap alphabetic',
      // textBoxTrim: 'both',
      // textBoxEdge: 'cap alphabetic',
    }),
    ...(variant === 'default' && {
      position: 'relative',
      paddingRight: size === 'default' ? '20px' : '24px',
      [`> .${getSvgIconUtilityClass('root')}`]: {
        position: 'absolute',
        right: 0,
        ...(size === 'default' && {
          width: '16px',
          height: '16px',
          top: 'calc(50% - 8px)',
        }),
        ...(size === 'comfort' && {
          width: '20px',
          height: '20px',
          top: 'calc(50% - 10px)',
        }),
      },
    }),
  }),
);

export default Link;
