import {
  Bar,
  BarChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { calculateDataMax, formatChartAxis } from '@utils/chart';
import { formatDate, formatLocaleString } from '@utils/formatter';

function TooltipItem({
  label,
  value,
  background,
}: {
  label: string;
  value: number;
  background: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
        <Box
          sx={{
            display: 'block',
            width: '12px',
            height: '12px',
            borderRadius: '2px',
            mr: '8px',
            background,
          }}
        />
        <Typography fontWeight={500}>{label}</Typography>
      </Box>
      <Typography fontWeight={500}>{formatLocaleString(value)}</Typography>
    </Box>
  );
}

type DashboardAdditionalServiceChartProps = {
  data?: Array<AdditionalServiceTrend>;
};
export default function DashboardAdditionalServiceChart({
  data = [],
}: DashboardAdditionalServiceChartProps) {
  return (
    <Box>
      <ResponsiveContainer width="100%" height={256}>
        <BarChart data={data} barSize={32}>
          <XAxis
            dataKey="Month"
            stroke="none"
            strokeWidth={0}
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
            height={26}
            padding={{ left: 16, right: 16 }}
          />
          <YAxis
            scale="linear"
            stroke="none"
            strokeWidth={0}
            width={32}
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={8}
            tickFormatter={formatChartAxis}
            domain={[0, calculateDataMax]}
            padding={{ top: 8, bottom: 8 }}
          />
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => {
              if (!active || !payload?.length) {
                return null;
              }
              const useCompany = Number(
                payload.find((v) => v.dataKey === 'UseCompany')?.value,
              );
              const notUseCompany = Number(
                payload.find((v) => v.dataKey === 'NotUseCompany')?.value,
              );
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    borderRadius: '24px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                    backdropFilter: 'blur(3px)',
                    p: '24px',
                  }}
                >
                  <Typography color="textSecondary" mb="8px">
                    {formatDate(label, 'YYYY.MM')}
                  </Typography>
                  <TooltipItem
                    label="미이용 고객사"
                    value={notUseCompany}
                    background="#CF466F33"
                  />
                  <TooltipItem
                    label="이용 고객사"
                    value={useCompany}
                    background="#CF466F"
                  />
                </Box>
              );
            }}
          />
          <Bar
            stackId="stack"
            dataKey="UseCompany"
            fill="#CF466F"
            shape={({ name, ...props }) => {
              const { height = 0, payload } = props as any;
              let radius: number | [number, number, number, number] = 0;
              if (height > 2) {
                radius = [0, 0, 8, 8];
              }
              if (!payload['NotUseCompany']) {
                radius = [8, 8, 8, 8];
              }
              return <Rectangle {...props} radius={radius} />;
            }}
          />
          <Bar
            stackId="stack"
            dataKey="NotUseCompany"
            fill="#CF466F33"
            shape={({ name, ...props }) => {
              const { height = 0, payload } = props as any;
              let radius: number | [number, number, number, number] = 0;
              if (height > 2) {
                radius = [8, 8, 0, 0];
              }
              if (!payload['UseCompany']) {
                radius = [8, 8, 8, 8];
              }
              return <Rectangle {...props} radius={radius} />;
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '16px',
          rowGap: '8px',
          mt: '40px',
          ml: '36px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'block',
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              background: '#CF466F33',
            }}
          />
          <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
            미이용 고객사
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'block',
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              background: '#CF466F',
            }}
          />
          <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
            이용 고객사
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
