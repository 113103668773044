import { useQuery } from '@tanstack/react-query';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useGetNotConfiguredAccountInterval() {
  return useQuery({
    queryKey: AWS_ACCOUNT_KEY.GET_NOT_CONFIGURED_ACCOUNT_INTERVAL,
    queryFn: () => AWSAccountService.getNotConfiguredAccount(),
    retry: true,
    retryDelay: 5000,
  });
}
