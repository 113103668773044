export const GET_ORGANIZATIONS = ['ORGANIZATION', 'GET_ORGANIZATIONS'];
export const GET_ORGANIZATION_LIST = ['ORGANIZATION', 'GET_ORGANIZATION_LIST'];
export const GET_ORGANIZATION_BY_ID = [
  'ORGANIZATION',
  'GET_ORGANIZATION_BY_ID',
];
export const GET_ORGANIZATION_FOR_UI = [
  'ORGANIZATION',
  'GET_ORGANIZATION_FOR_UI',
];
export const GET_ORGANIZATION_SUMMARY = [
  'ORGANIZATION',
  'GET_ORGANIZATION_SUMMARY',
];
export const GET_ORGANIZATION_AUTOCOMPLETE = [
  'ORGANIZATION',
  'GET_ORGANIZATION_AUTOCOMPLETE',
];
export const CREATE_ORGANIZATION = ['ORGANIZATION', 'CREATE_ORGANIZATION'];
export const UPDATE_ORGANIZATION = ['ORGANIZATION', 'UPDATE_ORGANIZATION'];
export const DELETE_ORGANIZATION = ['ORGANIZATION', 'DELETE_ORGANIZATION'];
export const UPDATE_BILLING_CUSTOM = ['ORGANIZATION', 'UPDATE_BILLING_CUSTOM'];
export const ADD_DEPOSIT_DETAIL = ['ORGANIZATION', 'ADD_DEPOSIT_DETAIL'];
export const UPDATE_DEPOSIT_DETAIL = ['ORGANIZATION', 'UPDATE_DEPOSIT_DETAIL'];
export const ADD_CONTACT = ['ORGANIZATION', 'ADD_CONTACT'];
export const UPDATE_CONTACT = ['ORGANIZATION', 'UPDATE_CONTACT'];
export const DELETE_CONTACT = ['ORGANIZATION', 'DELETE_CONTACT'];
export const ADD_CLOUD_ACCOUNT = ['ORGANIZATION', 'ADD_CLOUD_ACCOUNT'];
export const BULK_ADD_CLOUD_ACCOUNT = [
  'ORGANIZATION',
  'BULK_ADD_CLOUD_ACCOUNT',
];
export const UPDATE_CLOUD_ACCOUNT = ['ORGANIZATION', 'UPDATE_CLOUD_ACCOUNT'];
export const DELETE_CLOUD_ACCOUNT = ['ORGANIZATION', 'DELETE_CLOUD_ACCOUNT'];
