import type { FormEventHandler, PropsWithChildren, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Dialog, {
  DialogProps,
  getDialogUtilityClass,
} from '@mui/material/Dialog';
import { SuspenseBox } from '@components/suspense';

interface LargeModalProps extends ModalProps {
  type?: 'form';
  title: string;
  Actions: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  description?: ReactNode;
  maxWidth?: string | number;
  minHeight?: string | number;
  titleProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  scroll?: DialogProps['scroll'];
  pb?: string | number;
  sx?: SxProps<Theme>;
}

function Wrapper({
  children,
  type,
  onSubmit,
}: PropsWithChildren<Pick<LargeModalProps, 'type' | 'onSubmit'>>) {
  if (type === 'form') {
    return (
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box
      sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
    >
      {children}
    </Box>
  );
}

export default function LargeModal({
  children,
  type = undefined,
  open,
  onClose,
  title,
  Actions,
  onSubmit,
  description,
  maxWidth,
  minHeight,
  titleProps,
  descriptionProps,
  scroll,
  pb,
  sx,
}: PropsWithChildren<LargeModalProps>) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={scroll}
      fullWidth
      sx={{
        [`> .${getDialogUtilityClass('container')} > .${getDialogUtilityClass(
          'paper',
        )}`]: {
          maxWidth: maxWidth ?? '1200px',
          minHeight: minHeight ?? '880px',
          pb: pb ?? '56px',
        },
      }}
    >
      <Wrapper type={type} onSubmit={onSubmit}>
        <Box>
          <Typography
            fontSize="16px"
            fontWeight={500}
            letterSpacing="0.64px"
            {...titleProps}
          >
            {title}
          </Typography>
          {typeof description !== 'undefined' && (
            <Typography
              fontSize="14px"
              lineHeight="150%"
              letterSpacing="0.28px"
              mt="24px"
              {...descriptionProps}
            >
              {description}
            </Typography>
          )}
        </Box>
        <SuspenseBox
          FallbackProps={{
            size: 64,
            sx: { flex: 1 },
          }}
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
          }}
        >
          {children}
        </SuspenseBox>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
          {Actions}
        </Box>
      </Wrapper>
    </Dialog>
  );
}
