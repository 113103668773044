import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import MuiCheckbox, { getCheckboxUtilityClass } from '@mui/material/Checkbox';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import Check from '@mui/icons-material/Check';

export type MenuItemProps = MuiMenuItemProps &
  (
    | {
        check?: true;
        multi?: undefined;
        checked?: undefined;
        indeterminate?: undefined;
      }
    | {
        check?: undefined;
        multi?: true;
        checked?: boolean;
        indeterminate?: boolean;
      }
  );
// | { multi?: true; checked?: boolean; indeterminate?: boolean }

const MenuItem: StyledComponent<MenuItemProps> = styled(
  ({
    children,
    check,
    multi,
    checked,
    indeterminate,
    ...rest
  }: MenuItemProps) => (
    <MuiMenuItem {...rest}>
      {!!multi && (
        <MuiCheckbox
          size="small"
          checked={checked ?? rest.selected}
          indeterminate={indeterminate}
        />
      )}
      {children}
      {Boolean(rest.selected && check) && <Check />}
    </MuiMenuItem>
  ),
  {
    name: 'HekaMenuItem',
    skipVariantsResolver: true,
    // skipSx: true,
  },
)<MenuItemProps>(({ selected, check, multi }) => ({
  boxShadow: 'none',
  ...(check && {
    paddingRight: '40px',
  }),
  ...(Boolean(selected && check) && {
    position: 'relative',
    [`> .${getSvgIconUtilityClass('root')}`]: {
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: 'calc(50% - 10px)',
      right: '10px',
    },
  }),
  ...(multi && {
    paddingTop: '10px',
    paddingBottom: '10px',
    [`> .${getCheckboxUtilityClass('root')}`]: {
      marginRight: '8px',
    },
  }),
}));

export default MenuItem;
