import { useSuspenseQuery } from '@tanstack/react-query';
import { ExchangeRateService } from '@api/services';
import { EXCHANGE_KEY } from '@constants/query';
import { useState } from 'react';
import { isEmpty } from 'lodash';

export default function useGetExchangeRatesByInvoiceId(InvoiceId?: string) {
  const [Id, setId] = useState(InvoiceId ?? '');
  const query = useSuspenseQuery({
    queryKey: [...EXCHANGE_KEY.GET_EXCHANGE_RATES_BY_INVOICE_ID, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? ExchangeRateService.getExchangeRateByInvoiceId(queryKey[2])
        : null,
  });
  return { ...query, setId };
}
