import { useParams } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import { ReportContent, ReportSettings } from '@features/report/detail';
import { SuspenseBox, SuspensePaper } from '@components/suspense';
import { BackToListButton } from '@components/ui';
import ReportIcon from '@assets/icon/Report.svg?react';

function EmptyContent() {
  const { t } = useTranslation('report', { keyPrefix: 'text.empty' });
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> svg': {
          '> .foreground': {
            fill: '#EED700',
          },
        },
      }}
    >
      <ReportIcon />
      <Typography align="center" mt="24px">
        {t('title')}
      </Typography>
      <Typography
        color="textSecondary"
        fontSize="14px"
        align="center"
        mt="16px"
      >
        {t('description')}
      </Typography>
    </Box>
  );
}

export default function ReportDetailPage() {
  const { t } = useTranslation('report');
  const { uid } = useParams();
  return (
    <>
      <BackToListButton href="/report" label={t('button.backToList')} />
      <RecoilRoot>
        <SuspenseBox sx={{ flex: 1, display: 'flex', gap: '32px' }}>
          <Paper sx={{ minWidth: '380px', maxWidth: '380px' }}>
            <ReportSettings UID={uid} />
          </Paper>
          <SuspensePaper
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
            }}
          >
            {uid ? <ReportContent UID={uid} /> : <EmptyContent />}
          </SuspensePaper>
        </SuspenseBox>
      </RecoilRoot>
    </>
  );
}
