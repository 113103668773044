import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import hj from '@hotjar/browser';

export default function useHotjar() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (hj.isReady()) {
      hj.stateChange(`${pathname}${search}`);
    }
  }, [pathname, search]);
}
