import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import _ from 'lodash';

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceEstimatedCharge({ Invoices }: Props) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const TotalCost = useMemo(() => {
    const uniqueInvoices = _.uniqBy(Invoices, 'CSP');
    const base =
      _.sumBy(uniqueInvoices, 'OrgAdditionalCost.KRW') -
      _.sumBy(uniqueInvoices, 'OrgDiscountCost.KRW');
    return Invoices.reduce((acc, v) => v.TotalCost.KRW + acc, base);
  }, [Invoices]);
  const VAT = useMemo(() => {
    return Math.floor(TotalCost * 0.1);
  }, [TotalCost]);
  const DepositUsed = useMemo(() => {
    return Invoices[0].DepositUsed ?? 0;
  }, [Invoices]);
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
        {t('title.estimatedCharge')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography color="textSecondary">
          {t('text.invoicedCharge')}
        </Typography>
        <Typography color="text.main" fontSize="20px" fontWeight={500}>
          {globalT('format.currency.krw', { value: TotalCost })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography color="textSecondary">{t('text.estimatedVAT')}</Typography>
        <Typography color="text.main" fontSize="20px" fontWeight={500}>
          {globalT('format.currency.krw', { value: VAT })}
        </Typography>
      </Box>
      {!!DepositUsed && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography color="textSecondary">
            {t('text.depositDeducted')}
          </Typography>
          <Typography color="text.main" fontSize="20px" fontWeight={500}>
            {globalT('format.currency.krw', { value: DepositUsed })}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography color="textSecondary">
          {t('text.estimatedCharge')}
        </Typography>
        <Typography color="text.main" fontSize="20px" fontWeight={500}>
          {globalT('format.currency.krw', {
            value: TotalCost + VAT - DepositUsed,
          })}
        </Typography>
      </Box>
    </Paper>
  );
}
