import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  useDownloadNCPSalesAsExcel,
  useGetNCPSalesTable,
} from '@api/queries/ncp/sales';
import SimpleTable from '@components/table/simple';
import { CloudTypeChip } from '@components/styled/chip';
import SalesMonthPicker from 'src/features/sales/common/picker';
import { NCP_SALES_FILTER_STATE } from '@atoms/page/filter';
import { formatDate, formatKRW } from '@utils/formatter';
import NCPSalesManagementFilter from '../filter';

export default function NCPSalesManagementTable() {
  const { t } = useTranslation('table');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetNCPSalesTable();
  const { mutateAsync, isPending } = useDownloadNCPSalesAsExcel();
  const downloadExcel = useCallback(async () => {
    try {
      const { data: blob, filename } = await mutateAsync();
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.id = 'heka_ncp_sales_download';
      link.href = url;
      if (filename) {
        link.download = filename;
      }
      const child = document.body.appendChild(link);
      link.click();
      document.body.removeChild(child);
    } catch (e) {
      console.error(e);
    }
  }, [mutateAsync]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<NCPSalesItem>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('sales.accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrganizationName', {
        id: 'OrganizationName',
        header: t('sales.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Month', {
        id: 'Month',
        header: t('sales.billingPeriod'),
        cell: (v) => formatDate(v.getValue(), 'YYYY MMM'),
      }),
      columnHelper.accessor('CloudType', {
        id: 'CloudType',
        header: t('sales.cloudType'),
        cell: (v) => <CloudTypeChip type={v.getValue()} />,
      }),
      columnHelper.accessor('UseAmount', {
        id: 'UseAmount',
        header: t('sales.usageAmount'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('DiscountAmount', {
        id: 'DiscountAmount',
        header: t('sales.usageDiscount'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalDemandAmount', {
        id: 'TotalDemandAmount',
        header: t('sales.usageSales'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('PartnerMarginDiscountAmount', {
        id: 'PartnerMarginDiscountAmount',
        header: t('sales.partnerDiscount'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('PartnerDemandAmount', {
        id: 'PartnerDemandAmount',
        header: t('sales.partnerSales'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('PartnerMarginAmount', {
        id: 'PartnerMarginAmount',
        header: t('sales.partnerMargin'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('Currency', {
        id: 'Currency',
        header: t('sales.currency'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        size="comfort"
        columns={columns}
        data={response?.data}
        enableGlobalFilter
      >
        <SalesMonthPicker state={NCP_SALES_FILTER_STATE} />
        <NCPSalesManagementFilter />
        <LoadingButton
          onClick={downloadExcel}
          loading={isPending}
          startIcon={<Download />}
        >
          {globalT('button.download')}
        </LoadingButton>
      </SimpleTable>
    </>
  );
}
