import { useQuery } from '@tanstack/react-query';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useGetExternalId() {
  return useQuery({
    queryKey: AWS_ACCOUNT_KEY.GET_EXTERNAL_ID,
    queryFn: () => AWSAccountService.getExternalId(),
  });
}
