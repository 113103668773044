import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import _ from 'lodash';
import { Link, Note } from '@heka/theme';
import { useGetCurrentUser } from '@api/queries/user';
import {
  useGetExternalId,
  useGetNotConfiguredAccountInterval,
} from '@api/queries/aws/account';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
  SupportFAQ,
} from '@components/layout/auth';

const Step = styled('div')({
  display: 'flex',
  gap: '16px',
});
const StepContent = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
});

export default function OnboardIntegrateAWSPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('onboard');
  const { t: globalT } = useTranslation('global');
  const { data: user } = useGetCurrentUser();
  const { data: external } = useGetExternalId();
  const scriptText = useMemo(() => {
    if (user?.data && external?.data) {
      const userId = user.data.UID;
      const externalId = external.data.ExternalId;
      const shellFileName =
        import.meta.env.VITE_APP_ENV === 'prd'
          ? 'heka-aws-script.sh'
          : 'heka-aws-script-dev.sh';
      return `rm -f ./${shellFileName} && wget https://cdn.grumatic.com/scripts/${shellFileName} && sh ./${shellFileName} ${externalId} ${userId}`;
    }
    return '';
  }, [user?.data, external?.data]);
  const { data: response } = useGetNotConfiguredAccountInterval();
  const accountUID = useMemo(() => {
    return response?.data?.UID;
  }, [response?.data?.UID]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <PanelContainer disableOverflow>
        <LeftPanel
          title={t('integrateAWS.title')}
          description={t('integrateAWS.description')}
        />
        <MainPanel>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography>{t('integrateAWS.content')}</Typography>
            <Note
              color="emphasis"
              title={t('integrateAWS.info')}
              disableCollapse
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                py: '40px',
              }}
            >
              <Step>
                <Typography sx={{ mx: '5px' }}>1.</Typography>
                <StepContent>
                  <Typography>{t('integrateAWS.steps.copy.text')}</Typography>
                  <CopyToClipboard text={scriptText}>
                    <Button size="small" disabled={_.isEmpty(scriptText)}>
                      {t('integrateAWS.steps.copy.cta')}
                    </Button>
                  </CopyToClipboard>
                </StepContent>
              </Step>
              <Step>
                <Typography sx={{ mx: '5px' }}>2.</Typography>
                <StepContent>
                  <Typography>{t('integrateAWS.steps.shell.text')}</Typography>
                  <Link
                    href="https://ap-northeast-2.console.aws.amazon.com/cloudshell"
                    target="_blank"
                  >
                    {t('integrateAWS.steps.shell.cta')}
                  </Link>
                </StepContent>
              </Step>
              <Step>
                <Typography sx={{ mx: '5px' }}>3.</Typography>
                <Typography>
                  {t('integrateAWS.steps.configure.text')}
                </Typography>
              </Step>
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions
        enableSupport
        faq={
          t('integrateAWS.faq', { returnObjects: true }) as Array<SupportFAQ>
        }
      >
        <Button color="cancel" onClick={() => navigate(-1)}>
          {globalT('button.goBack')}
        </Button>
        <Button
          href={`/onboard/integrate/aws/${accountUID}`}
          color="emphasis1"
          disabled={!_.isString(accountUID)}
        >
          {globalT('button.next')}
        </Button>
      </BottomActions>
    </Box>
  );
}
