import * as Yup from 'yup';

export function useCustomerServiceForm(
  isDiscount = false,
): UseFormValidation<AdditionalService & { ApplyToInvoice?: boolean }> {
  return {
    initialValues: {
      Providers: [],
      ServiceName: '',
      ApplyLevel: 'Company',
      ApplyAccount: [],
      UsageAmount: 1,
      PricingUnit: 'KRW',
      Cost: 0,
      ApplyToInvoice: undefined,
    },
    validationSchema: Yup.object()
      .shape({
        Providers: Yup.array(Yup.mixed().oneOf(['aws', 'ncp', 'nhn']))
          .min(1)
          .defined(),
        ServiceName: Yup.string().defined(),
        ApplyLevel: Yup.mixed().oneOf(['Company', 'Account']).defined(),
        ApplyAccount: Yup.array().of(Yup.string().defined()),
        UsageAmount: Yup.number().positive().defined(),
        PricingUnit: Yup.mixed().oneOf(['KRW', 'USD', 'PCT']).defined(),
        Cost: Yup.number().when('PricingUnit', ([PricingUnit], schema) => {
          if (isDiscount && PricingUnit === 'PCT') {
            return schema.max(100).defined();
          }
          if (PricingUnit === 'KRW') {
            return schema.integer().defined();
          }
          return schema.defined();
        }),
        ApplyToInvoice: Yup.bool(),
      })
      .defined(),
  };
}
