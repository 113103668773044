import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  useDownloadInvoiceAsCsv,
  useDownloadInvoiceAsExcel,
  useDownloadInvoiceAsPdf,
  useDownloadInvoiceTransaction,
} from '@api/queries/invoice';
import FormModal from '@components/modal/form';

type FileType = 'PDF' | 'EXCEL' | 'TRANSACTION';

export default function DownloadInvoiceModal({
  open,
  onClose,
  InvoiceId,
  InvoiceType,
}: ModalProps & { InvoiceId: string; InvoiceType?: CloudProvider }) {
  const { t: globalT } = useTranslation('global');
  const { mutateAsync: downloadPdf, isPending: isPdfDownloading } =
    useDownloadInvoiceAsPdf();
  const { mutateAsync: downloadExcel, isPending: isExcelDownloading } =
    useDownloadInvoiceAsExcel();
  const { mutateAsync: downloadCsv, isPending: isCsvDownloading } =
    useDownloadInvoiceAsCsv();
  const {
    mutateAsync: downloadTransaction,
    isPending: isTransactionDownloading,
  } = useDownloadInvoiceTransaction();
  const handlePdf = useCallback(
    async (ExcludeZero: boolean) => {
      try {
        const { data: blob, filename } = await downloadPdf({
          InvoiceId,
          ExcludeZero,
        });
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/pdf' }),
        );
        const link = document.createElement('a');
        link.id = 'heka_invoice_pdf_download';
        link.href = url;
        if (filename) {
          link.download = filename;
        }
        const child = document.body.appendChild(link);
        link.click();
        document.body.removeChild(child);
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
    [InvoiceId, downloadPdf, onClose],
  );
  const handleExcel = useCallback(async () => {
    try {
      const { data: blob, filename } = await downloadExcel(InvoiceId);
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.id = 'heka_invoice_excel_download';
      link.href = url;
      if (filename) {
        link.download = filename;
      }
      const child = document.body.appendChild(link);
      link.click();
      document.body.removeChild(child);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [InvoiceId, downloadExcel, onClose]);
  const handleCsv = useCallback(async () => {
    try {
      const { data: blob, filename } = await downloadCsv(InvoiceId);
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'text/csv',
        }),
      );
      const link = document.createElement('a');
      link.id = 'heka_invoice_csv_download';
      link.href = url;
      if (filename) {
        link.download = filename;
      }
      const child = document.body.appendChild(link);
      link.click();
      document.body.removeChild(child);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [InvoiceId, downloadCsv, onClose]);
  const handleTransaction = useCallback(async () => {
    try {
      const { data: blob, filename } = await downloadTransaction(InvoiceId);
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.id = 'heka_invoice_transaction';
      link.href = url;
      if (filename) {
        link.download = filename;
      }
      const child = document.body.appendChild(link);
      link.click();
      document.body.removeChild(child);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [InvoiceId, downloadTransaction, onClose]);
  const {
    values,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = useFormik<{
    Type: FileType | '';
    ExcludeZero: boolean;
  }>({
    initialValues: {
      Type: '',
      ExcludeZero: false,
    },
    validationSchema: Yup.object()
      .shape({
        Type: Yup.mixed().oneOf(['PDF', 'EXCEL', 'TRANSACTION']).defined(),
        ExcludeZero: Yup.bool().defined(),
      })
      .defined(),
    onSubmit: async (v) => {
      switch (v.Type) {
        case 'PDF':
          await handlePdf(v.ExcludeZero);
          break;
        case 'EXCEL':
          InvoiceType === 'ncp' ? await handleExcel() : await handleCsv();
          break;
        case 'TRANSACTION':
          await handleTransaction();
          break;
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="청구서 다운로드"
      description="청구서 형식을 선택해주세요"
      maxWidth="320px"
      minHeight="376px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={
              isPdfDownloading || isExcelDownloading || isTransactionDownloading
            }
            disabled={!isValid || _.isEmpty(values.Type)}
          >
            {globalT('button.download')}
          </LoadingButton>
        </>
      }
    >
      <RadioGroup
        value={values.Type}
        onChange={(e, v) => setFieldValue('Type', v as FileType)}
        sx={{ gap: '16px', ml: '11px' }}
      >
        <FormControlLabel
          value="PDF"
          control={<Radio size="medium" />}
          label="PDF"
        />
        <FormControlLabel
          value="EXCEL"
          control={<Radio size="medium" />}
          label="Excel"
        />
        <FormControlLabel
          value="TRANSACTION"
          control={<Radio size="medium" />}
          label="거래명세서(Excel)"
        />
      </RadioGroup>
      {values.Type === 'PDF' && (
        <FormControlLabel
          name="ExcludeZero"
          value={values.ExcludeZero}
          onChange={handleChange}
          onBlur={handleBlur}
          control={
            <Checkbox
              size="small"
              color="emphasis"
              checked={values.ExcludeZero}
            />
          }
          label="클라우드 요금 없는 항목 제외"
          sx={{ mt: '24px' }}
        />
      )}
    </FormModal>
  );
}
