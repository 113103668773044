import { useCallback } from 'react';

export default function useUserHelper() {
  const includesRole = useCallback((user: User, role: UserRole) => {
    switch (role) {
      case 'ROOT':
        return user.Role == 'ROOT';
      case 'ADMIN':
        return user.Role == 'ROOT' || user.Role == 'ADMIN';
      case 'MANAGER':
        return (
          user.Role == 'ROOT' || user.Role == 'ADMIN' || user.Role == 'MANAGER'
        );
      case 'CLIENT_ADMIN':
        return (
          user.Role == 'ROOT' ||
          user.Role == 'ADMIN' ||
          user.Role == 'MANAGER' ||
          user.Role == 'CLIENT_ADMIN'
        );
      case 'CLIENT_USER':
        return (
          user.Role == 'ROOT' ||
          user.Role == 'ADMIN' ||
          user.Role == 'MANAGER' ||
          user.Role == 'CLIENT_ADMIN' ||
          user.Role == 'CLIENT_USER'
        );
    }
  }, []);
  return { includesRole };
}
