import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from '@components/layout/auth';
import MainLayout from '@components/layout/main';
import LoginPage from '@pages/login';
import PasswordRecoveryPage from '@pages/login/recover';
import PasswordRecoverySentPage from '@pages/login/recover/sent';
import PasswordResetPage from '@pages/login/reset';
import RegisterPage from '@pages/register';
import RegisterDonePage from '@pages/register/done';
import OnboardPage from '@pages/onboard';
import OnboardDonePage from '@pages/onboard/done';
import OnboardIntegrateAWSPage from '@pages/onboard/integrate/aws';
import OnboardIntegrateNCPPage from '@pages/onboard/integrate/ncp';
import OnboardVerifyNHNUserPage from '@pages/onboard/integrate/nhn';
import OnboardIntegrateNHNPage from '@pages/onboard/integrate/nhn/reseller';
import OnboardIntegrateAWSDonePage from '@pages/onboard/integrate/aws/done';
import OnboardConfigureAWSCURPage from '@pages/onboard/configure/aws/cur';
import DashboardPage from '@pages/dashboard';
import CostManagementPage from '@pages/cost';
import SalesManagementPage from '@pages/sales';
import NCPSalesManagementPage from '@pages/sales/ncp';
import NHNSalesManagementPage from '@pages/sales/nhn';
import SettingsProfilePage from '@pages/settings/profile';
import SettingsPermissionPage from '@pages/settings/permission';
import SettingsPermissionDetailPage from '@pages/settings/permission/detail';
import SettingsAccountPage from '@pages/settings/account';
import SettingsAWSAccountPage from '@pages/settings/account/aws';
import SettingsNCPAccountPage from '@pages/settings/account/ncp';
import SettingsNHNAccountPage from '@pages/settings/account/nhn';
import InvoiceListPage from '@pages/invoice';
import InvoiceDetailPage from '@pages/invoice/detail';
import CustomerPage from '@pages/customer';
import CustomerDetailPage from '@pages/customer/detail';
import UserInitialPage from '@pages/user/initial';
import UserInitialInformationPage from '@pages/user/initial/info';
import UserCustomPage from '@pages/user/custom';
import UserDonePage from '@pages/user/done';
import ReportListPage from '@pages/report';
import ReportDetailPage from '@pages/report/detail';
import SettingsCompanyPage from '@pages/settings/company';
import SettingsCustomerPage from '@pages/settings/customer';
import SettingsCustomerDetailPage from '@pages/settings/customer/detail';

export default function Pages() {
  return (
    <Routes>
      <Route element={<AuthLayout showWordPhrase />}>
        <Route path="login">
          <Route index element={<LoginPage />} />
          <Route path="recover">
            <Route index element={<PasswordRecoveryPage />} />
            <Route path="sent" element={<PasswordRecoverySentPage />} />
          </Route>
        </Route>
      </Route>
      <Route element={<AuthLayout />}>
        <Route path="login/reset/:token" element={<PasswordResetPage />} />
        <Route path="register">
          <Route index element={<RegisterPage />} />
          <Route path="done" element={<RegisterDonePage />} />
        </Route>
        <Route path="onboard">
          <Route index element={<OnboardPage />} />
          <Route path="done" element={<OnboardDonePage />} />
          <Route path="integrate">
            <Route path="aws">
              <Route index element={<OnboardIntegrateAWSPage />} />
              <Route path=":uid" element={<OnboardIntegrateAWSDonePage />} />
            </Route>
            <Route path="ncp" element={<OnboardIntegrateNCPPage />} />
            <Route path="nhn">
              <Route path=":uid" element={<OnboardIntegrateNHNPage />} />
              <Route index element={<OnboardVerifyNHNUserPage />} />
            </Route>
          </Route>
          <Route path="configure">
            <Route
              path="aws/cur/:uid"
              element={<OnboardConfigureAWSCURPage />}
            />
          </Route>
        </Route>
        <Route path="user">
          <Route path="initial">
            <Route index element={<UserInitialPage />} />
            <Route path="info" element={<UserInitialInformationPage />} />
          </Route>
          <Route path="custom" element={<UserCustomPage />} />
          <Route path="done" element={<UserDonePage />} />
        </Route>
      </Route>
      <Route element={<MainLayout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="report" element={<ReportListPage />} />
        <Route path="cost" element={<CostManagementPage />} />
        <Route path="invoice">
          <Route index element={<InvoiceListPage />} />
          <Route path=":invoiceId" element={<InvoiceDetailPage />} />
        </Route>
        <Route path="customer">
          <Route index element={<CustomerPage />} />
          <Route path=":uid" element={<CustomerDetailPage />} />
        </Route>
        <Route path="sales" element={<SalesManagementPage />}>
          <Route index element={<></>} />
          <Route path="ncp" element={<NCPSalesManagementPage />} />
          <Route path="nhn" element={<NHNSalesManagementPage />} />
          <Route path="*" element={<Navigate to=".." />} />
        </Route>
      </Route>
      <Route path="settings" element={<MainLayout settings />}>
        <Route path="profile" element={<SettingsProfilePage />} />
        <Route path="permission">
          <Route index element={<SettingsPermissionPage />} />
          <Route path=":uid" element={<SettingsPermissionDetailPage />} />
        </Route>
        <Route path="customer">
          <Route index element={<SettingsCustomerPage />} />
          <Route path=":uid" element={<SettingsCustomerDetailPage />} />
        </Route>
        <Route path="account" element={<SettingsAccountPage />}>
          <Route index element={<></>} />
          <Route path="aws" element={<SettingsAWSAccountPage />} />
          <Route path="ncp" element={<SettingsNCPAccountPage />} />
          <Route path="nhn" element={<SettingsNHNAccountPage />} />
          <Route path="*" element={<Navigate to=".." />} />
        </Route>
        <Route path="company" element={<SettingsCompanyPage />} />
      </Route>
      <Route path="report" element={<MainLayout disableLeftPanel />}>
        <Route path="create" element={<ReportDetailPage />} />
        <Route path=":uid" element={<ReportDetailPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}
