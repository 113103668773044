import { useTranslation } from 'react-i18next';
import { ReportContentSection } from './styled';
import SimpleTable from '@components/table/simple';
import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatDate, formatKRW, formatLocaleString } from '@utils/formatter';
import { useCurrentInfo } from '@hooks';

export default function ReportSummarySection({
  Summary = [],
  includeMargin = false,
}: Partial<Pick<ReportContent, 'Summary'>> & { includeMargin?: boolean }) {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const { hasRole } = useCurrentInfo(true);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ReportSummary>();
    const items: Array<ColumnDef<ReportSummary, any>> = [
      columnHelper.accessor('Date', {
        id: 'Date',
        header: t('general.date'),
        cell: (v) => formatDate(v.getValue(), 'YYYY년 MMM'),
      }),
      columnHelper.accessor('TotalCost', {
        id: 'TotalCost',
        header: t('report.billingAmount'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
    ];
    if (hasRole('MANAGER')) {
      items.push(
        columnHelper.accessor('Customer', {
          id: 'Customer',
          header: t('report.numOfAccumulatedCustomers'),
          cell: (v) => formatLocaleString(v.getValue()),
          meta: {
            align: 'right',
          },
        }),
        columnHelper.accessor('NewCustomer', {
          id: 'NewCustomer',
          header: t('report.numOfNewCustomers'),
          cell: (v) => formatLocaleString(v.getValue()),
          meta: {
            align: 'right',
          },
        }),
      );
    }
    if (includeMargin) {
      items.push(
        columnHelper.accessor('Margin', {
          id: 'Margin',
          header: t('report.ncpMarginAmount'),
          cell: (v) => formatKRW(v.getValue()),
          meta: {
            align: 'right',
          },
        }),
      );
    }
    return items;
  }, [hasRole, includeMargin, t]);
  return (
    <ReportContentSection title={reportT('title.summary')}>
      <SimpleTable columns={columns} data={Summary} />
    </ReportContentSection>
  );
}
