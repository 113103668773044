import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Button, Typography, Divider, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import _ from 'lodash';
import {
  useGetNHNAccounts,
  useGetNHNAssignedProjectList,
} from '@api/queries/nhn/account';
import {
  DeleteAccessAccountModal,
  EditAccessAccountModal,
} from '@features/settings/account/ncp';
import {
  DeleteNHNAccountModal,
  EditNHNAccountModal,
  EditNHNAccountUserModal,
} from '@features/settings/account/nhn';
import SimpleTable from '@components/table/simple';
import { formatDate } from '@utils/formatter';

export default function SettingsNHNAccountPage() {
  const { t } = useTranslation('table');
  const { t: settingsT } = useTranslation('settings');
  const [toEdit, setToEdit] = useState<NHNAccount | undefined>(undefined);
  const [toEditUser, setToEditUser] = useState<string | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: nhnAccounts } = useGetNHNAccounts();
  const { data: projects } = useGetNHNAssignedProjectList();
  const resellerColumns = useMemo(() => {
    const columnHelper = createColumnHelper<NHNAccount>();
    return [
      columnHelper.accessor('Email', {
        id: 'Email',
        header: t('settings.resellerEmail'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('AccountName', {
        id: 'AccountName',
        header: t('settings.accountAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('CreatedAt', {
        id: 'CreatedAt',
        header: t('settings.registeredOn'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD'),
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'EditUser',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEditUser(v.row.original.UID)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('settings.deleteAccount'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  const projectColumns = useMemo(() => {
    const columnHelper = createColumnHelper<NHNAssignedProject>();
    return [
      columnHelper.accessor('ProjectInfo.ProjectId', {
        id: 'ProjectId',
        header: t('settings.projectId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ProjectInfo.ProjectName', {
        id: 'ProjectName',
        header: t('settings.projectName'),
        cell: (v) => {
          const orgName = v.row.original.ProjectInfo.OrgName;
          if (!_.isEmpty(orgName)) {
            return `${v.getValue()} (${orgName})`;
          }
          return v.getValue();
        },
      }),
      columnHelper.accessor('OrgName', {
        id: 'OrgName',
        header: t('customer.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ProjectInfo.AccountName', {
        id: 'AccountName',
        header: t('settings.parentReseller'),
        cell: (v) => v.getValue() || '-',
      }),
      columnHelper.accessor('CreatedAt', {
        id: 'CreatedAt',
        header: t('settings.registeredOn'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD'),
      }),
    ];
  }, [t]);
  return (
    <Box>
      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="24px"
      >
        {settingsT('title.account.nhn')}
      </Typography>
      <SimpleTable
        columns={resellerColumns}
        data={nhnAccounts?.data}
        title={t('title.settings.account.reseller')}
        enableGlobalFilter
        enablePagination
      >
        <Button href="/onboard/integrate/nhn" startIcon={<Add />}>
          {settingsT('button.addAccount')}
        </Button>
      </SimpleTable>
      <Divider sx={{ mt: '64px', mb: '40px' }} />
      <SimpleTable
        columns={projectColumns}
        data={projects?.data}
        title={t('title.settings.account.project')}
        enableGlobalFilter
        enablePagination
      />
      <EditNHNAccountModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={toEdit}
      />
      <EditNHNAccountUserModal
        open={typeof toEditUser !== 'undefined'}
        onClose={() => setToEditUser(undefined)}
        UID={toEditUser}
      />
      <DeleteNHNAccountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </Box>
  );
}
