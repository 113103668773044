import { ReactNode, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUpdateInvoiceStatus } from '@api/queries/invoice';
import {
  INVOICE_EDIT_STATE,
  INVOICE_PUBLISH_STATE,
  INVOICE_UPDATE_STATE,
} from '@features/invoice/detail/atom';
import { InvoiceChip } from '@components/styled/chip';
import { formatDate } from '@utils/formatter';
import PublishInvoiceModal from './PublishInvoiceModal';

function FieldItem({
  label,
  content,
}: {
  label: string;
  content: ReactNode | string;
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
      <Typography
        color="textSecondary"
        letterSpacing="0.64px"
        sx={{ flexBasis: '80px' }}
      >
        {label}
      </Typography>
      {typeof content === 'string' ? (
        <Typography color="text.main" sx={{ flex: 1 }}>
          {content}
        </Typography>
      ) : (
        content
      )}
    </Box>
  );
}

type Props = Pick<
  Invoice,
  | 'OrganizationId'
  | 'Status'
  | 'Date'
  | 'InvoiceId'
  | 'InvoicedDate'
  | 'PaymentDate'
  | 'DepositUsed'
> &
  Pick<Organization, 'Name' | 'Contact' | 'BusinessLocation'>;
export default function InvoiceHeader({
  OrganizationId,
  Status,
  Date,
  InvoiceId,
  InvoicedDate,
  PaymentDate,
  DepositUsed,
  Name,
  Contact,
  BusinessLocation,
}: Props) {
  const { t } = useTranslation('invoice');
  const editMode = useRecoilValue(INVOICE_EDIT_STATE);
  const [publishState, setPublishState] = useRecoilState(INVOICE_PUBLISH_STATE);
  const setUpdateState = useSetRecoilState(INVOICE_UPDATE_STATE);
  const { mutateAsync, isPending } = useUpdateInvoiceStatus();
  const handleInvoicedDateChange = useCallback(
    async (newDate: dayjs.Dayjs | null) => {
      if (
        !newDate ||
        (InvoicedDate && dayjs(InvoicedDate).isSame(newDate, 'days'))
      ) {
        return;
      }
      if (newDate) {
        try {
          await mutateAsync({
            InvoiceId,
            Status,
            InvoicedDate: newDate.toDate(),
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [InvoiceId, InvoicedDate, Status, mutateAsync],
  );
  const handlePaymentDateChange = useCallback(
    async (newDate: dayjs.Dayjs | null) => {
      if (
        !newDate ||
        (PaymentDate && dayjs(PaymentDate).isSame(newDate, 'days'))
      ) {
        return;
      }
      if (newDate) {
        try {
          await mutateAsync({
            InvoiceId,
            Status,
            PaymentDate: newDate.toDate(),
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [InvoiceId, PaymentDate, Status, mutateAsync],
  );
  useEffect(() => {
    setPublishState((v) => ({
      ...v,
      OrganizationId,
      Status,
      Date,
      InvoiceId,
      DepositUsed: DepositUsed ?? undefined,
      InvoicedDate: InvoicedDate ?? undefined,
      PaymentDate: PaymentDate ?? undefined,
    }));
  }, [
    setPublishState,
    OrganizationId,
    Status,
    Date,
    InvoiceId,
    InvoicedDate,
    PaymentDate,
    DepositUsed,
  ]);
  useEffect(() => {
    setUpdateState(isPending);
  }, [isPending, setUpdateState]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      <Box>
        <InvoiceChip status={Status} />
        <Typography
          fontSize="28px"
          fontWeight={700}
          letterSpacing="1.12px"
          mt="16px"
        >
          {Name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <FieldItem
            label={t('text.usagePeriod')}
            content={`${formatDate(
              dayjs(Date).startOf('month'),
            )} ~ ${formatDate(dayjs(Date).endOf('month'))}`}
          />
          <FieldItem
            label={t('text.invoicedDate')}
            content={
              editMode ? (
                <DatePicker
                  value={InvoicedDate ? dayjs(InvoicedDate) : dayjs()}
                  onChange={handleInvoicedDateChange}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                    openPickerButton: {
                      sx: {
                        '&.Mui-disabled': {
                          background: 'none',
                        },
                      },
                    },
                  }}
                  disabled={isPending}
                  showDaysOutsideCurrentMonth
                />
              ) : InvoicedDate ? (
                formatDate(InvoicedDate)
              ) : (
                '-'
              )
            }
          />
          <FieldItem
            label={t('text.paymentDue')}
            content={
              editMode ? (
                <DatePicker
                  value={PaymentDate ? dayjs(PaymentDate) : dayjs()}
                  onChange={handlePaymentDateChange}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                    openPickerButton: {
                      sx: {
                        '&.Mui-disabled': {
                          background: 'none',
                        },
                      },
                    },
                  }}
                  disabled={isPending}
                  showDaysOutsideCurrentMonth
                />
              ) : PaymentDate ? (
                formatDate(PaymentDate)
              ) : (
                '-'
              )
            }
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Typography
              color="textSecondary"
              letterSpacing="0.64px"
              sx={{ flexBasis: '80px' }}
            >
              {t('text.recipient')}
            </Typography>
            <Typography
              color="text.main"
              lineHeight="150%"
              sx={{ flex: 1 }}
              dangerouslySetInnerHTML={{
                __html: `${Name}<br/>${BusinessLocation}`,
              }}
            />
          </Box>
        </Box>
      </Box>
      <PublishInvoiceModal
        open={publishState.open}
        onClose={() => setPublishState((v) => ({ ...v, open: false }))}
        type={Status === 'Unissued' ? 'initial' : 'correction'}
        Contacts={Contact}
      />
    </Box>
  );
}
