import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { CLOUD_USAGE_BANNER_STATE } from '@atoms/global/banner';

export function useCloudUsageBanner(): [boolean, () => void] {
  const [rejected, setRejected] = useRecoilState(CLOUD_USAGE_BANNER_STATE);
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setRejected(dayjs().toDate());
  }, [setRejected]);
  useEffect(() => {
    if (dayjs().date() < 6) {
      if (
        !rejected ||
        (rejected && dayjs(rejected).isBefore(dayjs(), 'months'))
      ) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [rejected]);
  return [open, onClose];
}
