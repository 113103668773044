export const GET_COMPANY = ['COMPANY', 'GET_COMPANY'];
export const GET_COMPANY_BY_ID = ['COMPANY', 'GET_COMPANY_BY_ID'];
export const UPDATE_COMPANY = ['COMPANY', 'UPDATE_COMPANY'];
export const DELETE_COMPANY = ['COMPANY', 'DELETE_COMPANY'];
export const DELETE_COMPANY_BY_ID = ['COMPANY', 'DELETE_COMPANY_BY_ID'];
export const SEND_SUPPORT = ['COMPANY', 'SEND_SUPPORT'];
export const GET_COMPANY_EXTRA_INFO = ['COMPANY', 'GET_COMPANY_EXTRA_INFO'];
export const UPLOAD_COMPANY_EXTRA_INFO = [
  'COMPANY',
  'UPLOAD_COMPANY_EXTRA_INFO',
];
export const GET_LOGO = ['COMPANY', 'GET_LOGO'];
export const UPLOAD_LOGO = ['COMPANY', 'UPLOAD_LOGO'];
export const DELETE_LOGO = ['COMPANY', 'DELETE_LOGO'];
