import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useUpdateInvoiceCloudUsage } from '@api/queries/invoice';
import LargeModal from '@components/modal/large';
import { InvoiceAccountHeader } from '../../styled';
import {
  InvoiceAccordion,
  InvoiceCloudProduct,
  InvoiceCloudRegion,
  InvoiceCloudService,
} from '../styled';

const BodyTableCell = styled(TableCell)({
  padding: '8px',
});

type FormData = {
  Key: string;
  Cost: number;
  PricingUnit?: string;
  UsageAmount: number;
};

export default function EditAWSCloudUsageModal({
  open,
  onClose,
  Invoice,
  Data,
}: AWSCloudUsageModalProps) {
  const { t } = useTranslation('invoice');
  const { t: tableT } = useTranslation('table', { keyPrefix: 'invoice.aws' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateInvoiceCloudUsage();
  const handler = useCallback(
    async (v: Array<FormData>) => {
      if (Data) {
        try {
          await mutateAsync({
            InvoiceId: Data.InvoiceId,
            CSP: 'aws',
            CloudUsage: [
              {
                AccountId: Data.AccountId,
                ServiceCode: Data.ServiceCode,
                Region: Data.Region,
                Product: Data.Product,
                ProductUsage: Data.ProductUsage,
                Data: _.reduce(
                  v,
                  (obj, param) => {
                    return _.assign(obj, {
                      [param.Key]: {
                        Cost: param.Cost,
                        PricingUnit: param.PricingUnit,
                        UsageAmount: param.UsageAmount,
                      },
                    });
                  },
                  {},
                ),
              },
            ],
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
    [mutateAsync, Data, onClose],
  );
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik<Array<FormData>>({
    initialValues: [],
    validationSchema: Yup.array()
      .of(
        Yup.object()
          .shape({
            Key: Yup.string().defined(),
            Cost: Yup.number().defined(),
            PricingUnit: Yup.string(),
            UsageAmount: Yup.number().defined(),
          })
          .defined(),
      )
      .defined(),
    onSubmit: handler,
  });
  const addItem = useCallback(() => {
    const clone = _.cloneDeep(values);
    clone.push({
      Key: '',
      Cost: 0,
      PricingUnit: '',
      UsageAmount: 1,
    });
    setValues(clone);
  }, [values, setValues]);
  const removeItem = useCallback(
    (index: number) => () => {
      if (values.length > index) {
        const clone = _.cloneDeep(values);
        clone.splice(index, 1);
        setValues(clone);
      }
    },
    [values, setValues],
  );
  const hasError = useCallback(
    (key: string) => {
      const error = _.get(errors, key);
      const touch = _.get(touched, key);
      return Boolean(error && touch);
    },
    [errors, touched],
  );
  useEffect(() => {
    if (Invoice && Data) {
      const { ServiceCode, Region, Product, ProductUsage } = Data;
      const items = _.get(
        Invoice.Data,
        `${ServiceCode}.${Region}.${Product}.${ProductUsage}`,
      );
      if (items) {
        const initialValue = Object.keys(items)
          .filter((v) => v !== 'ProductFamilyTotalCost')
          .map((usage) => ({
            Key: usage,
            Cost: Invoice.Data[ServiceCode][Region][Product][ProductUsage][
              usage
            ].Cost,
            PricingUnit:
              Invoice.Data[ServiceCode][Region][Product][ProductUsage][usage]
                .PricingUnit,
            UsageAmount:
              Invoice.Data[ServiceCode][Region][Product][ProductUsage][usage]
                .UsageAmount,
          }));
        resetForm({
          values: initialValue,
        });
      }
    }
  }, [Invoice, Data, resetForm]);
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="960px"
      minHeight="880px"
      title={t('modal.cloud.edit.title')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {globalT('button.save')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ flex: 1 }}>
        <InvoiceAccordion
          csp="aws"
          accountId={Invoice.AccountId ?? ''}
          cost={Invoice.TotalCost.KRW ?? 0}
          forceOpen
        >
          {/*<InvoiceAccountHeader*/}
          {/*  accountId={Invoice.AccountId ?? ''}*/}
          {/*  totalCost={Invoice.TotalCost.KRW ?? 0}*/}
          {/*/>*/}
          <InvoiceCloudService
            name={Data?.ServiceCode ?? ''}
            cost={Number(
              _.get(Invoice.Data, `${Data?.ServiceCode}.ServiceTotalCost`),
            )}
          >
            <InvoiceCloudRegion
              name={Data?.Region ?? ''}
              cost={Number(
                _.get(
                  Invoice.Data,
                  `${Data?.ServiceCode}.${Data?.Region}.RegionTotalCost`,
                ),
              )}
            >
              <InvoiceCloudProduct name={Data?.ProductUsage ?? ''} />
            </InvoiceCloudRegion>
          </InvoiceCloudService>
          <Box sx={{ px: '40px', mb: '16px' }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', mb: '16px' }}
            >
              <Button onClick={addItem} startIcon={<Add />}>
                {t('button.addNewItem')}
              </Button>
            </Box>
            <Table size="condensed">
              <colgroup>
                <col width="380px" />
                <col width="140px" />
                <col width="112px" />
                <col width="112px" />
                <col width="64px" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>{tableT('usageDetail')}</TableCell>
                  <TableCell>{tableT('usageAmount')}</TableCell>
                  <TableCell>{tableT('pricingUnit')}</TableCell>
                  <TableCell>{tableT('usageCost')}</TableCell>
                  <TableCell align="center">{t('button.delete')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item, index) => (
                  <TableRow
                    key={`cloud-usage-edit-item-${index}`}
                    sx={{ border: 'none !important' }}
                  >
                    <BodyTableCell>
                      <TextField
                        name={`${index}.Key`}
                        value={item.Key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={hasError(`${index}.Key`)}
                        type="text"
                        inputMode="text"
                        autoComplete="off"
                        fullWidth
                      />
                    </BodyTableCell>
                    <BodyTableCell>
                      <TextField
                        name={`${index}.UsageAmount`}
                        value={item.UsageAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={hasError(`${index}.UsageAmount`)}
                        type="number"
                        inputMode="decimal"
                        autoComplete="off"
                        fullWidth
                      />
                    </BodyTableCell>
                    <BodyTableCell>
                      <TextField
                        name={`${index}.PricingUnit`}
                        value={item.PricingUnit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={hasError(`${index}.PricingUnit`)}
                        type="text"
                        inputMode="text"
                        autoComplete="off"
                        fullWidth
                      />
                    </BodyTableCell>
                    <BodyTableCell>
                      <TextField
                        name={`${index}.Cost`}
                        value={item.Cost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={hasError(`${index}.Cost`)}
                        type="number"
                        inputMode="decimal"
                        autoComplete="off"
                        fullWidth
                      />
                    </BodyTableCell>
                    <TableCell align="center">
                      <IconButton color="danger" onClick={removeItem(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </InvoiceAccordion>
      </Box>
    </LargeModal>
  );
}
