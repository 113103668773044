import { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Download, FilterList } from '@mui/icons-material';
import _ from 'lodash';
import {
  useDownloadAWSCostAsCsv,
  useGetAWSCostUsage,
} from '@api/queries/aws/cost';
import { AWS_COST_FILTER_STATE } from '@atoms/page/filter';
import PinnedTable from '@components/table/pinned';
import { formatDate, formatUSD } from '@utils/formatter';
import DateRangeFilter from '../filter/date';
import COST_FILTER_OPEN_STATE from '../filter/atom';
import { useTranslation } from 'react-i18next';

export default function CostManagementTable() {
  const { t } = useTranslation('table');
  const { t: costT } = useTranslation('cost');
  const { t: globalT } = useTranslation('global');
  const filter = useRecoilValue(AWS_COST_FILTER_STATE);
  const setFilterOpen = useSetRecoilState(COST_FILTER_OPEN_STATE);
  const { data: response } = useGetAWSCostUsage();
  const { mutateAsync, isPending } = useDownloadAWSCostAsCsv();
  const dateLabels = useMemo(() => {
    if (response?.data) {
      return response.data.Table.map((v) => v.Date);
    }
    return [];
  }, [response?.data]);
  const serviceLabels = useMemo(() => {
    if (response?.data && response.data.GroupBy !== 'Cost') {
      return Object.keys(
        _.fromPairs(
          _.sortBy(_.toPairs(response.data.TotalCostByItems), 1).reverse(),
        ),
      ).filter((v) => v !== 'TotalCost');
    }
    return [];
  }, [response?.data]);
  const tableData = useMemo(() => {
    const items: Array<Record<string, any>> = [];
    if (response?.data) {
      const { Table, GroupBy, TotalCost } = response.data;
      const tableSummary = {
        Name: '합계 총액',
        TotalCost,
      };
      dateLabels.forEach((v) => {
        const item = Table.find((i) => i.Date === v);
        if (item) {
          Object.assign(tableSummary, { [item.Date.toString()]: item.Cost });
        }
      });
      items.push(tableSummary);
      if (GroupBy !== 'Cost') {
        const { TotalCostByItems } = response.data;
        serviceLabels.forEach((service) => {
          const item = {
            Name: service,
            TotalCost: _.get(TotalCostByItems, service) ?? 0,
          };
          dateLabels.forEach((v) => {
            const byDate = Table.find((i) => i.Date === v);
            if (byDate) {
              const byItem = byDate.Items.find((j) => j.Name === service);
              if (byItem) {
                Object.assign(item, { [byDate.Date.toString()]: byItem.Cost });
              }
            }
          });
          items.push(item);
        });
      }
    }
    return items;
  }, [response?.data, dateLabels, serviceLabels]);
  const nameHeader = useMemo(() => {
    switch (response?.data.GroupBy) {
      case 'SERVICE':
        return costT('table.label.service');
      case 'TAG':
        return costT('table.label.tag');
      case 'LINKED_ACCOUNT':
        return costT('table.label.linkedAccount');
      case 'PURCHASE_TYPE':
        return costT('table.label.purchaseOption');
      default:
        return ' ';
    }
  }, [response?.data.GroupBy, costT]);
  const totalHeader = useMemo(() => {
    switch (response?.data.GroupBy) {
      case 'SERVICE':
        return costT('table.text.totalHolder', {
          name: costT('table.label.service'),
        });
      case 'TAG':
        return costT('table.text.totalHolder', {
          name: costT('table.label.tag'),
        });
      case 'LINKED_ACCOUNT':
        return costT('table.text.totalHolder', {
          name: costT('table.label.linkedAccount'),
        });
      case 'PURCHASE_TYPE':
        return costT('table.text.totalHolder', {
          name: costT('table.label.purchaseOption'),
        });
      default:
        return costT('table.text.totalCost');
    }
  }, [response?.data.GroupBy, costT]);
  const downloadCSV = useCallback(async () => {
    try {
      const { blob, filename } = await mutateAsync({
        ...filter,
        FromDate: formatDate(filter.FromDate, 'YYYY-MM-DDT00:00:00'),
        ToDate: formatDate(filter.ToDate, 'YYYY-MM-DDT23:59:59'),
      });
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: 'text/csv' }),
      );
      const link = document.createElement('a');
      link.id = 'heka_aws_cost_usage_download';
      link.href = url;
      link.download = filename ?? `AWSCostUsage_${new Date().getTime()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  }, [filter, mutateAsync]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, any>>();
    const items = [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: nameHeader,
        cell: (v) => v.getValue(),
        size: 284,
        minSize: 284,
        maxSize: 284,
      }),
      columnHelper.accessor('TotalCost', {
        id: 'TotalCost',
        header: totalHeader,
        cell: (v) => formatUSD(v.getValue()),
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
    dateLabels.forEach((v) => {
      items.push(
        columnHelper.accessor(v.toString(), {
          header: formatDate(v, 'MMM-DD'),
          cell: (c) => formatUSD(c.getValue()),
          size: 160,
          minSize: 160,
          meta: {
            align: 'right',
          },
        }),
      );
    });
    return items;
  }, [dateLabels, nameHeader, totalHeader]);
  return (
    <>
      <Typography fontSize="14px" letterSpacing="0.56px" mb="24px">
        {costT('table.title')}
      </Typography>
      <PinnedTable
        size="comfort"
        columns={columns}
        data={tableData}
        title={t('title.cost')}
        background="linear-gradient(270deg, #F8F8F8 0%, rgba(255, 255, 255, 0.00) 100%)"
      >
        <DateRangeFilter />
        <Button
          color="sub"
          startIcon={<FilterList />}
          onClick={() => setFilterOpen((v) => !v)}
        >
          {globalT('button.filter')}
        </Button>
        <LoadingButton
          onClick={downloadCSV}
          loading={isPending}
          startIcon={<Download />}
        >
          CSV
        </LoadingButton>
      </PinnedTable>
    </>
  );
}
