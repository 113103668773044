import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardService } from '@api/services';
import { DASHBOARD_KEY } from '@constants/query';

export default function useGetRevenueTrend() {
  return useSuspenseQuery({
    queryKey: DASHBOARD_KEY.GET_REVENUE_TREND,
    queryFn: () => DashboardService.getRevenueTrend(),
  });
}
