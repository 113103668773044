import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, RadioGroup, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { MenuItem, Note, Tile } from '@heka/theme';
import {
  useCreateCUR,
  useGetCURList,
  useGetPayerAccountById,
  useUpdatePayerAccount,
} from '@api/queries/aws/account';
import { useAWSCURForm } from '@features/onboard/aws';
import {
  PanelContainer,
  LeftPanel,
  MainPanel,
  BottomActions,
} from '@components/layout/auth';

const AWS_REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-southeast-3',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
] as const;

export default function OnboardConfigureAWSCURPage() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const { t } = useTranslation('onboard');
  const { t: awsT } = useTranslation('aws');
  const { data: account, setUID: setAccountId } = useGetPayerAccountById(uid);
  const { data: curList, setUID: setCURListId } = useGetCURList(uid);
  useEffect(() => {
    if (uid) {
      setAccountId(uid);
      setCURListId(uid);
    }
  }, [uid, setAccountId, setCURListId]);
  const { mutateAsync: createCUR, isPending: isCreateLoading } = useCreateCUR();
  const { mutateAsync: updateAccount, isPending: isUpdateLoading } =
    useUpdatePayerAccount();
  const { initialValues, validationSchema } = useAWSCURForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (account) {
        try {
          if (v.Type === 'create') {
            await createCUR({
              ...account.data,
              ReportName: v.ReportName!,
              BucketName: v.BucketName!,
              S3Region: v.S3Region!,
            });
          } else if (v.Type === 'exist') {
            const selectedCUR = curList?.data.find(
              (r) => r.ReportName === v.SelectedReport,
            );
            if (selectedCUR) {
              await updateAccount({
                ...account.data,
                ReportName: selectedCUR.ReportName,
                BucketName: selectedCUR.BucketName,
                ReportPrefix: selectedCUR.Prefix,
              });
            }
          }
          navigate('/onboard/done');
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('configureAWSCUR.title')}
          description={t('configureAWSCUR.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: '16px',
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: t('configureAWSCUR.content'),
              }}
            />
            <Note
              color="calm"
              title={t('configureAWSCUR.note.title')}
              content={t('configureAWSCUR.note.content')}
              sx={{ mt: '24px' }}
            />
            <RadioGroup
              name="Type"
              value={values.Type}
              onChange={handleChange}
              row
              sx={{ gap: '24px', mt: '40px' }}
            >
              <Tile
                value="exist"
                label={t('configureAWSCUR.text.exist.label')}
                description={t('configureAWSCUR.text.exist.description')}
                sx={{ flex: 1 }}
              />
              <Tile
                value="create"
                label={t('configureAWSCUR.text.create.label')}
                description={t('configureAWSCUR.text.create.description')}
                sx={{ flex: 1 }}
              />
            </RadioGroup>
            {values.Type === 'exist' && (
              <Box sx={{ width: '240px', mt: '24px' }}>
                <TextField
                  name="SelectedReport"
                  value={values.SelectedReport}
                  onChange={handleChange}
                  label={t('configureAWSCUR.form.label.selectedReport')}
                  select
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    {t('configureAWSCUR.form.holder.selectedReport')}
                  </MenuItem>
                  {curList?.data.map((cur) => (
                    <MenuItem
                      key={`cur_list_${cur.ReportName}`}
                      value={cur.ReportName}
                    >
                      {cur.ReportName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
            {values.Type === 'create' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  width: '286px',
                  mt: '24px',
                }}
              >
                <TextField
                  name="ReportName"
                  value={values.ReportName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('configureAWSCUR.form.label.reportName')}
                  placeholder={t('configureAWSCUR.form.holder.reportName')}
                  error={Boolean(errors.ReportName && touched.ReportName)}
                  helperText={
                    Boolean(errors.ReportName && touched.ReportName) &&
                    String(errors.ReportName)
                  }
                  fullWidth
                />
                <TextField
                  name="BucketName"
                  value={values.BucketName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('configureAWSCUR.form.label.bucketName')}
                  placeholder={t('configureAWSCUR.form.holder.bucketName')}
                  error={Boolean(errors.BucketName && touched.BucketName)}
                  helperText={
                    Boolean(errors.BucketName && touched.BucketName) &&
                    String(errors.BucketName)
                  }
                  fullWidth
                />
                <TextField
                  name="S3Region"
                  value={values.S3Region}
                  onChange={handleChange}
                  label={t('configureAWSCUR.form.label.region')}
                  select
                  fullWidth
                >
                  {AWS_REGIONS.map((region) => (
                    <MenuItem key={`aws_cur_${region}`} value={region}>
                      {awsT(`region.${region}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions enableSupport>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isCreateLoading || isUpdateLoading}
          disabled={!isValid || !dirty}
        >
          {t('configureAWSCUR.cta')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
