import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGetAdditionalServiceTrend } from '@api/queries/dashboard';
import { DashboardNotFound } from '@features/dashboard/styled';
import { formatLocaleString } from '@utils/formatter';
import dayjs from 'dayjs';

export default function ServiceRevenueChart() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetAdditionalServiceTrend();
  const targetData = useMemo(() => {
    return response?.data.find((v) =>
      dayjs(v.Month).isSame(dayjs().subtract(1, 'months')),
    );
  }, [response?.data]);
  const totalAmount = useMemo(() => {
    return targetData?.BillingAmount ?? 0;
  }, [targetData]);
  const additionalAmount = useMemo(() => {
    return targetData?.BillingAdditional ?? 0;
  }, [targetData]);
  const isDataEmpty = useMemo(() => {
    return totalAmount === 0;
  }, [totalAmount]);
  const additionalRatio = useMemo(() => {
    if (totalAmount > 0) {
      return (additionalAmount / totalAmount) * 100.0;
    }
    return 0;
  }, [totalAmount, additionalAmount]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.additionalServiceCost')}
      </Typography>
      {!isDataEmpty ? (
        <>
          <Typography
            color="#362F26"
            fontSize="24px"
            fontWeight={500}
            mb="32px"
          >
            {globalT('format.currency.krw', { value: additionalAmount })}
          </Typography>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mb: '8px',
                }}
              >
                {/*<Typography color="#D9BE89">*/}
                {/*  {formatLocaleString(0)}%*/}
                {/*</Typography>*/}
                <Typography color="#E17A00">
                  {formatLocaleString(additionalRatio)}%
                </Typography>
              </Box>
              <Box
                sx={{
                  height: '70px',
                  minHeight: '70px',
                  maxHeight: '70px',
                  display: 'flex',
                }}
              >
                <Box sx={{ background: '#F9E49B', flex: 1 }} />
                <Box
                  sx={{ background: '#E17A00', minWidth: additionalRatio }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: '32px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px',
                mb: '16px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '999px',
                    background: '#F9E49B',
                  }}
                />
                <Typography color="#362F26" fontSize="14px">
                  전체 납부 금액
                </Typography>
              </Box>
              <Typography color="#362F26" fontSize="14px">
                {globalT('format.currency.krw', { value: totalAmount })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '999px',
                    background: '#E17A00',
                  }}
                />
                <Typography color="#362F26" fontSize="14px">
                  부가서비스 금액
                </Typography>
              </Box>
              <Typography color="#362F26" fontSize="14px">
                {globalT('format.currency.krw', { value: additionalAmount })}
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
