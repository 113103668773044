import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { BackToListButton } from '@components/ui';
import CustomerDetailHeader from '@features/customer/detail/header';
import CustomerDetailTabs from '@features/customer/detail/tabs';
import { SuspensePaper } from '@components/suspense';
import {
  CustomerAWSAccount,
  CustomerContact,
  CustomerContract,
  CustomerDeposit,
  CustomerDiscount,
  CustomerEtc,
  CustomerInformation,
  CustomerManager,
  CustomerNCPAccount,
  CustomerNHNAccount,
  CustomerService,
} from '@features/customer/detail/sections';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

function CustomerDetailSections() {
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');
  if (!UID || isEmpty(UID)) {
    return null;
  }
  return (
    <>
      {section === 'info' && <CustomerInformation />}
      {section === 'contact' && <CustomerContact UID={UID} />}
      {section === 'manager' && <CustomerManager UID={UID} />}
      {section === 'aws' && <CustomerAWSAccount />}
      {section === 'ncp' && <CustomerNCPAccount />}
      {section === 'nhn' && <CustomerNHNAccount />}
      {section === 'service' && <CustomerService />}
      {section === 'discount' && <CustomerDiscount />}
      {section === 'deposit' && <CustomerDeposit UID={UID} />}
      {section === 'contract' && <CustomerContract UID={UID} />}
      {section === 'etc' && <CustomerEtc UID={UID} />}
    </>
  );
}

export default function CustomerDetailPage() {
  const { uid } = useParams();
  const setCustomerId = useSetRecoilState(CUSTOMER_UID_STATE);
  useEffect(() => {
    if (!isEmpty(uid)) {
      setCustomerId(uid);
    }
    return () => {
      setCustomerId(undefined);
    };
  }, [uid, setCustomerId]);
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <BackToListButton href={-1} />
      <CustomerDetailHeader />
      <CustomerDetailTabs />
      <SuspensePaper
        FallbackProps={{ size: 48, sx: { flex: 1 } }}
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '32px' }}
      >
        <CustomerDetailSections />
      </SuspensePaper>
    </Box>
  );
}
