import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useCreateBulkUsers() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: USER_KEY.CREATE_BULK_USERS,
    mutationFn: (v: CreateBulkUsers) => UserService.createBulkUsers(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] });
    },
  });
}
