import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: USER_KEY.DELETE_USER,
    mutationFn: (UID: string) => UserService.deleteUser(UID),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] });
    },
  });
}
