import { useSuspenseQuery } from '@tanstack/react-query';
import { ReportService } from '@api/services';
import { REPORT_KEY } from '@constants/query';

export default function useGetReportList() {
  return useSuspenseQuery({
    queryKey: REPORT_KEY.GET_REPORT_LIST,
    queryFn: () => ReportService.getReportList(),
  });
}
