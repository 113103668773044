import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from '@heka/theme';

export default function PasswordRecoverySentPage() {
  const { t } = useTranslation('auth');
  return (
    <>
      <Box sx={{ py: '24px' }}>
        <Typography fontWeight={500} letterSpacing="1.28px">
          {t('recoverySent.description')}
        </Typography>
      </Box>
      <Box sx={{ py: '40px' }}>
        <Button component="a" href="/login" color="emphasis1" fullWidth>
          {t('recoverySent.cta')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          pt: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Typography>{t('common.text.login')}</Typography>
          <Link size="comfort" color="calm" href="/login">
            {t('common.cta.login')}
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Typography>{t('common.text.register')}</Typography>
          <Link size="comfort" color="calm" href="/register">
            {t('common.cta.register')}
          </Link>
        </Box>
      </Box>
    </>
  );
}
