import type { Dispatch, MouseEvent, SetStateAction } from 'react';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, ClickAwayListener, ListItemText } from '@mui/material';
import _ from 'lodash';
import { useGetOrganizationAutoComplete } from '@api/queries/organization';
import { AutocompleteIndicator } from '@components/styled/input';
import { FilterPopper } from '@components/styled/popper';
import { FilterAutocomplete } from '@components/styled/autocomplete';

type FilterOption = string | { UID: string; Name: string };
type IProps = {
  basis: 'org';
  filter: OrganizationFilter;
  setFilter: Dispatch<SetStateAction<OrganizationFilter>>;
};
export default function CustomerFilterAutocomplete({
  basis,
  filter,
  setFilter,
}: IProps) {
  const { t } = useTranslation('customer');
  const { t: filterT } = useTranslation('global', {
    keyPrefix: 'component.filter',
  });
  const { t: globalT } = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = useMemo(() => {
    return open ? `heka-customer-filter-${basis}-popper` : undefined;
  }, [basis, open]);
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { data: response } = useGetOrganizationAutoComplete();
  const checkStringArray = useCallback(
    (target: Array<unknown>): target is Array<string> => {
      return target.every((v) => typeof v === 'string');
    },
    [],
  );
  const labelKey = useMemo(() => {
    switch (basis) {
      case 'org':
        return 'filter.label.org';
    }
  }, [basis]);
  const values = useMemo<Array<FilterOption>>(() => {
    switch (basis) {
      case 'org':
        return filter.Organizations ?? [];
    }
  }, [basis, filter]);
  const optionsOrigin = useMemo<Array<FilterOption>>(() => {
    if (response?.data) {
      switch (basis) {
        case 'org':
          return response.data.Organizations;
      }
    }
    return [];
  }, [basis, response?.data, globalT]);
  const options = useMemo(() => {
    if (checkStringArray(optionsOrigin)) {
      return _.concat(['HEKA_SELECT_ALL'], optionsOrigin);
    }
    return [{ UID: 'HEKA_SELECT_ALL', Name: '' }, ...optionsOrigin];
  }, [checkStringArray, optionsOrigin]);
  const setValues = useCallback(
    (newValue: Array<FilterOption>) => {
      if (basis === 'org') {
        setFilter((v) => ({
          ...v,
          Organizations: newValue as Array<{ UID: string; Name: string }>,
        }));
      }
    },
    [basis, setFilter],
  );
  const isAllSelected = useMemo(() => {
    if (checkStringArray(values) && checkStringArray(optionsOrigin)) {
      return optionsOrigin.every((v) => values.includes(v));
    }
    return optionsOrigin.every((v) =>
      Boolean(
        values.find(
          (k) =>
            typeof v !== 'string' && typeof k !== 'string' && k.UID === v.UID,
        ),
      ),
    );
  }, [checkStringArray, optionsOrigin, values]);
  const isPartialSelected = useMemo(() => {
    if (checkStringArray(values) && checkStringArray(optionsOrigin)) {
      return optionsOrigin.some((v) => values.includes(v));
    }
    return optionsOrigin.some((v) =>
      Boolean(
        values.find(
          (k) =>
            typeof v !== 'string' && typeof k !== 'string' && k.UID === v.UID,
        ),
      ),
    );
  }, [checkStringArray, optionsOrigin, values]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else {
      setValues(optionsOrigin ?? []);
    }
  }, [isAllSelected, optionsOrigin, setValues]);
  const getChecked = useCallback(
    (option: FilterOption, selected: boolean) => {
      if (typeof option === 'string') {
        if (option === 'HEKA_SELECT_ALL') {
          return isAllSelected;
        }
      } else if (option.UID === 'HEKA_SELECT_ALL') {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: FilterOption) => {
      if (typeof option === 'string') {
        if (option === 'HEKA_SELECT_ALL') {
          return isPartialSelected && !isAllSelected;
        }
      } else if (option.UID === 'HEKA_SELECT_ALL') {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  const getOptionLabel = useCallback(
    (option: FilterOption) => {
      if (typeof option !== 'string') {
        if (option.UID === 'HEKA_SELECT_ALL') {
          return filterT('option.selectAll', {
            count: Math.max(optionsOrigin.length, 0),
          });
        }
        return option.Name;
      }
      if (option === 'HEKA_SELECT_ALL') {
        return filterT('option.selectAll', {
          count: Math.max(optionsOrigin.length, 0),
        });
      }
      if (_.isEmpty(option)) {
        return filterT('option.none', { name: t(labelKey) });
      }
      return option;
    },
    [basis, t, filterT, globalT, labelKey, optionsOrigin.length],
  );
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return filterT('holder.disabled', { name: t(labelKey) });
    }
    if (values.length) {
      return filterT('holder.selected', {
        count: values.length,
      });
    }
    return filterT('holder.selected', { context: 'all', name: t(labelKey) });
  }, [filterT, labelKey, optionsOrigin.length, t, values.length]);
  return (
    <Box>
      <AutocompleteIndicator
        open={open}
        onClick={handleOpen}
        label={t(labelKey)}
        placeholder={placeholder}
      />
      <FilterPopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <FilterAutocomplete
              open
              onClose={(e, r) => {
                if (r === 'escape') {
                  handleClose();
                }
              }}
              onChange={(e, v, r) => {
                if (
                  e.type === 'keydown' &&
                  (e as KeyboardEvent).key === 'Backspace' &&
                  r === 'removeOption'
                ) {
                  return;
                }
                if (
                  v.find(
                    (v) =>
                      (typeof v === 'string' && v === 'HEKA_SELECT_ALL') ||
                      (typeof v !== 'string' && v.UID === 'HEKA_SELECT_ALL'),
                  )
                ) {
                  toggleAll();
                  return;
                }
                setValues(v);
              }}
              value={values}
              options={options}
              getOptionLabel={(v) => (typeof v === 'string' ? v : v.Name)}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string' && typeof value === 'string')
                  return option === value;
                if (typeof option !== 'string' && typeof value !== 'string')
                  return option.UID === value.UID;
                return false;
              }}
              disabled={!optionsOrigin.length}
              renderOption={(props, option, { selected }) => (
                <li {...props} aria-selected="false">
                  <Checkbox
                    checked={getChecked(option, selected)}
                    indeterminate={getIndeterminate(option)}
                    sx={{ p: 0, pr: '8px' }}
                  />
                  <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
                    {getOptionLabel(option)}
                  </ListItemText>
                </li>
              )}
            />
          </div>
        </ClickAwayListener>
      </FilterPopper>
    </Box>
  );
}
