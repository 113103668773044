import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useUpdateBillingCustom() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.UPDATE_BILLING_CUSTOM,
    mutationFn: ({ UID, ...v }: UpdateBillingCustom & { UID: string }) =>
      OrganizationService.updateBillingCustom(UID, v),
    onSuccess: ({ data }, variables) => {
      queryClient.setQueryData<
        SingleResponse<
          Organization,
          {
            TotalBillingAmount: number;
            TotalDiscountAmount: number;
            LastInvoicedAmount: number;
            DepositBalance: number;
          }
        >
      >(
        [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, variables.UID],
        (oldData) => {
          if (oldData) {
            return Object.assign(oldData, { data });
          }
          return oldData;
        },
      );
      queryClient.setQueryData<
        SingleResponse<
          Organization,
          {
            TotalBillingAmount: number;
            TotalDiscountAmount: number;
            LastInvoicedAmount: number;
            DepositBalance: number;
          }
        >
      >(
        [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, variables.UID],
        (oldData) => {
          if (oldData) {
            return Object.assign(oldData, { data });
          }
          return oldData;
        },
      );
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, variables.UID],
      });
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, variables.UID],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
    },
  });
}
