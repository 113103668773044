import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { CostByStatus, InvoiceTable } from '@features/invoice/list';
import { SuspenseBox, SuspensePaper } from '@components/suspense';
import { useCurrentInfo } from '@hooks';

export default function InvoiceListPage() {
  const { t } = useTranslation('invoice');
  const { hasRole } = useCurrentInfo();
  return (
    <Paper sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
        {hasRole('MANAGER') && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
              {t('title.statusSummary')}
            </Typography>
            <Grid container spacing="24px">
              <Grid tablet={3} zero={12}>
                <SuspensePaper sx={{ p: '16px 24px' }}>
                  <CostByStatus status="Unissued" />
                </SuspensePaper>
              </Grid>
              <Grid tablet={3} zero={12}>
                <SuspensePaper sx={{ p: '16px 24px' }}>
                  <CostByStatus status="Invoiced" />
                </SuspensePaper>
              </Grid>
              <Grid tablet={3} zero={12}>
                <SuspensePaper sx={{ p: '16px 24px' }}>
                  <CostByStatus status="Paid" />
                </SuspensePaper>
              </Grid>
              <Grid tablet={3} zero={12}>
                <SuspensePaper sx={{ p: '16px 24px' }}>
                  <CostByStatus status="Overdue" />
                </SuspensePaper>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
            {t('title.invoiceList')}
          </Typography>
          <SuspenseBox>
            <InvoiceTable />
          </SuspenseBox>
        </Box>
      </Box>
    </Paper>
  );
}
