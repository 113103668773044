import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useGetPayerAccountById(UID?: string) {
  const [Id, setUID] = useState(UID ?? '');
  const query = useSuspenseQuery({
    queryKey: [...AWS_ACCOUNT_KEY.GET_PAYER_ACCOUNT_BY_ID, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? AWSAccountService.getPayerAccountById(queryKey[2])
        : null,
    refetchOnMount: false,
  });
  return {
    ...query,
    setUID,
  };
}
