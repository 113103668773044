import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useGetOrganizations } from '@api/queries/organization';
import SimpleTable from '@components/table/simple';

type Form = {
  UID: string;
  Name: string;
};

type Props = Pick<ReturnType<typeof useFormik>, 'values' | 'setFieldValue'> & {
  valueKey?: string;
};
export default function SettingsPermissionForm({
  values,
  setFieldValue,
  valueKey = '',
}: Props) {
  const { data: response } = useGetOrganizations();
  const getPermission = useCallback(
    (UID: string) => {
      const contents = _.get(values, valueKey);
      if (_.isArray(contents)) {
        return contents.includes(UID);
      }
      return undefined;
    },
    [values, valueKey],
  );
  const getPermissionIndex = useCallback(
    (UID: string): number => {
      const contents = _.get(values, valueKey);
      if (_.isArray(contents)) {
        return contents.findIndex((v) => v === UID);
      }
      return -1;
    },
    [values, valueKey],
  );
  const hasPermission = useCallback(
    (UID: string) => {
      return Boolean(getPermission(UID));
    },
    [getPermission],
  );
  const onPermissionChanged = useCallback(
    (UID: string) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const contents = _.get(values, valueKey);
      if (_.isArray(contents)) {
        const clone: Array<string> = _.cloneDeep(contents);
        const idx = getPermissionIndex(UID);
        if (checked) {
          clone.push(UID);
        }
        if (idx > -1 && idx < contents.length) {
          if (!checked) {
            clone.splice(idx, 1);
          }
        }
        if (contents.length !== clone.length) {
          setFieldValue(valueKey, clone);
        }
      }
    },
    [values, setFieldValue, valueKey, getPermissionIndex],
  );
  const tableData = useMemo(() => {
    const items: Array<Form> = [];
    if (response?.data?.length) {
      items.push(...response.data.map((v) => ({ UID: v.UID, Name: v.Name })));
    }
    return items;
  }, [response?.data]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Form>();
    return [
      columnHelper.display({
        id: 'Select',
        header: '선택',
        cell: (v) => (
          <Checkbox
            checked={hasPermission(v.row.original.UID)}
            onChange={onPermissionChanged(v.row.original.UID)}
          />
        ),
        enableGlobalFilter: false,
        size: 150,
        minSize: 150,
        maxSize: 150,
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('Name', {
        id: 'Name',
        header: '고객사',
        cell: (v) => v.getValue(),
        size: 600,
        minSize: 600,
      }),
    ];
  }, [hasPermission, onPermissionChanged]);
  return <SimpleTable columns={columns} data={tableData} enableGlobalFilter />;
}
