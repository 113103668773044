/* eslint-disable react-hooks/rules-of-hooks */
import type { ComponentType } from 'react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentInfo } from '@hooks';

const WithManager = <P extends object>(
  Component: ComponentType<P>,
): React.FC<P> => {
  return function ComponentWithManagerPermission(props) {
    const { hasRole } = useCurrentInfo(true);
    const navigate = useNavigate();
    useEffect(() => {
      if (!hasRole('MANAGER')) {
        navigate('/', { replace: true });
      }
    }, [hasRole, navigate]);
    if (!hasRole('MANAGER')) {
      return null;
    }
    return <Component {...props} />;
  };
};

export default WithManager;
