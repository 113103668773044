import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { useGetNHNAssignedProjectList } from '@api/queries/nhn/account';
import AddNHNAccountModal from '@features/customer/modal/account/nhn/add';
import EditNHNAccountModal from '@features/customer/modal/account/nhn/edit';
import DeleteNHNAccountModal from '@features/customer/modal/account/nhn/delete';
import { useToggle } from '@hooks';
import SimpleTable from '@components/table/simple';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';
import { formatLocaleString } from '@utils/formatter';

type ProjectInfo = NHNAccount & {
  ProjectId: string;
  ProjectName: string;
  OrgName: string;
  AccountName: string;
};

export default function CustomerNHNAccount() {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const uid = useRecoilValue(CUSTOMER_UID_STATE);
  const { data: response } = useGetOrganizationForUI();
  const { data: projects } = useGetNHNAssignedProjectList();
  const [openAdd, toggleAdd] = useToggle();
  const [toEdit, setToEdit] = useState<NHNAccount | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const tableData = useMemo(() => {
    const projectList = projects?.data.filter((v) => v.OrgUID === uid) ?? [];
    return (
      response?.data.CloudAccounts.filter((v) => v.CSP === 'nhn').map((v) => {
        const projectInfo = projectList.find(
          (k) => k.ProjectInfo.ProjectId === v.AccountId,
        );
        if (projectInfo) {
          return {
            ...v,
            ...projectInfo.ProjectInfo,
          };
        }
        return {
          ...v,
          ProjectId: v.AccountId,
          ProjectName: '',
          OrgName: '',
          AccountName: '',
        };
      }) ?? []
    );
  }, [uid, response?.data, projects?.data]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ProjectInfo>();
    return [
      columnHelper.accessor('ProjectId', {
        id: 'ProjectId',
        header: t('settings.projectId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ProjectName', {
        id: 'ProjectName',
        header: t('customer.projectName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrgName', {
        id: 'OrgName',
        header: t('customer.projectOrgName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('AccountName', {
        id: 'AccountName',
        header: t('settings.parentReseller'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('DiscountRate', {
        id: 'DiscountRate',
        header: t('customer.discountRate'),
        cell: (v) => `${formatLocaleString(v.getValue())}%`,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: '수정하기',
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Delete',
        header: '계정 연동 해제',
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={tableData}
        title={customerT('title.nhnAccount')}
        enableGlobalFilter
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addAccount')}
        </Button>
      </SimpleTable>
      <AddNHNAccountModal open={openAdd} onClose={toggleAdd} />
      <EditNHNAccountModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={toEdit}
      />
      <DeleteNHNAccountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        AccountUID={toDelete}
      />
    </>
  );
}
