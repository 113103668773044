import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import { useHekaTheme } from '@theme';
import { useHotjar } from '@hooks/analytics';
import i18n from '@utils/i18n';
import Pages from '@pages';

import 'dayjs/locale/ko';

dayjs.extend(utcPlugin);
dayjs.extend(relativeTimePlugin);

function App() {
  const { t } = useTranslation('global');
  const [currentLang, setCurrentLang] = useState('ko');
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);
  const { theme, setThemeLang } = useHekaTheme();
  const languageListener = useCallback(
    (lng: string) => {
      setCurrentLang(lng);
      dayjs.locale(lng);
      setThemeLang(lng);
    },
    [setThemeLang],
  );
  useHotjar();
  useEffect(() => {
    i18n.on('languageChanged', languageListener);
    languageListener(i18n.language);
    return () => i18n.off('languageChanged', languageListener);
  }, [languageListener]);
  return (
    <>
      <Helmet
        defaultTitle={t('metadata.title')}
        titleTemplate="%s - heka"
        meta={[
          {
            name: 'description',
            content: t('metadata.description'),
          },
        ]}
      />
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={currentLang}
        >
          <CssBaseline />
          <SnackbarProvider>
            <Pages />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
