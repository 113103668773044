import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateInvoiceCloudUsage } from '@api/queries/invoice';
import SimpleModal from '@components/modal/simple';

export default function DeleteAWSCloudUsageModal({
  open,
  onClose,
  Data,
}: DeleteAWSCloudUsageModalProps) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateInvoiceCloudUsage();
  const handler = useCallback(async () => {
    if (Data) {
      try {
        await mutateAsync({
          InvoiceId: Data.InvoiceId,
          CSP: 'aws',
          CloudUsage: [
            {
              AccountId: Data.AccountId,
              ServiceCode: Data.ServiceCode,
              Region: Data.Region,
              Product: Data.Product,
              ProductUsage: Data.ProductUsage,
              Data: {},
            },
          ],
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [mutateAsync, Data, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('modal.cloud.delete.title')}
      description={t('modal.cloud.delete.description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton color="danger" onClick={handler} loading={isPending}>
            {globalT('button.delete')}
          </LoadingButton>
        </>
      }
      enableBorder
    />
  );
}
