import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import { useCurrentInfo } from '@hooks';
import { BANNER_HEIGHT_STATE } from '@atoms/global/banner';
import DashboardIcon from '@assets/icon/Dashboard.svg?react';
import ReportIcon from '@assets/icon/Report.svg?react';
import CostIcon from '@assets/icon/Cost.svg?react';
import BillingIcon from '@assets/icon/Billing.svg?react';
import CompanyIcon from '@assets/icon/Company.svg?react';
import SalesIcon from '@assets/icon/Sales.svg?react';
import HelpIcon from '@assets/icon/Help.svg?react';
import { Drawer, NavLink, NavList, PanelToggle } from './styled';

export default function MainLeftPanel() {
  const { t } = useTranslation('global');
  const [isOpen, setIsOpen] = useState(true);
  const { hasRole } = useCurrentInfo();
  const { hasAWS, hasNCP } = useCurrentInfo();
  const bannerOffset = useRecoilValue(BANNER_HEIGHT_STATE);
  return (
    <Drawer variant="permanent" anchor="left" open={isOpen}>
      <Box
        sx={(theme) => {
          const totalOffset =
            Number(theme.mixins.toolbar.height ?? 0) + bannerOffset;
          return {
            mt: `${totalOffset}px`,
            height: `calc(100vh - ${totalOffset}px)`,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            padding: '24px',
          };
        }}
      >
        <Box
          component="nav"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <NavList>
            <NavLink
              href="/dashboard"
              label={t('sidebar.main.dashboard')}
              Icon={<DashboardIcon />}
              shrink={!isOpen}
            />
            <NavLink
              href="/report"
              label={t('sidebar.main.report')}
              Icon={<ReportIcon />}
              shrink={!isOpen}
            />
            {hasAWS && (
              <NavLink
                href="/cost"
                label={t('sidebar.main.cost')}
                Icon={<CostIcon />}
                shrink={!isOpen}
              />
            )}
            <NavLink
              href="/invoice"
              label={t('sidebar.main.invoice')}
              Icon={<BillingIcon />}
              shrink={!isOpen}
            />
            {hasRole('MANAGER') && (
              <>
                <NavLink
                  href="/customer"
                  label={t('sidebar.main.company')}
                  Icon={<CompanyIcon />}
                  shrink={!isOpen}
                />
                {hasNCP && (
                  <NavLink
                    href="/sales"
                    label={t('sidebar.main.sales')}
                    Icon={<SalesIcon />}
                    shrink={!isOpen}
                  />
                )}
              </>
            )}
          </NavList>
          {/*<Divider sx={{ borderColor: '#E6E6E6' }} />*/}
          {/*<NavList>*/}
          {/*  <NavButton label={t('sidebar.main.help')} Icon={<HelpIcon />} shrink={!isOpen} />*/}
          {/*</NavList>*/}
        </Box>
        <Box>
          <PanelToggle onClick={() => setIsOpen((v) => !v)} shrink={!isOpen} />
        </Box>
      </Box>
    </Drawer>
  );
}
