import AxiosClient from '@api/client';
import { isEmpty } from 'lodash';

const axios = AxiosClient.getInstance();
const PREFIX = '/aws/cost' as const;

async function getCostUsage({
  FromDate,
  ToDate,
  Granularity,
  Metric,
  AccountIdList,
  GroupBy,
  Organizations,
  OrganizationsExclude,
  Services,
  ServicesExclude,
  Tags,
  TagsExclude,
  Records,
  RecordsExclude,
  InstanceType,
  InstanceTypeExclude,
  UsageType,
  UsageTypeExclude,
  UsageTypeGroup,
  UsageTypeGroupExclude,
  TagKey,
}: AWSCEUsageRequest) {
  const { data } = await axios.post<SingleResponse<AWSCEUsage>>(
    `${PREFIX}/usage`,
    {
      FromDate,
      ToDate,
      Granularity,
      Metric,
      AccountIdList,
      GroupBy: GroupBy !== 'Cost' ? GroupBy : undefined,
      Organizations,
      OrganizationsExclude,
      Services,
      ServicesExclude,
      Tags,
      TagsExclude,
      Records,
      RecordsExclude,
      InstanceType,
      InstanceTypeExclude,
      UsageType,
      UsageTypeExclude,
      UsageTypeGroup,
      UsageTypeGroupExclude,
      TagKey: !isEmpty(TagKey?.trim()) ? TagKey : undefined,
    },
  );
  return data;
}

async function getCostAutoComplete() {
  const { data } = await axios.get<SingleResponse<AWSCEAutoComplete>>(
    `${PREFIX}/acdata`,
  );
  return data;
}

async function getCostTagAutoComplete({ TagKeys }: AWSCETagValuesRequest) {
  const { data } = await axios.post<SingleResponse<AWSCETagValues>>(
    `${PREFIX}/acdata/tag`,
    {
      TagKeys,
    },
  );
  return data;
}

async function downloadCostAsCsv({
  FromDate,
  ToDate,
  Granularity,
  Metric,
  AccountIdList,
  GroupBy,
  Organizations,
  OrganizationsExclude,
  Services,
  ServicesExclude,
  Tags,
  TagsExclude,
  Records,
  RecordsExclude,
  InstanceType,
  InstanceTypeExclude,
  UsageType,
  UsageTypeExclude,
  UsageTypeGroup,
  UsageTypeGroupExclude,
  TagKey,
}: AWSCEUsageRequest) {
  const { headers, data } = await axios.post(
    `${PREFIX}/csv`,
    {
      FromDate,
      ToDate,
      Granularity,
      Metric,
      AccountIdList,
      GroupBy: GroupBy !== 'Cost' ? GroupBy : undefined,
      Organizations,
      OrganizationsExclude,
      Services,
      ServicesExclude,
      Tags,
      TagsExclude,
      Records,
      RecordsExclude,
      InstanceType,
      InstanceTypeExclude,
      UsageType,
      UsageTypeExclude,
      UsageTypeGroup,
      UsageTypeGroupExclude,
      TagKey: !isEmpty(TagKey?.trim()) ? TagKey : undefined,
    },
    {
      responseType: 'blob',
    },
  );
  let filename: string | undefined;
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment !== -1')) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return {
    blob: data,
    filename,
  };
}

export default {
  getCostUsage,
  getCostAutoComplete,
  getCostTagAutoComplete,
  downloadCostAsCsv,
};
