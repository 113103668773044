import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Button, Typography, Divider, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
  useGetAccessAccounts,
  useGetNCPMemberAccounts,
} from '@api/queries/ncp/account';
import {
  DeleteAccessAccountModal,
  EditAccessAccountModal,
} from '@features/settings/account/ncp';
import SimpleTable from '@components/table/simple';
import { CloudTypeChip } from '@components/styled/chip';
import { formatDate } from '@utils/formatter';

export default function SettingsNCPAccountPage() {
  const { t } = useTranslation('table');
  const { t: settingsT } = useTranslation('settings');
  const [toAccessEdit, setToAccessEdit] = useState<AccessAccount | undefined>(
    undefined,
  );
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: accessAccounts } = useGetAccessAccounts();
  const { data: linkedAccounts } = useGetNCPMemberAccounts();
  const accessColumns = useMemo(() => {
    const columnHelper = createColumnHelper<AccessAccount>();
    return [
      columnHelper.accessor('AccessKey', {
        id: 'AccessKey',
        header: 'Access Key',
        cell: (v) => {
          const value = v.getValue();
          if (typeof value === 'string') {
            const startIdx = 2;
            const endIdx = value.length - 3;
            return value
              .split('')
              .map((char, idx) => {
                if (idx >= startIdx && idx <= endIdx) {
                  return '*';
                }
                return char;
              })
              .join('');
          }
          return v.getValue();
        },
      }),
      columnHelper.accessor('CloudType', {
        id: 'CloudType',
        header: t('settings.cloudType'),
        cell: (v) =>
          v.getValue() ? <CloudTypeChip type={v.getValue()} /> : '-',
      }),
      columnHelper.accessor('CreatedAt', {
        id: 'CreatedAt',
        header: t('settings.registeredOn'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD'),
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('settings.deleteAccount'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToAccessEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  const linkedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<NCPMemberAccountList>();
    return [
      columnHelper.accessor('MemberId', {
        id: 'MemberId',
        header: t('settings.memberNumber'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrganizationName', {
        id: 'OrganizationName',
        header: t('customer.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('CloudType', {
        id: 'CloudType',
        header: t('settings.cloudType'),
        cell: (v) =>
          v.getValue() ? <CloudTypeChip type={v.getValue()} /> : '-',
      }),
      columnHelper.accessor('CreatedAt', {
        id: 'CreatedAt',
        header: t('settings.registeredOn'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD'),
      }),
    ];
  }, [t]);
  return (
    <Box>
      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="24px"
      >
        {settingsT('title.account.ncp')}
      </Typography>
      <SimpleTable
        columns={accessColumns}
        data={accessAccounts?.data}
        title={t('title.settings.account.management')}
        enableGlobalFilter
        enablePagination
      >
        <Button href="/onboard/integrate/ncp" startIcon={<Add />}>
          {settingsT('button.addAccount')}
        </Button>
      </SimpleTable>
      <Divider sx={{ mt: '64px', mb: '40px' }} />
      <SimpleTable
        columns={linkedColumns}
        data={linkedAccounts?.data}
        title={t('title.settings.account.member')}
        enableGlobalFilter
        enablePagination
      />
      <EditAccessAccountModal
        open={typeof toAccessEdit !== 'undefined'}
        onClose={() => setToAccessEdit(undefined)}
        item={toAccessEdit}
      />
      <DeleteAccessAccountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </Box>
  );
}
