import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type AccessAccountForm = Pick<
  CreateAccessAccount,
  'AccessKey' | 'SecretKey'
> & {
  AccountType: NCPAccountType | string;
  LastStep: boolean;
};

export function useAccessAccountForm(): UseFormValidation<AccessAccountForm> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      LastStep: false,
      AccessKey: '',
      SecretKey: '',
      AccountType: ' ',
    },
    validationSchema: Yup.object().shape({
      AccessKey: Yup.string()
        .strict(false)
        .matches(/^[a-zA-Z0-9]{20}$/, t('onboard.ncp.accessKeyId'))
        .defined(t('common.required.base')),
      SecretKey: Yup.string().when('LastStep', ([LastStep], schema) => {
        return LastStep
          ? schema
              .strict(false)
              .matches(/^[a-zA-Z0-9]{40}$/, t('onboard.ncp.secretKey'))
              .defined(t('common.required.base'))
          : schema;
      }),
      AccountType: Yup.string().when('LastStep', ([LastStep], schema) => {
        return LastStep
          ? schema
              .strict(false)
              .matches(/^[^ ][\w\W ]*[^ ]/, t('common.required.base'))
              .defined(t('common.required.base'))
          : schema;
      }),
    }),
  };
}
