import type { PropsWithChildren } from 'react';
import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Grid2Props } from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Link } from '@heka/theme';
import { useToggle } from '@hooks';
import SupportModal from '@components/modal/support';

export function PanelContainer({
  children,
  disableOverflow,
}: PropsWithChildren<{ disableOverflow?: boolean }>) {
  return (
    <Grid
      container
      columnSpacing="32px"
      sx={{
        flex: 1,
        ...(!disableOverflow && {
          overflowY: 'auto',
        }),
      }}
    >
      {children}
    </Grid>
  );
}

type MainPanelProps = PropsWithChildren<{
  disableLeftPanel?: boolean;
  sx?: Grid2Props['sx'];
}>;
export function MainPanel({
  children,
  sx,
  disableLeftPanel = false,
}: MainPanelProps) {
  return (
    <Grid
      {...(!disableLeftPanel && {
        lg: 5,
        md: true,
      })}
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '> div': {
          width: '100%',
        },
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
}

type LeftPanelProps = {
  title: string;
  description?: string;
};
export function LeftPanel({ title, description }: LeftPanelProps) {
  return (
    <>
      <Grid md={3} xs={12}>
        <Typography fontSize="20px" fontWeight="500" letterSpacing="1.6px">
          {title}
        </Typography>
        {typeof description !== 'undefined' && (
          <Typography letterSpacing="1.28px" mt="16px">
            {description}
          </Typography>
        )}
      </Grid>
      <Grid md={1} display={{ md: 'block', xs: 'none' }} />
    </>
  );
}

export type SupportFAQ = {
  title: string;
  content?: string | TrustedHTML;
};
type SupportLinkProps = {
  faq?: Array<SupportFAQ>;
};
export function SupportLink({ faq }: SupportLinkProps) {
  const { t } = useTranslation('global');
  const [open, toggleOpen] = useToggle();
  return (
    <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
      <Link
        color="calm"
        size="comfort"
        href="javascript:void(0);"
        onClick={toggleOpen}
      >
        {t('button.support')}
      </Link>
      <SupportModal open={open} onClose={toggleOpen} faq={faq} />
    </Box>
  );
}

type BottomActionsProps = PropsWithChildren<{
  enableSupport?: boolean;
  faq?: Array<SupportFAQ>;
}>;
export function BottomActions({
  children,
  enableSupport,
  faq,
}: BottomActionsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        py: '16px',
      }}
    >
      {Boolean(enableSupport) && <SupportLink faq={faq} />}
      {children}
    </Box>
  );
}
