import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetCurrentUser, useUpdateCurrentUser } from '@api/queries/user';

export default function SettingsProfileUser() {
  const { t } = useTranslation('settings');
  const { t: roleT } = useTranslation('global', { keyPrefix: 'role' });
  const { data: response } = useGetCurrentUser();
  const { mutateAsync, isPending } = useUpdateCurrentUser();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdateUser>({
    initialValues: {
      FirstName: '',
      PhoneNumber: '',
    },
    validationSchema: Yup.object().shape({
      FirstName: Yup.string().defined(),
      PhoneNumber: Yup.string(),
    }),
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          FirstName: v.FirstName,
          PhoneNumber: !isEmpty(v.PhoneNumber) ? v.PhoneNumber : undefined,
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (response?.data) {
      resetForm({
        values: {
          FirstName: response.data.FirstName ?? '',
          PhoneNumber: response.data.PhoneNumber ?? '',
        },
      });
    }
  }, [response?.data, resetForm]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
        maxWidth: '608px',
      }}
    >
      <Typography color="textSecondary" fontSize="14px" letterSpacing="0.56px">
        {t('title.profile.user')}
      </Typography>
      <Grid container spacing="24px">
        <Grid xs={6}>
          <InputLabel sx={{ mb: '18px' }}>{t('form.label.email')}</InputLabel>
          <Typography color="text.main" mb="10px">
            {response?.data.Email}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <InputLabel sx={{ mb: '18px' }}>{t('form.label.role')}</InputLabel>
          <Typography color="text.main" mb="10px">
            {roleT(response?.data.Role ?? 'CLIENT_USER')}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <TextField
            name="FirstName"
            value={values.FirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.FirstName && touched.FirstName)}
            label={t('form.label.name')}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="PhoneNumber"
            value={values.PhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.PhoneNumber && touched.PhoneNumber)}
            label={t('form.label.phone')}
            type="tel"
            inputMode="tel"
            fullWidth
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        color="emphasis1"
        loading={isPending}
        disabled={!isValid || !dirty}
      >
        {t('button.saveChanges')}
      </LoadingButton>
    </Box>
  );
}
