import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/dashboard' as const;

async function getDashboardTrend() {
  const { data } = await axios.get<SingleResponse<DashboardTrend>>(
    `${PREFIX}/invoice/summary`,
  );
  return data;
}

async function getRevenueTrend() {
  const { data } = await axios.get<MultipleResponse<RevenueTrend>>(
    `${PREFIX}/invoice/paid`,
  );
  return data;
}

async function getCustomerTrend() {
  const { data } = await axios.get<MultipleResponse<CustomerTrend>>(
    `${PREFIX}/linked`,
  );
  return data;
}

async function getTop10Customer(type: 'paid' | 'stacked' = 'paid') {
  const { data } = await axios.get<MultipleResponse<Top10Customer>>(
    `${PREFIX}/top10/${type}`,
  );
  return data;
}

async function getAdditionalServiceTrend() {
  const { data } = await axios.get<MultipleResponse<AdditionalServiceTrend>>(
    `${PREFIX}/additional/trend`,
  );
  return data;
}

async function getNCPAccountRatio() {
  const { data } = await axios.get<SingleResponse<NCPAccountRatio>>(
    `${PREFIX}/ncp/accounts`,
  );
  return data;
}

async function getSalesTrend() {
  const { data } = await axios.get<MultipleResponse<SalesTrend>>(
    `${PREFIX}/sales`,
  );
  return data;
}

export default {
  getDashboardTrend,
  getRevenueTrend,
  getCustomerTrend,
  getTop10Customer,
  getAdditionalServiceTrend,
  getNCPAccountRatio,
  getSalesTrend,
};
