import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useUpdateContact } from '@api/queries/organization';
import CustomerContactForm, {
  useCustomerContactForm,
} from '@features/customer/form/contact';
import FormModal from '@components/modal/form';

type Props = {
  item?: Contact;
};
export default function EditContactModal({
  open,
  onClose,
  item,
}: ModalProps & Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contact.edit' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateContact();
  const { initialValues, validationSchema } = useCustomerContactForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (item) {
        try {
          await mutateAsync({ ...v, UID: item.UID, OrganizationId: item.UID });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: {
          Name: item.Name,
          Email: item.Email,
          Title: item.Title ?? '',
          Department: item.Department ?? '',
          Team: item.Team ?? '',
          Notes: item.Notes ?? '',
          Birthday: item.Birthday,
          PhoneNumber: item.PhoneNumber ?? '',
          Providers: item.Providers,
        },
      });
    }
  }, [item]);
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm({
          values: initialValues,
        });
      }, 500);
    }
  }, [open]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerContactForm {...formik} />
    </FormModal>
  );
}
