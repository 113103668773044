import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import {
  PanelContainer,
  LeftPanel,
  MainPanel,
  BottomActions,
} from '@components/layout/auth';
import { ResponsiveImage } from '@components/styled/image';

export default function OnboardIntegrateAWSDonePage() {
  const { uid } = useParams();
  const { t } = useTranslation('onboard');
  const { t: globalT } = useTranslation('global');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel title={t('integrateAWSDone.title')} />
        <MainPanel>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <ResponsiveImage
              src="/assets/img/img-registered.svg"
              alt="Done"
              sx={{ maxWidth: '536px' }}
            />
            <Typography
              dangerouslySetInnerHTML={{
                __html: t('integrateAWSDone.content'),
              }}
            />
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <Button href={`/onboard/configure/aws/cur/${uid}`} color="emphasis1">
          {globalT('button.next')}
        </Button>
      </BottomActions>
    </Box>
  );
}
