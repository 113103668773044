import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/notification' as const;

async function getNotifications() {
  const { data } = await axios.get<MultipleResponse<HekaNotification>>(PREFIX);
  return data;
}

async function updateNotification(body: UpdateNotification) {
  const { data } = await axios.put<SingleResponse<HekaNotification>>(
    PREFIX,
    body,
  );
  return data;
}

async function bulkUpdateNotifications(body: BulkUpdateNotification) {
  const { data } = await axios.put<
    MultipleResponse<
      HekaNotification,
      {
        Count: {
          Success: number;
          Failure: number;
          NotFound: number;
          Forbidden: number;
        };
        Data: {
          Success: Array<string>;
          Failure: Array<string>;
          NotFound: Array<string>;
          Forbidden: Array<string>;
        };
      }
    >
  >(`${PREFIX}/bulk`, body);
  return data;
}

export default {
  getNotifications,
  updateNotification,
  bulkUpdateNotifications,
};
