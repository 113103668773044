import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiRadio from '@mui/material/Radio';
import { useRadioGroup } from '@mui/material/RadioGroup';

export interface TileProps
  extends Omit<
    MuiFormControlLabelProps,
    'control' | 'labelPlacement' | 'label' | 'disableTypography'
  > {
  type?: 'radio' | 'checkbox';
  label?: React.ReactNode;
  description?: string | TrustedHTML;
}

interface TileComponentProps extends TileProps {}

const TileRadioComponent: StyledComponent<TileComponentProps> = styled(
  ({ checked, label, description, ...rest }: TileComponentProps) => (
    <MuiFormControlLabel
      control={<MuiRadio />}
      label={
        <div className="HekaTile-content">
          {typeof label !== 'undefined' && (
            <p className="HekaTile-label">{label}</p>
          )}
          {typeof description !== 'undefined' && (
            <p
              className="HekaTile-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      }
      disableTypography
      {...rest}
    />
  ),
  {
    name: 'HekaTile',
    slot: 'Root',
    overridesResolver: (props, styles) => [styles.root],
    skipVariantsResolver: true,
  },
)<TileComponentProps>();

const TileCheckboxComponent: StyledComponent<TileComponentProps> = styled(
  ({ checked, label, description, ...rest }: TileComponentProps) => (
    <MuiFormControlLabel
      control={<MuiCheckbox checked={checked} onChange={rest.onChange} />}
      label={
        <div className="HekaTile-content">
          {typeof label !== 'undefined' && (
            <p className="HekaTile-label">{label}</p>
          )}
          {typeof description !== 'undefined' && (
            <p
              className="HekaTile-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      }
      disableTypography
      {...rest}
    />
  ),
  {
    name: 'HekaTile',
    slot: 'Root',
    overridesResolver: (props, styles) => [styles.root],
    skipVariantsResolver: true,
  },
)<TileComponentProps>();

function Tile({ type, ...rest }: TileProps) {
  const { value } = rest;
  const group = useRadioGroup();
  let checked = false;
  if (group) {
    checked = group.value === value;
  }
  if (type === 'checkbox') {
    return <TileCheckboxComponent {...rest} />;
  }
  return <TileRadioComponent value={value} checked={checked} {...rest} />;
}
Tile.displayName = 'HekaTile';

export default Tile;
