import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ReportService } from '@api/services';
import { REPORT_KEY } from '@constants/query';

export default function useGetReportById(UID?: string) {
  const [Id, setId] = useState(UID ?? '');
  const query = useSuspenseQuery({
    queryKey: [...REPORT_KEY.GET_REPORT_BY_ID, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) ? ReportService.getReportById(queryKey[2]) : null,
  });
  return { ...query, setId };
}
