import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetDashboardTrend } from '@api/queries/dashboard';
import { DifferenceSection } from '../styled';

export default function RevenueTrend() {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetDashboardTrend();
  const difference = useMemo(() => {
    if (response?.data) {
      if (Number(response.data.LastMonthPaidAmount) !== 0) {
        return (
          ((Number(response.data.MonthPaidAmount) -
            Number(response.data.LastMonthPaidAmount)) /
            Number(response.data.LastMonthPaidAmount)) *
          100.0
        );
      }
    }
    return 0;
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.lastMonthRevenue')}
      </Typography>
      <Typography color="#362F26" fontSize="28px" fontWeight={500} mb="8px">
        {globalT('format.currency.krw', {
          value: response?.data.MonthPaidAmount ?? 0,
        })}
      </Typography>
      <DifferenceSection formatKey="percent" difference={difference} />
    </>
  );
}
