import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NHNAccountService } from '@api/services';
import { NHN_ACCOUNT_KEY } from '@constants/query';

export default function useUpdateNHNAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: NHN_ACCOUNT_KEY.UPDATE_ACCOUNT,
    mutationFn: (v: UpdateNHNAccount) => NHNAccountService.updateNHNAccount(v),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...NHN_ACCOUNT_KEY.GET_ACCOUNT_BY_ID, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: NHN_ACCOUNT_KEY.GET_ACCOUNTS,
      });
      queryClient.invalidateQueries({
        queryKey: NHN_ACCOUNT_KEY.GET_ACCOUNT_HIERARCHY,
      });
    },
  });
}
