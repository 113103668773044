import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useChangePasswordForm(): UseFormValidation<{
  Type: 'direct' | 'request';
  Password: string;
  Confirm: string;
}> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Type: 'direct',
      Password: '',
      Confirm: '',
    },
    validationSchema: Yup.object().shape({
      Type: Yup.mixed().oneOf(['direct', 'request']).defined(),
      Password: Yup.string().when('Type', ([Type], schema) => {
        if (Type === 'direct') {
          return schema
            .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
            .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
            .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
            .matches(
              /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
              t('auth.password.special'),
            )
            .min(8, t('auth.password.length'))
            .defined(t('common.required.base'));
        }
        return schema;
      }),
      Confirm: Yup.string().when('Type', ([Type], schema) => {
        if (Type === 'direct') {
          return schema
            .oneOf([Yup.ref('Password')], t('auth.confirm'))
            .defined(t('common.required.base'));
        }
        return schema;
      }),
    }),
  };
}
