import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import { DateCalendar } from '@mui/x-date-pickers';
import { getPickersCalendarHeaderUtilityClass } from '@mui/x-date-pickers/PickersCalendarHeader/pickersCalendarHeaderClasses';
import { DateRange } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useToggle } from '@hooks';
import { formatDate } from '@utils/formatter';

interface IProps<T extends object> {
  values: T;
  onValueChanged: (startValue: Date, endValue: Date) => void;
  startKey: keyof T;
  endKey: keyof T;
  maxDiff?: number;
  type?: 'field' | 'button';
}
export default function MonthRangePicker<T extends object>({
  values,
  onValueChanged,
  startKey,
  endKey,
  maxDiff,
  type = 'field',
}: IProps<T>) {
  const [StartMonth, setStartMonth] = useState<dayjs.Dayjs>(
    dayjs(values[startKey] as Date),
  );
  const [EndMonth, setEndMonth] = useState<dayjs.Dayjs>(
    dayjs(values[endKey] as Date),
  );
  const [showStart, setShowStart] = useState(true);
  const [openPicker, togglePicker] = useToggle();
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleStartMonth = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setStartMonth(newValue);
        if (
          EndMonth.isBefore(newValue, 'months') ||
          (maxDiff && EndMonth.diff(newValue, 'months') > maxDiff)
        ) {
          const current = dayjs(newValue)
            .set('years', dayjs().year())
            .set('months', dayjs().month());
          if (maxDiff) {
            const maximum = dayjs(newValue).add(maxDiff, 'months');
            setEndMonth(
              current.isBefore(maximum, 'months') ? current : maximum,
            );
          } else {
            setEndMonth(current);
          }
        }
      }
    },
    [EndMonth, maxDiff],
  );
  const handleEndMonth = useCallback((newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setEndMonth(newValue);
    }
  }, []);
  const handleAccept = useCallback(() => {
    onValueChanged(StartMonth.toDate(), EndMonth.toDate());
    togglePicker();
    setTimeout(() => {
      setShowStart(true);
    }, 500);
  }, [onValueChanged, StartMonth, EndMonth, togglePicker]);
  const handleClose = useCallback(() => {
    togglePicker();
    setTimeout(() => {
      setStartMonth(dayjs(values[startKey] as Date));
      setEndMonth(dayjs(values[endKey] as Date));
      setShowStart(true);
    }, 500);
  }, [values, startKey, endKey, togglePicker]);
  useEffect(() => {
    setStartMonth(dayjs(values[startKey] as Date));
  }, [values, startKey]);
  useEffect(() => {
    setEndMonth(dayjs(values[endKey] as Date));
  }, [values, endKey]);
  return (
    <>
      {type === 'field' ? (
        <TextField
          inputRef={ref}
          value={`${formatDate(StartMonth, 'YYYY.MM')} ~ ${formatDate(
            EndMonth,
            'YYYY.MM',
          )}`}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="calm"
                  onClick={togglePicker}
                  sx={{
                    p: '8px',
                    [`> .${getSvgIconUtilityClass('root')}`]: {
                      width: '24px',
                      height: '24px',
                    },
                  }}
                >
                  <DateRange sx={{ width: '24px', height: '24px' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            [`> .${getInputBaseUtilityClass('root')}`]: {
              paddingRight: 0,
            },
          }}
          fullWidth
        />
      ) : (
        <Button
          ref={ref}
          color="sub"
          onClick={togglePicker}
          startIcon={<DateRange />}
        >
          {`${formatDate(StartMonth, 'YYYY.MM')} ~ ${formatDate(
            EndMonth,
            'YYYY.MM',
          )}`}
        </Button>
      )}
      <Popper
        open={openPicker}
        anchorEl={ref.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            {showStart ? (
              <>
                <Typography
                  color="text.main"
                  fontWeight={500}
                  letterSpacing={0}
                >
                  시작월 설정
                </Typography>
                <DateCalendar
                  value={StartMonth}
                  onChange={handleStartMonth}
                  views={['year', 'month']}
                  openTo="month"
                  disableFuture
                  sx={{
                    [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                      px: 0,
                    },
                  }}
                />
                <Button
                  color="emphasis1"
                  onClick={() => setShowStart(false)}
                  fullWidth
                >
                  종료월 설정
                </Button>
              </>
            ) : (
              <>
                <Typography
                  color="text.main"
                  fontWeight={500}
                  letterSpacing={0}
                >
                  종료월 설정
                </Typography>
                <DateCalendar
                  value={EndMonth}
                  onChange={handleEndMonth}
                  views={['year', 'month']}
                  openTo="month"
                  shouldDisableMonth={(v) =>
                    v.isBefore(StartMonth, 'months') ||
                    (!!maxDiff && v.diff(StartMonth, 'months') > maxDiff)
                  }
                  shouldDisableYear={(v) => v.isBefore(StartMonth, 'years')}
                  disableFuture
                  sx={{
                    [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                      px: 0,
                    },
                  }}
                />
                <Button color="emphasis1" onClick={handleAccept} fullWidth>
                  기간 적용
                </Button>
              </>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
