import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  InputLabel,
  RadioGroup,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useUpdateInvoiceCustom } from '@api/queries/invoice';
import LargeModal from '@components/modal/large';
import { Tile } from '@heka/theme';
import { formatKRW } from '@utils/formatter';
import { CloudProviderChip } from '@components/styled/chip';

function InfoItem({ children, label }: PropsWithChildren<{ label: string }>) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <Typography color="text.main" sx={{ flexBasis: '152px' }}>
        {label}
      </Typography>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  );
}

type FormData = Omit<EditAdditionalService, 'AccountId'> & {
  ApplyLevel: string;
  Type: 'Service' | 'Discount';
};

export default function AddServiceFromCloudModal({
  open,
  onClose,
  Invoices,
  Data,
}: AddServiceModalProps) {
  const { t } = useTranslation('invoice');
  const { t: customerT } = useTranslation('customer', {
    keyPrefix: 'form.service',
  });
  const { t: ncpT } = useTranslation('ncp');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateInvoiceCustom();
  const serviceTotalCost = useMemo(() => {
    if (Data) {
      const { CSP, AccountId, ServiceCode, Region, Product } = Data;
      const invoice = Invoices.find(
        (v) => v.AccountId === AccountId && v.CSP === CSP,
      );
      if (invoice) {
        const costKey =
          CSP === 'aws' ? 'ProductTotalCost' : 'DescriptionTotalCost';
        const totalCost = _.get(
          invoice.Data,
          `${ServiceCode}.${Region}.${Product}.${costKey}`,
        );
        if (typeof totalCost === 'number') {
          return totalCost;
        }
      }
    }
    return undefined;
  }, [Data, Invoices]);
  const handler = useCallback(
    async (v: FormData, helpers: FormikHelpers<FormData>) => {
      if (Data && typeof serviceTotalCost !== 'undefined') {
        const originKey =
          v.Type === 'Service' ? 'AdditionalDataOrigin' : 'DiscountDataOrigin';
        const items = _.flatten(
          Invoices.filter(
            (account) =>
              account.CSP === Data.CSP && account.AccountId === Data.AccountId,
          ).map((account) => {
            return _.get(
              account,
              `${originKey}.AdditionalService.No region.AdditionalService.AdditionalService`,
            )
              ? Object.keys(
                  _.get(
                    account,
                    `${originKey}.AdditionalService.No region.AdditionalService.AdditionalService`,
                  ),
                ).map((key) => ({
                  AccountId: account.AccountId,
                  ServiceName: key,
                  UsageAmount: _.get(
                    account,
                    `${originKey}.AdditionalService.No region.AdditionalService.AdditionalService.${key}.UsageAmount`,
                  ),
                  PricingUnit: _.get(
                    account,
                    `${originKey}.AdditionalService.No region.AdditionalService.AdditionalService.${key}.PricingUnit`,
                  ),
                  Cost: _.get(
                    account,
                    `${originKey}.AdditionalService.No region.AdditionalService.AdditionalService.${key}.Cost`,
                  ),
                }))
              : [];
          }),
        );
        const Cost =
          v.PricingUnit === 'PCT'
            ? Math.floor((serviceTotalCost * v.Cost) / 100)
            : v.Cost;
        items.push({
          AccountId: Data.AccountId,
          ServiceName: v.ServiceName,
          UsageAmount: v.UsageAmount,
          PricingUnit: 'KRW',
          Cost,
        });
        try {
          await mutateAsync({
            CSP: Data.CSP,
            InvoiceId: Invoices[0].InvoiceId,
            AccountId: Data.AccountId,
            ...(v.Type === 'Service'
              ? {
                  AdditionalServices: items,
                }
              : {
                  Discounts: items,
                }),
          });
          onClose();
          setTimeout(() => {
            helpers.resetForm();
          }, 500);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [Data, Invoices, mutateAsync, onClose, serviceTotalCost],
  );
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik<FormData>({
    initialValues: {
      Type: 'Service',
      ServiceName: '',
      UsageAmount: 1,
      PricingUnit: 'KRW',
      Cost: 0,
      ApplyLevel: 'Account',
    },
    validationSchema: Yup.object()
      .shape({
        Type: Yup.mixed().oneOf(['Service', 'Discount']).defined(),
        ServiceName: Yup.string().defined(),
        UsageAmount: Yup.number().positive().defined(),
        PricingUnit: Yup.mixed().oneOf(['KRW', 'USD', 'PCT']).defined(),
        Cost: Yup.number().positive().defined(),
        ApplyLevel: Yup.mixed().oneOf(['Company', 'Account']).defined(),
      })
      .defined(),
    onSubmit: handler,
  });
  const fixedMethod = useMemo(() => {
    return values.PricingUnit !== 'PCT';
  }, [values.PricingUnit]);
  const getRegionName = useCallback(
    (region?: string) => {
      if (Data?.CSP === 'ncp' && region) {
        return ncpT(`region.${region}`);
      }
      return region;
    },
    [Data?.CSP, ncpT],
  );
  useEffect(() => {
    if (Data) {
      const { CSP, ServiceCode, Region, Product } = Data;
      const regionText = getRegionName(Region);
      const serviceName = `${ServiceCode}-${regionText}-${Product}`;
      setFieldValue('ServiceName', serviceName);
    }
  }, [Data, getRegionName, ncpT, setFieldValue]);
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="640px"
      minHeight="880px"
      title={t('modal.cloud.service.title')}
      description={t('modal.cloud.service.description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {globalT('button.save')}
          </LoadingButton>
        </>
      }
      sx={{ alignItems: 'flex-start' }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography color="text.main">선택된 클라우드 서비스</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            mt: '16px',
            mb: '24px',
            px: '24px',
            py: '20px',
          }}
        >
          <InfoItem label="클라우드">
            <CloudProviderChip provider={Data?.CSP} />
          </InfoItem>
          <InfoItem label="서비스명">
            <Typography color="text.main">{Data?.ServiceCode}</Typography>
          </InfoItem>
          <InfoItem label="서비스 금액">
            <Typography color="text.main">
              {formatKRW(serviceTotalCost)}
            </Typography>
          </InfoItem>
          <InfoItem label="계정">
            <Typography color="text.main">{Data?.AccountId}</Typography>
          </InfoItem>
          <InfoItem label="리전">
            <Typography color="text.main">
              {getRegionName(Data?.Region)}
            </Typography>
          </InfoItem>
        </Box>
        <Grid container spacing="16px">
          <Grid xs={12}>
            <InputLabel>서비스 구분</InputLabel>
            <RadioGroup
              value={values.Type}
              onChange={(e, v) => {
                setFieldValue('Type', v);
              }}
              row
              sx={{ gap: '8px' }}
            >
              <Tile
                value="Service"
                description="부가서비스"
                sx={{ flex: 1, p: '16px' }}
              />
              <Tile
                value="Discount"
                description="할인"
                sx={{ flex: 1, p: '16px' }}
              />
            </RadioGroup>
          </Grid>
          <Grid xs={12}>
            <InputLabel>{customerT('label.billingMethod')}</InputLabel>
            <RadioGroup
              value={fixedMethod ? 'Fixed' : 'Ratio'}
              onChange={(e, v) => {
                if (v === 'Fixed') setFieldValue('PricingUnit', 'KRW');
                if (v === 'Ratio') setFieldValue('PricingUnit', 'PCT');
              }}
              row
              sx={{ gap: '8px' }}
            >
              <Tile
                value="Fixed"
                description={customerT('label.billingFixed')}
                sx={{ flex: 1, p: '16px' }}
              />
              <Tile
                value="Ratio"
                description={customerT('label.billingRatio')}
                sx={{ flex: 1, p: '16px' }}
              />
            </RadioGroup>
          </Grid>
          <Grid xs={12}>
            <TextField
              name="Cost"
              type="number"
              inputMode="decimal"
              value={values.Cost}
              onChange={handleChange}
              onBlur={handleBlur}
              label={customerT('label.cost')}
              placeholder={customerT('holder.cost')}
              error={Boolean(errors.Cost && touched.Cost)}
              InputProps={{
                ...(values.PricingUnit === 'KRW' && {
                  startAdornment: (
                    <InputAdornment position="start">₩</InputAdornment>
                  ),
                }),
                ...(values.PricingUnit === 'USD' && {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }),
                ...(values.PricingUnit === 'PCT' && {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }),
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              name="UsageAmount"
              type="number"
              inputMode="decimal"
              value={values.UsageAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              label={customerT('label.usageAmount')}
              placeholder={customerT('holder.usageAmount')}
              error={Boolean(errors.UsageAmount && touched.UsageAmount)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </LargeModal>
  );
}
