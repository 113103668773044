import * as Yup from 'yup';
import { DEFAULT_REPORT_STATE } from '@constants/filter/report';

export function useReportSettingsForm(): UseFormValidation<CreateReport> {
  return {
    initialValues: DEFAULT_REPORT_STATE,
    validationSchema: Yup.object()
      .shape({
        ChartType: Yup.mixed().oneOf(['bar', 'line']).defined(),
        Name: Yup.string().defined(),
        Memo: Yup.string(),
        Providers: Yup.array(Yup.mixed<CloudProvider>().defined())
          .min(1)
          .defined(),
        StartMonth: Yup.date().defined(),
        EndMonth: Yup.date().defined(),
        Organizations: Yup.array(Yup.string().defined()).min(1).defined(),
        InvoiceStatus: Yup.array(Yup.mixed<InvoiceStatus>().defined())
          .min(1)
          .defined(),
        OrganizationStatus: Yup.array(Yup.mixed<OrganizationStatus>().defined())
          .min(1)
          .defined(),
        DisplayOptions: Yup.array(
          Yup.mixed<ReportDisplayOption>().defined(),
        ).defined(),
        NCPSettings: Yup.object()
          .shape({
            CloudType: Yup.array(Yup.mixed<NCPAccountType>().defined())
              .min(1)
              .defined(),
            DisplayOptions: Yup.array(
              Yup.mixed<ReportNCPDisplayOption>().defined(),
            ).defined(),
          })
          .defined(),
      })
      .defined(),
  };
}
