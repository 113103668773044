import { useCallback } from 'react';
import { type RecoilState, useRecoilState } from 'recoil';
import { MonthRangePicker } from '@components/styled/picker';
import { NCP_SALES_FILTER_STATE } from '@atoms/page/filter';

type IProps = {
  state: RecoilState<NCPSalesFilter | NHNSalesFilter>;
};
export default function SalesMonthPicker({ state }: IProps) {
  const [filter, setFilter] = useRecoilState(state);
  const handleValueChange = useCallback(
    (startValue: Date, endValue: Date) => {
      setFilter((v) => ({
        ...v,
        StartDate: startValue,
        EndDate: endValue,
      }));
    },
    [setFilter],
  );
  return (
    <MonthRangePicker
      type="button"
      values={filter}
      onValueChanged={handleValueChange}
      startKey="StartDate"
      endKey="EndDate"
    />
  );
}
