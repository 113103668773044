import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateUser } from '@api/queries/user';
import SettingsRolePermissions from '@features/settings/permission/common/rolePermissions';
import BasicModal from '@components/modal/basic';

export default function SettingsMemberPermissionModal({
  open,
  onClose,
  user,
}: ModalProps & {
  user?: {
    UID: string;
    ToAdmin: boolean;
  };
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const [navigateAfter, setNavigateAfter] = useState(true);
  const { mutateAsync, isPending } = useUpdateUser();
  const handleClose = useCallback(() => {
    setNavigateAfter(true);
    onClose();
  }, [onClose]);
  const handler = useCallback(async () => {
    if (user) {
      try {
        await mutateAsync({
          UID: user.UID,
          Role: user.ToAdmin ? 'ADMIN' : 'MANAGER',
        });
        if (!user.ToAdmin && navigateAfter) {
          navigate(`/settings/permission/${user.UID}`);
        }
        handleClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [user, mutateAsync, navigate, handleClose, navigateAfter]);
  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      title="멤버 역할 변경"
      description={`선택한 멤버의 역할을 ${
        user?.ToAdmin ? 'User' : 'Admin'
      }에서 ${
        user?.ToAdmin ? 'Admin으' : 'User'
      }로 변경하시겠습니까? 역할 변경에 따라 권한이 변경됩니다.`}
      Actions={
        <>
          {Boolean(!user?.ToAdmin) && (
            <Box sx={{ flex: 1 }}>
              <FormControlLabel
                value={navigateAfter}
                onChange={(e, v) => setNavigateAfter(v)}
                control={<Checkbox color="emphasis" />}
                label="변경 후 고객사별 권한 설정하기"
              />
            </Box>
          )}
          <Button color="cancel" onClick={handleClose}>
            돌아가기
          </Button>
          <LoadingButton
            color="emphasis1"
            onClick={handler}
            loading={isPending}
          >
            변경하기
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography color="text.main">기존</Typography>
          <SettingsRolePermissions
            canEdit
            canInvite
            openMargin={!user?.ToAdmin}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography color="text.main">변경 후</Typography>
          <SettingsRolePermissions
            canEdit
            canInvite
            openMargin={user?.ToAdmin}
          />
          {Boolean(!user?.ToAdmin) && (
            <Typography color="text.main">
              {t('text.memberPermissionUserWarn')}
            </Typography>
          )}
        </Box>
      </Box>
    </BasicModal>
  );
}
