import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSetInitialPassword } from '@api/queries/auth';
import { useUserInitialPasswordForm } from '@features/auth/user/initial';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function UserInitialPage() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutateAsync, isPending } = useSetInitialPassword();
  const { initialValues, validationSchema } = useUserInitialPasswordForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        navigate('/user/initial/info');
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (searchParams.get('uid')) {
      setFieldValue('UID', searchParams.get('uid'));
    }
    if (searchParams.get('sid')) {
      setFieldValue('Token', searchParams.get('sid'));
    }
  }, [searchParams, setFieldValue]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('userInitial.title')}
          description={t('userInitial.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: '24px',
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: t('userInitial.content') }}
            />
            <Box sx={{ mt: '48px' }}>
              <Box sx={{ py: '8px' }}>
                <Typography
                  color="text.main"
                  fontSize="18px"
                  lineHeight="120%"
                  fontWeight={500}
                  letterSpacing="0.72px"
                >
                  {t('userInitial.setPassword')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  py: '24px',
                }}
              >
                <TextField
                  name="Email"
                  value={values.Email}
                  error={Boolean(errors.Email && touched.Email)}
                  helperText={
                    Boolean(errors.Email && touched.Email) &&
                    String(errors.Email)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.emailInitial')}
                  placeholder={t('common.form.holder.emailInitial')}
                  type="email"
                  inputMode="email"
                  autoComplete="username"
                  autoCapitalize="off"
                  fullWidth
                />
                <TextField
                  name="Password"
                  value={values.Password}
                  error={Boolean(errors.Password && touched.Password)}
                  helperText={
                    Boolean(errors.Password && touched.Password) &&
                    String(errors.Password)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.passwordInitial')}
                  placeholder={t('common.form.holder.passwordInitial')}
                  type="password"
                  inputMode="text"
                  autoComplete="new-password"
                  autoCapitalize="off"
                  fullWidth
                />
                <TextField
                  name="Confirm"
                  value={values.Confirm}
                  error={Boolean(errors.Confirm && touched.Confirm)}
                  helperText={
                    Boolean(errors.Confirm && touched.Confirm) &&
                    String(errors.Confirm)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.confirm')}
                  placeholder={t('common.form.holder.confirm')}
                  type="password"
                  inputMode="text"
                  autoComplete="new-password"
                  autoCapitalize="off"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {t('userInitial.setPassword')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
