import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { useToggle } from '@hooks';
import { useGetContracts } from '@api/queries/contract';
import SimpleTable from '@components/table/simple';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { CloudProviderChip, ContractChip } from '@components/styled/chip';
import AddContractModal from '@features/customer/modal/contract/add';
import EditContractModal from '@features/customer/modal/contract/edit';
import dayjs from 'dayjs';

type IProps = {
  UID: string;
};
export default function CustomerContract({ UID }: IProps) {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const [openAdd, toggleAdd] = useToggle();
  const [toEdit, setToEdit] = useState<Contract | undefined>(undefined);
  const { data: response } = useGetContracts(UID);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Contract>();
    return [
      columnHelper.accessor('CSP', {
        id: 'CSP',
        header: t('general.csp'),
        cell: (v) => <CloudProviderChip provider={v.getValue()} />,
      }),
      columnHelper.display({
        id: 'Status',
        header: t('customer.contractStatus'),
        cell: (v) => {
          const diff = dayjs(v.row.original.EndDate).diff(dayjs(), 'days');
          if (diff <= 0) {
            return <ContractChip status="expired" />;
          }
          if (diff <= v.row.original.ReminderDays) {
            return <ContractChip status="scheduled" />;
          }
          return <ContractChip status="active" />;
        },
      }),
      columnHelper.accessor('StartDate', {
        id: 'StartDate',
        header: t('customer.contractStartDate'),
        cell: (v) => formatDate(v.getValue()),
      }),
      columnHelper.accessor('EndDate', {
        id: 'EndDate',
        header: t('customer.contractEndDate'),
        cell: (v) => formatDate(v.getValue()),
      }),
      columnHelper.accessor('AccountIdList', {
        id: 'AccountIdList',
        header: t('customer.accountList'),
        cell: (v) => v.getValue().join(','),
      }),
      // columnHelper.accessor('DiscountRate', {
      //   id: 'DiscountRate',
      //   header: t('customer.contractRate'),
      //   cell: (v) => `${formatLocaleString(v.getValue() ?? 0)}%`,
      //   meta: {
      //     align: 'right',
      //   },
      // }),
      columnHelper.accessor('EnableNotification', {
        id: 'EnableNotification',
        header: t('customer.notification'),
        cell: (v) => (v.getValue() ? 'O' : 'X'),
      }),
      columnHelper.accessor('Memo', {
        id: 'Memo',
        header: t('general.memo'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.original)}
          >
            <Edit />
          </IconButton>
        ),
        size: 96,
        minSize: 96,
        maxSize: 96,
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data}
        title={customerT('title.contract')}
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addContract')}
        </Button>
      </SimpleTable>
      <AddContractModal open={openAdd} onClose={toggleAdd} UID={UID} />
      {typeof toEdit !== 'undefined' && (
        <EditContractModal
          open={typeof toEdit !== 'undefined'}
          onClose={() => setToEdit(undefined)}
          UID={UID}
          Item={toEdit}
        />
      )}
    </>
  );
}
