import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useAddContact } from '@api/queries/organization';
import CustomerContactForm, {
  useCustomerContactForm,
} from '@features/customer/form/contact';
import FormModal from '@components/modal/form';

type Props = {
  UID: string;
};
export default function AddContactModal({
  open,
  onClose,
  UID,
}: ModalProps & Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contact.add' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync } = useAddContact();
  const { initialValues, validationSchema } = useCustomerContactForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync({ UID, ...v });
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerContactForm {...formik} />
    </FormModal>
  );
}
