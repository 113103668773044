import { Outlet } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import { SuspenseBox } from '@components/suspense';
import { Background, Logo } from '@components/ui';

function HalfView() {
  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', pl: '145px' }}>
        <Typography
          sx={{
            color: '#3a4050',
            fontSize: '64px',
            lineHeight: '120%',
            fontWeight: '600',
            letterSpacing: '5.12px',
          }}
        >
          Make
          <br />
          Your
          <br />
          Workflow
          <br />
          Clear
        </Typography>
      </Box>
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '308px' }}>
          <Box sx={{ pb: '40px' }}>
            <Logo />
          </Box>
          <Outlet />
        </Box>
      </Paper>
    </>
  );
}

function FullView() {
  return (
    <Paper
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '24px',
        px: '96px',
        py: '40px',
      }}
    >
      <Box sx={{ py: '16px', mb: '24px' }}>
        <Logo />
      </Box>
      <Outlet />
    </Paper>
  );
}

type Props = {
  showWordPhrase?: boolean;
};
export default function AuthLayout({ showWordPhrase = false }: Props) {
  return (
    <>
      <Background />
      <SuspenseBox
        component="main"
        FallbackProps={{ size: 96, sx: { minHeight: '100vh' } }}
        sx={{ minHeight: '100vh', display: 'flex', p: '24px 40px' }}
      >
        {showWordPhrase ? <HalfView /> : <FullView />}
      </SuspenseBox>
    </>
  );
}
