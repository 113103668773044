import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps as MuiChipProps } from '@mui/material/Chip';

interface ReportProviderChipProps extends Pick<MuiChipProps, 'classes'> {
  provider: CloudProvider | 'all';
}
const ReportProviderChip = styled(
  ({ provider, ...props }: ReportProviderChipProps) => {
    const label = useMemo(() => {
      switch (provider) {
        case 'all':
          return 'All';
        case 'aws':
          return 'AWS';
        case 'ncp':
          return 'Naver Cloud';
        case 'nhn':
          return 'NHN Cloud';
      }
    }, [provider]);
    return <MuiChip {...props} size="selected" label={label} />;
  },
)<ReportProviderChipProps>(({ provider }) => ({
  ...(provider === 'aws' && {
    color: '#F7B83D',
    background: '#F7B83D33',
  }),
  ...(provider === 'ncp' && {
    color: '#448016',
    background: '#67BE2033',
  }),
  ...(provider === 'nhn' && {
    color: '#1353E2',
    background: '#1353E233',
  }),
  ...(provider === 'all' && {
    background: '#CECFDE33',
  }),
}));

export default ReportProviderChip;
