import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { useUpdateBillingCustom } from '@api/queries/organization';
import SimpleModal from '@components/modal/simple';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

type Props = {
  item?: BillingCustom;
  index?: number;
};
export default function DeleteServiceModal({
  open,
  onClose,
  item,
  index,
}: ModalProps & Props) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.service.delete',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useUpdateBillingCustom();
  const deleteItem = useCallback(async () => {
    if (UID && item && typeof index !== 'undefined') {
      try {
        const clone = _.cloneDeep(item.AdditionalServices);
        clone.splice(index, 1);
        await mutateAsync({
          UID,
          AdditionalServices: clone,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [UID, item, index, mutateAsync, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="danger"
            onClick={deleteItem}
            loading={isPending}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
      enableBorder
    />
  );
}
