import { useMutation } from '@tanstack/react-query';
import { AuthService } from '@api/services';
import { AUTH_KEY } from '@constants/query';

export default function useSendVerification() {
  return useMutation({
    mutationKey: AUTH_KEY.SEND_VERIFICATION,
    mutationFn: (v: CreateVerificationRequest) =>
      AuthService.sendVerification(v),
  });
}
