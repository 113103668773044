import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ContractService } from '@api/services';
import { CONTRACT_KEY } from '@constants/query';

export default function useUpdateContract() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: CONTRACT_KEY.UPDATE_CONTRACT,
    mutationFn: ({
      OrganizationId,
      ContractId,
      ...v
    }: UpdateContract & { OrganizationId: string; ContractId: string }) =>
      ContractService.updateContract(OrganizationId, ContractId, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['CONTRACT'] });
    },
  });
}
