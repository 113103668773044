import { useEffect } from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import FormModal from '@components/modal/form';
import CustomerDepositForm, {
  useCustomerDepositForm,
} from '@features/customer/form/deposit';
import { useUpdateDepositDetail } from '@api/queries/organization';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

type Props = ModalProps & {
  detail?: DepositDetail;
  OrganizationId?: string;
};

export default function EditDepositModal({
  open,
  onClose,
  detail,
  OrganizationId,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.deposit.edit' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateDepositDetail();
  const { initialValues, validationSchema } = useCustomerDepositForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (detail && OrganizationId) {
        try {
          await mutateAsync({
            ...v,
            UID: detail.UID,
            OrganizationId,
            Amount: Number(v.Amount),
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (detail) {
      resetForm({
        values: {
          Name: detail.Name ?? '',
          Date:
            detail.Date ??
            dayjs()
              .startOf('hours')
              .startOf('minutes')
              .startOf('seconds')
              .startOf('milliseconds')
              .toDate(),
          Amount: String(detail.Amount ?? ''),
        },
      });
    }
  }, [detail, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      minHeight="560px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerDepositForm {...formik} activeColor="#098FB9" />
    </FormModal>
  );
}
