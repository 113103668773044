import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useCreateContract } from '@api/queries/contract';
import CustomerContractForm, {
  useCustomerContractForm,
} from '@features/customer/form/contract';
import FormModal from '@components/modal/form';

type IProps = {
  UID: string;
};
export default function AddContractModal({
  open,
  onClose,
  UID,
}: ModalProps & IProps) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contract.add' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useCreateContract();
  const { initialValues, validationSchema } = useCustomerContractForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          ...v,
          OrganizationId: UID,
          CSP: v.CSP as CloudProvider,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      onSubmit={handleSubmit}
      maxWidth="480px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerContractForm {...formik} />
    </FormModal>
  );
}
