import type { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  InputBase,
  Typography,
} from '@mui/material';
import { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion/Accordion';
import { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary/AccordionSummary';
import { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { getAutocompleteUtilityClass } from '@mui/material/Autocomplete';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getInputAdornmentUtilityClass } from '@mui/material/InputAdornment';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import { KeyboardArrowDown } from '@mui/icons-material';
import { MenuItem } from '@heka/theme';

type ReportAccordionWrapperProps = Pick<
  MuiAccordionProps,
  'classes' | 'children'
>;
const ReportAccordionWrapper = styled(
  ({ children, ...props }: ReportAccordionWrapperProps) => (
    <MuiAccordion elevation={0} disableGutters defaultExpanded {...props}>
      {children}
    </MuiAccordion>
  ),
)<ReportAccordionWrapperProps>({
  padding: 0,
  '&::before': { height: 0 },
  border: 'none',
});

type ReportAccordionSummaryProps = Pick<
  MuiAccordionSummaryProps,
  'classes' | 'children'
>;
const ReportAccordionSummary = styled(
  MuiAccordionSummary,
)<ReportAccordionSummaryProps>({
  padding: '8px',
  [`> .${accordionSummaryClasses.content}`]: {
    color: '#098FB9',
    margin: 0,
  },
  '&.Mui-expanded': {
    borderBottom: '0.5px solid #098FB9',
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  paddingTop: '24px',
  paddingLeft: '8px',
  paddingRight: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

type ReportAccordionProps = {
  title: string;
};
export function ReportAccordion({
  children,
  title,
}: PropsWithChildren<ReportAccordionProps>) {
  return (
    <ReportAccordionWrapper>
      <ReportAccordionSummary
        expandIcon={
          <KeyboardArrowDown
            sx={{ width: '20px', height: '20px', color: '#323232' }}
          />
        }
      >
        <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
          {title}
        </Typography>
      </ReportAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </ReportAccordionWrapper>
  );
}

export const AdditionalContentItem = styled(
  ({
    children,
    selected,
    ...props
  }: Pick<
    MuiMenuItemProps,
    'classes' | 'children' | 'value' | 'onClick' | 'selected'
  >) => (
    <MenuItem {...props} selected={selected} sx={{ py: '12px' }} multi>
      {children}
    </MenuItem>
  ),
)();

const AutocompletePopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  marginTop: '8px',
  [`& .${getAutocompleteUtilityClass('paper')}`]: {
    margin: 0,
    [`> .${getAutocompleteUtilityClass('listbox')}`]: {
      padding: 0,
      maxHeight: '200px',
    },
  },
}));

interface PopperComponentProps {
  open: boolean;
  anchorEl?: any;
  disablePortal?: boolean;
}

export function PopperComponent({
  open,
  anchorEl = undefined,
  disablePortal = undefined,
  ...rest
}: PopperComponentProps) {
  return <AutocompletePopper {...rest} />;
}

export const FilterInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  background: '#DFDFDF33',
  borderRadius: '999px',
  [`> .${getInputBaseUtilityClass('input')}`]: {
    padding: '12px 16px 12px 8px !important',
  },
  [`> .${getInputAdornmentUtilityClass('root')}`]: {
    color: '#B1B1B1',
    height: '16px',
    maxHeight: '16px',
    marginLeft: '8px',
    marginRight: 0,
    [`> .${getSvgIconUtilityClass('root')}`]: {
      width: '24px',
      height: '24px',
    },
  },
}));
