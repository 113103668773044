import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const TableEmptyState = styled(Box)({
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  border: '1px solid #E0E0E0',
  background: 'rgba(224, 224, 224, 0.20)',
  padding: '24px 16px',
});
