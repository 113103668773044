import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useUpdateInvoiceCustom() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: INVOICE_KEY.UPDATE_INVOICE_CUSTOM,
    mutationFn: ({
      CSP,
      InvoiceId,
      ...v
    }: UpdateInvoiceCustom & {
      InvoiceId: string;
      CSP?: CloudProvider;
    }) => InvoiceService.updateInvoiceCustom(InvoiceId, CSP, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['INVOICE'] });
    },
  });
}
