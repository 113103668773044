import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteCloudAccount } from '@api/queries/organization';
import SimpleModal from '@components/modal/simple';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

export default function DeleteNCPAccountModal({
  open,
  onClose,
  AccountUID,
}: ModalProps & { AccountUID?: string }) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.ncp.delete',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useDeleteCloudAccount();
  const handleDelete = useCallback(async () => {
    if (UID && AccountUID) {
      try {
        await mutateAsync({
          OrganizationId: UID,
          CSP: 'ncp',
          AccountUID,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [UID, AccountUID, mutateAsync, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="danger"
            onClick={handleDelete}
            loading={isPending}
            disabled={!AccountUID}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
      enableBorder
    />
  );
}
