import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  InputLabel,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { Tile } from '@heka/theme';

type Props = Pick<
  ReturnType<typeof useFormik<CreateContact>>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
>;

export default function CustomerContactForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contact' });
  const handleCloudChange = useCallback(
    (value: CloudProvider) => () => {
      const providers = new Set(values.Providers);
      if (providers.has(value)) {
        providers.delete(value);
      } else {
        providers.add(value);
      }
      setFieldValue('Providers', Array.from(providers).sort());
    },
    [setFieldValue, values.Providers],
  );
  return (
    <Grid container rowSpacing="16px" columnSpacing="24px">
      <Grid xs={12}>
        <TextField
          name="Name"
          value={values.Name}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.name')}
          placeholder={t('holder.name')}
          error={Boolean(errors.Name && touched.Name)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Department"
          value={values.Department}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.department')}
          placeholder={t('holder.department')}
          error={Boolean(errors.Department && touched.Department)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Title"
          value={values.Title}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.title')}
          placeholder={t('holder.title')}
          error={Boolean(errors.Title && touched.Title)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Email"
          value={values.Email}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.email')}
          placeholder={t('holder.email')}
          error={Boolean(errors.Email && touched.Email)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="PhoneNumber"
          value={values.PhoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.phone')}
          placeholder={t('holder.phone')}
          error={Boolean(errors.PhoneNumber && touched.PhoneNumber)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Notes"
          value={values.Notes}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.notes')}
          placeholder={t('holder.notes')}
          error={Boolean(errors.Notes && touched.Notes)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <InputLabel>{t('label.applyCloud')}</InputLabel>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Tile
            type="checkbox"
            value={values.Providers.includes('aws')}
            onChange={handleCloudChange('aws')}
            description="AWS"
            sx={{ flex: 1 }}
          />
          <Tile
            type="checkbox"
            value={values.Providers.includes('ncp')}
            onChange={handleCloudChange('ncp')}
            description="Naver Cloud"
            sx={{ flex: 1 }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
