import { styled } from '@mui/material/styles';
import { Popper as MuiPopper, InputBase } from '@mui/material';
import { getAutocompleteUtilityClass } from '@mui/material/Autocomplete';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getInputAdornmentUtilityClass } from '@mui/material/InputAdornment';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';

export const Popper = styled(MuiPopper)(({ theme }) => ({
  borderRadius: '16px',
  minWidth: '300px',
  zIndex: theme.zIndex.modal,
  background: 'rgba(255, 255, 255, 0.98)',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
  backdropFilter: 'blur(3px)',
}));

const AutocompletePopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  borderTop: '0.5px solid #E0E0E0',
  [`& .${getAutocompleteUtilityClass('paper')}`]: {
    margin: 0,
    color: 'inherit',
    // background: 'rgba(255, 255, 255, 0.98)',
    // borderBottomLeftRadius: '16px',
    // borderBottomRightRadius: '16px',
  },
  [`& .${getAutocompleteUtilityClass('listbox')}`]: {
    // background: 'rgba(255, 255, 255, 0.98)',
    padding: 0,
    width: '100%',
    // maxHeight: '400px',
    overflowY: 'auto',
    [`& .${getAutocompleteUtilityClass('option')}`]: {
      display: 'flex',
      minHeight: 'auto',
      alignItems: 'center',
      padding: '16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.Mui-focused': {
        background: 'none',
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #f0f0f7',
      },
    },
  },
}));

interface PopperComponentProps {
  open: boolean;
  anchorEl?: any;
  disablePortal?: boolean;
}

export function PopperComponent({
  open,
  anchorEl = undefined,
  disablePortal = undefined,
  ...rest
}: PopperComponentProps) {
  return <AutocompletePopper {...rest} />;
}

export const FilterInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  background: '#FFFFFF',
  [`> .${getInputBaseUtilityClass('input')}`]: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    paddingLeft: '8px !important',
    paddingRight: '16px !important',
  },
  [`> .${getInputAdornmentUtilityClass('root')}`]: {
    color: '#777B86',
    height: '16px',
    maxHeight: '16px',
    marginLeft: '16px',
    marginRight: 0,
    [`> .${getSvgIconUtilityClass('root')}`]: {
      width: '24px',
      height: '24px',
    },
  },
}));
