function getFromDate() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
}

export const DEFAULT_FILTER_STATE: AWSCEUsageRequestInternal = {
  FromDate: getFromDate(),
  ToDate: new Date(),
  GroupBy: 'SERVICE',
  Granularity: 'DAILY',
  Metric: 'UNBLENDED_COST',
  TagKey: ' ',
};
