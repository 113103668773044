import * as React from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import MuiCollapse from '@mui/material/Collapse';
import MuiSvgIcon from '@mui/material/SvgIcon';
import Info from '@mui/icons-material/Info';
import ExpandLess from '@mui/icons-material/ExpandLess';

export interface NoteProps {
  variant?: 'default' | 'expand';
  color?: 'default' | 'calm' | 'emphasis' | 'negative';
  defaultOpen?: boolean;
  disableCollapse?: boolean;
  title: string;
  content?: string | TrustedHTML;
  sx?: SxProps<Theme>;
}

type WrapperProps = Pick<NoteProps, 'variant' | 'color' | 'disableCollapse'>;

const Wrapper = styled('div', {
  name: 'HekaNote',
  slot: 'Root',
  shouldForwardProp: (propName) => propName !== 'disableCollapse',
  overridesResolver: (props, styles) => [
    styles.root,
    props.variant === 'default' && styles.default,
    props.variant === 'expand' && styles.expand,
    props.color === 'default' && styles.colorDefault,
    props.color === 'calm' && styles.colorCalm,
    props.color === 'emphasis' && styles.colorEmphasis,
    props.color === 'negative' && styles.colorNegative,
    props.disableCollapse && styles.disableCollapse,
  ],
})<WrapperProps>();

function Note({
  variant = 'default',
  color = 'default',
  defaultOpen = false,
  disableCollapse = false,
  title,
  content,
  sx,
}: NoteProps) {
  const [opened, setOpened] = React.useState(defaultOpen);
  const toggleOpened = React.useCallback(() => {
    if (!disableCollapse) {
      setOpened((v) => !v);
    }
  }, [disableCollapse]);
  const variantClass = `HekaNote-${variant}`;
  const colorClass = `HekaNote-color${color
    ?.charAt(0)
    .toUpperCase()}${color?.slice(1)}`;
  const openClass = opened ? 'HekaNote-open' : undefined;
  const disableClass = disableCollapse ? 'HekaNote-disableCollapse' : undefined;
  return (
    <Wrapper
      className={[
        'HekaNote-root',
        variantClass,
        colorClass,
        openClass,
        disableClass,
      ]
        .filter((v) => v !== undefined)
        .join(' ')}
      onClick={toggleOpened}
      variant={variant}
      color={color}
      disableCollapse={disableCollapse}
      sx={sx}
    >
      <div className="HekaNote-header">
        {variant === 'default' && (
          <MuiSvgIcon className="HekaNote-iconInfo" component={Info} />
        )}
        <p className="HekaNote-title">{title}</p>
        {variant === 'expand' && (
          <MuiSvgIcon className="HekaNote-iconExpand" component={ExpandLess} />
        )}
      </div>
      {typeof content !== 'undefined' && (
        <MuiCollapse in={opened}>
          <p
            className="HekaNote-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </MuiCollapse>
      )}
    </Wrapper>
  );
}
Note.displayName = 'HekaNote';

export default Note;
