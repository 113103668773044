import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { useGetCompanyExtraInfo, useUploadStamp } from '@api/queries/company';
import { ImageFile, Thumbnail } from '../styled';

function ImageUploader() {
  const { t } = useTranslation('settings');
  const [image, setImage] = useState<ImageFile | undefined>(undefined);
  const { data: response } = useGetCompanyExtraInfo();
  const { mutateAsync, isPending } = useUploadStamp();
  const uploadStamp = useCallback(async () => {
    if (image) {
      try {
        await mutateAsync(image);
      } catch (e) {
        console.error(e);
      }
    }
  }, [image, mutateAsync]);
  const convertBase64ToFile = useCallback((content: string) => {
    const chunk = content.split(',');
    const mimeTypes = chunk[0].match(/:(.*?);/);
    if (!mimeTypes || mimeTypes.length < 2) {
      return;
    }
    const mime = mimeTypes[1];
    const bstr = atob(chunk[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], 'image', { type: mime });
    setImage(Object.assign(file, { preview: URL.createObjectURL(file) }));
  }, []);
  const { open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setImage(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        );
      }
    },
  });
  useEffect(() => {
    if (response?.data && !_.isEmpty(response.data.StampImg)) {
      convertBase64ToFile(response.data.StampImg);
    }
  }, [convertBase64ToFile, response?.data]);
  return (
    <Box sx={{ minWidth: '100%', mt: '16px' }}>
      <Thumbnail preview={image?.preview} onClear={() => setImage(undefined)} />
      <Box sx={{ display: 'flex', gap: '8px', mt: '16px' }}>
        <Button size="small" color="sub" onClick={open}>
          {t('button.uploadStamp')}
        </Button>
        {!_.isEmpty(response?.data.StampImg) && (
          <Button size="small" color="danger" disabled>
            {t('button.deleteStamp')}
          </Button>
        )}
        {Boolean(image) && (
          <LoadingButton
            size="small"
            onClick={uploadStamp}
            loading={isPending}
            disabled={!image}
          >
            {t('button.saveStamp')}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

export default function SettingsCompanyStamp() {
  const { t } = useTranslation('settings');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography color="textSecondary" fontSize="14px" letterSpacing="0.56px">
        {t('title.company.stamp')}
      </Typography>
      <Typography>{t('text.stampDescription')}</Typography>
      <Box sx={{ maxWidth: '292px' }}>
        <ImageUploader />
      </Box>
    </Box>
  );
}
