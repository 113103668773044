import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { Note } from '@heka/theme';
import {
  useGetOrganizationForUI,
  useUpdateOrganization,
} from '@api/queries/organization';
import DeleteCustomerModal from '@features/customer/modal/customer/delete';
import CustomerInformationForm, {
  useCustomerInformationForm,
} from '@features/customer/form/info';

export default function CustomerInformation() {
  const { uid } = useParams();
  const { t } = useTranslation('customer');
  const { t: globalT } = useTranslation('global');
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: response } = useGetOrganizationForUI();
  const { mutateAsync: updateCustomer, isPending } = useUpdateOrganization();
  const { validationSchema } = useCustomerInformationForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues: {
      UID: response?.data.UID ?? '',
      Name: response?.data.Name ?? '',
      Representative: response?.data.Representative ?? '',
      RegistrationNumber: response?.data.RegistrationNumber ?? '',
      BusinessLocation: response?.data.BusinessLocation ?? '',
      HeadOfficeLocation: response?.data.HeadOfficeLocation ?? '',
      Telephone: response?.data.Telephone ?? '',
      Website: response?.data.Website ?? '',
      TypeOfBusiness: response?.data.TypeOfBusiness ?? '',
      ItemOfBusiness: response?.data.ItemOfBusiness ?? '',
    },
    validationSchema,
    onSubmit: async (v) => {
      try {
        await updateCustomer(v);
      } catch (e) {
        console.error(e);
      }
    },
  });
  const handleDelete = useCallback(() => {
    if (uid) {
      setToDelete(uid);
    }
  }, [uid]);
  useEffect(() => {
    if (response?.data) {
      resetForm({
        values: {
          UID: response?.data.UID ?? '',
          Name: response?.data.Name ?? '',
          Representative: response?.data.Representative ?? '',
          RegistrationNumber: response?.data.RegistrationNumber ?? '',
          BusinessLocation: response?.data.BusinessLocation ?? '',
          HeadOfficeLocation: response?.data.HeadOfficeLocation ?? '',
          Telephone: response?.data.Telephone ?? '',
          Website: response?.data.Website ?? '',
          TypeOfBusiness: response?.data.TypeOfBusiness ?? '',
          ItemOfBusiness: response?.data.ItemOfBusiness ?? '',
        },
      });
    }
  }, [resetForm, response?.data]);
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: '807px' }}>
      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="24px"
      >
        {t('title.information')}
      </Typography>
      <CustomerInformationForm {...formik} gap="40px" />
      <LoadingButton
        type="submit"
        loading={isPending}
        disabled={!isValid || !dirty}
        sx={{ mt: '40px' }}
      >
        {globalT('button.save')}
      </LoadingButton>
      <Box sx={{ mt: '40px' }}>
        <Typography letterSpacing="0.64px" mb="24px">
          {t('modal.customer.delete.title')}
        </Typography>
        <Note
          color="negative"
          title={t('modal.customer.delete.title')}
          content={t('text.deleteCustomer')}
          disableCollapse
          defaultOpen
          sx={{ maxWidth: '462px' }}
        />
        <Button color="danger" onClick={handleDelete} sx={{ mt: '40px' }}>
          {globalT('button.delete')}
        </Button>
      </Box>
      <DeleteCustomerModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </Box>
  );
}
