import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Link, MenuItem, Note } from '@heka/theme';
import { ResponsiveImage } from '@components/styled/image';
import type { AccessAccountForm } from './form';

type Props = Pick<
  ReturnType<typeof useFormik<AccessAccountForm>>,
  'values' | 'errors' | 'touched' | 'handleChange' | 'handleBlur'
>;
export function AccessKeyStep({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: Props) {
  const { t } = useTranslation('onboard');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('integrateNCP.content') }}
          mb="16px"
        />
        <Link href="/">{t('integrateNCP.text.link')}</Link>
      </Box>
      <ResponsiveImage
        src="/assets/img/ncp-access-key.png"
        alt="How to integrate access key"
        sx={{ maxWidth: '604px' }}
      />
      <Note
        title={t('integrateNCP.note.title')}
        content={t('integrateNCP.note.content')}
        disableCollapse
        defaultOpen
      />
      <Typography>{t('integrateNCP.text.accessKeyId')}</Typography>
      <Box sx={{ maxWidth: '392px' }}>
        <TextField
          name="AccessKey"
          value={values.AccessKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('integrateNCP.form.label.accessKeyId')}
          placeholder={t('integrateNCP.form.holder.accessKeyId')}
          error={Boolean(errors.AccessKey && touched.AccessKey)}
          helperText={
            Boolean(errors.AccessKey && touched.AccessKey) &&
            String(errors.AccessKey)
          }
          type="password"
          inputMode="text"
          autoComplete="off"
          fullWidth
        />
      </Box>
    </Box>
  );
}

export function SecretKeyStep({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: Props) {
  const { t } = useTranslation('onboard');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ResponsiveImage
        src="/assets/img/ncp-secret-key.png"
        alt="How to integrate secret key"
        sx={{ maxWidth: '604px' }}
      />
      <Typography mt="40px" mb="24px">
        {t('integrateNCP.text.secretKey')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          maxWidth: '498px',
        }}
      >
        <TextField
          name="AccessKey"
          value={values.AccessKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('integrateNCP.form.label.accessKeyId')}
          placeholder={t('integrateNCP.form.holder.accessKeyId')}
          error={Boolean(errors.AccessKey && touched.AccessKey)}
          helperText={
            Boolean(errors.AccessKey && touched.AccessKey) &&
            String(errors.AccessKey)
          }
          type="password"
          inputMode="text"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="SecretKey"
          value={values.SecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('integrateNCP.form.label.secretKey')}
          placeholder={t('integrateNCP.form.holder.secretKey')}
          error={Boolean(errors.SecretKey && touched.SecretKey)}
          helperText={
            Boolean(errors.SecretKey && touched.SecretKey) &&
            String(errors.SecretKey)
          }
          type="password"
          inputMode="text"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="AccountType"
          value={values.AccountType}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('integrateNCP.form.label.accountType')}
          placeholder={t('integrateNCP.form.holder.accountType')}
          error={Boolean(errors.AccountType && touched.AccountType)}
          helperText={
            Boolean(errors.AccountType && touched.AccountType) &&
            String(errors.AccountType)
          }
          select
          fullWidth
        >
          <MenuItem value=" " disabled>
            {t('integrateNCP.form.holder.accountType')}
          </MenuItem>
          <MenuItem value="enterprise">
            {t('integrateNCP.text.typeEnterprise')}
          </MenuItem>
          <MenuItem value="public">
            {t('integrateNCP.text.typePublic')}
          </MenuItem>
          <MenuItem value="finance">
            {t('integrateNCP.text.typeFinancial')}
          </MenuItem>
        </TextField>
      </Box>
    </Box>
  );
}
