import type {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
} from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  TextField,
  RadioGroup,
  Autocomplete as MuiAutocomplete,
  ClickAwayListener,
  FormControlLabel,
  Radio,
  InputLabel,
  Checkbox,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import { getOutlinedInputUtilityClass } from '@mui/material/OutlinedInput';
import { getInputAdornmentUtilityClass } from '@mui/material/InputAdornment';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from '@mui/icons-material';
import _ from 'lodash';
import { useGetAWSCostAutocomplete } from '@api/queries/aws/cost';
import { FilterInput, Popper, PopperComponent } from './styled';

type AutocompleteProps = {
  basis:
    | 'SERVICE'
    | 'LINKED_ACCOUNT'
    | 'LINKED_COMPANIES'
    | 'RECORD'
    | 'INSTANCE_TYPE'
    | 'USAGE_TYPE'
    | 'USAGE_TYPE_GROUP';
  filter: AWSCEUsageRequestInternal;
  setFilter: Dispatch<SetStateAction<AWSCEUsageRequestInternal>>;
};

export default function Autocomplete({
  basis,
  filter,
  setFilter,
}: AutocompleteProps) {
  const { t } = useTranslation('cost', { keyPrefix: 'filter' });
  const [isExclude, setIsExclude] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popperId = open ? `cost-filter-popper-${basis}` : undefined;
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { data: response } = useGetAWSCostAutocomplete();
  const nameTranslationKey = useMemo(() => {
    switch (basis) {
      case 'LINKED_ACCOUNT':
        return 'label.linked_account';
      case 'RECORD':
        return 'label.record';
      case 'INSTANCE_TYPE':
        return 'label.instanceType';
      case 'USAGE_TYPE':
        return 'label.usageType';
      case 'USAGE_TYPE_GROUP':
        return 'label.usageTypeGroup';
      default:
        return 'label.service';
    }
  }, [basis]);
  const values = useMemo(() => {
    switch (basis) {
      case 'SERVICE':
        return filter.ServicesExclude ?? filter.Services ?? [];
      case 'RECORD':
        return filter.RecordsExclude ?? filter.Records ?? [];
      case 'LINKED_ACCOUNT':
        return filter.AccountIdList ?? [];
      case 'INSTANCE_TYPE':
        return filter.InstanceTypeExclude ?? filter.InstanceType ?? [];
      case 'USAGE_TYPE':
        return filter.UsageTypeExclude ?? filter.UsageType ?? [];
      case 'USAGE_TYPE_GROUP':
        return filter.UsageTypeGroupExclude ?? filter.UsageTypeGroup ?? [];
      default:
        return [];
    }
  }, [basis, filter]);
  const optionsOrigin = useMemo(() => {
    if (response?.data) {
      switch (basis) {
        case 'SERVICE':
          return _.uniq(response.data.Services ?? []);
        case 'RECORD':
          return _.uniq(response.data.Records ?? []);
        case 'LINKED_ACCOUNT':
          return _.uniq(response.data.Accounts ?? []);
        case 'INSTANCE_TYPE':
          return _.uniq(response.data.InstanceType ?? []);
        case 'USAGE_TYPE':
          return _.uniq(response.data.UsageType ?? []);
        case 'USAGE_TYPE_GROUP':
          return _.uniq(response.data.UsageTypeGroup ?? []);
      }
    }
    return [];
  }, [basis, response]);
  const options = useMemo(() => {
    return _.concat(['HEKA_SELECT_ALL'], optionsOrigin);
  }, [optionsOrigin]);
  const setValues = useCallback(
    (newValue: string[], manualExclude?: boolean) => {
      const exclusion = manualExclude ?? isExclude;
      if (basis === 'SERVICE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            ServicesExclude: newValue,
            Services: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            Services: newValue,
            ServicesExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'RECORD') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            RecordsExclude: newValue,
            Records: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            Records: newValue,
            RecordsExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'INSTANCE_TYPE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            InstanceTypeExclude: newValue,
            InstanceType: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            InstanceType: newValue,
            InstanceTypeExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'USAGE_TYPE') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            UsageTypeExclude: newValue,
            UsageType: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            UsageType: newValue,
            UsageTypeExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'USAGE_TYPE_GROUP') {
        if (exclusion) {
          setFilter((v) => ({
            ...v,
            UsageTypeGroupExclude: newValue,
            UsageTypeGroup: undefined,
          }));
        } else {
          setFilter((v) => ({
            ...v,
            UsageTypeGroup: newValue,
            UsageTypeGroupExclude: undefined,
          }));
        }
        return;
      }
      if (basis === 'LINKED_ACCOUNT') {
        setFilter((v) => ({
          ...v,
          AccountIdList: newValue,
          Organizations: undefined,
          OrganizationsExclude: undefined,
        }));
      }
    },
    [basis, setFilter, isExclude],
  );
  const isAllSelected = useMemo(() => {
    return optionsOrigin.every((e) => values.includes(e)) ?? false;
  }, [optionsOrigin, values]);
  const isPartialSelected = useMemo(() => {
    return optionsOrigin.some((e) => values.includes(e)) ?? false;
  }, [optionsOrigin, values]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else {
      setValues(optionsOrigin ?? []);
    }
  }, [optionsOrigin, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: string, selected: boolean) => {
      if (option === 'HEKA_SELECT_ALL') {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: string) => {
      if (option === 'HEKA_SELECT_ALL') {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  const getOptionLabel = useCallback(
    (option: string) => {
      if (option === 'HEKA_SELECT_ALL') {
        return t('text.selectAll', {
          count: Math.max(optionsOrigin.length, 0),
        });
      }
      if (_.isEmpty(option)) {
        return t('text.optionNone', { name: t(nameTranslationKey) });
      }
      if (basis === 'LINKED_COMPANIES') {
        return response?.data.Organizations?.find((v) => v.UID === option)
          ?.Name;
      }
      return option;
    },
    [
      basis,
      t,
      optionsOrigin.length,
      nameTranslationKey,
      response?.data.Organizations,
    ],
  );
  const placeholder = useMemo(() => {
    if (!optionsOrigin.length) {
      return t('text.disabled', { name: t(nameTranslationKey) });
    }
    if (values.length) {
      return t(`text.${isExclude ? 'excluded' : 'included'}`, {
        count: values.length,
      });
    }
    return t('text.allSelected', { name: t(nameTranslationKey) });
  }, [values, optionsOrigin, isExclude, nameTranslationKey, t]);
  const handleChangeExclusion = useCallback(
    (v: boolean) => {
      setIsExclude(v);
      setValues(values, v);
    },
    [values, setValues],
  );
  return (
    <Box>
      <InputLabel>{t(nameTranslationKey)}</InputLabel>
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick: handleOpen,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ '> svg': { color: '#323232 !important' } }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={(theme) => ({
          [`> .${getOutlinedInputUtilityClass('root')}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${getOutlinedInputUtilityClass('input')}`]: {
              cursor: 'pointer',
              userSelect: 'none',
            },
            [`> .${getInputAdornmentUtilityClass('root')} > svg`]: {
              width: '24px',
              height: '24px',
              color: 'text.secondary',
            },
          },
        })}
      />
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ p: '16px', borderBottom: '0.5px solid #E0E0E0' }}>
              <RadioGroup
                value={isExclude}
                onChange={(e, v) => handleChangeExclusion(v === 'true')}
                row
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('text.include')}
                  sx={{ ml: 0 }}
                />
                <FormControlLabel
                  value
                  control={<Radio />}
                  label={t('text.exclude')}
                  sx={{ ml: 0 }}
                />
              </RadioGroup>
            </Box>
            <MuiAutocomplete
              open
              onClose={(e, r) => {
                if (r === 'escape') {
                  handleClose();
                }
              }}
              value={values}
              onChange={(e, v, r) => {
                if (
                  e.type === 'keydown' &&
                  (e as KeyboardEvent).key === 'Backspace' &&
                  r === 'removeOption'
                ) {
                  return;
                }
                if (v.find((v) => v === 'HEKA_SELECT_ALL')) {
                  toggleAll();
                  return;
                }
                setValues(v);
              }}
              options={options}
              getOptionLabel={(v) => v}
              noOptionsText={t('text.disabled', {
                name: t(nameTranslationKey),
              })}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props} aria-selected="false">
                  <Checkbox
                    checked={getChecked(option, selected)}
                    indeterminate={getIndeterminate(option)}
                    sx={{ p: 0, pr: '8px' }}
                  />
                  <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
                    {getOptionLabel(option)}
                  </ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <FilterInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  placeholder={t('text.filtering', {
                    name: t(nameTranslationKey),
                  })}
                  autoFocus
                  fullWidth
                />
              )}
              disabled={!optionsOrigin.length}
              PopperComponent={PopperComponent}
              PaperComponent={Box}
              disableCloseOnSelect
              disableClearable
              multiple
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
