import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate, formatLocaleString } from '@utils/formatter';

type CostSingleChartProps = {
  data?: Array<{
    Date: Date;
    Cost: number;
  }>;
  Granularity: CEGranularity;
};

export default function CostSingleChart({
  data = [],
  Granularity,
}: CostSingleChartProps) {
  return (
    <ResponsiveContainer width="100%" height={460}>
      <BarChart data={data} barSize={32}>
        <XAxis
          dataKey="Date"
          stroke="#CECFDE"
          strokeWidth={0.5}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={16}
          tickFormatter={(v) =>
            formatDate(v, Granularity === 'MONTHLY' ? 'YYYY.MM' : 'YYYY.MM.DD')
          }
          padding={{ left: 16, right: 16 }}
        />
        <YAxis
          scale="linear"
          stroke="#CECFDE"
          strokeWidth={0.5}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={8}
          tickFormatter={(v) => formatLocaleString(v) ?? ''}
          padding={{ top: 8, bottom: 8 }}
        />
        <CartesianGrid vertical={false} stroke="#E0E0E0" strokeWidth={0.5} />
        <defs>
          <linearGradient
            id="colorCostSingleGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#B9DEAC" />
            <stop offset="1" stopColor="#DBE9A6" />
          </linearGradient>
        </defs>
        <Bar dataKey="Cost" fill="url(#colorCostSingleGradient)" radius={8} />
      </BarChart>
    </ResponsiveContainer>
  );
}
