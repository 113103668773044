import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminService } from '@api/services';
import { encryptLocalStorage } from '@utils/crypto';
import { ADMIN_KEY } from '@constants/query';
import STORAGE_KEY from '@constants/storage';

export default function useGetUserToken() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ADMIN_KEY.GET_USER_TOKEN,
    mutationFn: (v: string) => AdminService.getUserToken(v),
    onSuccess: ({ data }) => {
      queryClient.clear();
      localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
      encryptLocalStorage(STORAGE_KEY.ACCESS_TOKEN, data.AccessToken);
    },
  });
}
