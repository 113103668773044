import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSortInvoiceCloudUsage } from '@api/queries/invoice';
import FormModal from '@components/modal/form';

type IProps = ModalProps & {
  InvoiceId?: string;
};
export default function SortCloudUsageModal({
  open,
  onClose,
  InvoiceId,
}: IProps) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useSortInvoiceCloudUsage();
  const { values, isValid, handleChange, handleBlur, handleSubmit, resetForm } =
    useFormik<SortInvoiceCloudUsage>({
      initialValues: {
        SortKey: 'cost',
        SortBy: 'desc',
      },
      validationSchema: Yup.object()
        .shape({
          SortKey: Yup.mixed().oneOf(['name', 'cost']).defined(),
          SortBy: Yup.mixed().oneOf(['asc', 'desc']).defined(),
        })
        .defined(),
      onSubmit: async (v) => {
        if (InvoiceId) {
          try {
            await mutateAsync({ InvoiceId, ...v });
            onClose();
          } catch (e) {
            console.error(e);
          }
        }
      },
    });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('modal.cloud.sort.title')}
      maxWidth="320px"
      minHeight="400px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid}
          >
            {t('modal.cloud.sort.cta')}
          </LoadingButton>
        </>
      }
      sx={{ alignItems: 'flex-start' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box>
          <InputLabel>정렬 기준</InputLabel>
          <RadioGroup
            name="SortKey"
            value={values.SortKey}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ ml: '11px' }}
          >
            <FormControlLabel value="name" control={<Radio />} label="이름" />
            <FormControlLabel value="cost" control={<Radio />} label="금액" />
          </RadioGroup>
        </Box>
        <Box>
          <InputLabel>정렬</InputLabel>
          <RadioGroup
            name="SortBy"
            value={values.SortBy}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ ml: '11px' }}
          >
            <FormControlLabel
              value="asc"
              control={<Radio />}
              label="오름차순"
            />
            <FormControlLabel
              value="desc"
              control={<Radio />}
              label="내림차순"
            />
          </RadioGroup>
        </Box>
      </Box>
    </FormModal>
  );
}
