import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, CSSObject, Theme } from '@mui/material/styles';
import {
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from '@mui/material';
import {
  DrawerProps as MuiDrawerProps,
  getDrawerUtilityClass,
} from '@mui/material/Drawer';
import { ListProps as MuiListProps } from '@mui/material/List';
import { ListItemButtonProps as MuiListItemButtonProps } from '@mui/material/ListItemButton';
import { getListItemIconUtilityClass } from '@mui/material/ListItemIcon';
import CollapseIcon from '@assets/icon/Collapse.svg?react';

const DRAWER_WIDTH = 180 as const;
const DRAWER_SHRINK_WIDTH = 100 as const;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_SHRINK_WIDTH,
  transition: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
});

interface DrawerProps extends MuiDrawerProps {
  open?: boolean;
}
export const Drawer = styled(({ open, ...props }: DrawerProps) => (
  <MuiDrawer
    {...props}
    PaperProps={{ className: !open ? 'MuiDrawer-shrink' : undefined }}
  />
))(({ theme, open }) => ({
  position: 'relative',
  minWidth: 0,
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  [`> .${getDrawerUtilityClass('paper')}`]: {
    border: 'none',
    background: 'none',
    borderRadius: 0,
    padding: 0,
    // background: 'linear-gradient(180deg, #FFF 0%, #F5F3F0 100%)',
    ...(open && {
      ...openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
    }),
  },
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  margin: 0,
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

type NavListProps = Pick<MuiListProps, 'classes' | 'children'>;
export const NavList = styled((props) => (
  <MuiList {...props} disablePadding />
))<NavListProps>({
  boxShadow: 'none',
  '> li:not(:first-of-type)': {
    marginTop: '12px',
  },
});

type BaseNavItemProps = {
  label: string;
  Icon: ReactNode;
  shrink?: boolean;
};

type NavLinkProps = {
  href: string;
  label: string;
  Icon: ReactNode;
  shrink?: boolean;
};
export function NavLink({ href, label, Icon, shrink = false }: NavLinkProps) {
  const { pathname } = useLocation();
  const isActive = useMemo(() => {
    return pathname.startsWith(href);
  }, [pathname, href]);
  return (
    <MuiListItem disablePadding>
      <MuiListItemButton
        component={Link}
        to={href}
        disableRipple
        disableTouchRipple
        sx={(theme) => ({
          px: '16px',
          py: '10px',
          borderRadius: '16px',
          transition: theme.transitions.create(['width', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          color: '#1A1818',
          // [`> .${getListItemIconUtilityClass('root')}`]: {
          //   color: '#1A1818',
          // },
          '&:hover': {
            background: 'rgba(255, 239, 93, 0.10)',
            [`> .${getListItemIconUtilityClass('root')} > svg`]: {
              '> .foreground': {
                fill: '#EED700',
              },
            },
          },
          ...(isActive && {
            color: '#FAFAFA',
            background: '#100F0F',
            [`> .${getListItemIconUtilityClass('root')} > svg`]: {
              '> .foreground': {
                fill: '#EED700',
              },
            },
            '&:hover': {
              color: '#1A1818',
              background: 'rgba(255, 239, 93, 0.15)',
            },
          }),
          ...(shrink && {
            p: '10px',
          }),
        })}
      >
        <MuiListItemIcon
          sx={{
            color: 'inherit',
            minWidth: '32px',
            mr: '8px',
            ...(shrink && {
              mr: 'auto',
            }),
          }}
        >
          {Icon}
        </MuiListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            lineHeight: '16px',
            color: 'inherit',
            letterSpacing: 0,
            ...(isActive && {
              fontWeight: 500,
            }),
          }}
          sx={{
            ...(shrink && {
              display: 'none',
              opacity: 0,
            }),
          }}
        />
      </MuiListItemButton>
    </MuiListItem>
  );
}

type NavButtonProps = BaseNavItemProps;
export function NavButton({ label, Icon, shrink = false }: NavButtonProps) {
  return (
    <MuiListItem disablePadding>
      <MuiListItemButton
        sx={(theme) => ({
          px: '16px',
          py: '10px',
          borderRadius: '16px',
          transition: theme.transitions.create(['width', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          '&:hover': {
            background: 'rgba(255, 239, 93, 0.10)',
          },
          ...(shrink && {
            p: '10px',
          }),
        })}
      >
        <MuiListItemIcon
          sx={{
            minWidth: '32px',
            mr: '8px',
            ...(shrink && {
              mr: 'auto',
            }),
          }}
        >
          {Icon}
        </MuiListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            lineHeight: '16px',
            color: '#1A1818',
            letterSpacing: 0,
          }}
          sx={{
            ...(shrink && {
              display: 'none',
              opacity: 0,
            }),
          }}
        />
      </MuiListItemButton>
    </MuiListItem>
  );
}

type PanelToggleProps = Pick<MuiListItemButtonProps, 'onClick'> & {
  shrink?: boolean;
};
export function PanelToggle({ onClick, shrink = false }: PanelToggleProps) {
  const { t } = useTranslation('global');
  return (
    <MuiListItem disablePadding>
      <MuiListItemButton
        onClick={onClick}
        disableRipple
        disableTouchRipple
        sx={(theme) => ({
          px: '16px',
          py: '10px',
          borderRadius: '16px',
          '&:hover': {
            background: 'none',
          },
          transition: theme.transitions.create(['width', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ...(shrink && {
            p: '10px',
          }),
        })}
      >
        <MuiListItemIcon
          sx={(theme) => ({
            minWidth: '32px',
            mr: '8px',
            ...(shrink && {
              mr: 'auto',
            }),
            '> svg': {
              transition: theme.transitions.create('transform', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shortest,
              }),
              ...(shrink && {
                transform: 'rotate(180deg)',
              }),
            },
          })}
        >
          <CollapseIcon />
        </MuiListItemIcon>
        <ListItemText
          primary={t('sidebar.main.collapse')}
          primaryTypographyProps={{
            lineHeight: '16px',
            color: 'textSecondary',
            letterSpacing: 0,
          }}
          sx={{
            ...(shrink && {
              display: 'none',
              opacity: 0,
            }),
          }}
        />
      </MuiListItemButton>
    </MuiListItem>
  );
}
