import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import _ from 'lodash';
import ReportSingleChart from '@components/chart/report/simple';
import ReportStackChart from '@components/chart/report/stack';
import ReportTable from '@components/table/report';
import { ReportProviderChip } from '@components/styled/chip';
import { REPORT_FILTER_STATE } from '@atoms/page/filter';
import { formatDate, formatKRW } from '@utils/formatter';
import { ReportContentSection } from './styled';

export default function ReportBillingSection({
  InvoiceAmount,
}: Pick<ReportContent, 'InvoiceAmount'>) {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const reportSettings = useRecoilValue(REPORT_FILTER_STATE);
  const dateList = useMemo(() => {
    if (!_.isEmpty(InvoiceAmount)) {
      return Object.keys(Object.values(InvoiceAmount)[0].DateList);
    }
    return [];
  }, [InvoiceAmount]);
  const pinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    const items = [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('report.customerName'),
        cell: (v) => {
          if (v.getValue() === 'HEKA_TABLE_TOTAL') {
            return !reportSettings.DisplayOptions.includes('BY_CSP')
              ? t('report.totalCost')
              : '';
          }
          return v.getValue();
        },
        size: 140,
        minSize: 140,
        maxSize: 140,
      }),
      columnHelper.accessor('Total', {
        id: 'Total',
        header: t('report.totalBillingAmount'),
        cell: (v) => formatKRW(v.getValue()),
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
    if (reportSettings.DisplayOptions.includes('BY_CSP')) {
      items.unshift(
        columnHelper.accessor('CSP', {
          id: 'CSP',
          header: t('report.csp'),
          cell: (v) =>
            v.row.original.Name !== 'HEKA_TABLE_TOTAL' ? (
              <ReportProviderChip provider={v.getValue() as CloudProvider} />
            ) : (
              t('report.totalCost')
            ),
          size: 140,
          minSize: 140,
          maxSize: 140,
        }),
      );
    }
    return items;
  }, [reportSettings.DisplayOptions, t]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, string | number>>();
    return dateList.map((date) =>
      columnHelper.accessor(date, {
        id: date,
        header: formatDate(dayjs(date), 'YYYY.MM'),
        cell: (v) => formatKRW(v.getValue() ?? 0),
        meta: {
          align: 'right',
        },
      }),
    );
  }, [dateList]);
  const chartData = useMemo(() => {
    let obj: Record<
      string,
      { Date: Date; Total: number; aws: number; ncp: number; nhn: number }
    > = {};
    Object.keys(InvoiceAmount).forEach((org) => {
      Object.keys(InvoiceAmount[org].DateList).forEach((date) => {
        if (_.has(obj, date)) {
          obj[date].Total += InvoiceAmount[org].DateList[date].Total;
        } else {
          obj = _.assign(obj, {
            [date]: {
              Date: dayjs(date).toDate(),
              Total: InvoiceAmount[org].DateList[date].Total,
              aws: 0,
              ncp: 0,
            },
          });
        }
        if (reportSettings.DisplayOptions.includes('BY_CSP')) {
          Object.keys(InvoiceAmount[org].DateList[date].CSPList).forEach(
            (csp) => {
              obj[date][csp as CloudProvider] +=
                InvoiceAmount[org].DateList[date].CSPList[
                  csp as CloudProvider
                ].Cost;
            },
          );
        }
      });
    });
    return Object.values(obj);
  }, [InvoiceAmount, reportSettings.DisplayOptions]);
  const tableData = useMemo(() => {
    let obj: Record<
      string,
      {
        Name: string;
        Total: number;
        CSPList: Record<string, Record<string, number>>;
      }
    > = {
      Total: {
        Name: 'HEKA_TABLE_TOTAL',
        Total: 0,
        CSPList: {
          Global: {},
        },
      },
    };
    Object.keys(InvoiceAmount).forEach((org) => {
      if (!_.has(obj, org)) {
        obj = _.assign(obj, {
          [org]: {
            Name: InvoiceAmount[org].Name,
            Total: InvoiceAmount[org].TotalCost,
            CSPList: {},
          },
        });
      }
      obj['Total'].Total += InvoiceAmount[org].TotalCost;
      Object.keys(InvoiceAmount[org].DateList).forEach((date) => {
        if (!_.has(obj['Total'].CSPList['Global'], date)) {
          obj['Total'].CSPList['Global'][date] = 0;
        }
        if (reportSettings.DisplayOptions.includes('BY_CSP')) {
          Object.keys(InvoiceAmount[org].DateList[date].CSPList).forEach(
            (csp) => {
              if (!_.has(obj[org].CSPList, csp)) {
                obj[org].CSPList[csp] = {};
              }
              if (!_.has(obj[org].CSPList[csp], date)) {
                obj[org].CSPList[csp][date] = 0;
              }
              obj[org].CSPList[csp][date] +=
                InvoiceAmount[org].DateList[date].Total;
              obj['Total'].CSPList['Global'][date] +=
                InvoiceAmount[org].DateList[date].Total;
            },
          );
        } else {
          if (!_.has(obj[org].CSPList, 'Global')) {
            obj[org].CSPList['Global'] = {};
          }
          if (!_.has(obj[org].CSPList['Global'], date)) {
            obj[org].CSPList['Global'][date] = 0;
          }
          obj[org].CSPList['Global'][date] +=
            InvoiceAmount[org].DateList[date].Total;
          obj['Total'].CSPList['Global'][date] +=
            InvoiceAmount[org].DateList[date].Total;
        }
      });
    });
    const result: Array<Record<string, string | number>> = [];
    Object.keys(obj).forEach((org) => {
      Object.keys(obj[org].CSPList)
        .filter((v) => v !== 'Total')
        .forEach((csp) => {
          result.push({
            Name: obj[org].Name,
            Total: obj[org].Total,
            CSP: csp,
            ...obj[org].CSPList[csp],
          });
        });
    });
    return result;
  }, [InvoiceAmount, reportSettings.DisplayOptions]);
  return (
    <ReportContentSection title={reportT('title.billingAmount')}>
      {reportSettings.DisplayOptions.includes('BY_CSP') ? (
        <ReportStackChart
          data={chartData}
          dateKey="Date"
          labelList={[
            {
              label: 'AWS',
              color: '#E17A00',
              valueKey: 'aws',
            },
            {
              label: 'Naver Cloud',
              color: '#2783A0',
              valueKey: 'ncp',
            },
          ]}
        />
      ) : (
        <ReportSingleChart
          data={chartData}
          dateKey="Date"
          valueKey="Total"
          color="url(#colorReportBillingGradient)"
          Gradient={
            <defs>
              <linearGradient
                id="colorReportBillingGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
              >
                <stop offset="0" stopColor="#E17A00" />
                <stop offset="1" stopColor="#EBC781" />
              </linearGradient>
            </defs>
          }
        />
      )}
      <ReportTable
        data={tableData}
        pinnedColumns={pinnedColumns}
        columns={columns}
      />
    </ReportContentSection>
  );
}
