import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteCloudAccount } from '@api/queries/organization';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';
import SimpleModal from '@components/modal/simple';

export default function DeleteAWSAccountModal({
  open,
  onClose,
  AccountId,
}: ModalProps & { AccountId?: string }) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.aws.delete',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useDeleteCloudAccount();
  const handleDelete = useCallback(async () => {
    if (AccountId && UID) {
      try {
        await mutateAsync({
          OrganizationId: UID,
          CSP: 'aws',
          AccountUID: AccountId,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [AccountId, UID, mutateAsync, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="danger"
            onClick={handleDelete}
            loading={isPending}
            disabled={!AccountId || !UID}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
      enableBorder
    />
  );
}
