import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    fallbackLng: 'ko',
    supportedLngs: ['ko'],
    nonExplicitSupportedLngs: true,
    ns: ['global'],
    load: 'currentOnly',
    returnEmptyString: true,
    backend: {
      loadPath: '/locale/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: '@heka/lang',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: false,
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });

export default i18n;
