import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: USER_KEY.UPDATE_USER,
    mutationFn: ({ UID, ...v }: UpdateUser & { UID: string }) =>
      UserService.updateUser(UID, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] });
    },
  });
}
