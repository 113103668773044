import type { FocusEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  TextField,
  Unstable_Grid2 as Grid,
  Divider,
  InputLabel,
} from '@mui/material';
import {
  Delete,
  KeyboardArrowLeft,
  Block,
  LockOpen,
  Settings,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Note } from '@heka/theme';
import {
  useGetCurrentUser,
  useGetUserById,
  useUpdateUser,
} from '@api/queries/user';
import SettingsPermissionForm from '@features/settings/common/form/permission';
import { useUserHelper } from '@hooks';
import { UserRoleChip } from '@components/styled/chip';
import ChangeRoleModal from '@features/settings/permission/modal/role';
import ActivateUserModal from '@features/settings/permission/modal/activate';
import ChangePasswordModal from '@features/settings/permission/modal/password';
import DeleteUserModal from '@features/settings/permission/modal/delete';
import { RoleContainer, RoleItem } from './styled';
import { useUserInformationForm } from './form';
import { FormikErrors } from 'formik/dist/types';

type Props = {
  UID: string;
  isClient?: boolean;
};
export default function SettingsPermissionUserDetail({
  UID,
  isClient = false,
}: Props) {
  const { t } = useTranslation('settings');
  const { includesRole } = useUserHelper();
  const [toRole, setToRole] = useState<string | undefined>(undefined);
  const [toActivate, setToActivate] = useState<
    Pick<User, 'UID' | 'Deactivate'> | undefined
  >(undefined);
  const [toPassword, setToPassword] = useState<string | undefined>(undefined);
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: current } = useGetCurrentUser();
  const { data: user, setUID } = useGetUserById(UID);
  const { mutateAsync, isPending } = useUpdateUser();
  const { initialValues, validationSchema } = useUserInformationForm();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v, helpers) => {
      try {
        const { data } = await mutateAsync({
          UID,
          Role: v.Role,
        });
        helpers.resetForm({
          values: {
            Role: data.Role,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const fetchChanges = useCallback(
    async (key: string) => {
      if (Object.keys(values).includes(key)) {
        try {
          await mutateAsync({
            UID,
            [key]: _.get(values, key),
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [UID, mutateAsync, values],
  );
  const saveChanges = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      const { name } = e.target;
      handleBlur(e);
      await fetchChanges(name);
    },
    [fetchChanges, handleBlur],
  );
  //Promise<FormikErrors<Values>> | Promise<void>
  const setOrganizations = useCallback(
    async (_: string, value: any) => {
      await setFieldValue('Organizations', value);
      try {
        await mutateAsync({
          UID,
          Organizations: value,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [UID, mutateAsync, setFieldValue],
  );
  const deptTitle = useMemo(() => {
    if (user?.data) {
      let full = '';
      if (!_.isEmpty(user.data.Department)) {
        full += user.data.Department.trim() + ' ';
      }
      if (!_.isEmpty(user.data.Title)) {
        full += user.data.Title.trim();
      }
      return full.trim();
    }
    return '';
  }, [user?.data]);
  useEffect(() => {
    if (user?.data) {
      resetForm({
        values: {
          FirstName: user.data.FirstName,
          PhoneNumber: user.data.PhoneNumber,
          Department: user.data.Department,
          Title: user.data.Title,
          Organizations: user.data.Organizations,
        },
      });
    }
  }, [resetForm, user?.data]);
  useEffect(() => {
    setUID(UID);
  }, [UID]);
  if (!user) {
    return null;
  }
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
    >
      <Box>
        <Box
          component={Link}
          to={`/settings/${isClient ? 'customer' : 'permission'}`}
          sx={{
            color: '#A7A7A7',
            display: 'flex',
            alignSelf: 'flex-start',
            alignItems: 'center',
            gap: '4px',
            mb: '24px',
            textDecoration: 'none',
          }}
        >
          <KeyboardArrowLeft sx={{ width: '20px', height: '20px' }} />
          <Typography>
            {isClient
              ? t('title.permission.customer')
              : t('title.permission.member')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography
                color="text.main"
                fontSize="24px"
                fontWeight={500}
                letterSpacing="0.96px"
              >
                {user.data.FirstName}
              </Typography>
              <UserRoleChip role={user.data.Role} />
            </Box>
            {!_.isEmpty(deptTitle) && (
              <Typography color="textSecondary" letterSpacing="0.64px" mt="8px">
                {deptTitle}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {!isClient && (
              <Button
                color="sub"
                onClick={() => setToRole(user.data.UID)}
                startIcon={<Settings />}
              >
                역할 변경
              </Button>
            )}
            <Button
              color="sub"
              onClick={() =>
                setToActivate({
                  UID: user.data.UID,
                  Deactivate: user.data.Deactivate,
                })
              }
              startIcon={<Block />}
            >
              {user.data.Deactivate ? '계정 활성화' : '계정 비활성화'}
            </Button>
            <Button
              color="sub"
              onClick={() => setToPassword(user.data.UID)}
              startIcon={<LockOpen />}
            >
              비밀번호 초기화
            </Button>
            <Button
              color="danger"
              onClick={() => setToDelete(user.data.UID)}
              startIcon={<Delete />}
              disabled={current?.data.UID === UID}
            >
              멤버 삭제
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ mt: '8px', mb: '24px' }}>
        <Typography color="textSecondary" letterSpacing="0.64px" mb="32px">
          사용자 정보
        </Typography>
        <Grid container spacing="24px">
          <Grid xs={6}>
            <InputLabel sx={{ mb: '18px' }}>
              {t('form.label.userEmail')}
            </InputLabel>
            <Typography color="text.main" mb="10px">
              {user.data.Email}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <TextField
              name="PhoneNumber"
              value={values.PhoneNumber}
              onChange={handleChange}
              onBlur={saveChanges}
              label="연락처"
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="Department"
              value={values.Department}
              onChange={handleChange}
              onBlur={saveChanges}
              label={t('form.label.department')}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="Title"
              value={values.Title}
              onChange={handleChange}
              onBlur={saveChanges}
              label={t('form.label.title')}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography color="textSecondary" letterSpacing="0.64px" mb="8px">
          사용자 권한
        </Typography>
        <RoleContainer sx={{ my: '24px' }}>
          {user.data.Role === 'ROOT' && (
            <RoleItem role="ROOT" currentRole={user.data.Role} />
          )}
          <RoleItem role="ADMIN" currentRole={user.data.Role} />
          <RoleItem role="MANAGER" currentRole={user.data.Role} />
          <RoleItem role="CLIENT_USER" currentRole={user.data.Role} />
        </RoleContainer>
        <Note
          title="권한 안내"
          content={t(`text.roleDescription.${user.data.Role}`)}
          defaultOpen
          disableCollapse
        />
      </Box>
      {!includesRole(user.data, 'ADMIN') && (
        <>
          <Divider />
          <Box>
            <Typography
              color="textSecondary"
              letterSpacing="0.64px"
              mt="8px"
              mb="32px"
            >
              고객사별 권한
            </Typography>
            <SettingsPermissionForm
              values={values}
              setFieldValue={setOrganizations}
              valueKey="Organizations"
            />
          </Box>
        </>
      )}
      <ChangeRoleModal
        open={typeof toRole !== 'undefined'}
        onClose={() => setToRole(undefined)}
        UID={toRole}
        currentRole={user.data.Role}
      />
      <ActivateUserModal
        open={typeof toActivate !== 'undefined'}
        onClose={() => setToActivate(undefined)}
        Data={toActivate}
      />
      <ChangePasswordModal
        open={typeof toPassword !== 'undefined'}
        onClose={() => setToPassword(undefined)}
        UID={toPassword}
      />
      <DeleteUserModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </Box>
  );
}
