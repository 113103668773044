import {
  Bar,
  BarChart,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { calculateDataMax } from '@utils/chart';

export default function ReportStackChart({
  data = [],
  dateKey = 'Date',
  labelList,
}: ReportStackChartProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
      <ResponsiveContainer width="100%" height={328}>
        <BarChart data={data} barSize={32}>
          <XAxis
            dataKey={dateKey}
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
          />
          <YAxis
            type="number"
            scale="linear"
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={8}
            width={80}
            tickFormatter={(v) => formatLocaleString(v) ?? ''}
            domain={[0, calculateDataMax]}
          />
          {labelList.map((item) => (
            <Bar
              key={`report_stack_${item.valueKey}_${item.label}`}
              stackId="stack"
              dataKey={item.valueKey}
              fill={item.color}
              shape={(props: any) => {
                const { height = 0, dataKey } = props;
                const idx = _.findIndex(
                  labelList,
                  (v) => v.valueKey === dataKey,
                );
                let radius: number | [number, number, number, number] = 0;
                if (height > 2) {
                  if (idx === labelList.length - 1) {
                    radius = [8, 8, 0, 0];
                  } else if (idx === 0) {
                    radius = [0, 0, 8, 8];
                  }
                }
                return <Rectangle {...props} radius={radius} />;
              }}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {!!labelList.length && (
        <Box sx={{ display: 'flex', gap: '16px', pl: '88px' }}>
          {labelList.map((item) => (
            <Box
              key={`report_stack_label_${item.valueKey}_${item.label}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  background: item.color,
                  borderRadius: '999px',
                }}
              />
              <Typography color="textSecondary" fontSize="12px">
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
