import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NCPAccountService } from '@api/services';
import { NCP_ACCOUNT_KEY } from '@constants/query';

export default function useDeleteAccessAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: NCP_ACCOUNT_KEY.DELETE_ACCESS_ACCOUNT,
    mutationFn: (v: string) => NCPAccountService.deleteAccessAccount(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['NCP_ACCOUNT'] });
    },
  });
}
