import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useUpdateContact() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.UPDATE_CONTACT,
    mutationFn: (v: UpdateContact & { OrganizationId: string }) =>
      OrganizationService.updateContact(v.UID, v),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
    },
  });
}
