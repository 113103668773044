import { useSuspenseQuery } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useGetLogo() {
  return useSuspenseQuery({
    queryKey: COMPANY_KEY.GET_LOGO,
    queryFn: () => CompanyService.getLogo(),
    staleTime: 5000,
  });
}
