import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Link } from '@heka/theme';
import { useUpdateInvoiceCustom } from '@api/queries/invoice';
import FormModal from '@components/modal/form';
import { InvoiceServiceForm } from '@features/invoice/detail/common/form';
import { CloudProviderChip } from '@components/styled/chip';

type ServiceForm = Omit<EditAdditionalService, 'AccountId'> & {
  ApplyLevel: string;
};

export default function AddAdditionalServiceModal({
  open,
  onClose,
  Invoices,
  Data,
}: BaseExtraModalProps) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const AccountId = useMemo(() => {
    if (Data?.CompanyLevel) {
      const filtered = Invoices.filter((v) => v.CSP === Data.CSP);
      if (filtered.length) {
        return filtered[0].AccountId;
      }
    }
    return Data?.AccountId ?? '';
  }, [Data, Invoices]);
  const AccountName = useMemo(() => {
    if (Data?.CompanyLevel) {
      return t('text.invoiceCommonTitle');
    }
    return Data?.AccountName ?? Data?.AccountId ?? '';
  }, [Data?.AccountId, Data?.AccountName, Data?.CompanyLevel, t]);
  const { mutateAsync, isPending } = useUpdateInvoiceCustom();
  const { isValid, dirty, handleSubmit, resetForm, ...formik } =
    useFormik<ServiceForm>({
      initialValues: {
        ServiceName: '',
        UsageAmount: 1,
        PricingUnit: 'KRW',
        Cost: 0,
        ApplyLevel: Data?.CompanyLevel ? 'Company' : 'Account',
      },
      validationSchema: Yup.object()
        .shape({
          ServiceName: Yup.string().defined(),
          UsageAmount: Yup.number().positive().defined(),
          PricingUnit: Yup.mixed().oneOf(['KRW', 'USD', 'PCT']),
          Cost: Yup.number().positive().defined(),
          ApplyLevel: Yup.mixed().oneOf(['Company', 'Account']).defined(),
        })
        .defined(),
      onSubmit: async (v, helpers) => {
        if (Data && !_.isEmpty(AccountId)) {
          const items = _.flatten(
            Invoices.filter(
              (account) =>
                account.CSP === Data.CSP && account.AccountId === AccountId,
            ).map((account) => {
              return _.get(
                account,
                'AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService',
              )
                ? Object.keys(
                    _.get(
                      account,
                      'AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService',
                    ),
                  ).map((key) => ({
                    AccountId: account.AccountId,
                    ServiceName: key,
                    UsageAmount: _.get(
                      account,
                      `AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.UsageAmount`,
                    ),
                    PricingUnit: _.get(
                      account,
                      `AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.PricingUnit`,
                    ),
                    Cost: _.get(
                      account,
                      `AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.Cost`,
                    ),
                    CompanyLevel: !!_.get(
                      account,
                      `AdditionalDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.CompanyLevel`,
                    ),
                  }))
                : [];
            }),
          );
          items.push({
            AccountId,
            ServiceName: v.ServiceName,
            UsageAmount: v.UsageAmount,
            PricingUnit: v.PricingUnit,
            Cost: v.Cost,
            CompanyLevel: v.ApplyLevel === 'Company',
          });
          try {
            await mutateAsync({
              CSP: Data.CSP,
              InvoiceId: Data.InvoiceId,
              AccountId,
              AdditionalServices: items,
            });
            helpers.resetForm();
            onClose();
          } catch (e) {
            console.error(e);
          }
        }
      },
      enableReinitialize: true,
    });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t(`modal.additional.add.title.${Data?.CSP}`)}
      description={
        <Trans
          i18nKey="invoice:modal.additional.add.description"
          components={[
            <Link
              variant="inline"
              href={`/customer/${Data?.OrganizationId}?section=service`}
              target="_blank"
            />,
          ]}
        />
      }
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={_.isEmpty(AccountId) || !isValid || !dirty}
          >
            {globalT('button.save')}
          </LoadingButton>
        </>
      }
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '4px', mb: '16px' }}
      >
        <CloudProviderChip provider={Data?.CSP} />
        <Typography>{AccountName}</Typography>
      </Box>
      <InvoiceServiceForm {...formik} csp={Data?.CSP} scopeOnly />
    </FormModal>
  );
}
