import type { ReactElement, Ref } from 'react';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Slide, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DialogProps } from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSendSupport } from '@api/queries/company';
import { SupportDone, SupportFAQ, SupportForm } from './components';
import { SupportDialog } from './styled';
import { useSupportForm } from './form';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  const theme = useTheme();
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      easing={{
        enter: theme.transitions.easing.easeOut,
        exit: theme.transitions.easing.easeIn,
      }}
      timeout={600}
    />
  );
});

type Props = Pick<DialogProps, 'open'> & {
  onClose?: () => void;
  faq?: Array<{ title: string; content?: string | TrustedHTML }>;
};

export default function SupportModal({ open, onClose, faq }: Props) {
  const { t } = useTranslation('global');
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(1 - Number(Boolean(faq)));
  const { mutateAsync, isPending } = useSendSupport();
  const { initialValues, validationSchema } = useSupportForm();
  const { handleSubmit, isValid, dirty, setFieldValue, resetForm, ...formik } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (v, helpers) => {
        try {
          await mutateAsync(v);
          setActiveStep((v) => v + 1);
          helpers.resetForm();
        } catch (e) {
          console.error(e);
        }
      },
    });
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setActiveStep(1 - Number(Boolean(faq)));
    resetForm();
  }, [onClose, faq, resetForm]);
  const handleNextStep = useCallback(() => {
    if (activeStep < 2) {
      setActiveStep((v) => v + 1);
    }
    if (activeStep === 2) {
      handleClose();
    }
  }, [activeStep, handleClose]);
  useEffect(() => {
    setFieldValue('PageUrl', pathname);
  }, [pathname, setFieldValue]);
  return (
    <SupportDialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
      >
        <Typography fontWeight={500} letterSpacing="0.64px" color="#1A1818">
          {t('modal.support.title')}
        </Typography>
        {typeof faq !== 'undefined' && activeStep === 0 && (
          <SupportFAQ faq={faq} />
        )}
        {activeStep === 1 && <SupportForm {...formik} />}
        {activeStep === 2 && <SupportDone />}
        {activeStep !== 1 && (
          <Button color="emphasis1" onClick={handleNextStep} fullWidth>
            {activeStep === 2 ? t('button.close') : t('modal.support.button')}
          </Button>
        )}
        {activeStep === 1 && (
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
            fullWidth
          >
            {t('modal.support.button')}
          </LoadingButton>
        )}
      </Box>
    </SupportDialog>
  );
}
