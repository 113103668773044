import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClickAwayListener,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useGetOrganizationForUI,
  useUpdateOrganization,
} from '@api/queries/organization';
import { useToggle } from '@hooks';
import { CustomerChip } from '@components/styled/chip';

export default function CustomerName() {
  const { t } = useTranslation('global');
  const [openPopper, togglePopper] = useToggle();
  const ref = useRef<HTMLDivElement | null>(null);
  const { data: response } = useGetOrganizationForUI();
  const { mutateAsync, isPending } = useUpdateOrganization();
  const { values, isValid, dirty, handleSubmit, setFieldValue, resetForm } =
    useFormik<UpdateOrganization>({
      initialValues: {
        UID: '',
        Status: 'in-use',
      },
      validationSchema: Yup.object().shape({
        UID: Yup.string().defined(),
        Status: Yup.mixed().oneOf(['in-use', 'suspended', 'canceled', 'poc']),
      }),
      onSubmit: async (v) => {
        try {
          await mutateAsync(v);
          togglePopper();
        } catch (e) {
          console.error(e);
        }
      },
    });
  useEffect(() => {
    if (response?.data) {
      resetForm({
        values: {
          UID: response.data.UID,
          Status: response.data.Status,
        },
      });
    }
  }, [resetForm, response?.data]);
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: '16px', minWidth: 0 }}
    >
      <Typography
        color="text.main"
        fontSize="36px"
        fontWeight={500}
        letterSpacing="1.44px"
      >
        {response?.data.Name}
      </Typography>
      <Box ref={ref}>
        <CustomerChip onClick={togglePopper} status={response?.data.Status} />
      </Box>
      <Popper
        open={openPopper}
        anchorEl={ref.current}
        modifiers={[{ name: 'offset', options: { offset: [0, 16] } }]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={() => togglePopper()}>
          <Paper component="form" onSubmit={handleSubmit}>
            <RadioGroup
              value={values.Status}
              onChange={(e, v) =>
                setFieldValue('Status', v as OrganizationStatus)
              }
              sx={{ gap: '16px', pl: '11px', mb: '56px' }}
            >
              <FormControlLabel
                value="in-use"
                control={<Radio />}
                label={<CustomerChip status="in-use" />}
              />
              <FormControlLabel
                value="suspended"
                control={<Radio />}
                label={<CustomerChip status="suspended" />}
              />
              <FormControlLabel
                value="canceled"
                control={<Radio />}
                label={<CustomerChip status="canceled" />}
              />
              <FormControlLabel
                value="poc"
                control={<Radio />}
                label={<CustomerChip status="poc" />}
              />
            </RadioGroup>
            <LoadingButton
              type="submit"
              size="small"
              color="emphasis1"
              loading={isPending}
              disabled={!isValid || !dirty}
              fullWidth
            >
              {t('button.changeStatus')}
            </LoadingButton>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
