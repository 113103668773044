import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { NHNAccountService } from '@api/services';
import { NHN_ACCOUNT_KEY } from '@constants/query';

export default function useGetVerifiedNHNUser(UID?: string) {
  const [Id, setId] = useState(UID ?? '');
  const query = useQuery({
    queryKey: [...NHN_ACCOUNT_KEY.GET_VERIFIED_USER, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? NHNAccountService.getVerifiedNHNUser(queryKey[2])
        : null,
  });
  return { ...query, Id, setId };
}
