import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFormik } from 'formik';
import { MenuItem } from '@heka/theme';
import { useUserCustomForm } from '@features/auth/user/custom';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function UserCustomPage() {
  const { t } = useTranslation('auth');
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navigateToNext = useCallback(() => {
    const initial = searchParams.get('initial');
    if (initial === 'true') {
      navigate('/user/done');
    } else {
      navigate('/');
    }
  }, [navigate, searchParams]);
  const { initialValues, validationSchema } = useUserCustomForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (v) => {
      navigateToNext();
    },
  });
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('userCustom.title')}
          description={t('userCustom.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              py: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <TextField
                name="team"
                value={values.team}
                error={Boolean(errors.team && touched.team)}
                helperText={
                  Boolean(errors.team && touched.team) && String(errors.team)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.team')}
                placeholder={t('common.form.holder.team')}
                type="text"
                inputMode="text"
                autoComplete="off"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="title"
                value={values.title}
                error={Boolean(errors.title && touched.title)}
                helperText={
                  Boolean(errors.title && touched.title) && String(errors.title)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.title')}
                placeholder={t('common.form.holder.title')}
                type="text"
                inputMode="text"
                autoComplete="off"
                autoCapitalize="off"
                fullWidth
              />
              <FormControl>
                <InputLabel id="initial_settings_dept_label">
                  {t('common.form.label.department')}
                </InputLabel>
                <Select
                  labelId="initial_settings_dept_label"
                  name="department"
                  value={values.department}
                  error={Boolean(errors.department && touched.department)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  IconComponent={KeyboardArrowDown}
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    {t('common.form.holder.department')}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="initial_settings_feature_label">
                  {t('common.form.label.feature')}
                </InputLabel>
                <Select
                  labelId="initial_settings_feature_label"
                  name="feature"
                  value={values.feature}
                  error={Boolean(errors.feature && touched.feature)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  IconComponent={KeyboardArrowDown}
                  fullWidth
                >
                  <MenuItem value=" " disabled>
                    {t('common.form.holder.department')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <Button color="sub" onClick={navigateToNext}>
          {globalT('button.skip')}
        </Button>
        <Button type="submit" color="emphasis1">
          {globalT('button.getStart')}
        </Button>
      </BottomActions>
    </Box>
  );
}
