import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetAWSCostUsage } from '@api/queries/aws/cost';
import { formatUSD } from '@utils/formatter';

export default function AverageCost() {
  const { t } = useTranslation('cost');
  const { data: response } = useGetAWSCostUsage();
  return (
    <>
      <Typography fontSize="14px" letterSpacing="0.56px">
        {t('summary.averageCost.title')}
      </Typography>
      <Typography fontSize="24px" fontWeight={500} color="#362F26">
        {formatUSD(response?.data.AverageCost)}
      </Typography>
    </>
  );
}
