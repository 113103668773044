import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import {
  useDeleteLogo,
  useGetCompany,
  useUploadLogo,
} from '@api/queries/company';
import { ImageFile, Thumbnail } from '../styled';

function ImageUploader() {
  const { t } = useTranslation('settings');
  const [image, setImage] = useState<ImageFile | undefined>(undefined);
  const { data: response } = useGetCompany();
  const { mutateAsync: uploadLogo, isPending: isUploading } = useUploadLogo();
  const { mutateAsync: deleteLogo, isPending: isDeleting } = useDeleteLogo();
  const uploadStamp = useCallback(async () => {
    if (image) {
      try {
        await uploadLogo(image);
      } catch (e) {
        console.error(e);
      }
    }
  }, [image, uploadLogo]);
  const { open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setImage(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        );
      }
    },
  });
  return (
    <Box sx={{ minWidth: '100%', mt: '16px' }}>
      <Thumbnail
        preview={image?.preview ?? response?.data.Logo}
        onClear={() => setImage(undefined)}
      />
      <Box sx={{ display: 'flex', gap: '8px', mt: '16px' }}>
        <Button size="small" color="sub" onClick={open}>
          {t('button.uploadStamp')}
        </Button>
        {!_.isEmpty(response?.data.Logo) && (
          <LoadingButton
            size="small"
            color="danger"
            onClick={() => deleteLogo()}
            loading={isDeleting}
          >
            {t('button.deleteStamp')}
          </LoadingButton>
        )}
        {Boolean(image) && (
          <LoadingButton
            size="small"
            onClick={uploadStamp}
            loading={isUploading}
            disabled={!image}
          >
            {t('button.saveStamp')}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

export default function SettingsCompanyLogo() {
  const { t } = useTranslation('settings');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography color="textSecondary" fontSize="14px" letterSpacing="0.56px">
        {t('title.company.logo')}
      </Typography>
      <Typography>{t('text.logoDescription')}</Typography>
      <Box sx={{ maxWidth: '292px' }}>
        <ImageUploader />
      </Box>
    </Box>
  );
}
