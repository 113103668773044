import { PropsWithChildren, SyntheticEvent, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import {
  AccordionSummaryProps as MuiAccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import { ExpandMore } from '@mui/icons-material';
import _ from 'lodash';
import { CloudProviderChip } from '@components/styled/chip';
import CircleIcon from '@assets/vector/ChipCircleIcon.svg?react';
import { formatKRW, formatLocaleString, formatUSD } from '@utils/formatter';
import { AccordionDetails } from '../styled';
import { INVOICE_EDIT_STATE } from '../atom';

interface BaseCloudProps {
  name: string;
}

interface CloudCostProps extends BaseCloudProps {
  cost?: number;
  currency?: 'USD' | 'KRW';
  hideCost?: boolean;
}

interface CloudValueProps extends BaseCloudProps {
  cost?: number;
  usage?: number;
  unit?: string;
  discountRate?: number;
  originCost?: number;
  currency?: 'USD' | 'KRW';
}

export function InvoiceCloudService({
  children,
  name,
  cost,
  currency = 'USD',
  hideCost = false,
}: PropsWithChildren<CloudCostProps>) {
  return (
    <Box sx={{ pb: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #E0E0E0',
          gap: '16px',
          py: '16px',
          pl: '16px',
          pr: '32px',
        }}
      >
        <Typography color="text.main" fontWeight={500}>
          {name}
        </Typography>
        {!hideCost && (
          <Typography color="text.main" fontWeight={500}>
            {currency === 'USD' ? formatUSD(cost ?? 0) : formatKRW(cost ?? 0)}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
}

export function InvoiceCloudRegion({
  children,
  name,
  cost,
  currency = 'USD',
  hideCost = false,
}: PropsWithChildren<CloudCostProps>) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          py: '16px',
          pl: '16px',
          pr: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '> svg': {
              color: 'text.secondary',
              width: '16px',
              height: '16px',
            },
          }}
        >
          <CircleIcon />
          <Typography color="text.main">{name}</Typography>
        </Box>
        {!hideCost && (
          <Typography color="text.main">
            {currency === 'USD' ? formatUSD(cost ?? 0) : formatKRW(cost ?? 0)}
          </Typography>
        )}
      </Box>
      <Box sx={{ pl: '40px' }}>{children}</Box>
    </>
  );
}

export function InvoiceCloudProduct({
  children,
  name,
  onEdit,
  onDelete,
  onAddService,
  disablePadding = false,
}: PropsWithChildren<
  BaseCloudProps & {
    onEdit?: () => void;
    onDelete?: () => void;
    onAddService?: () => void;
    disablePadding?: boolean;
  }
>) {
  const { t } = useTranslation('invoice');
  const isEdit = useRecoilValue(INVOICE_EDIT_STATE);
  return (
    <Box sx={{ pb: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          p: '8px',
        }}
      >
        <Typography color="text.main">{name}</Typography>
        {isEdit &&
          (typeof onEdit !== 'undefined' ||
            typeof onDelete !== 'undefined' ||
            typeof onAddService !== 'undefined') && (
            <Box sx={{ display: 'flex', gap: '8px' }}>
              {typeof onDelete !== 'undefined' && (
                <Button size="small" color="danger" onClick={onDelete}>
                  {t('button.delete')}
                </Button>
              )}
              {typeof onEdit !== 'undefined' && (
                <Button size="small" color="sub" onClick={onEdit}>
                  {t('button.edit')}
                </Button>
              )}
              {typeof onAddService !== 'undefined' && (
                <Button size="small" color="sub" onClick={onAddService}>
                  {t('button.addService')}
                </Button>
              )}
            </Box>
          )}
      </Box>
      <Box
        sx={{
          pl: '8px',
          ...(!disablePadding && {
            pr: '24px',
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export function InvoiceCloudProductFamily({
  name,
  cost,
  usage,
  unit,
  discountRate,
  originCost,
  currency = 'USD',
}: CloudValueProps) {
  return (
    <Box
      sx={{
        pl: '16px',
        py: '12px',
        pr: '8px',
      }}
    >
      <Grid container columns={15} columnSpacing="32px">
        <Grid xs>
          <Typography lineHeight="150%">{name}</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography lineHeight="150%">
            {formatLocaleString(usage, 0, 2)} {unit}
          </Typography>
        </Grid>
        {typeof originCost !== 'undefined' && (
          <Grid xs={2}>
            <Typography align="right" lineHeight="150%">
              {currency === 'USD'
                ? formatUSD(originCost ?? 0)
                : formatKRW(originCost ?? 0)}
            </Typography>
          </Grid>
        )}
        {typeof discountRate !== 'undefined' && (
          <Grid xs={1}>
            <Typography align="right" lineHeight="150%">
              {formatLocaleString(discountRate)}%
            </Typography>
          </Grid>
        )}
        <Grid xs={2}>
          <Typography align="right" lineHeight="150%">
            {currency === 'USD' ? formatUSD(cost ?? 0) : formatKRW(cost ?? 0)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

type InvoiceAccordionWrapperProps = Pick<
  MuiAccordionProps,
  'children' | 'classes' | 'expanded' | 'onChange'
> & { forceOpen?: boolean };
const InvoiceAccordionWrapper = styled(
  ({
    children,
    expanded,
    onChange,
    forceOpen,
    ...props
  }: InvoiceAccordionWrapperProps) => (
    <MuiAccordion
      expanded={forceOpen || expanded}
      onChange={onChange}
      elevation={0}
      disableGutters
      {...props}
    >
      {children}
    </MuiAccordion>
  ),
)<InvoiceAccordionWrapperProps>(({ expanded, forceOpen }) => ({
  padding: 0,
  '&::before': {
    height: 0,
  },
  border: '1px solid #00000000',
  borderRadius: 0,
  ...((Boolean(forceOpen) || Boolean(expanded)) && {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  }),
  ...(Boolean(forceOpen) && {
    [`& .${accordionSummaryClasses.root}`]: {
      cursor: 'default',
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      display: 'none',
    },
  }),
}));

type InvoiceAccordionSummaryProps = Omit<
  MuiAccordionSummaryProps,
  'expandIcon'
>;
const InvoiceAccordionSummary = styled(
  (props: InvoiceAccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
      expandIcon={
        <ExpandMore sx={{ width: '20px', height: '20px', color: '#323232' }} />
      }
    />
  ),
)<InvoiceAccordionSummaryProps>(() => ({
  // flexDirection: 'row-reverse',
  gap: '40px',
  background: '#E0E0E033',
  padding: '16px',
  [`> .${accordionSummaryClasses.content}`]: {
    margin: '4px 0',
    alignItems: 'center',
    gap: '12px',
  },
  [`> .${accordionSummaryClasses.expandIconWrapper}`]: {
    padding: '3px',
    border: '1px solid #E0E0E0',
    borderRadius: '12px',
  },
}));

type InvoiceAccordionProps = {
  csp: CloudProvider;
  accountId: string;
  cost: number;
  forceOpen?: boolean;
};
export function InvoiceAccordion({
  csp,
  accountId,
  cost,
  forceOpen,
  children,
}: PropsWithChildren<InvoiceAccordionProps>) {
  const { t } = useTranslation('invoice');
  const [expanded, setExpanded] = useState(false);
  const accountName = useMemo(() => {
    if (accountId === 'COMMON') {
      return t('text.invoiceCommonTitle');
    }
    return csp !== 'nhn' ? t('text.accountId', { accountId }) : accountId;
  }, [csp, accountId]);
  const handleChange = useCallback(
    (e: SyntheticEvent, newExpanded: boolean) => {
      if (!_.isEmpty(children)) {
        setExpanded(newExpanded);
      }
    },
    [children],
  );
  return (
    <InvoiceAccordionWrapper
      expanded={expanded}
      onChange={handleChange}
      forceOpen={forceOpen}
    >
      <InvoiceAccordionSummary>
        <CloudProviderChip provider={csp} />
        <Typography>{accountName}</Typography>
        <Typography color="textSecondary" ml="8px">
          {formatKRW(cost)}
        </Typography>
      </InvoiceAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </InvoiceAccordionWrapper>
  );
}

type InvoiceEmailAccordionProps = {
  title: string;
};
export function InvoiceEmailAccordion({
  children,
  title,
}: PropsWithChildren<InvoiceEmailAccordionProps>) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback(
    (e: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [children],
  );
  return (
    <InvoiceAccordionWrapper
      expanded={expanded}
      onChange={handleChange}
      sx={{ border: '1px solid #E0E0E0', mt: '24px' }}
    >
      <InvoiceAccordionSummary>
        <Typography color="text.main" lineHeight="150%">
          {title}
        </Typography>
      </InvoiceAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </InvoiceAccordionWrapper>
  );
}
