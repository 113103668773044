import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/company' as const;

async function getCompany() {
  const { data } = await axios.get<SingleResponse<CurrentCompany>>(PREFIX);
  return data;
}

async function getCompanyById(UID: string) {
  const { data } = await axios.get<SingleResponse<Company>>(`${PREFIX}/${UID}`);
  return data;
}

async function updateCompany(company: UpdateCompany) {
  const { data } = await axios.put<SingleResponse<Company>>(PREFIX, company);
  return data;
}

async function deleteCompany() {
  await axios.delete(PREFIX);
}

async function deleteCompanyById(UID: string) {
  await axios.delete(`${PREFIX}/${UID}`);
}

async function sendSupport(body: SupportEmail) {
  const { data } = await axios.post(`${PREFIX}/support`, body);
  return data;
}

async function getExtraInfo() {
  const { data } = await axios.get<SingleResponse<CompanyExtraInfo>>(
    `${PREFIX}/stamp`,
  );
  return data;
}

async function uploadExtraInfo(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post<SingleResponse<Company>>(
    `${PREFIX}/stamp`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}

async function getLogo() {
  const { data } = await axios.get<SingleResponse<CompanyLogo>>(
    `${PREFIX}/logo`,
  );
  return data;
}

async function uploadLogo(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post<SingleResponse<CompanyWhiteLabel>>(
    `${PREFIX}/logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data;
}

async function deleteLogo() {
  await axios.delete(`${PREFIX}/logo`);
}

export default {
  getCompany,
  getCompanyById,
  updateCompany,
  deleteCompany,
  deleteCompanyById,
  sendSupport,
  getExtraInfo,
  uploadExtraInfo,
  getLogo,
  uploadLogo,
  deleteLogo,
};
