import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import MuiInputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';

export type SearchProps = Omit<TextFieldProps, 'variant'>;

const Search: StyledComponent<SearchProps> = styled(
  (props: SearchProps) => (
    <MuiTextField
      variant="filled"
      InputProps={{
        startAdornment: (
          <MuiInputAdornment position="start">
            <SearchIcon
              sx={{ color: '#B1B1B1', width: '24px', height: '24px' }}
            />
          </MuiInputAdornment>
        ),
      }}
      {...props}
    />
  ),
  {
    skipVariantsResolver: true,
  },
)<SearchProps>({
  [`> .${getInputBaseUtilityClass('root')}`]: {
    borderRadius: '999px',
    paddingLeft: '8px',
    paddingRight: '16px',
    [`> .${getInputBaseUtilityClass('input')}`]: {
      padding: 0,
      paddingTop: '12px',
      paddingBottom: '12px',
      '&::placeholder': {
        color: '#B1B1B1',
      },
      '&::-webkit-input-placeholder': {
        color: '#B1B1B1',
      },
      '&::-ms-input-placeholder': {
        color: '#B1B1B1',
      },
    },
  },
});
Search.displayName = 'HekaSearch';

export default Search;
