export const GET_USERS = ['USER', 'GET_USERS'];
export const GET_CLIENT_USERS = ['USER', 'GET_CLIENT_USERS'];
export const GET_USER_BY_ID = ['USER', 'GET_USER_BY_ID'];
export const GET_CURRENT_USER = ['USER', 'GET_CURRENT_USER'];
export const CREATE_USER = ['USER', 'CREATE_USER'];
export const CREATE_BULK_USERS = ['USER', 'CREATE_BULK_USERS'];
export const UPDATE_CURRENT_USER = ['USER', 'UPDATE_CURRENT_USER'];
export const UPDATE_USER = ['USER', 'UPDATE_USER'];
export const DELETE_USER = ['USER', 'DELETE_USER'];
export const CHANGE_PASSWORD = ['USER', 'CHANGE_PASSWORD'];
export const REQUEST_CHANGE_PASSWORD = ['USER', 'REQUEST_CHANGE_PASSWORD'];
export const CHECK_PASSWORD = ['USER', 'CHECK_PASSWORD'];
