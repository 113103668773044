import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@mui/material';
import { Tabs } from '@heka/theme';
import { SuspenseBox } from '@components/suspense';
import { useCurrentInfo } from '@hooks';

export default function SalesManagementPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hasNCP, hasNHN } = useCurrentInfo();
  const targetCSP = useMemo(() => {
    return pathname.replace('/sales', '').replace('/', '').trim();
  }, [pathname]);
  useEffect(() => {
    const csp = pathname.replace('/sales', '').replace('/', '').trim();
    if (!csp.length) {
      if (hasNCP) {
        navigate('./ncp', { replace: true });
      } else if (hasNHN) {
        navigate('./nhn', { replace: true });
      } else {
        navigate('./ncp', { replace: true });
      }
    }
  }, [hasNCP, hasNHN, navigate, pathname]);
  if (!targetCSP.length) {
    return null;
  }
  return (
    <>
      <Tabs variant="square" value={targetCSP} sx={{ mb: '-2px' }}>
        <Tab value="ncp" label="Naver Cloud" href="/sales/ncp" />
        <Tab value="nhn" label="NHN Cloud" href="/sales/nhn" />
      </Tabs>
      <SuspenseBox sx={{ flex: 1 }}>
        <Outlet />
      </SuspenseBox>
    </>
  );
}
