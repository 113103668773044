import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useGetInvoiceByAccountId(
  InvoiceId?: string,
  AccountId?: string,
) {
  const [inId, setInvoiceId] = useState<string>(InvoiceId ?? '');
  const [acId, setAccountId] = useState<string>(AccountId ?? '');
  const query = useSuspenseQuery({
    queryKey: [...INVOICE_KEY.GET_INVOICE_BY_ACCOUNT_ID, inId, acId],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) && !isEmpty(queryKey[3])
        ? InvoiceService.getInvoiceByAccountId(queryKey[2], queryKey[3])
        : null,
    staleTime: 10 * 60 * 1000,
  });
  return { ...query, setInvoiceId, setAccountId };
}
