import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { NCPSalesService } from '@api/services';
import { NCP_SALES_FILTER_STATE } from '@atoms/page/filter';
import { NCP_SALES_KEY } from '@constants/query';

export default function useGetNCPSalesGraph() {
  const filter = useRecoilValue(NCP_SALES_FILTER_STATE);
  return useSuspenseQuery({
    queryKey: [...NCP_SALES_KEY.GET_SALES_GRAPH, filter],
    queryFn: ({ queryKey }) =>
      NCPSalesService.getNCPSalesGraph(queryKey[2] as NCPSalesFilter),
  });
}
