import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useUpdateBillingCustom } from '@api/queries/organization';
import CustomerServiceForm, {
  useCustomerServiceForm,
} from '@features/customer/form/service';
import FormModal from '@components/modal/form';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

type Props = {
  item?: BillingCustom;
};
export default function AddServiceModal({
  open,
  onClose,
  item,
}: ModalProps & Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.service.add' });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useUpdateBillingCustom();
  const { initialValues, validationSchema } = useCustomerServiceForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (UID && item) {
        try {
          const clone = _.cloneDeep(item.AdditionalServices);
          clone.push(v);
          await mutateAsync({
            UID,
            AdditionalServices: clone,
            ApplyToInvoice: v.ApplyToInvoice,
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!UID || !item || !isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <CustomerServiceForm {...formik} isApplicable />
    </FormModal>
  );
}
