import { useMutation } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useSendSupport() {
  return useMutation({
    mutationKey: COMPANY_KEY.SEND_SUPPORT,
    mutationFn: (v: SupportEmail) => CompanyService.sendSupport(v),
  });
}
