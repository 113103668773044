import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReportService } from '@api/services';
import { REPORT_KEY } from '@constants/query';

export default function useCreateReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: REPORT_KEY.CREATE_REPORT,
    mutationFn: (v: CreateReport) => ReportService.createReport(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['REPORT'] });
    },
  });
}
