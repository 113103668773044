import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/admin' as const;

async function getAllUsers() {
  const { data } = await axios.get<MultipleResponse<SimpleUser>>(
    `${PREFIX}/users`,
  );
  return data;
}

async function getUserToken(UID: string) {
  const { data } = await axios.get<SingleResponse<TokenWithUserResponse>>(
    `${PREFIX}/users/${UID}`,
  );
  return data;
}

export default {
  getAllUsers,
  getUserToken,
};
