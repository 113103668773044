import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useChangePassword() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: USER_KEY.CHANGE_PASSWORD,
    mutationFn: ({ UID, ...v }: ChangePassword & { UID?: string }) =>
      UserService.changePassword(v, UID),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] });
    },
  });
}
