import * as Yup from 'yup';

type InitialSettingsForm = {
  team?: string;
  title?: string;
  department?: string;
  feature?: string;
};

export function useUserCustomForm(): UseFormValidation<InitialSettingsForm> {
  return {
    initialValues: {
      team: '',
      title: '',
      department: ' ',
      feature: ' ',
    },
    validationSchema: Yup.object().shape({
      team: Yup.string().strict(false).trim(),
      title: Yup.string().strict(false).trim(),
      department: Yup.string().strict(false).trim(),
      feature: Yup.string().strict(false).trim(),
    }),
  };
}
