import { type PropsWithChildren } from 'react';
import { Box, Checkbox, Collapse, IconButton, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useToggle } from '@hooks';

interface NHNOrgAccordionProps {
  checked: boolean;
  indeterminate: boolean;
  onChange: () => void;
  orgName: string;
}
export function NHNOrgAccordion({
  children,
  checked,
  indeterminate,
  onChange,
  orgName,
}: PropsWithChildren<NHNOrgAccordionProps>) {
  const [expanded, toggleExpanded] = useToggle();
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '48px' }}>
        <IconButton onClick={toggleExpanded}>
          <ExpandMore
            sx={{
              width: '24px',
              height: '24px',
              color: '#323232',
              ...(expanded && { transform: 'rotate(-180deg)' }),
            }}
          />
        </IconButton>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          sx={{ mx: '12px', my: '8px' }}
        />
        <Typography color="text.main">{orgName}</Typography>
      </Box>
      <Collapse in={expanded}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            background: '#E0E0E033',
            pl: '80px',
            borderTop: '1px solid #E0E0E0',
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}

interface NHNProjectItemProps {
  checked: boolean;
  onChange: () => void;
  projectId: string;
  projectName: string;
}
export function NHNProjectItem({
  checked,
  onChange,
  projectId,
  projectName,
}: NHNProjectItemProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '48px' }}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        sx={{ mx: '12px', my: '8px' }}
      />
      <Typography color="text.main">
        {projectName} ({projectId})
      </Typography>
    </Box>
  );
}
