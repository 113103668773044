import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Popper,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import DateRange from '@mui/icons-material/DateRange';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useToggle } from '@hooks';
import { formatDate } from '@utils/formatter';

type Props = {
  filter: AWSCEUsageRequestInternal;
  setFilter: Dispatch<SetStateAction<AWSCEUsageRequestInternal>>;
};

export default function DateRangePicker({ filter, setFilter }: Props) {
  const [FromDate, setFromDate] = useState<dayjs.Dayjs>(dayjs(filter.FromDate));
  const [ToDate, setToDate] = useState<dayjs.Dayjs>(dayjs(filter.ToDate));
  const [openFrom, toggleFrom] = useToggle();
  const [openTo, toggleTo] = useToggle();
  const ref = useRef();
  const handleFromDate = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setFromDate(newValue);
        toggleFrom();
        toggleTo();
      }
    },
    [toggleFrom, toggleTo],
  );
  const revertFromDate = useCallback(() => {
    setFromDate(dayjs(filter.FromDate));
    toggleTo();
  }, [filter.FromDate, toggleTo]);
  const handleAccept = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setToDate(newValue);
        setFilter((v) => ({
          ...v,
          FromDate: FromDate.toDate(),
          ToDate: newValue.toDate(),
        }));
        toggleTo();
      }
    },
    [setFilter, FromDate, toggleTo],
  );
  return (
    <>
      <TextField
        inputRef={ref}
        value={`${formatDate(FromDate)} ~ ${formatDate(ToDate)}`}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="calm"
                onClick={() => toggleFrom()}
                sx={{
                  p: '8px',
                  [`> .${getSvgIconUtilityClass('root')}`]: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                <DateRange sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          [`> .${getInputBaseUtilityClass('root')}`]: {
            paddingRight: 0,
          },
        }}
        fullWidth
      />
      <Popper
        open={openFrom}
        anchorEl={ref.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={() => toggleFrom()}>
          <Paper>
            <Typography>시작일 선택</Typography>
            <DateCalendar
              value={FromDate}
              onChange={handleFromDate}
              showDaysOutsideCurrentMonth
              disableFuture
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Popper
        open={openTo}
        anchorEl={ref.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={revertFromDate}>
          <Paper>
            <Typography>종료일 선택</Typography>
            <DateCalendar
              value={ToDate}
              onChange={handleAccept}
              shouldDisableDate={(v) => v.isBefore(FromDate, 'days')}
              shouldDisableMonth={(v) => v.isBefore(FromDate, 'months')}
              shouldDisableYear={(v) => v.isBefore(FromDate, 'years')}
              showDaysOutsideCurrentMonth
              disableFuture
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
