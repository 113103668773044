import { styled } from '@mui/material/styles';
import { InputBase as MuiInputBase } from '@mui/material';
import {
  getInputBaseUtilityClass,
  InputBaseProps,
} from '@mui/material/InputBase';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface StyledInputProps extends InputBaseProps {
  activeColor?: string;
}
const StyledInput = styled(MuiInputBase, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'activeColor',
})<StyledInputProps>(({ activeColor }) => ({
  [`> .${getInputBaseUtilityClass('input')}`]: {
    fontSize: '24px',
    lineHeight: '24px',
    color: activeColor,
    '&::placeholder': {
      color: 'text.secondary',
    },
    '&::-webkit-input-placeholder': {
      color: 'text.secondary',
    },
    '&::-ms-input-placeholder': {
      color: 'text.secondary',
    },
  },
}));

export function CurrencyInput({
  value,
  name,
  ...props
}: Pick<
  NumericFormatProps,
  'value' | 'name' | 'onValueChange' | 'valueIsNumericString'
> &
  Pick<
    StyledInputProps,
    'onChange' | 'onBlur' | 'placeholder' | 'activeColor' | 'readOnly'
  >) {
  return (
    <NumericFormat
      name={name}
      value={value}
      customInput={StyledInput}
      thousandSeparator
      valueIsNumericString
      suffix="원"
      {...props}
    />
  );
}
