import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useUpdateCurrentUser } from '@api/queries/user';
import { useUserInitialInfoForm } from '@features/auth/user/initial';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function UserInitialInformationPage() {
  const { t } = useTranslation('auth');
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useUpdateCurrentUser();
  const { initialValues, validationSchema } = useUserInitialInfoForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        navigate('/user/done');
      } catch (e) {
        console.error(e);
      }
    },
  });
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('userInitial.title')}
          description={t('userInitial.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: '24px',
            }}
          >
            <Box>
              <Box sx={{ py: '8px' }}>
                <Typography
                  color="text.main"
                  fontSize="18px"
                  lineHeight="120%"
                  fontWeight={500}
                  letterSpacing="0.72px"
                >
                  {t('userInitial.userInformation')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  py: '24px',
                }}
              >
                <TextField
                  name="FirstName"
                  value={values.FirstName}
                  error={Boolean(errors.FirstName && touched.FirstName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.nameInitial')}
                  placeholder={t('common.form.holder.nameInitial')}
                  type="text"
                  inputMode="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  fullWidth
                />
                <TextField
                  name="PhoneNumber"
                  value={values.PhoneNumber}
                  error={Boolean(errors.PhoneNumber && touched.PhoneNumber)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.phoneInitial')}
                  placeholder={t('common.form.holder.phone')}
                  type="text"
                  inputMode="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {globalT('button.next')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
