import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

const Image = styled('img')({
  width: '100%',
  maxWidth: '536px',
  height: 'auto',
});

export default function UserDonePage() {
  const { t } = useTranslation('auth');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel title={t('userDone.title')} />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
            }}
          >
            <Image src="/assets/img/img-registered.svg" alt="Welcome" />
            <Typography
              dangerouslySetInnerHTML={{ __html: t('userDone.content') }}
            />
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <Button href="/" color="emphasis1">
          {t('userDone.cta')}
        </Button>
      </BottomActions>
    </Box>
  );
}
