import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@mui/material';
import { getTableCellUtilityClass } from '@mui/material/TableCell';
import { Search } from '@heka/theme/src';

export default function ReportTable({
  size,
  columns,
  pinnedColumns,
  data,
  title = undefined,
  children = undefined,
  columnVisibility = undefined,
  background = undefined,
  enableGlobalFilter = false,
  disableHighlight = false,
}: PropsWithChildren<ReportTableProps>) {
  const { t } = useTranslation('table');
  const [filterValue, setFilterValue] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  useEffect(() => {
    const timeout = setTimeout(() => {
      setGlobalFilter(filterValue);
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);
  const fuzzyFilter: FilterFn<any> = useCallback(
    (row, columnId, filterValue, addMeta) => {
      const itemRank = rankItem(row.getValue(columnId), filterValue);
      addMeta({ itemRank });
      return itemRank.passed;
    },
    [],
  );
  const table = useReactTable({
    data: data ?? [],
    columns: [...pinnedColumns, ...columns],
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      ...(!!enableGlobalFilter && {
        globalFilter,
      }),
    },
    ...(!!enableGlobalFilter && {
      getFilteredRowModel: getFilteredRowModel(),
    }),
    ...(!!enableGlobalFilter && {
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: fuzzyFilter,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
    }),
  });
  const getPinnedOffset = useCallback(
    (targetIdx: number) => {
      let width = 0;
      pinnedColumns.forEach((v, index) => {
        if (index < targetIdx) {
          width += v.size ?? 150;
        }
      });
      return width;
    },
    [pinnedColumns],
  );
  const showHeader = useMemo(() => {
    return Boolean(title || children || enableGlobalFilter);
  }, [title, children, enableGlobalFilter]);
  const justifyContent = useMemo(() => {
    if (!title) {
      return 'flex-end';
    }
    if (!children && !enableGlobalFilter) {
      return 'flex-start';
    }
    return 'space-between';
  }, [title, children, enableGlobalFilter]);
  return (
    <Box>
      {showHeader && (
        <Box
          sx={{
            display: 'flex',
            justifyContent,
            alignItems: 'center',
            mb: '16px',
          }}
        >
          {Boolean(title) && (
            <Typography
              fontSize="18px"
              lineHeight="120%"
              fontWeight={500}
              letterSpacing="0.72px"
            >
              {title}
            </Typography>
          )}
          {Boolean(children || enableGlobalFilter) && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {!!enableGlobalFilter && (
                <Search
                  value={filterValue ?? ''}
                  onChange={(e) => setFilterValue(e.target.value)}
                  placeholder={t('holder.search')}
                  type="search"
                  inputMode="text"
                  autoComplete="off"
                />
              )}
              {!!children && children}
            </Box>
          )}
        </Box>
      )}
      <TableContainer
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          position: 'relative',
          background,
        }}
      >
        <Table size={size} sx={{ borderCollapse: 'separate' }}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    align={header.column.columnDef.meta?.align}
                    sx={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                      ...(idx < pinnedColumns.length && {
                        position: 'sticky',
                        background: '#FAFAFA',
                      }),
                      ...(idx < pinnedColumns.length && {
                        left: getPinnedOffset(idx),
                      }),
                      ...(idx === pinnedColumns.length - 1 && {
                        borderRight: '1px solid #E0E0E0',
                      }),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, rowIdx) => (
              <TableRow
                key={row.id}
                hover
                sx={{
                  zIndex: 2,
                  '&:hover': {
                    [`> .${getTableCellUtilityClass('pinned')}`]: {
                      background: '#F7F7F7',
                    },
                  },
                  '&:active': {
                    [`> .${getTableCellUtilityClass('pinned')}`]: {
                      background: '#F5F5F5',
                    },
                  },
                }}
              >
                {row.getVisibleCells().map((cell, idx) => (
                  <TableCell
                    key={cell.id}
                    align={cell.column.columnDef.meta?.align}
                    className={
                      idx < pinnedColumns.length
                        ? getTableCellUtilityClass('pinned')
                        : undefined
                    }
                    sx={{
                      borderBottom: '0.5px solid #E0E0E0',
                      ...(idx < pinnedColumns.length && {
                        position: 'sticky',
                        background: '#FEFEFE',
                        left: getPinnedOffset(idx),
                      }),
                      ...(idx === pinnedColumns.length - 1 && {
                        borderRight: '1px solid #E0E0E0',
                      }),
                      ...(!disableHighlight &&
                        rowIdx === 0 && {
                          fontWeight: 500,
                        }),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
