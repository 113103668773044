import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';
import type { RowSelectionState, Updater } from '@tanstack/react-table';

export default function useRowSelect(
  callback?: (value: RowSelectionState) => void,
): [
  Record<string, boolean>,
  (updater: Updater<RowSelectionState>) => void,
  Dispatch<SetStateAction<Record<string, boolean>>>,
] {
  const [value, setValue] = useState<Record<string, boolean>>({});
  const onRowSelected = useCallback(
    (updater: Updater<RowSelectionState>) => {
      if (typeof updater === 'function') {
        const updated = updater(value);
        if (callback) {
          callback(updated);
        }
      }
    },
    [callback, value],
  );
  return [value, onRowSelected, setValue];
}
