import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthService } from '@api/services';
import { AUTH_KEY } from '@constants/query';
import { encryptLocalStorage } from '@utils/crypto';
import STORAGE_KEY from '@constants/storage';

export default function useLogin() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: AUTH_KEY.LOGIN,
    mutationFn: (v: LoginRequest) => AuthService.login(v),
    onSuccess: ({ data }) => {
      queryClient.clear();
      encryptLocalStorage(STORAGE_KEY.ACCESS_TOKEN, data.AccessToken);
      if (data.RefreshToken) {
        encryptLocalStorage(STORAGE_KEY.REFRESH_TOKEN, data.RefreshToken);
      }
    },
  });
}
