import ReportListTable from '@features/report/list/table';
import { SuspensePaper } from '@components/suspense';

function ReportListPage() {
  return (
    <SuspensePaper sx={{ flex: 1 }}>
      <ReportListTable />
    </SuspensePaper>
  );
}

export default ReportListPage;
