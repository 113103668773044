export const GET_INVOICES = ['INVOICE', 'GET_INVOICES'];
export const GET_INVOICES_BY_COMPANY_ID = [
  'INVOICE',
  'GET_INVOICES_BY_COMPANY_ID',
];
export const GET_INVOICE_LIST_BY_COMPANY_ID = [
  'INVOICE',
  'GET_INVOICE_LIST_BY_COMPANY_ID',
];
export const GET_INVOICES_BY_INVOICE_ID = [
  'INVOICE',
  'GET_INVOICES_BY_INVOICE_ID',
];
export const GET_INVOICE_DETAIL = ['INVOICE', 'GET_INVOICE_DETAIL'];
export const GET_INVOICE_BY_ACCOUNT_ID = [
  'INVOICE',
  'GET_INVOICE_BY_ACCOUNT_ID',
];
export const GET_INVOICE_BY_ID = ['INVOICE', 'GET_INVOICE_BY_ID'];
export const GET_INVOICE_HISTORY = ['INVOICE', 'GET_INVOICE_HISTORY'];
export const UPDATE_INVOICE_STATUS = ['INVOICE', 'UPDATE_INVOICE_STATUS'];
export const UPDATE_INVOICE_CLOUD_USAGE = [
  'INVOICE',
  'UPDATE_INVOICE_CLOUD_USAGE',
];
export const RESTORE_INVOICE_CLOUD_USAGE = [
  'INVOICE',
  'RESTORE_INVOICE_CLOUD_USAGE',
];
export const SORT_INVOICE_CLOUD_USAGE = ['INVOICE', 'SORT_INVOICE_CLOUD_USAGE'];
export const UPDATE_INVOICE_CUSTOM = ['INVOICE', 'UPDATE_INVOICE_CUSTOM'];
export const DOWNLOAD_INVOICE_LIST_AS_CSV = [
  'INVOICE',
  'DOWNLOAD_INVOICE_LIST_AS_CSV',
];
export const DOWNLOAD_INVOICE_AS_PDF = ['INVOICE', 'DOWNLOAD_INVOICE_AS_PDF'];
export const DOWNLOAD_INVOICE_AS_EXCEL = [
  'INVOICE',
  'DOWNLOAD_INVOICE_AS_EXCEL',
];
export const DOWNLOAD_INVOICE_AS_CSV = ['INVOICE', 'DOWNLOAD_INVOICE_AS_CSV'];
export const DOWNLOAD_INVOICE_TRANSACTION = [
  'INVOICE',
  'DOWNLOAD_INVOICE_TRANSACTION',
];

export const GET_INVOICE_DATE_LIST_BY_LINKED_COMPANY = [
  'INVOICE',
  'GET_INVOICE_DATE_LIST_BY_LINKED_COMPANY',
];
export const CREATE_INVOICE = ['INVOICE', 'CREATE_INVOICE'];
export const UPDATE_INVOICE = ['INVOICE', 'UPDATE_INVOICE'];
export const DOWNLOAD_INVOICE_LIST_CSV = [
  'INVOICE',
  'DOWNLOAD_INVOICE_LIST_CSV',
];
