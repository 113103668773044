import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useUpdateCurrentUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: USER_KEY.UPDATE_CURRENT_USER,
    mutationFn: (v: UpdateUser) => UserService.updateCurrentUser(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] });
    },
  });
}
