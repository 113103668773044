import type { PropsWithChildren, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog, { getDialogUtilityClass } from '@mui/material/Dialog';
import { SuspenseBox } from '@components/suspense';

interface BasicModalProps extends ModalProps {
  title: string;
  Actions: ReactNode;
  description?: string;
  maxWidth?: string | number;
  minHeight?: string | number;
  pb?: string | number;
  sx?: SxProps<Theme>;
}

export default function BasicModal({
  children,
  open,
  onClose,
  title,
  Actions,
  description,
  maxWidth,
  minHeight,
  pb,
  sx,
}: PropsWithChildren<BasicModalProps>) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        [`> .${getDialogUtilityClass('container')} > .${getDialogUtilityClass(
          'paper',
        )}`]: {
          maxWidth: maxWidth ?? '640px',
          minHeight: minHeight ?? '640px',
          pb,
        },
      }}
    >
      <Box
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
      >
        <Box>
          <Typography
            color="text.main"
            fontSize="16px"
            fontWeight={500}
            letterSpacing="0.64px"
          >
            {title}
          </Typography>
          {typeof description !== 'undefined' && (
            <Typography
              color="text.main"
              fontSize="14px"
              lineHeight="150%"
              mt="24px"
            >
              {description}
            </Typography>
          )}
        </Box>
        <SuspenseBox sx={{ flex: 1 }}>{children}</SuspenseBox>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {Actions}
        </Box>
      </Box>
    </Dialog>
  );
}
