import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthService } from '@api/services';
import { AUTH_KEY } from '@constants/query';
import STORAGE_KEY from '@constants/storage';

export default function useLogout() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: AUTH_KEY.LOGOUT,
    mutationFn: () => AuthService.logout(),
    onMutate: () => {
      queryClient.cancelQueries();
    },
    onSuccess: () => {
      localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
      localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
      queryClient.invalidateQueries({ queryKey: ['TOKEN'] });
    },
  });
}
