import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export default function CustomerComplete() {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.customer.steps.complete',
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {t('title')}
      </Typography>
      <Typography
        fontSize="14px"
        lineHeight="150%"
        letterSpacing="0.56px"
        dangerouslySetInnerHTML={{ __html: t('content') }}
      />
    </Box>
  );
}
