import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

export default function useGetOrganizationForUI() {
  const uid = useRecoilValue(CUSTOMER_UID_STATE);
  return useSuspenseQuery({
    queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, uid ?? ''],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? OrganizationService.getOrganizationById(queryKey[2])
        : null,
    staleTime: 30000,
  });
}
