import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, RadioGroup, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { Tile } from '@heka/theme';
import { useChangePassword, useRequestChangePassword } from '@api/queries/user';
import { useCurrentInfo } from '@hooks';
import FormModal from '@components/modal/form';
import { useChangePasswordForm } from './form';

type Props = ModalProps & { UID?: string };
export default function ChangePasswordModal({ open, onClose, UID }: Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.permission.password',
  });
  const { t: authT } = useTranslation('auth', { keyPrefix: 'common.form' });
  const { t: globalT } = useTranslation('global');
  const { hasRole } = useCurrentInfo(true);
  const { mutateAsync: change, isPending: isChanging } = useChangePassword();
  const { mutateAsync: request, isPending: isRequesting } =
    useRequestChangePassword();
  const { initialValues, validationSchema } = useChangePasswordForm();
  const {
    values,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (UID) {
        try {
          v.Type === 'direct'
            ? await change({
                UID: UID,
                Password: v.Password,
              })
            : await request(UID);
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      maxWidth="400px"
      minHeight="513px"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isChanging || isRequesting}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ flex: 1 }}>
        <RadioGroup
          name="Type"
          value={values.Type}
          onChange={(e, v) => setFieldValue('Type', v)}
          row
          sx={{ gap: '8px' }}
        >
          <Tile
            value="direct"
            description="직접 설정"
            sx={{ flex: 1, p: '16px' }}
          />
          <Tile
            value="request"
            description="이메일로 재설정"
            sx={{ flex: 1, p: '16px' }}
          />
        </RadioGroup>
        {values.Type === 'direct' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              mt: '16px',
            }}
          >
            <TextField
              name="Password"
              value={values.Password}
              onChange={handleChange}
              onBlur={handleBlur}
              label={authT('label.passwordNew')}
              placeholder={authT('holder.passwordNew')}
              type="password"
              inputMode="text"
              autoComplete="off"
              autoCapitalize="off"
              fullWidth
            />
            <TextField
              name="Confirm"
              value={values.Confirm}
              onChange={handleChange}
              onBlur={handleBlur}
              label={authT('label.confirm')}
              placeholder={authT('holder.confirm')}
              type="password"
              inputMode="text"
              autoComplete="off"
              autoCapitalize="off"
              fullWidth
            />
          </Box>
        )}
        {values.Type === 'request' && (
          <Typography color="text.main" lineHeight="150%" mt="40px">
            {t('request')}
          </Typography>
        )}
      </Box>
    </FormModal>
  );
}
