import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardService } from '@api/services';
import { DASHBOARD_KEY } from '@constants/query';

export default function useGetCustomerTrend() {
  return useSuspenseQuery({
    queryKey: DASHBOARD_KEY.GET_CUSTOMER_TREND,
    queryFn: () => DashboardService.getCustomerTrend(),
  });
}
