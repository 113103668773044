import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';
import { Logo } from '@components/ui';
import { useCurrentInfo } from '@hooks';
import Masquerade from './Masquerade';
import Notification from './Notification';
import UserProfile from './UserProfile';
import Banners from '../banners';

export default function GlobalHeader() {
  const { isRoot, hasRole } = useCurrentInfo(true);
  return (
    <AppBar
      position="fixed"
      variant="elevation"
      elevation={0}
      sx={(theme) => ({
        p: 0,
        background: 'rgba(255, 255, 255, 0.98)',
        border: 'none',
        borderRadius: 0,
        zIndex: theme.zIndex.drawer + 1,
        // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      })}
    >
      {hasRole('MANAGER') && <Banners />}
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: '40px !important',
          py: '20px',
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {isRoot && <Masquerade />}
          <Notification />
          <UserProfile />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
