import { useEffect, useRef, useState } from 'react';

const SECTION_IDS = [
  'summary',
  'detail',
  'cloud',
  'service',
  'discount',
  'deposit',
];

export function useHeadObserver() {
  const observer = useRef<IntersectionObserver | null>(null);
  const [activeId, setActiveId] = useState('summary');
  useEffect(() => {
    const handleObserver: IntersectionObserverCallback = (entries) => {
      const intersected = entries.filter((e) => Boolean(e?.isIntersecting));
      const idx = SECTION_IDS.findIndex((v) =>
        Boolean(intersected.find((e) => e.target.id === v)),
      );
      if (idx > -1) {
        const elem = intersected.find((e) => e.target.id === SECTION_IDS[idx]);
        if (elem) {
          setActiveId(elem.target.id);
        }
      }
    };
    observer.current = new IntersectionObserver(handleObserver, {
      rootMargin: '-80px -30% -60% -180px',
    });
    const elements = document.querySelectorAll('section');
    elements.forEach((elem) => observer.current?.observe(elem));
    return () => observer.current?.disconnect();
  }, []);
  return { activeId };
}
