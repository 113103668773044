import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useUpdateCloudAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.UPDATE_CLOUD_ACCOUNT,
    mutationFn: ({
      OrganizationId,
      CSP,
      ...v
    }: UpdateCloudAccount & { OrganizationId: string; CSP: CloudProvider }) =>
      OrganizationService.updateCloudAccount(OrganizationId, CSP, v),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
    },
  });
}
