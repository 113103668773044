import { styled } from '@mui/material/styles';

export const CSPDotContainer = styled('div')({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '4px',
});

interface CSPDotProps {
  csp: CloudProvider;
}
export const CSPDot = styled('div')<CSPDotProps>(({ csp }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '999px',
  ...(csp === 'aws' && {
    background: '#F7B83D',
  }),
  ...(csp === 'ncp' && {
    background: '#67BE20',
  }),
  ...(csp === 'nhn' && {
    background: '#1353E2',
  }),
}));
