import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps as MuiChipProps } from '@mui/material/Chip';

interface UserStatusChipProps extends Pick<MuiChipProps, 'classes'> {
  deactivated?: boolean;
}
const UserStatusChip = styled(
  ({ deactivated = false, ...props }: UserStatusChipProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'chip.user' });
    return (
      <MuiChip {...props} label={t(deactivated ? 'deactivate' : 'activate')} />
    );
  },
)<UserStatusChipProps>(({ deactivated = false }) => ({
  color: '#448016',
  background: '#66BE211A',
  ...(deactivated && {
    color: '#C8003C',
    background: '#C8003C1A',
  }),
}));

export default UserStatusChip;
