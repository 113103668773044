import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useUpdatePayerAccountForm(): UseFormValidation<UpdatePayerAccount> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      UID: '',
      CompanyId: '',
      AccountName: '',
      ARN: '',
      ExternalId: '',
      BucketName: '',
      ReportName: '',
      ReportPrefix: '',
      AccountId: '',
    },
    validationSchema: Yup.object().shape({
      UID: Yup.string().defined(),
      CompanyId: Yup.string().defined(),
      AccountName: Yup.string().defined(),
      ExternalId: Yup.string().defined(),
      AccountId: Yup.string().defined(),
      ARN: Yup.string().defined(),
      ReportName: Yup.string()
        .matches(/^[a-zA-Z\d!\-_.*'()]+$/, t('onboard.aws.report.name'))
        .defined(),
      ReportPrefix: Yup.string().defined(),
      BucketName: Yup.string()
        .min(3, t('onboard.aws.bucket.length'))
        .max(63, t('onboard.aws.bucket.length'))
        .matches(/^[a-z\d.-]*$/, t('onboard.aws.bucket.consist'))
        .matches(/^[a-z\d].*[a-z\d]$/, t('onboard.aws.bucket.beginEnd'))
        .matches(/^(?!xn--).*$/, t('onboard.aws.bucket.xn'))
        .matches(
          /^.*((?!-s3alias).{8}|^.{0,7})$/,
          t('onboard.aws.bucket.s3alias'),
        )
        .test('matches', t('onboard.aws.bucket.ip'), (value) => {
          if (value) {
            return !value.match(
              /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            );
          }
          return true;
        })
        .defined(),
    }),
  };
}
