import { useMutation } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { AUTH_KEY } from '@constants/query';

export default function useForgotPassword() {
  return useMutation({
    mutationKey: AUTH_KEY.FORGET_PASSWORD,
    mutationFn: (email: string) => UserService.requestResetPassword(email),
  });
}
