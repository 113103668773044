import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useDeleteOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.DELETE_ORGANIZATION,
    mutationFn: (v: string) => OrganizationService.deleteOrganization(v),
    onSuccess: (_, UID) => {
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
      queryClient.removeQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, UID],
      });
      queryClient.removeQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, UID],
      });
    },
  });
}
