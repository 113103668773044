import { PropsWithChildren } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { SuspensePaper } from '@components/suspense';
import TotalCost from './TotalCost';
import AverageCost from './AverageCost';
import ServiceCount from './ServiceCount';
import MemberAccounts from './MemberAccounts';

function SummaryPaper({ children }: PropsWithChildren) {
  return (
    <SuspensePaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        p: '24px',
      }}
      FallbackProps={{ size: 48, sx: { minHeight: '62px' } }}
    >
      {children}
    </SuspensePaper>
  );
}

export default function CostManagementSummary() {
  return (
    <Grid container spacing="16px">
      <Grid md={3} sm={6} xs={12}>
        <SummaryPaper>
          <TotalCost />
        </SummaryPaper>
      </Grid>
      <Grid md={3} sm={6} xs={12}>
        <SummaryPaper>
          <AverageCost />
        </SummaryPaper>
      </Grid>
      <Grid md={3} sm={6} xs={12}>
        <SummaryPaper>
          <ServiceCount />
        </SummaryPaper>
      </Grid>
      <Grid md={3} sm={6} xs={12}>
        <SummaryPaper>
          <MemberAccounts />
        </SummaryPaper>
      </Grid>
    </Grid>
  );
}
