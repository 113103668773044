import { type MouseEvent, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { Clear, Notifications } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import {
  useBulkUpdateNotifications,
  useGetNotifications,
  useUpdateNotification,
} from '@api/queries/notification';
import { useOrganizationHelper } from '@hooks';
import { SuspenseBox } from '@components/suspense';
import { formatDate } from '@utils/formatter';

function NotificationItem({
  item,
  onClose,
}: {
  item: HekaNotification;
  onClose: () => void;
}) {
  const { t } = useTranslation('notification');
  const { getOrganizationNameById } = useOrganizationHelper();
  const descriptionParams = useMemo(() => {
    switch (item.Category) {
      case 'CONTRACT':
        switch (item.SubCategory) {
          case 'EXPIRED':
            return {
              Name: getOrganizationNameById(item.OrganizationId ?? '') ?? '',
              Remaining: dayjs(item.Params['EXPIRED_AT']).diff(dayjs(), 'days'),
              Expired: formatDate(item.Params['EXPIRED_AT']),
            };
          default:
            return {};
        }
      default:
        return {};
    }
  }, [
    getOrganizationNameById,
    item.Category,
    item.OrganizationId,
    item.Params,
    item.SubCategory,
  ]);
  const targetURL = useMemo(() => {
    switch (item.Category) {
      case 'CONTRACT':
        switch (item.SubCategory) {
          case 'EXPIRED':
            return `/customer/${item.OrganizationId}?section=contract`;
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  }, [item]);
  const { mutateAsync } = useUpdateNotification();
  const updateCheckedAt = useCallback(
    async (e?: MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
      }
      try {
        await mutateAsync({
          UID: item.UID,
          CheckedAt: new Date(),
        });
      } catch (e) {
        console.error(e);
      }
    },
    [item.UID, mutateAsync],
  );
  const handleClick = useCallback(() => {
    if (typeof targetURL !== 'undefined') {
      // navigate(targetURL);
      void updateCheckedAt();
      onClose();
    }
  }, [onClose, targetURL, updateCheckedAt]);
  return (
    <Box
      sx={{
        position: 'relative',
        ...(!!targetURL && {
          '&:hover': {
            background: '#E0E0E022',
          },
        }),
      }}
    >
      <Box
        {...(!!targetURL && {
          component: Link,
          to: targetURL,
          onClick: handleClick,
        })}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          minWidth: '480px',
          maxWidth: '480px',
          px: '32px',
          py: '24px',
          ...(!!targetURL && {
            color: 'inherit',
            textDecoration: 'none',
            '&:active': {
              background: '#E0E0E066',
            },
          }),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography fontWeight={500}>
            {t(`title.${item.Category}.${item.SubCategory}`)}
          </Typography>
          <Typography color="text.tertiary" fontSize="14px">
            {formatDate(item.CreatedAt)}
          </Typography>
        </Box>
        <Typography>
          {t(`description.${item.Category}.${item.SubCategory}`, {
            ...descriptionParams,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          right: '28px',
        }}
      >
        <IconButton size="small" color="calm" onClick={updateCheckedAt}>
          <Clear />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function Notification() {
  const { t } = useTranslation('global', {
    keyPrefix: 'component.notification',
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { data: response } = useGetNotifications();
  const { mutateAsync, isPending } = useBulkUpdateNotifications();
  const updateNotifications = useCallback(async () => {
    if (response?.data.length) {
      try {
        await mutateAsync({
          IdList: response.data.map((v) => v.UID),
          CheckedAt: new Date(),
        });
        handleClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [handleClose, mutateAsync, response?.data]);
  return (
    <Box>
      <IconButton
        onClick={handleOpen}
        sx={{
          background: '#F5F3F033',
          borderRadius: '8px',
          '&:hover': { background: '#F5F3F099' },
          '&:active': { background: '#F5F3F0' },
          p: '8px',
        }}
      >
        <Badge color="brand" variant="dot" invisible={!response?.data.length}>
          <Notifications
            sx={{ width: '24px', height: '24px', color: '#3A4050' }}
          />
        </Badge>
      </IconButton>
      <Popper
        id={open ? 'global-notification-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 2,
        })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              position: 'relative',
              minWidth: response?.data.length ? '480px' : '240px',
              border: 'none',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
              backdropFilter: 'blur(3px)',
              p: 0,
              pb: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '32px',
                py: '24px',
              }}
            >
              <Typography fontSize="18px" fontWeight={500}>
                {t('text.totalCount', { count: Number(response?.data.length) })}
              </Typography>
              {!!response?.data.length && (
                <LoadingButton
                  size="small"
                  color="danger"
                  onClick={updateNotifications}
                  loading={isPending}
                >
                  {t('button.readAll')}
                </LoadingButton>
              )}
            </Box>
            <Divider />
            {response?.data.length ? (
              <SuspenseBox
                FallbackProps={{
                  size: 48,
                  sx: {
                    p: '32px',
                  },
                }}
                sx={{
                  maxHeight: 'min(775px, calc(100vh - 245px))',
                  overflowY: 'auto',
                }}
              >
                {response.data.map((item) => (
                  <NotificationItem
                    key={`notification-${item.UID}`}
                    item={item}
                    onClose={handleClose}
                  />
                ))}
              </SuspenseBox>
            ) : (
              <Box sx={{ px: '32px', pt: '24px' }}>
                <Typography>{t('text.empty')}</Typography>
              </Box>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
