import { Box } from '@mui/material';

export default function Background() {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        background: 'linear-gradient(180deg, #FFF 0%, #F5F3F0 100%)',
      }}
    />
  );
}
