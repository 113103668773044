import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useBulkAddCloudAccount } from '@api/queries/organization';
import { useGetNHNAccountHierarchy } from '@api/queries/nhn/account';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';
import FormModal from '@components/modal/form';
import { NHNOrgAccordion, NHNProjectItem } from './styled';

type BodyProps = Pick<
  ReturnType<
    typeof useFormik<{
      Accounts: Array<CreateNHNAccount>;
      DiscountRate: number;
    }>
  >,
  'values' | 'handleChange' | 'handleBlur' | 'setFieldValue'
>;
function AddNHNAccountModalBody({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
}: BodyProps) {
  const { data: hierarchy } = useGetNHNAccountHierarchy(true);
  const organizations = useMemo(() => {
    if (hierarchy?.data) {
      return _.flatten(hierarchy.data.map((v) => v.Organizations));
    }
    return [];
  }, [hierarchy?.data]);

  const checkOrgAll = useCallback(
    (orgId: string): boolean => {
      const org = organizations.find((v) => v.OrgId === orgId);
      if (org) {
        return org.Projects.every((v) =>
          values.Accounts.some(
            (k) => k.AccountId === v.ProjectId && org.OrgId === k.Options.OrgId,
          ),
        );
      }
      return false;
    },
    [values.Accounts, organizations],
  );
  const checkOrgIndeterminate = useCallback(
    (orgId: string) => {
      return (
        !checkOrgAll(orgId) &&
        values.Accounts.some((v) => v.Options.OrgId === orgId)
      );
    },
    [values.Accounts, checkOrgAll],
  );
  const checkProject = useCallback(
    (orgId: string, projectId: string) => {
      return values.Accounts.some(
        (v) => v.AccountId === projectId && v.Options.OrgId === orgId,
      );
    },
    [values.Accounts],
  );
  const toggleOrg = useCallback(
    (orgId: string) => () => {
      const allChecked = checkOrgAll(orgId);
      const result = _.cloneDeep(values.Accounts).filter(
        (v) => v.Options.OrgId !== orgId,
      );
      if (!allChecked) {
        const projects: Array<CreateNHNAccount> = [];
        for (const acc of hierarchy?.data ?? []) {
          for (const org of acc.Organizations) {
            if (org.OrgId === orgId) {
              for (const proj of org.Projects) {
                projects.push({
                  AccountId: proj.ProjectId,
                  ParentUID: acc.AccountUID,
                  DiscountRate: 0,
                  Memo: '',
                  Options: {
                    OrgId: org.OrgId,
                  },
                });
              }
            }
          }
        }
        result.push(...projects);
      }
      setFieldValue('Accounts', result);
    },
    [values.Accounts, setFieldValue, hierarchy?.data, checkOrgAll],
  );
  const toggleProject = useCallback(
    (orgId: string, projectId: string) => () => {
      const exists = values.Accounts.some(
        (v) => v.AccountId === projectId && v.Options.OrgId === orgId,
      );
      const result = _.cloneDeep(values.Accounts);
      if (exists) {
        setFieldValue(
          'Accounts',
          result.filter(
            (v) => v.AccountId !== projectId || v.Options.OrgId !== orgId,
          ),
        );
      } else {
        const acc = hierarchy?.data.find((v) =>
          v.Organizations.some((k) => k.OrgId === orgId),
        );
        if (acc) {
          result.push({
            AccountId: projectId,
            ParentUID: acc.AccountUID,
            DiscountRate: 0,
            Memo: '',
            Options: {
              OrgId: orgId,
            },
          });
          setFieldValue('Accounts', result);
        }
      }
    },
    [values.Accounts, setFieldValue, hierarchy?.data],
  );
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '682px',
          overflowY: 'auto',
        }}
      >
        {!!hierarchy &&
          hierarchy.data.map((acc) =>
            acc.Organizations.map((org) => (
              <NHNOrgAccordion
                key={`nhn_account_${org.OrgId}`}
                checked={checkOrgAll(org.OrgId)}
                indeterminate={checkOrgIndeterminate(org.OrgId)}
                onChange={toggleOrg(org.OrgId)}
                orgName={org.OrgName}
              >
                {org.Projects.map((proj) => (
                  <NHNProjectItem
                    key={`nhn_account_${org.OrgId}_${proj.ProjectId}`}
                    checked={checkProject(org.OrgId, proj.ProjectId)}
                    onChange={toggleProject(org.OrgId, proj.ProjectId)}
                    projectId={proj.ProjectId}
                    projectName={proj.ProjectName}
                  />
                ))}
              </NHNOrgAccordion>
            )),
          )}
      </Box>
      <Box>
        <TextField
          name="DiscountRate"
          value={values.DiscountRate}
          onChange={handleChange}
          onBlur={handleBlur}
          label="할인율"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          inputMode="decimal"
          autoComplete="off"
          fullWidth
        />
      </Box>
    </Box>
  );
}

export default function AddNHNAccountModal({ open, onClose }: ModalProps) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.nhn.add',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useBulkAddCloudAccount();
  const {
    values,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<{
    Accounts: Array<CreateNHNAccount>;
    DiscountRate: number;
  }>({
    initialValues: {
      Accounts: [],
      DiscountRate: 0,
    },
    validationSchema: Yup.object()
      .shape({
        Accounts: Yup.array()
          .of(
            Yup.object()
              .shape({
                AccountId: Yup.string().defined(),
                ParentUID: Yup.string().defined(),
                Options: Yup.object().shape({
                  OrgId: Yup.string().defined(),
                }),
              })
              .defined(),
          )
          .min(1)
          .defined(),
        DiscountRate: Yup.number().min(0).max(100).defined(),
      })
      .defined(),
    onSubmit: async (v) => {
      if (UID) {
        try {
          await mutateAsync({
            OrganizationId: UID,
            CSP: 'nhn',
            ...v,
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      minHeight="960px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <AddNHNAccountModalBody
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
    </FormModal>
  );
}
