import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/aws/account' as const;

async function getPayerAccounts() {
  const { data } = await axios.get<MultipleResponse<PayerAccount>>(PREFIX);
  return data;
}

async function getPayerAccountById(UID: string) {
  const { data } = await axios.get<SingleResponse<PayerAccount>>(
    `${PREFIX}/${UID}`,
  );
  return data;
}

async function createPayerAccount({
  CompanyId,
  AccountName,
  ARN,
  ExternalId,
  BucketName,
  ReportName,
  ReportPrefix,
  AccountId,
}: CreatePayerAccount) {
  const { data } = await axios.post<SingleResponse<PayerAccount>>(PREFIX, {
    CompanyId,
    AccountName,
    ARN,
    ExternalId,
    BucketName,
    ReportName,
    ReportPrefix,
    AccountId,
  });
  return data;
}

async function updatePayerAccount({
  UID,
  AccountName,
  ARN,
  ExternalId,
  BucketName,
  ReportName,
  AccountId,
  Organizations,
  ...Rest
}: UpdatePayerAccount) {
  const { data } = await axios.put<SingleResponse<PayerAccount>>(PREFIX, {
    UID,
    AccountName,
    ARN,
    ExternalId,
    BucketName,
    ReportName,
    AccountId,
    Organizations,
    ...Rest,
  });
  return data;
}

async function deletePayerAccount(UID: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}`);
  return data;
}

async function getCURList(UID: string) {
  const { data } = await axios.get<MultipleResponse<CURList>>(
    `${PREFIX}/cur/${UID}`,
  );
  return data;
}

async function createCUR({
  CompanyId,
  AccountName,
  ARN,
  ExternalId,
  BucketName,
  ReportName,
  AccountId,
  S3Region,
}: CreateCUR) {
  const { data } = await axios.post<MultipleResponse<CURList>>(
    `${PREFIX}/cur`,
    {
      CompanyId,
      AccountName,
      ARN,
      ExternalId,
      BucketName,
      ReportName,
      ReportPrefix: 'heka',
      AccountId,
      S3Region,
    },
  );
  return data;
}

async function getExternalId() {
  const { data } = await axios.get<SingleResponse<{ ExternalId: string }>>(
    `${PREFIX}/cloudshell/external`,
  );
  return data;
}

async function getNotConfiguredAccount() {
  const { data } = await axios.get<SingleResponse<PayerAccount>>(
    `${PREFIX}/cloudshell/created`,
  );
  return data;
}

async function getOrganizationAccounts() {
  const { data } = await axios.get<MultipleResponse<AWSOrganizationAccount>>(
    `${PREFIX}/aws/organization`,
  );
  return data;
}

async function getOrganizationAccountsWithAlias() {
  const { data } = await axios.get<
    MultipleResponse<AWSOrganizationAccountWithAlias>
  >(`${PREFIX}/aws/organization/alias`);
  return data;
}

async function getLinkedAccounts() {
  const { data } = await axios.get<MultipleResponse<AWSLinkedAccount>>(
    `${PREFIX}/linked/list`,
  );
  return data;
}

async function updateLinkedAccounts() {
  const { data } = await axios.put(`${PREFIX}/linked/accounts`);
  return data;
}

async function updateLinkedAccountsByPayerId(UID: string) {
  const { data } = await axios.put(`${PREFIX}/linked/accounts/${UID}`);
  return data;
}

export default {
  getPayerAccounts,
  getPayerAccountById,
  createPayerAccount,
  updatePayerAccount,
  deletePayerAccount,
  getCURList,
  createCUR,
  getExternalId,
  getNotConfiguredAccount,
  getOrganizationAccounts,
  getOrganizationAccountsWithAlias,
  getLinkedAccounts,
  updateLinkedAccounts,
  updateLinkedAccountsByPayerId,
};
