import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useGetCompany } from '@api/queries/company';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useGetInvoiceListForCurrentCompany() {
  const { data: response } = useGetCompany();
  return useSuspenseQuery({
    queryKey: [
      ...INVOICE_KEY.GET_INVOICE_LIST_BY_COMPANY_ID,
      response?.data.UID,
    ],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2])
        ? InvoiceService.getInvoiceListByCompanyId(queryKey[2] as string)
        : null,
  });
}
