import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Link, Note } from '@heka/theme';
import FormModal from '@components/modal/form';
import { formatDate } from '@utils/formatter';

type Props = ModalProps & {
  history: DepositHistory;
};

function DepositInvoiceModalBody({ onClose, history }: Omit<Props, 'open'>) {
  const { t } = useTranslation('customer');
  if (history.ItemType !== 'Used') {
    return null;
  }
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '24px', pb: '68px' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          py: '40px',
        }}
      >
        <Typography color="textSecondary" fontSize="14px" lineHeight="150%">
          {formatDate(history.UsageDate, 'YYYY.MM.DD')}
        </Typography>
        <Typography color="#C8003C" fontSize="24px" lineHeight="150%">
          {t('text.depositMinusValue', { value: history.Amount })}
        </Typography>
        <Link
          color="calm"
          href={`/invoice/${history.InvoiceId}`}
          onClick={onClose}
        >
          {t('table.link.invoice', { invoiceId: history.InvoiceId })}
        </Link>
      </Box>
      <Note
        color="emphasis"
        title={t('form.deposit.invoice.noteTitle')}
        content={t('form.deposit.invoice.noteContent', {
          startDate: formatDate(history.InvoiceDate, 'YYYY.MM.DD'),
          endDate: formatDate(
            dayjs(history.InvoiceDate).endOf('month'),
            'YYYY.MM.DD',
          ),
          invoiceId: history.InvoiceId,
        })}
        defaultOpen
        disableCollapse
      />
    </Box>
  );
}

export default function DepositInvoiceModal({ open, onClose, history }: Props) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.deposit.invoice',
  });
  const { t: globalT } = useTranslation('global');
  if (history.ItemType !== 'Used') {
    return null;
  }
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      minHeight="700px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <Button
            color="emphasis1"
            href={`/invoice/${history.InvoiceId}`}
            onClick={onClose}
          >
            {t('cta')}
          </Button>
        </>
      }
    >
      <DepositInvoiceModalBody onClose={onClose} history={history} />
    </FormModal>
  );
}
