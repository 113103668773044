import { styled } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  getRadioUtilityClass,
  Radio,
  Typography,
  useRadioGroup,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type RoleTileProps = Omit<
  FormControlLabelProps,
  'control' | 'labelPlacement' | 'label' | 'disableTypography'
>;
const RoleTileComponent = styled(({ checked, ...props }: RoleTileProps) => {
  const { t } = useTranslation('global', { keyPrefix: 'role' });
  const { value } = props;
  return (
    <FormControlLabel
      control={<Radio />}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {checked && (
            <CheckCircle
              sx={{ width: '20px', height: '20px', color: '#66BE21' }}
            />
          )}
          <Typography>{t(value as UserRole)}</Typography>
        </Box>
      }
      disableTypography
      {...props}
    />
  );
})<RoleTileProps>({
  flex: 1,
  margin: 0,
  justifyContent: 'center',
  padding: '16px',
  boxSizing: 'border-box',
  border: '0.5px solid #E0E0E0',
  [`.${getRadioUtilityClass('root')}`]: {
    display: 'none',
  },
  [`&:has(> .${getRadioUtilityClass('root')}.Mui-checked)`]: {
    background: '#66BE211A',
    borderColor: '#66BE21',
  },
  '&.Mui-disabled': {
    color: '#B8B8B8',
    background: '#B8B8B833',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
});

export function RoleTile(props: RoleTileProps) {
  const { value } = props;
  const group = useRadioGroup();
  let checked = false;
  if (group) {
    checked = group.value === value;
  }
  return <RoleTileComponent value={value} checked={checked} {...props} />;
}
