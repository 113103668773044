import type { FormEventHandler, PropsWithChildren, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog, { getDialogUtilityClass } from '@mui/material/Dialog';
import { SuspenseBox } from '@components/suspense';

interface FormModalProps extends ModalProps {
  title: string;
  Actions: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  description?: ReactNode;
  maxWidth?: string | number;
  minHeight?: string | number;
  pb?: string | number;
  sx?: SxProps<Theme>;
}

export default function FormModal({
  children,
  open,
  onClose,
  title,
  Actions,
  onSubmit,
  description,
  maxWidth,
  minHeight,
  pb,
  sx,
}: PropsWithChildren<FormModalProps>) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth
      sx={{
        [`> .${getDialogUtilityClass('container')}`]: {
          [`> .${getDialogUtilityClass('paper')}`]: {
            display: 'inline-flex',
            flexDirection: 'column',
            maxWidth: maxWidth ?? '480px',
            minHeight: minHeight ?? '800px',
            pb: '56px',
          },
        },
      }}
    >
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box>
          <Typography
            color="text.main"
            fontSize="16px"
            fontWeight={500}
            letterSpacing="0.64px"
          >
            {title}
          </Typography>
          {typeof description !== 'undefined' &&
            (typeof description === 'string' ? (
              <Typography
                dangerouslySetInnerHTML={{ __html: description }}
                sx={{
                  color: 'text.main',
                  fontSize: '14px',
                  lineHeight: '150%',
                  mt: '40px',
                }}
              />
            ) : (
              <Typography
                sx={{
                  color: 'text.main',
                  fontSize: '14px',
                  lineHeight: '150%',
                  mt: '40px',
                }}
              >
                {description}
              </Typography>
            ))}
        </Box>
        <SuspenseBox sx={{ flex: 1 }}>{children}</SuspenseBox>
        <Box sx={{ display: 'flex', gap: '16px', '> *': { flex: 1 } }}>
          {Actions}
        </Box>
      </Box>
    </Dialog>
  );
}
