import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRestoreInvoiceCloudUsage } from '@api/queries/invoice';
import SimpleModal from '@components/modal/simple';

export default function RestoreCloudUsageModal({
  open,
  onClose,
  Data,
}: BaseCloudUsageModalProps) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useRestoreInvoiceCloudUsage();
  const handler = useCallback(async () => {
    if (Data) {
      try {
        await mutateAsync(Data.InvoiceId);
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [mutateAsync, Data, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('modal.cloud.restore.title')}
      description={t('modal.cloud.restore.description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton color="danger" onClick={handler} loading={isPending}>
            {t('modal.cloud.restore.cta')}
          </LoadingButton>
        </>
      }
      enableBorder
    />
  );
}
