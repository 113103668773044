import { useTranslation } from 'react-i18next';
import { Unstable_Grid2 as Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateRange } from '@mui/icons-material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { formatDate } from '@utils/formatter';
import { CurrencyInput } from './styled';

type Props = Pick<
  ReturnType<typeof useFormik>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
> & { activeColor?: string };

export default function CustomerDepositForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  activeColor,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.deposit' });
  return (
    <Grid container rowSpacing="16px" columnSpacing="24px">
      <Grid xs={12} sx={{ pt: '40px', pb: '48px' }}>
        <CurrencyInput
          name="Amount"
          value={values.Amount}
          onValueChange={(v) =>
            setFieldValue('Amount', v.floatValue?.toString() ?? '')
          }
          onBlur={handleBlur}
          placeholder={t('holder.value')}
          activeColor={activeColor}
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          name="Name"
          value={values.Name}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('label.name')}
          placeholder={t('holder.name')}
          error={Boolean(errors.Name && touched.Name)}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <DesktopDatePicker
          format="YYYY.MM.DD"
          value={dayjs(values.Date)}
          onChange={(newValue) => {
            if (newValue)
              setFieldValue(
                'Date',
                dayjs
                  .utc(formatDate(newValue, 'YYYY-MM-DD'))
                  .startOf('day')
                  .toDate(),
              );
          }}
          slots={{
            openPickerIcon: DateRange,
          }}
          slotProps={{
            textField: {
              name: 'Date',
              onBlur: handleBlur,
              label: t('label.date'),
              fullWidth: true,
              inputProps: {
                readOnly: true,
              },
            },
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
              ],
            },
          }}
          showDaysOutsideCurrentMonth
          closeOnSelect
        />
      </Grid>
    </Grid>
  );
}
