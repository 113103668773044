import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/nhn/account' as const;

async function getNHNAccounts() {
  const { data } = await axios.get<MultipleResponse<NHNAccount>>(PREFIX);
  return data;
}

async function getNHNAccountHierarchy(unused = false) {
  const { data } = await axios.get<MultipleResponse<NHNAccountHierarchy>>(
    `${PREFIX}/hierarchy${unused ? '/unused' : ''}`,
  );
  return data;
}

async function getNHNAccountById(UID: string) {
  const { data } = await axios.get<SingleResponse<NHNAccount>>(
    `${PREFIX}/${UID}`,
  );
  return data;
}

async function createNHNAccount(body: CreateNHNAccount) {
  const { data } = await axios.post<SingleResponse<NHNAccount>>(
    `${PREFIX}/ui`,
    body,
  );
  return data;
}

async function updateNHNAccount(body: UpdateNHNAccount) {
  const { data } = await axios.put<SingleResponse<NHNAccount>>(PREFIX, body);
  return data;
}

async function updateNHNAccountUser(body: UpdateNHNAccountUser) {
  const { data } = await axios.put<SingleResponse<NHNAccount>>(
    `${PREFIX}/uuid`,
    body,
  );
  return data;
}

async function deleteNHNAccount(UID: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}`);
  return data;
}

async function getVerifiedNHNUser(UID: string) {
  const { data } = await axios.get<SingleResponse<VerifiedNHNUser>>(
    `${PREFIX}/verify/user/${UID}`,
  );
  return data;
}

async function verifyNHNUser(body: NHNUserInfo) {
  const { data } = await axios.post<SingleResponse<VerifiedNHNUser>>(
    `${PREFIX}/verify/user`,
    body,
  );
  return data;
}

async function getAssignedProjectList() {
  const { data } = await axios.get<MultipleResponse<NHNAssignedProject>>(
    `${PREFIX}/project/list`,
  );
  return data;
}

export default {
  getNHNAccounts,
  getNHNAccountHierarchy,
  getNHNAccountById,
  createNHNAccount,
  updateNHNAccount,
  updateNHNAccountUser,
  deleteNHNAccount,
  getVerifiedNHNUser,
  verifyNHNUser,
  getAssignedProjectList,
};
