import { formatLocaleString } from '@utils/formatter';

export type PathRadius = 'NONE' | 'BOTH' | 'TOP' | 'BOTTOM';

const SUFFIX_MAP = [
  { suffix: 'T', threshold: 1e12 },
  { suffix: 'B', threshold: 1e9 },
  { suffix: 'M', threshold: 1e6 },
  { suffix: 'K', threshold: 1e3 },
  { suffix: '', threshold: 1 },
] as const;

export function calculateDataMax(dataMax: number) {
  const length = Math.trunc(dataMax).toString().length;
  if (length > 1) {
    return dataMax + Math.pow(10, length - 1);
  }
  return 10;
}

function formatNumberUnit(value: number) {
  const unit = SUFFIX_MAP.find((v) => Math.abs(value) >= v.threshold);
  if (unit) {
    const calculated = value / unit.threshold;
    return (
      formatLocaleString(calculated, calculated % 1 !== 0 ? 1 : 0) + unit.suffix
    );
  }
  return formatLocaleString(value) as string;
}

export function formatChartAxis(value: number) {
  return formatNumberUnit(value);
}

export function formatChartLabel(value: number) {
  if (Math.abs(value) > 0) {
    return formatNumberUnit(value);
  }
  return '';
}

export function formatChartLabelLocaleString(value: number) {
  if (Math.abs(value) > 0) {
    return formatLocaleString(value);
  }
  return '';
}

export function makePath(
  type: PathRadius,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number = 8,
) {
  switch (type) {
    case 'BOTH':
      return `M${x},${y + radius}A ${radius},${radius},0,0,1,${
        x + radius
      },${y}L ${x + width - radius},${y}A ${radius},${radius},0,0,1,${
        x + width
      },${y + radius}L ${x + width},${
        y + height - radius
      }A ${radius},${radius},0,0,1,${x + width - radius},${y + height}L ${
        x + radius
      },${y + height}A ${radius},${radius},0,0,1,${x},${y + height - radius}Z`;
    case 'TOP':
      return `M${x},${y + radius}A ${radius},${radius},0,0,1,${
        x + radius
      },${y}L ${x + width - radius},${y}A ${radius},${radius},0,0,1,${
        x + width
      },${y + radius}L ${x + width},${y + height}L ${x},${y + height}Z`;
    case 'BOTTOM':
      return `M${x},${y}L ${x + width},${y}L ${x + width},${
        y + height - radius
      }A ${radius},${radius},0,0,1,${x + width - radius},${y + height}L ${
        x + radius
      },${y + height}A ${radius},${radius},0,0,1,${x},${y + height - radius}Z`;
    default:
      return `M ${x},${y} h ${width} v ${height} h -${width} Z`;
  }
}
