export const GET_ACCOUNTS = ['NHN_ACCOUNT', 'GET_ACCOUNTS'];
export const GET_ACCOUNT_HIERARCHY = ['NHN_ACCOUNT', 'GET_ACCOUNT_HIERARCHY'];
export const GET_ACCOUNT_BY_ID = ['NHN_ACCOUNT', 'GET_ACCOUNT_BY_ID'];
export const CREATE_ACCOUNT = ['NHN_ACCOUNT', 'CREATE_ACCOUNT'];
export const UPDATE_ACCOUNT = ['NHN_ACCOUNT', 'UPDATE_ACCOUNT'];
export const UPDATE_ACCOUNT_USER = ['NHN_ACCOUNT', 'UPDATE_ACCOUNT_USER'];
export const DELETE_ACCOUNT = ['NHN_ACCOUNT', 'DELETE_ACCOUNT'];
export const GET_VERIFIED_USER = ['NHN_ACCOUNT', 'GET_VERIFIED_USER'];
export const VERIFY_USER = ['NHN_ACCOUNT', 'VERIFY_USER'];
export const GET_ASSIGNED_PROJECT_LIST = [
  'NHN_ACCOUNT',
  'GET_ASSIGNED_PROJECT_LIST',
];
