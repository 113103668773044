import { Stack, Divider } from '@mui/material';
import { SuspensePaper } from '@components/suspense';
import {
  SettingsProfilePassword,
  SettingsProfileUser,
} from '@features/settings/profile';

export default function SettingsProfilePage() {
  return (
    <SuspensePaper
      sx={{ flex: 1 }}
      FallbackProps={{ size: 72, sx: { flex: 1 } }}
    >
      <Stack spacing="40px" divider={<Divider />}>
        <SettingsProfileUser />
        <SettingsProfilePassword />
      </Stack>
      <div />
    </SuspensePaper>
  );
}
