import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Link } from '@heka/theme';
import { ResponsiveImage } from '@components/styled/image';

export function NHNOnboardUserInfo({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: Pick<
  ReturnType<typeof useFormik<NHNUserInfo>>,
  'values' | 'errors' | 'touched' | 'handleChange' | 'handleBlur'
>) {
  const { t } = useTranslation('onboard', { keyPrefix: 'integrateNHN' });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        py: '24px',
        maxWidth: '604px',
      }}
    >
      <Box>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('text.consoleLogin') }}
          sx={{
            mb: '16px',
            '> span': {
              fontWeight: 500,
            },
          }}
        />
        <Link href="https://console.nhncloud.com" target="_blank">
          {t('text.link')}
        </Link>
      </Box>
      <ResponsiveImage
        src="/assets/img/nhn-instance.png"
        alt="NHN Instance Example"
        sx={{ maxWidth: '604px' }}
      />
      <Typography
        dangerouslySetInnerHTML={{ __html: t('text.endpointDialog') }}
      />
      <ResponsiveImage
        src="/assets/img/nhn-endpoint-dialog.png"
        alt="NHN Endpoint Dialog"
        sx={{ maxWidth: '604px' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          maxWidth: '392px',
        }}
      >
        <TextField
          name="TenantId"
          value={values.TenantId}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.tenantId')}
          placeholder={t('form.holder.tenantId')}
          error={Boolean(errors.TenantId && touched.TenantId)}
          helperText={
            Boolean(errors.TenantId && touched.TenantId) &&
            String(errors.TenantId)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="Username"
          value={values.Username}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.loginEmail')}
          placeholder={t('form.holder.loginEmail')}
          error={Boolean(errors.Username && touched.Username)}
          helperText={
            Boolean(errors.Username && touched.Username) &&
            String(errors.Username)
          }
          type="email"
          inputMode="email"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="Password"
          value={values.Password}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.apiPassword')}
          placeholder={t('form.holder.apiPassword')}
          error={Boolean(errors.Password && touched.Password)}
          helperText={
            Boolean(errors.Password && touched.Password) &&
            String(errors.Password)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
      </Box>
    </Box>
  );
}

export function NHNOnboardResellerInfo({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: Pick<
  ReturnType<typeof useFormik<CreateNHNAccount>>,
  'values' | 'errors' | 'touched' | 'handleChange' | 'handleBlur'
>) {
  const { t } = useTranslation('onboard', { keyPrefix: 'integrateNHN' });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        py: '24px',
        maxWidth: '604px',
      }}
    >
      <Typography dangerouslySetInnerHTML={{ __html: t('content') }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          maxWidth: '392px',
        }}
      >
        <TextField
          name="APIPrefix"
          value={values.APIPrefix}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.apiPrefix')}
          placeholder={t('form.holder.apiPrefix')}
          error={Boolean(errors.APIPrefix && touched.APIPrefix)}
          helperText={
            Boolean(errors.APIPrefix && touched.APIPrefix) &&
            String(errors.APIPrefix)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="HmacSecretKey"
          value={values.HmacSecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.hmacSecretKey')}
          placeholder={t('form.holder.hmacSecretKey')}
          error={Boolean(errors.HmacSecretKey && touched.HmacSecretKey)}
          helperText={
            Boolean(errors.HmacSecretKey && touched.HmacSecretKey) &&
            String(errors.HmacSecretKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="AccessKey"
          value={values.AccessKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.accessKey')}
          placeholder={t('form.holder.accessKey')}
          error={Boolean(errors.AccessKey && touched.AccessKey)}
          helperText={
            Boolean(errors.AccessKey && touched.AccessKey) &&
            String(errors.AccessKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="SecretKey"
          value={values.SecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.secretKey')}
          placeholder={t('form.holder.secretKey')}
          error={Boolean(errors.SecretKey && touched.SecretKey)}
          helperText={
            Boolean(errors.SecretKey && touched.SecretKey) &&
            String(errors.SecretKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="AccountName"
          value={values.AccountName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('form.label.accountName')}
          placeholder={t('form.holder.accountName')}
          error={Boolean(errors.AccountName && touched.AccountName)}
          helperText={
            Boolean(errors.AccountName && touched.AccountName) &&
            String(errors.AccountName)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
      </Box>
    </Box>
  );
}
