import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Clear, UploadFile } from '@mui/icons-material';

export interface ImageFile extends File {
  preview?: string;
}
const Image = styled('img')({
  display: 'block',
  width: '56px',
  height: '56px',
  objectFit: 'cover',
});

type ThumbnailProps = {
  onClear: () => void;
  preview?: string;
};
export function Thumbnail({ onClear, preview }: ThumbnailProps) {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        background: 'rgba(255, 255, 255, 0.40)',
        p: '12px',
      }}
    >
      {preview ? (
        <Box>
          <Image
            src={preview}
            alt="Thumbnail"
            onLoad={() => URL.revokeObjectURL(preview)}
          />
          <Clear
            onClick={onClear}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: 4,
              right: 4,
              color: '#777B86',
              width: '16px',
              height: '16px',
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <UploadFile
            sx={{ color: '#A7A7A7', width: '32px', height: '32px' }}
          />
          <Typography color="textSecondary" fontSize="14px">
            JPG, JPEG, PNG
          </Typography>
        </Box>
      )}
    </Box>
  );
}
