import { useCallback, useMemo, useState } from 'react';
import { Box, Button, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useGetAllUsers, useGetUserToken } from '@api/queries/admin';
import SimpleModal from '@components/modal/simple';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Masquerade() {
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const [selected, setSelected] = useState(' ');
  const { data: response } = useGetAllUsers(false);
  const { mutateAsync, isPending } = useGetUserToken();
  const selectedUser = useMemo(() => {
    if (!isEmpty(selected.trim()) && response?.data?.length) {
      return response.data.find((v) => v.UID === selected);
    }
    return undefined;
  }, [selected, response?.data]);
  const handleClose = useCallback(() => {
    setSelected(' ');
  }, []);
  const handleChange = useCallback((e: SelectChangeEvent<string>) => {
    setSelected(e.target.value);
  }, []);
  const startMasquerade = useCallback(async () => {
    try {
      await mutateAsync(selected);
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  }, [selected, mutateAsync, navigate]);
  if (!response?.data.length) {
    return null;
  }
  return (
    <Box>
      <Select
        value={selected}
        onChange={handleChange}
        IconComponent={KeyboardArrowDown}
        sx={{ minWidth: '380px' }}
      >
        <MenuItem value=" " disabled>
          Masquerade Mode
        </MenuItem>
        {response?.data.map((user) => (
          <MenuItem key={`masquerade-user-item-${user.UID}`} value={user.UID}>
            {user.FirstName} ({user.CompanyName})
          </MenuItem>
        ))}
      </Select>
      <SimpleModal
        title="Masquerade Mode"
        description={`${selectedUser?.CompanyName}의 ${selectedUser?.FirstName}님의 계정으로 접속하시겠습니까?`}
        Actions={
          <>
            <Button color="cancel" onClick={handleClose}>
              {globalT('button.goBack')}
            </Button>
            <LoadingButton
              color="emphasis1"
              onClick={startMasquerade}
              loading={isPending}
            >
              접속하기
            </LoadingButton>
          </>
        }
        open={!isEmpty(selected.trim())}
        onClose={handleClose}
      />
    </Box>
  );
}
