import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, SvgIcon } from '@mui/material';
import Increase from '@assets/icon/increase-stroke.svg?react';
import Decrease from '@assets/icon/decrease-stroke.svg?react';

export function DifferenceSection({
  formatKey,
  difference,
}: {
  formatKey: 'percent' | 'quantity';
  difference: number;
}) {
  const { t } = useTranslation('dashboard');
  const { t: globalT } = useTranslation('global');
  const color = useMemo(() => {
    if (difference !== 0) {
      return difference > 0 ? '#FF4A3E' : '#3E69FF';
    }
    return 'textSecondary';
  }, [difference]);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {difference !== 0 && (
        <SvgIcon
          component={difference > 0 ? Increase : Decrease}
          viewBox="0 0 40 40"
          sx={{ width: '32px', height: '32px' }}
        />
      )}
      <Typography color={color}>
        {globalT(`format.number.${formatKey}`, { value: Math.abs(difference) })}
      </Typography>
      <Typography ml="4px">{t('text.compareWithLast')}</Typography>
    </Box>
  );
}
