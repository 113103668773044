import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { useCreateAccessAccount } from '@api/queries/ncp/account';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';
import {
  AccessKeyStep,
  SecretKeyStep,
  useAccessAccountForm,
} from '@features/onboard/ncp';

export default function OnboardIntegrateNCPPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('onboard');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync } = useCreateAccessAccount();
  const { initialValues, validationSchema } = useAccessAccountForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync({
          AccessKey: v.AccessKey,
          SecretKey: v.SecretKey,
          CloudType: v.AccountType as NCPAccountType,
        });
        navigate('/onboard/done');
      } catch (e) {
        console.error(e);
      }
    },
  });
  const handleButtonClick = useCallback(() => {
    if (!values.LastStep) {
      setFieldValue('LastStep', true);
    }
  }, [values.LastStep, setFieldValue]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <PanelContainer disableOverflow>
        <LeftPanel
          title={t('integrateNCP.title')}
          description={t('integrateNCP.description')}
        />
        <MainPanel>
          {!values.LastStep && (
            <AccessKeyStep
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
          {values.LastStep && (
            <SecretKeyStep
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
        </MainPanel>
      </PanelContainer>
      <BottomActions enableSupport>
        {!values.LastStep && (
          <Button type="button" color="cancel" onClick={() => navigate(-1)}>
            {globalT('button.goBack')}
          </Button>
        )}
        <Button
          type={values.LastStep ? 'submit' : 'button'}
          color="emphasis1"
          onClick={handleButtonClick}
          disabled={!isValid || !dirty}
        >
          {values.LastStep ? t('integrateNCP.cta') : globalT('button.next')}
        </Button>
      </BottomActions>
    </Box>
  );
}
