import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteReport } from '@api/queries/report';
import SimpleModal from '@components/modal/simple';

export default function DeleteReportModal({
  open,
  onClose,
  report,
}: ModalProps & { report?: ReportSettings }) {
  const { t } = useTranslation('report', {
    keyPrefix: 'modal.delete',
  });
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mutateAsync, isPending } = useDeleteReport();
  const handler = useCallback(async () => {
    if (report) {
      try {
        await mutateAsync(report.UID);
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [report, mutateAsync, pathname, onClose, navigate]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description', { name: report?.Name })}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton color="danger" onClick={handler} loading={isPending}>
            {globalT('button.delete')}
          </LoadingButton>
        </>
      }
    />
  );
}
