import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { calculateDataMax, formatChartLabelLocaleString } from '@utils/chart';

export default function ReportSingleChart({
  data = [],
  dateKey = 'Date',
  valueKey,
  color,
  Gradient,
}: ReportSingleChartProps) {
  return (
    <ResponsiveContainer width="100%" height={328}>
      <BarChart data={data} barSize={32}>
        <XAxis
          dataKey={dateKey}
          stroke="none"
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={16}
          tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
        />
        <YAxis
          type="number"
          scale="linear"
          stroke="none"
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={8}
          tickFormatter={(v) => formatLocaleString(v) ?? ''}
          width={80}
          domain={[0, calculateDataMax]}
        />
        {!!Gradient && <>{Gradient}</>}
        <Bar dataKey={valueKey} fill={color} radius={8}>
          <LabelList
            dataKey={valueKey}
            offset={8}
            position="top"
            fontSize={14}
            fill="#777B86"
            formatter={formatChartLabelLocaleString}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
