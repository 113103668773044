import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiTabs, {
  getTabsUtilityClass,
  TabsProps as MuiTabsProps,
} from '@mui/material/Tabs';
import { getTabUtilityClass } from '@mui/material/Tab';
import { getTabScrollButtonUtilityClass } from '@mui/material/TabScrollButton';

export type TabsProps = Pick<
  MuiTabsProps,
  'classes' | 'value' | 'onChange' | 'children' | 'sx'
> & {
  variant?: 'default' | 'square';
  color?: 'default' | 'sub';
};

const Tabs: StyledComponent<TabsProps> = styled(
  ({ variant, color, ...props }: TabsProps) => (
    <MuiTabs
      {...props}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    />
  ),
  {
    name: 'HekaTabs',
  },
)<TabsProps>(({ theme, variant = 'default', color = 'default' }) => ({
  ...(variant === 'default' && {
    minHeight: '40px',
  }),
  ...(variant === 'square' && {
    minHeight: '32px',
  }),
  [`> .${getTabsUtilityClass('scroller')} > .${getTabsUtilityClass(
    'flexContainer',
  )} > .${getTabUtilityClass('root')}`]: {
    ...(variant === 'default' && {
      minHeight: '40px',
      borderRadius: '40px',
      padding: '10px 16px',
      ...theme.palette.component.tab.default.common,
    }),
    ...(variant === 'square' && {
      minHeight: '30px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      padding: '8px 16px 6px 16px',
      borderBottomStyle: 'solid',
      ...theme.palette.component.tab.square.common,
    }),
    '&:hover': {
      ...(variant === 'default' && color === 'default'
        ? theme.palette.component.tab.default.default.hover
        : theme.palette.component.tab.default.sub.hover),
      ...(variant === 'square' && color === 'default'
        ? theme.palette.component.tab.square.default.hover
        : theme.palette.component.tab.square.sub.hover),
    },
    '&.Mui-selected': {
      ...(variant === 'default' && color === 'default'
        ? theme.palette.component.tab.default.default.selected
        : theme.palette.component.tab.default.sub.selected),
      ...(variant === 'square' && {
        ...(color === 'default'
          ? {
              ...theme.palette.component.tab.square.default.selected,
              fontWeight: 500,
            }
          : theme.palette.component.tab.square.sub.selected),
      }),
    },
  },
  [`> .${getTabScrollButtonUtilityClass('root')}.Mui-disabled`]: {
    width: 0,
  },
}));
Tabs.displayName = 'HekaTabs';

export default Tabs;
