import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useUpdatePayerAccount } from '@api/queries/aws/account';
import FormModal from '@components/modal/form';
import { useUpdatePayerAccountForm } from './form';

type Props = {
  item?: PayerAccount;
};
export default function EditPayerAccountModal({
  open,
  onClose,
  item,
}: ModalProps & Props) {
  const { t } = useTranslation('settings');
  const { t: accountT } = useTranslation('account');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdatePayerAccount();
  const { initialValues, validationSchema } = useUpdatePayerAccountForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdatePayerAccount>({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: item,
      });
    }
  }, [item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('modal.account.aws.edit.title')}
      description={t('modal.account.aws.edit.description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!item || !isValid || !dirty}
          >
            {t('modal.account.aws.edit.cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="ARN"
          value={values.ARN}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.ARN && touched.ARN)}
          label={accountT('label.aws.roleArn')}
        />
        <TextField
          name="ReportName"
          value={values.ReportName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.ReportName && touched.ReportName)}
          label={accountT('label.aws.reportName')}
        />
        <TextField
          name="ReportPrefix"
          value={values.ReportPrefix}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.ReportPrefix && touched.ReportPrefix)}
          label={accountT('label.aws.reportPrefix')}
        />
        <TextField
          name="BucketName"
          value={values.BucketName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.BucketName && touched.BucketName)}
          label={accountT('label.aws.bucketName')}
        />
      </Box>
    </FormModal>
  );
}
