import * as Yup from 'yup';
import dayjs from 'dayjs';
import { formatDate } from '@utils/formatter';

export function useCustomerDepositForm(): UseFormValidation<
  Omit<CreateDepositDetail, 'Amount'> & { Amount: string }
> {
  return {
    initialValues: {
      Name: '',
      Date: dayjs
        .utc(formatDate(dayjs().toDate(), 'YYYY-MM-DD'))
        .startOf('day')
        .toDate(),
      Amount: '',
    },
    validationSchema: Yup.object()
      .shape({
        Name: Yup.string(),
        Date: Yup.date().defined(),
        Amount: Yup.string().defined(),
      })
      .defined(),
  };
}
