import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  ChipProps as MuiChipProps,
  getChipUtilityClass,
} from '@mui/material/Chip';
import {
  CheckCircle,
  Error,
  ExpandMore,
  MonetizationOn,
} from '@mui/icons-material';
import ChipCircleIcon from '@assets/vector/ChipCircleIcon.svg?react';

interface InvoiceChipProps
  extends Pick<MuiChipProps, 'classes' | 'ref' | 'onClick'> {
  variant?: 'filled' | 'text';
  status?: InvoiceStatus;
}
const InvoiceChip = styled(
  ({
    onClick,
    variant = 'filled',
    status = 'Unissued',
    ...props
  }: InvoiceChipProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'chip.invoice' });
    const icon = useMemo(() => {
      switch (status) {
        case 'Invoiced':
          return <MonetizationOn />;
        case 'Paid':
          return <CheckCircle />;
        case 'Overdue':
          return <Error />;
        default:
          return variant === 'text' ? <ChipCircleIcon /> : undefined;
      }
    }, [status, variant]);
    const label = useMemo(() => {
      if (onClick) {
        return (
          <>
            {t(status)}
            <ExpandMore sx={{ width: '16px', height: '16px' }} />
          </>
        );
      }
      return t(status);
    }, [onClick, status, t]);
    return (
      <MuiChip
        {...props}
        variant={variant}
        size="selected"
        onClick={onClick}
        icon={icon}
        label={label}
      />
    );
  },
)<InvoiceChipProps>(({ variant = 'filled', status = 'Unissued' }) => ({
  color: variant === 'filled' ? '#3A4050' : '#777B86',
  ...(variant === 'filled' && {
    background: '#CECFDE4D',
  }),
  ...(status === 'Invoiced' && {
    color: '#E17A00',
    ...(variant === 'filled' && {
      background: '#E17A001A',
    }),
  }),
  ...(status === 'Paid' && {
    color: '#007AD1',
    ...(variant === 'filled' && {
      background: '#007AD11A',
    }),
  }),
  ...(status === 'Overdue' && {
    color: '#C8003C',
    ...(variant === 'filled' && {
      background: '#C8003C1A',
    }),
  }),
  [`> .${getChipUtilityClass('label')}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  [`> .${getChipUtilityClass('icon')}`]: {
    ...(status === 'Invoiced' && {
      color: '#E17A00',
    }),
    ...(status === 'Paid' && {
      color: '#007AD1',
    }),
    ...(status === 'Overdue' && {
      color: '#C8003C',
    }),
    ...(variant === 'text' &&
      status === 'Unissued' && {
        color: '#CECFDE',
      }),
  },
}));

export default InvoiceChip;
