import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { SuspenseBox } from '@components/suspense';
import CustomerName from './CustomerName';
import LastInvoiced from './LastInvoiced';
import CumulativeBilling from './CumulativeBilling';
import CumulativeDiscount from './CumulativeDiscount';
import DepositBalance from './DepositBalance';

export default function CustomerDetailHeader() {
  return (
    <SuspenseBox FallbackProps={{ size: 72, sx: { py: '114px' } }}>
      <Box
        sx={{
          px: '16px',
          py: '40px',
        }}
      >
        <CustomerName />
      </Box>
      <Grid container p="24px" mb="56px">
        <Grid md xs={6}>
          <LastInvoiced />
        </Grid>
        <Grid md xs={6}>
          <CumulativeBilling />
        </Grid>
        <Grid md xs={6}>
          <CumulativeDiscount />
        </Grid>
        <Grid md xs={6}>
          <DepositBalance />
        </Grid>
      </Grid>
    </SuspenseBox>
  );
}
