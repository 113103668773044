import dayjs from 'dayjs';

function getLastMonth(diff = 1) {
  return dayjs().subtract(diff, 'months').set('date', 1).toDate();
}

export const DEFAULT_NCP_SALES_FILTER_STATE: NCPSalesFilter = {
  StartDate: getLastMonth(6),
  EndDate: getLastMonth(),
  MemberId: [],
  Organizations: [],
  CloudType: [],
};

export const DEFAULT_NHN_SALES_FILTER_STATE: NHNSalesFilter = {
  StartDate: getLastMonth(6),
  EndDate: getLastMonth(),
  ProjectId: [],
  Organizations: [],
};
