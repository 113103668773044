import AxiosClient from '@api/client';
import { getFilenameFromHeader } from '@utils/blob';
import { formatDate } from '@utils/formatter';

const axios = AxiosClient.getInstance();
const PREFIX = '/ncp/sales' as const;

function extractFilterData(filter: NCPSalesFilter) {
  return {
    StartDate: formatDate(filter.StartDate, 'YYYY-MM-01T00:00:00'),
    EndDate: formatDate(filter.EndDate, 'YYYY-MM-01T00:00:00'),
    MemberId: filter.MemberId,
    Organizations: filter.Organizations?.map((v) => v.UID),
    CloudType: filter.CloudType?.map((v) => v.UID),
  };
}

async function getNCPSalesTable(filter: NCPSalesFilter) {
  const { data } = await axios.post<MultipleResponse<NCPSalesItem>>(
    `${PREFIX}/table`,
    extractFilterData(filter),
  );
  return data;
}

async function getNCPSalesGraph(filter: NCPSalesFilter) {
  const { data } = await axios.post<MultipleResponse<SalesGraphItem>>(
    `${PREFIX}/graph`,
    extractFilterData(filter),
  );
  return data;
}

async function getNCPSalesAutoComplete() {
  const { data } = await axios.get<SingleResponse<NCPSalesAutoComplete>>(
    `${PREFIX}/acdata`,
  );
  return data;
}

async function downloadNCPSalesAsExcel(filter: NCPSalesFilter) {
  const { data, headers } = await axios.post(
    `${PREFIX}/excel`,
    extractFilterData(filter),
    {
      responseType: 'blob',
    },
  );
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

export default {
  getNCPSalesTable,
  getNCPSalesGraph,
  getNCPSalesAutoComplete,
  downloadNCPSalesAsExcel,
};
