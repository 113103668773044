import axios, { Axios } from 'axios';
import { decryptLocalStorage } from '@utils/crypto';
import STORAGE_KEY from '@constants/storage';

export default class AxiosClient extends Axios {
  private static _instance?: AxiosClient;
  private static _requestInterceptorId?: number = undefined;
  private static _responseInterceptorId?: number = undefined;

  private constructor(baseURL?: string) {
    const transformRequest = axios.defaults.transformRequest
      ? Array.isArray(axios.defaults.transformRequest)
        ? [...axios.defaults.transformRequest]
        : [axios.defaults.transformRequest]
      : undefined;
    const transformResponse = axios.defaults.transformResponse
      ? Array.isArray(axios.defaults.transformResponse)
        ? [...axios.defaults.transformResponse]
        : [axios.defaults.transformResponse]
      : undefined;
    super({
      baseURL,
      transformRequest,
      transformResponse,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => status >= 200 && status < 300,
    });
  }

  public static initialize(baseURL: string): void {
    if (!this._instance) {
      this._instance = new AxiosClient(baseURL);
    } else {
      this._instance.defaults.baseURL = baseURL;
    }
    if (typeof this._requestInterceptorId === 'undefined') {
      this._requestInterceptorId = this._instance.interceptors.request.use(
        (config) => {
          if (config.headers.Authorization) {
            return config;
          }
          const token = decryptLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
          if (token) {
            config.headers.setAuthorization(`Bearer ${token}`);
          }
          return config;
        },
      );
    }
  }

  public static getInstance(): AxiosClient {
    if (!this._instance) {
      this._instance = new AxiosClient();
    }
    return this._instance;
  }
}
