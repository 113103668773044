import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import { Text } from '@visx/text';

type Props = {
  items: Array<{
    color: string;
    type: NCPAccountType;
    amount: number;
    percent: number;
  }>;
};
type WrapperProps = Props;

function formatPercent(value: number) {
  return (
    value.toLocaleString('en-US', {
      maximumFractionDigits: 1,
    }) + '%'
  );
}

function DualDifferentialChart({ items }: Props) {
  const { t } = useTranslation('global');
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg width="270px" viewBox="0 0 270 205" ref={svgRef}>
      <Group>
        <Circle cx={89} cy={89} r={89} fill={items[0].color} />
        <Text
          x={89}
          y={89}
          dy={-17}
          fill="#362F26"
          fontSize={18}
          lineHeight={18}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[0].type}`)}
        </Text>
        <Text
          x={89}
          y={89}
          dy={24}
          fill="#362F26"
          fontSize={32}
          lineHeight={32}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[0].percent))}
        </Text>
      </Group>
      <Group transform="translate(136, 71)">
        <Circle cx={67} cy={67} r={67} fill={items[1].color} />
        <Text
          x={67}
          y={67}
          dy={-16}
          fill="#362F26"
          fontSize={16}
          lineHeight={16}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[1].type}`)}
        </Text>
        <Text
          x={67}
          y={67}
          dy={20}
          fill="#362F26"
          fontSize={24}
          lineHeight={24}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[1].percent))}
        </Text>
      </Group>
    </svg>
  );
}

function DualEqualChart({ items }: Props) {
  const { t } = useTranslation('global');
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg width="314px" viewBox="0 0 314 178" ref={svgRef}>
      <Group>
        <Circle cx={89} cy={89} r={89} fill={items[0].color} />
        <Text
          x={89}
          y={89}
          dy={-17}
          fill="#362F26"
          fontSize={18}
          lineHeight={18}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[0].type}`)}
        </Text>
        <Text
          x={89}
          y={89}
          dy={24}
          fill="#362F26"
          fontSize={32}
          lineHeight={32}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[0].percent))}
        </Text>
      </Group>
      <Group transform="translate(136, 0)">
        <Circle cx={89} cy={89} r={89} fill={items[1].color} />
        <Text
          x={89}
          y={89}
          dy={-17}
          fill="#362F26"
          fontSize={18}
          lineHeight={18}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[1].type}`)}
        </Text>
        <Text
          x={89}
          y={89}
          dy={24}
          fill="#362F26"
          fontSize={32}
          lineHeight={32}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[1].percent))}
        </Text>
      </Group>
    </svg>
  );
}

function DifferentialChart({ items }: Props) {
  const { t } = useTranslation('global');
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg width="271px" viewBox="0 0 271 210" ref={svgRef}>
      <Group>
        <Circle cx={105} cy={105} r={105} fill={items[0].color} />
        <Text
          x={105}
          y={105}
          dy={-17}
          fill="#362F26"
          fontSize={18}
          lineHeight={18}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[0].type}`)}
        </Text>
        <Text
          x={105}
          y={105}
          dy={24}
          fill="#362F26"
          fontSize={32}
          lineHeight={32}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[0].percent))}
        </Text>
      </Group>
      <Group transform="translate(137, 0)">
        <Circle cx={67} cy={67} r={67} fill={items[1].color} />
        <Text
          x={67}
          y={67}
          dy={-16}
          fill="#362F26"
          fontSize={16}
          lineHeight={16}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[1].type}`)}
        </Text>
        <Text
          x={67}
          y={67}
          dy={20}
          fill="#362F26"
          fontSize={24}
          lineHeight={24}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[1].percent))}
        </Text>
      </Group>
      <Group transform="translate(159, 108)">
        <Circle cx={51} cy={51} r={51} fill={items[2].color} />
        <Text
          x={51}
          y={51}
          dy={-15}
          fill="#362F26"
          fontSize={14}
          lineHeight={14}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[2].type}`)}
        </Text>
        <Text
          x={51}
          y={51}
          dy={18}
          fill="#362F26"
          fontSize={20}
          lineHeight={20}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[2].percent))}
        </Text>
      </Group>
    </svg>
  );
}

function EqualChart({ items }: Props) {
  const { t } = useTranslation('global');
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg width="250px" viewBox="0 0 250 224" ref={svgRef}>
      <Group>
        <Circle cx={67} cy={67} r={67} fill={items[0].color} />
        <Text
          x={67}
          y={67}
          dy={-15}
          fill="#362F26"
          fontSize={14}
          lineHeight={14}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[0].type}`)}
        </Text>
        <Text
          x={67}
          y={67}
          dy={18}
          fill="#362F26"
          fontSize={20}
          lineHeight={20}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[0].percent))}
        </Text>
      </Group>
      <Group transform="translate(116, 0)">
        <Circle cx={67} cy={67} r={67} fill={items[1].color} />
        <Text
          x={67}
          y={67}
          dy={-15}
          fill="#362F26"
          fontSize={14}
          lineHeight={14}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[1].type}`)}
        </Text>
        <Text
          x={67}
          y={67}
          dy={18}
          fill="#362F26"
          fontSize={20}
          lineHeight={20}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[1].percent))}
        </Text>
      </Group>
      <Group transform="translate(58, 90)">
        <Circle cx={67} cy={67} r={67} fill={items[2].color} />
        <Text
          x={67}
          y={67}
          dy={-15}
          fill="#362F26"
          fontSize={14}
          lineHeight={14}
          fontWeight={400}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {t(`ncp.cloudType.${items[2].type}`)}
        </Text>
        <Text
          x={67}
          y={67}
          dy={18}
          fill="#362F26"
          fontSize={20}
          lineHeight={20}
          fontWeight={600}
          textAnchor="middle"
          verticalAnchor="middle"
        >
          {formatPercent(Math.round(items[2].percent))}
        </Text>
      </Group>
    </svg>
  );
}

export default function DashboardCloudTypeChart({ items }: WrapperProps) {
  if (
    items[0].amount === items[1].amount &&
    items[0].amount === items[2].amount
  ) {
    return <EqualChart items={items} />;
  }
  if (!items[2].amount && items[1].amount) {
    if (Math.round(items[0].percent) < 60) {
      return <DualEqualChart items={items} />;
    }
    return <DualDifferentialChart items={items} />;
  }
  return <DifferentialChart items={items} />;
}
