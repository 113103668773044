import * as Yup from 'yup';

export function useUserInformationForm(): UseFormValidation<UpdateUser> {
  return {
    initialValues: {
      FirstName: '',
      PhoneNumber: '',
      Department: '',
      Title: '',
      Organizations: [],
    },
    validationSchema: Yup.object().shape({
      FirstName: Yup.string().defined(),
      PhoneNumber: Yup.string(),
      Department: Yup.string(),
      Title: Yup.string(),
      Organizations: Yup.array(Yup.string()),
    }),
  };
}
