import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import _ from 'lodash';
import { checkZeroNumber } from '@utils/formatter';
import { AWSCloudUsage } from './aws';
import { DeleteNCPCloudUsageModal, EditNCPCloudUsageModal } from './ncp';
import { EditNHNCloudUsageModal } from './nhn';
import RestoreCloudUsageModal from './RestoreCloudUsageModal';
import AddServiceFromCloudModal from './AddServiceFromCloudModal';
import {
  InvoiceAccordion,
  InvoiceCloudProduct,
  InvoiceCloudProductFamily,
  InvoiceCloudRegion,
  InvoiceCloudService,
} from './styled';
import { INVOICE_EXCLUDE_ZERO_STATE } from '../atom';

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceCloudUsage({ Invoices }: Props) {
  const { t } = useTranslation('invoice');
  const { t: ncpT } = useTranslation('ncp');
  const excludeZero = useRecoilValue(INVOICE_EXCLUDE_ZERO_STATE);
  const [toNCPEdit, setToNCPEdit] = useState<
    NCPCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const [toNCPDelete, setToNCPDelete] = useState<
    DeleteNCPCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const [toNHNEdit, setToNHNEdit] = useState<
    NHNCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const [toRestore, setToRestore] = useState<
    BaseCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const [toAddService, setToAddService] = useState<
    AddServiceModalProps['Data'] | undefined
  >(undefined);
  const awsInvoices = useMemo(() => {
    return Invoices.filter((v) => v.CSP === 'aws');
  }, [Invoices]);
  const ncpInvoices = useMemo(() => {
    return Invoices.filter((v) => v.CSP === 'ncp');
  }, [Invoices]);
  const nhnInvoices = useMemo<Array<NHNInvoice>>(() => {
    return Invoices.filter((v) => v.CSP === 'nhn') as Array<NHNInvoice>;
  }, [Invoices]);
  const handleNCPEdit = useCallback(
    (AccountId: string, ServiceCode: string, Region: string) => () => {
      setToNCPEdit({
        OrganizationId: Invoices[0].OrganizationId,
        InvoiceId: Invoices[0].InvoiceId,
        AccountId,
        ServiceCode,
        Region,
      });
    },
    [Invoices],
  );
  const handleNCPDelete = useCallback(
    (AccountId: string, ServiceCode: string, Region: string) => () => {
      setToNCPDelete({
        OrganizationId: Invoices[0].OrganizationId,
        InvoiceId: Invoices[0].InvoiceId,
        AccountId,
        ServiceCode,
        Region,
      });
    },
    [Invoices],
  );
  const handleNHNEdit = useCallback(
    (
      AccountId: string,
      ServiceCode: string,
      Region: string,
      Product: string,
      Resource: string,
      ResourceName: string,
    ) =>
      () => {
        setToNHNEdit({
          OrganizationId: Invoices[0].OrganizationId,
          InvoiceId: Invoices[0].InvoiceId,
          AccountId,
          ServiceCode,
          Region,
          Product,
          Resource,
          ResourceName,
        });
      },
    [Invoices],
  );
  const handleRestore = useCallback(() => {
    setToRestore({
      OrganizationId: Invoices[0].OrganizationId,
      InvoiceId: Invoices[0].InvoiceId,
    });
  }, [Invoices]);
  const handleAddService = useCallback(
    (
      CSP: CloudProvider,
      AccountId: string,
      ServiceCode: string,
      Region: string,
      Product: string,
    ) =>
      () => {
        setToAddService({
          CSP,
          AccountId,
          ServiceCode,
          Region,
          Product,
        });
      },
    [],
  );
  const getNCPDescription = useCallback(
    (instance: Record<string, any>, product: string) => {
      const extra = _.get(instance, 'DescriptionExtra');
      return _.isEmpty(extra) ? product : `${product} (${extra})`;
    },
    [],
  );
  const getNHNResourceName = useCallback(
    (instance: Record<string, any>, product: string) => {
      let name = product;
      _.keys(instance).forEach((key) => {
        if (key === 'ResourceTotalCost') return;
        const resourceId = _.get(instance[key], 'ResourceId');
        if (resourceId === product) {
          name = _.get(instance[key], 'ResourceName');
        }
      });
      return name;
    },
    [],
  );
  return (
    <>
      <Stack spacing="16px">
        {awsInvoices
          .filter((v) => !checkZeroNumber(v.CloudCost.USD, 2) || !excludeZero)
          .map((invoice) => (
            <AWSCloudUsage
              key={`invoice-cloud-usage-aws-${invoice.AccountId}`}
              AccountId={invoice.AccountId}
              InvoiceId={invoice.InvoiceId}
              ExcludeZero={excludeZero}
            />
          ))}
        {ncpInvoices
          .filter((v) => !checkZeroNumber(v.CloudCost.KRW) || !excludeZero)
          .map((invoice) => (
            <InvoiceAccordion
              key={`invoice-cloud-usage-ncp-${invoice.AccountId}`}
              csp="ncp"
              accountId={invoice.AccountId}
              cost={invoice.CloudCost.KRW}
            >
              {Object.keys(invoice.Data)
                .filter(
                  (v) =>
                    v !== 'MemberTotalCost' &&
                    (!checkZeroNumber(
                      _.get(invoice.Data, `${v}.ProductTotalCost`),
                    ) ||
                      !excludeZero),
                )
                .map((service) => (
                  <InvoiceCloudService
                    key={`invoice-cloud-${invoice.AccountId}-${service}`}
                    name={service}
                    currency="KRW"
                    cost={
                      _.get(
                        invoice.Data,
                        `${service}.ProductTotalCost`,
                      ) as number
                    }
                  >
                    {Object.keys(invoice.Data[service])
                      .filter(
                        (v) =>
                          v !== 'ProductTotalCost' &&
                          (!checkZeroNumber(
                            _.get(
                              invoice.Data,
                              `${service}.${v}.RegionTotalCost`,
                            ),
                          ) ||
                            !excludeZero),
                      )
                      .map((region) => (
                        <InvoiceCloudRegion
                          key={`invoice-cloud-ncp-${invoice.AccountId}-${service}-${region}`}
                          name={ncpT(`region.${region as NCPRegion}`)}
                          currency="KRW"
                          cost={
                            _.get(
                              invoice.Data,
                              `${service}.${region}.RegionTotalCost`,
                            ) as number
                          }
                        >
                          {Object.keys(invoice.Data[service][region])
                            .filter(
                              (v) =>
                                v !== 'RegionTotalCost' &&
                                (!checkZeroNumber(
                                  _.get(
                                    invoice.Data,
                                    `${service}.${region}.${v}.DescriptionTotalCost`,
                                  ),
                                ) ||
                                  !excludeZero),
                            )
                            .map((product) => (
                              <InvoiceCloudProduct
                                key={`invoice-cloud-ncp-${invoice.AccountId}-${service}-${region}-${product}`}
                                name={product}
                                // TODO: Editing NCP usage disabled
                                // onEdit={handleNCPEdit(
                                //   invoice.AccountId,
                                //   service,
                                //   region,
                                // )}
                                // onDelete={handleNCPDelete(
                                //   invoice.AccountId,
                                //   service,
                                //   region,
                                // )}
                                onAddService={handleAddService(
                                  'ncp',
                                  invoice.AccountId,
                                  service,
                                  region,
                                  product,
                                )}
                              >
                                {Object.keys(
                                  invoice.Data[service][region][product],
                                )
                                  .filter(
                                    (v) =>
                                      v !== 'DescriptionTotalCost' &&
                                      (!checkZeroNumber(
                                        _.get(
                                          invoice.Data,
                                          `${service}.${region}.${product}.${v}.Cost`,
                                        ),
                                      ) ||
                                        !excludeZero),
                                  )
                                  .map((instance) => (
                                    <InvoiceCloudProductFamily
                                      key={`invoice-cloud-ncp-${invoice.AccountId}-${service}-${region}-${product}-${instance}`}
                                      name={getNCPDescription(
                                        invoice.Data[service][region][product][
                                          instance
                                        ],
                                        product,
                                      )}
                                      currency="KRW"
                                      cost={
                                        _.get(
                                          invoice.Data[service][region][
                                            product
                                          ][instance],
                                          'Cost',
                                        ) as number
                                      }
                                      usage={
                                        _.get(
                                          invoice.Data[service][region][
                                            product
                                          ][instance],
                                          'UsageAmount',
                                        ) as number
                                      }
                                      unit={_.get(
                                        invoice.Data[service][region][product][
                                          instance
                                        ],
                                        'PricingUnit',
                                      )}
                                    />
                                  ))}
                              </InvoiceCloudProduct>
                            ))}
                        </InvoiceCloudRegion>
                      ))}
                  </InvoiceCloudService>
                ))}
            </InvoiceAccordion>
          ))}
        {nhnInvoices
          .filter((v) => !checkZeroNumber(v.CloudCost.KRW) || !excludeZero)
          .map((invoice) => (
            <InvoiceAccordion
              key={`invoice-cloud-usage-nhn-${invoice.AccountId}`}
              csp="nhn"
              accountId={invoice.OriginData.ProjectName}
              cost={invoice.CloudCost.KRW}
            >
              {Object.keys(invoice.Data)
                .filter(
                  (v) =>
                    v !== 'ProjectTotalCost' &&
                    (!checkZeroNumber(
                      _.get(invoice.Data, `${v}.ServiceTotalCost`),
                    ) ||
                      !excludeZero),
                )
                .map((service) => (
                  <InvoiceCloudService
                    key={`invoice-cloud-${invoice.AccountId}-${service}`}
                    name={service}
                    currency="KRW"
                    cost={
                      _.get(
                        invoice.Data,
                        `${service}.ServiceTotalCost`,
                      ) as number
                    }
                  >
                    {Object.keys(invoice.Data[service])
                      .filter(
                        (v) =>
                          v !== 'ServiceTotalCost' &&
                          (!checkZeroNumber(
                            _.get(
                              invoice.Data,
                              `${service}.${v}.RegionTotalCost`,
                            ),
                          ) ||
                            !excludeZero),
                      )
                      .map((region) => (
                        <InvoiceCloudRegion
                          key={`invoice-cloud-nhn-${invoice.AccountId}-${service}-${region}`}
                          name={region}
                          currency="KRW"
                          cost={
                            _.get(
                              invoice.Data,
                              `${service}.${region}.RegionTotalCost`,
                            ) as number
                          }
                        >
                          {Object.keys(invoice.Data[service][region])
                            .filter(
                              (v) =>
                                v !== 'RegionTotalCost' &&
                                (!checkZeroNumber(
                                  _.get(
                                    invoice.Data,
                                    `${service}.${region}.${v}.ProductTotalCost`,
                                  ),
                                ) ||
                                  !excludeZero),
                            )
                            .map((product) => (
                              <InvoiceCloudProduct
                                key={`invoice-cloud-nhn-${invoice.AccountId}-${service}-${region}-${product}`}
                                name={product}
                                disablePadding
                              >
                                {Object.keys(
                                  invoice.Data[service][region][product],
                                )
                                  .filter(
                                    (v) =>
                                      v !== 'ProductTotalCost' &&
                                      (!checkZeroNumber(
                                        _.get(
                                          invoice.Data,
                                          `${service}.${region}.${product}.${v}.ResourceTotalCost`,
                                        ),
                                      ) ||
                                        !excludeZero),
                                  )
                                  .map((resource) => (
                                    <InvoiceCloudProduct
                                      key={`invoice-cloud-nhn-${invoice.AccountId}-${service}-${region}-${product}-${resource}`}
                                      name={getNHNResourceName(
                                        invoice.Data[service][region][product][
                                          resource
                                        ],
                                        resource,
                                      )}
                                      onEdit={handleNHNEdit(
                                        invoice.AccountId,
                                        service,
                                        region,
                                        product,
                                        resource,
                                        getNHNResourceName(
                                          invoice.Data[service][region][
                                            product
                                          ][resource],
                                          resource,
                                        ),
                                      )}
                                    >
                                      {Object.keys(
                                        invoice.Data[service][region][product][
                                          resource
                                        ],
                                      )
                                        .filter(
                                          (v) =>
                                            v !== 'ResourceTotalCost' &&
                                            (!checkZeroNumber(
                                              _.get(
                                                invoice.Data,
                                                `${service}.${region}.${product}.${resource}.${v}.Cost`,
                                              ),
                                            ) ||
                                              !excludeZero),
                                        )
                                        .map((instance) => (
                                          <InvoiceCloudProductFamily
                                            key={`invoice-cloud-nhn-${invoice.AccountId}-${service}-${region}-${product}-${instance}`}
                                            name={_.get(
                                              invoice.Data[service][region][
                                                product
                                              ][resource][instance],
                                              'DisplayName',
                                            )}
                                            currency="KRW"
                                            cost={
                                              _.get(
                                                invoice.Data[service][region][
                                                  product
                                                ][resource][instance],
                                                'Cost',
                                              ) as number
                                            }
                                            usage={
                                              _.get(
                                                invoice.Data[service][region][
                                                  product
                                                ][resource][instance],
                                                'UsageAmount',
                                              ) as number
                                            }
                                            unit={_.get(
                                              invoice.Data[service][region][
                                                product
                                              ][resource][instance],
                                              'PricingUnit',
                                            )}
                                            originCost={_.get(
                                              invoice.Data[service][region][
                                                product
                                              ][resource][instance],
                                              'UsageCost',
                                            )}
                                            discountRate={_.get(
                                              invoice.Data[service][region][
                                                product
                                              ][resource][instance],
                                              'DiscountRate',
                                            )}
                                          />
                                        ))}
                                    </InvoiceCloudProduct>
                                  ))}
                              </InvoiceCloudProduct>
                            ))}
                        </InvoiceCloudRegion>
                      ))}
                  </InvoiceCloudService>
                ))}
            </InvoiceAccordion>
          ))}
      </Stack>
      {/*<EditNCPCloudUsageModal*/}
      {/*  open={typeof toNCPEdit !== 'undefined'}*/}
      {/*  onClose={() => setToNCPEdit(undefined)}*/}
      {/*  Invoices={Invoices}*/}
      {/*  Data={toNCPEdit}*/}
      {/*/>*/}
      {/*<DeleteNCPCloudUsageModal*/}
      {/*  open={typeof toNCPDelete !== 'undefined'}*/}
      {/*  onClose={() => setToNCPDelete(undefined)}*/}
      {/*  Data={toNCPDelete}*/}
      {/*/>*/}
      <EditNHNCloudUsageModal
        open={typeof toNHNEdit !== 'undefined'}
        onClose={() => setToNHNEdit(undefined)}
        Invoices={Invoices}
        Data={toNHNEdit}
      />
      <RestoreCloudUsageModal
        open={typeof toRestore !== 'undefined'}
        onClose={() => setToRestore(undefined)}
        Data={toRestore}
      />
      <AddServiceFromCloudModal
        open={typeof toAddService !== 'undefined'}
        onClose={() => setToAddService(undefined)}
        Invoices={Invoices}
        Data={toAddService}
      />
    </>
  );
}
