export const GET_PAYER_ACCOUNTS = ['AWS_ACCOUNT', 'GET_PAYER_ACCOUNTS'];
export const GET_PAYER_ACCOUNT_BY_ID = [
  'AWS_ACCOUNT',
  'GET_PAYER_ACCOUNT_BY_ID',
];
export const CREATE_PAYER_ACCOUNT = ['AWS_ACCOUNT', 'CREATE_PAYER_ACCOUNT'];
export const UPDATE_PAYER_ACCOUNT = ['AWS_ACCOUNT', 'UPDATE_PAYER_ACCOUNT'];
export const DELETE_PAYER_ACCOUNT = ['AWS_ACCOUNT', 'DELETE_PAYER_ACCOUNT'];
export const GET_CUR_LIST = ['AWS_ACCOUNT', 'GET_CUR_LIST'];
export const CREATE_CUR = ['AWS_ACCOUNT', 'CREATE_CUR'];
export const GET_EXTERNAL_ID = ['AWS_ACCOUNT', 'GET_EXTERNAL_ID'];
export const GET_NOT_CONFIGURED_ACCOUNT = [
  'AWS_ACCOUNT',
  'GET_NOT_CONFIGURED_ACCOUNT',
];
export const GET_NOT_CONFIGURED_ACCOUNT_INTERVAL = [
  'AWS_ACCOUNT',
  'GET_NOT_CONFIGURED_ACCOUNT_INTERVAL',
];
export const GET_ORGANIZATION_ACCOUNTS = [
  'AWS_ACCOUNT',
  'GET_ORGANIZATION_ACCOUNTS',
];
export const GET_ORGANIZATION_ACCOUNTS_WITH_ALIAS = [
  'AWS_ACCOUNT',
  'GET_ORGANIZATION_ACCOUNTS_WITH_ALIAS',
];
export const GET_LINKED_ACCOUNTS = ['AWS_ACCOUNT', 'GET_LINKED_ACCOUNTS'];
export const UPDATE_LINKED_ACCOUNTS = ['AWS_ACCOUNT', 'UPDATE_LINKED_ACCOUNTS'];
export const UPDATE_LINKED_ACCOUNTS_BY_ID = [
  'AWS_ACCOUNT',
  'UPDATE_LINKED_ACCOUNTS_BY_ID',
];
