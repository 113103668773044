import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useUpdateUser } from '@api/queries/user';
import { useCurrentInfo } from '@hooks';
import LargeModal from '@components/modal/large';
import { RoleTile } from './styled';

type Props = ModalProps & {
  UID?: string;
  currentRole?: UserRole;
  isClient?: boolean;
};
export default function ChangeRoleModal({
  open,
  onClose,
  UID,
  currentRole = 'ADMIN',
}: Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.permission.role',
  });
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const { hasRole } = useCurrentInfo(true);
  const { mutateAsync, isPending } = useUpdateUser();
  const { values, isValid, dirty, handleSubmit, setFieldValue, resetForm } =
    useFormik<{
      Role: UserRole | '';
      Redirect: boolean;
    }>({
      initialValues: {
        Role: '',
        Redirect: true,
      },
      validationSchema: Yup.object().shape({
        Role: Yup.mixed<UserRole>().defined(),
        Redirect: Yup.bool(),
      }),
      onSubmit: async (v) => {
        if (!_.isEmpty(UID) && !_.isEmpty(v.Role)) {
          try {
            await mutateAsync({
              UID: UID!,
              Role: v.Role,
            });
            if (v.Redirect && v.Role !== 'ADMIN') {
              navigate(
                `/settings/${
                  v.Role === 'MANAGER' ? 'permission' : 'customer'
                }/${UID}`,
              );
            }
            onClose();
          } catch (e) {
            console.error(e);
          }
        }
      },
    });
  const isNotAdmin = useMemo(() => {
    return !_.isEmpty(values.Role) && values.Role !== 'ADMIN';
  }, [values.Role]);
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
      }, 500);
    }
  }, [open]);
  if (!UID) {
    return null;
  }
  return (
    <LargeModal
      open={open}
      onClose={onClose}
      type="form"
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      maxWidth="560px"
      minHeight="468px"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
      Actions={
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: isNotAdmin ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {isNotAdmin && (
            <FormControlLabel
              name="Redirect"
              value={values.Redirect}
              onChange={(e, v) => setFieldValue('Redirect', v)}
              control={<Checkbox color="emphasis" />}
              label={t('option')}
            />
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <Button color="cancel" onClick={onClose}>
              {globalT('button.goBack')}
            </Button>
            <LoadingButton
              type="submit"
              color="emphasis1"
              loading={isPending}
              disabled={!isValid || !dirty}
            >
              {t('cta')}
            </LoadingButton>
          </Box>
        </Box>
      }
    >
      <Box sx={{ flex: 1 }}>
        <RadioGroup
          name="Role"
          value={values.Role}
          onChange={(e, v) => setFieldValue('Role', v)}
          row
          sx={{ minWidth: '100%' }}
        >
          <RoleTile
            value="ADMIN"
            disabled={!hasRole('ADMIN') || currentRole === 'ADMIN'}
          />
          <RoleTile
            value="MANAGER"
            disabled={!hasRole('MANAGER') || currentRole === 'MANAGER'}
          />
          <RoleTile
            value="CLIENT_USER"
            disabled={currentRole === 'CLIENT_USER'}
          />
        </RadioGroup>
        {!_.isEmpty(values.Role) && (
          <Typography mt="40px">{t(`help.${values.Role}`)}</Typography>
        )}
      </Box>
    </LargeModal>
  );
}
