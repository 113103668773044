import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();

async function login({ Email, Password, Refresh }: LoginRequest) {
  const { data } = await axios.post<SingleResponse<LoginResponse>>(
    '/users/login',
    {
      Email,
      Password,
      Refresh: Refresh || false,
    },
  );
  return data;
}

async function logout() {
  const { data } = await axios.get('/users/logout');
  return data;
}

async function register({
  Email,
  Password,
  FirstName,
  LastName,
  PhoneNumber,
  Name,
  Language,
}: RegisterRequest) {
  const { data } = await axios.post<SingleResponse<RegisterResponse>>(
    '/company',
    {
      Email,
      Password,
      FirstName,
      LastName,
      PhoneNumber,
      Name,
      Language: Language || 'ko',
    },
  );
  return data;
}

async function forgotPassword(email: string) {
  const { data } = await axios.get(`/users/reset_password/${email}`);
  return data;
}

async function adminResetPassword(email: string) {
  const { data } = await axios.get(`/users/reset_password/request/${email}`);
  return data;
}

async function resetPassword({
  Token,
  Email,
  Password,
}: ResetPasswordRequest): Promise<SingleResponse<User>> {
  const { data } = await axios.post(`/users/reset_password/${Token}`, {
    Email,
    Password,
  });
  return data;
}

async function sendVerification({
  email,
  Language,
}: CreateVerificationRequest) {
  const { data } = await axios.post<SingleResponse<EmailVerificationResponse>>(
    '/verification/send',
    {
      email,
      Language,
    },
  );
  return data;
}

async function confirmVerification({
  email,
  code,
}: ConfirmVerificationRequest) {
  const { data } = await axios.post('/verification/confirm', {
    email,
    code,
  });
  return data;
}

async function initialPassword({
  UID,
  Token,
  Email,
  Password,
}: InitialPasswordRequest) {
  const { data } = await axios.post<SingleResponse<LoginResponse>>(
    `/users/set_password`,
    {
      UID,
      Token,
      Email,
      Password,
    },
  );
  return data;
}

export default {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  adminResetPassword,
  sendVerification,
  confirmVerification,
  initialPassword,
};
