import AxiosClient from '@api/client';
import { getFilenameFromHeader } from '@utils/blob';
import { formatDate } from '@utils/formatter';

const axios = AxiosClient.getInstance();
const PREFIX = '/nhn/sales' as const;

function extractFilterData(filter: NHNSalesFilter) {
  return {
    StartDate: formatDate(filter.StartDate, 'YYYY-MM-01T00:00:00'),
    EndDate: formatDate(filter.EndDate, 'YYYY-MM-01T00:00:00'),
    ProjectId: filter.ProjectId?.map((v) => v.UID),
    Organizations: filter.Organizations?.map((v) => v.UID),
  };
}

async function getNHNSalesTable(filter: NHNSalesFilter) {
  const { data } = await axios.post<MultipleResponse<NHNSalesItem>>(
    `${PREFIX}/table`,
    extractFilterData(filter),
  );
  return data;
}

async function getNHNSalesGraph(filter: NHNSalesFilter) {
  const { data } = await axios.post<MultipleResponse<SalesGraphItem>>(
    `${PREFIX}/graph`,
    extractFilterData(filter),
  );
  return data;
}

async function getNHNSalesAutoComplete() {
  const { data } = await axios.get<SingleResponse<NHNSalesAutoComplete>>(
    `${PREFIX}/acdata`,
  );
  return data;
}

async function downloadNHNSalesAsExcel(filter: NHNSalesFilter) {
  const { data, headers } = await axios.post(
    `${PREFIX}/excel`,
    extractFilterData(filter),
    {
      responseType: 'blob',
    },
  );
  const filename = getFilenameFromHeader(headers);
  return { data, filename };
}

export default {
  getNHNSalesTable,
  getNHNSalesGraph,
  getNHNSalesAutoComplete,
  downloadNHNSalesAsExcel,
};
