import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function RegisterDonePage() {
  const { t } = useTranslation('auth');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel title={t('registerDone.title')} />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
              '> img': {
                width: '100%',
                maxWidth: '536px',
                height: 'auto',
              },
            }}
          >
            <img src="/assets/img/bg-register.svg" alt="Image" />
            <Typography
              dangerouslySetInnerHTML={{ __html: t('registerDone.content') }}
            />
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        {/* TODO: User Customization Page */}
        <Button href="/onboard" color="emphasis1">
          {t('registerDone.cta')}
        </Button>
      </BottomActions>
    </Box>
  );
}
