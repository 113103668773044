import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY, USER_KEY } from '@constants/query';

export default function useUpdateCompany() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: COMPANY_KEY.UPDATE_COMPANY,
    mutationFn: (v: UpdateCompany) => CompanyService.updateCompany(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['COMPANY'] });
      queryClient.invalidateQueries({
        queryKey: USER_KEY.GET_CURRENT_USER,
      });
    },
  });
}
