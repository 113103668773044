import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useGetInvoiceByAccountId } from '@api/queries/invoice';
import { checkZeroNumber } from '@utils/formatter';
import EditAWSCloudUsageModal from './EditAWSCloudUsageModal';
import DeleteAWSCloudUsageModal from './DeleteAWSCloudUsageModal';
import {
  InvoiceAccordion,
  InvoiceCloudProduct,
  InvoiceCloudProductFamily,
  InvoiceCloudRegion,
  InvoiceCloudService,
} from '../styled';

type Props = {
  InvoiceId: string;
  AccountId: string;
  ExcludeZero: boolean;
};
export default function AWSCloudUsage({
  InvoiceId,
  AccountId,
  ExcludeZero,
}: Props) {
  const {
    data: invoice,
    setInvoiceId,
    setAccountId,
  } = useGetInvoiceByAccountId(InvoiceId, AccountId);
  useEffect(() => {
    if (InvoiceId) {
      setInvoiceId(InvoiceId);
    }
  }, [InvoiceId]);
  useEffect(() => {
    if (AccountId) {
      setAccountId(AccountId);
    }
  }, [AccountId]);
  const [toEdit, setToEdit] = useState<
    AWSCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const [toDelete, setToDelete] = useState<
    DeleteAWSCloudUsageModalProps['Data'] | undefined
  >(undefined);
  const handleAWSEdit = useCallback(
    (
      AccountId: string,
      ServiceCode: string,
      Region: string,
      Product: string,
      ProductUsage: string,
    ) =>
      () => {
        if (invoice?.data) {
          setToEdit({
            OrganizationId: invoice.data.OrganizationId,
            InvoiceId: invoice.data.InvoiceId,
            AccountId,
            ServiceCode,
            Region,
            Product,
            ProductUsage,
          });
        }
      },
    [invoice?.data],
  );
  const handleAWSDelete = useCallback(
    (
      AccountId: string,
      ServiceCode: string,
      Region: string,
      Product: string,
      ProductUsage: string,
    ) =>
      () => {
        if (invoice?.data) {
          setToDelete({
            OrganizationId: invoice.data.OrganizationId,
            InvoiceId: invoice.data.InvoiceId,
            AccountId,
            ServiceCode,
            Region,
            Product,
            ProductUsage,
          });
        }
      },
    [invoice?.data],
  );
  if (!invoice?.data) {
    return null;
  }
  return (
    <>
      <InvoiceAccordion
        csp="aws"
        accountId={invoice.data.AccountId}
        cost={invoice.data.CloudCost.KRW}
      >
        {Object.keys(invoice.data.Data)
          .filter(
            (v) =>
              v !== 'AccountTotalCost' &&
              (!checkZeroNumber(
                _.get(invoice.data.Data, `${v}.ServiceTotalCost`),
                2,
              ) ||
                !ExcludeZero),
          )
          .map((service) => (
            <InvoiceCloudService
              key={`invoice-cloud-aws-${invoice.data.AccountId}-${service}`}
              name={service}
              cost={
                _.get(
                  invoice.data.Data,
                  `${service}.ServiceTotalCost`,
                ) as number
              }
            >
              {Object.keys(invoice.data.Data[service])
                .filter(
                  (v) =>
                    v !== 'ServiceTotalCost' &&
                    (!checkZeroNumber(
                      _.get(
                        invoice.data.Data,
                        `${service}.${v}.RegionTotalCost`,
                      ),
                      2,
                    ) ||
                      !ExcludeZero),
                )
                .map((region) => (
                  <InvoiceCloudRegion
                    key={`invoice-cloud-aws-${invoice.data.AccountId}-${service}-${region}`}
                    name={region}
                    cost={
                      _.get(
                        invoice.data.Data,
                        `${service}.${region}.RegionTotalCost`,
                      ) as number
                    }
                  >
                    {Object.keys(invoice.data.Data[service][region])
                      .filter(
                        (v) =>
                          v !== 'RegionTotalCost' &&
                          (!checkZeroNumber(
                            _.get(
                              invoice.data.Data,
                              `${service}.${region}.${v}.ProductTotalCost`,
                            ),
                            2,
                          ) ||
                            !ExcludeZero),
                      )
                      .map((product) =>
                        Object.keys(invoice.data.Data[service][region][product])
                          .filter(
                            (v) =>
                              v !== 'ProductTotalCost' &&
                              (!checkZeroNumber(
                                _.get(
                                  invoice.data.Data,
                                  `${service}.${region}.${product}.${v}.ProductFamilyTotalCost`,
                                ),
                                2,
                              ) ||
                                !ExcludeZero),
                          )
                          .map((family) => (
                            <InvoiceCloudProduct
                              key={`invoice-cloud-aws-${invoice.data.AccountId}-${service}-${region}-${product}-${family}`}
                              name={service === 'Others' ? product : family}
                              onEdit={handleAWSEdit(
                                invoice.data.AccountId,
                                service,
                                region,
                                product,
                                family,
                              )}
                              onDelete={handleAWSDelete(
                                invoice.data.AccountId,
                                service,
                                region,
                                product,
                                family,
                              )}
                            >
                              {Object.keys(
                                invoice.data.Data[service][region][product][
                                  family
                                ],
                              )
                                .filter(
                                  (v) =>
                                    v !== 'ProductFamilyTotalCost' &&
                                    (!checkZeroNumber(
                                      _.get(
                                        invoice.data.Data,
                                        `${service}.${region}.${product}.${family}.${v}.Cost`,
                                      ),
                                      2,
                                    ) ||
                                      !ExcludeZero),
                                )
                                .map((usage) => (
                                  <InvoiceCloudProductFamily
                                    key={`invoice-cloud-aws-${invoice.data.AccountId}-${service}-${region}-${product}-${family}-${usage}`}
                                    name={usage}
                                    cost={
                                      _.get(
                                        invoice.data.Data[service][region][
                                          product
                                        ][family][usage],
                                        'Cost',
                                      ) as number
                                    }
                                    usage={
                                      _.get(
                                        invoice.data.Data[service][region][
                                          product
                                        ][family][usage],
                                        'UsageAmount',
                                      ) as number
                                    }
                                    unit={_.get(
                                      invoice.data.Data[service][region][
                                        product
                                      ][family][usage],
                                      'PricingUnit',
                                    )}
                                  />
                                ))}
                            </InvoiceCloudProduct>
                          )),
                      )}
                  </InvoiceCloudRegion>
                ))}
            </InvoiceCloudService>
          ))}
      </InvoiceAccordion>
      <EditAWSCloudUsageModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        Invoice={invoice.data}
        Data={toEdit}
      />
      <DeleteAWSCloudUsageModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        Data={toDelete}
      />
    </>
  );
}
