import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type AWSCURForm = {
  Type: 'exist' | 'create' | '';
  SelectedReport?: string;
  ReportName?: string;
  BucketName?: string;
  S3Region?: string;
};
export function useAWSCURForm(): UseFormValidation<AWSCURForm> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Type: '',
      SelectedReport: ' ',
      ReportName: '',
      BucketName: '',
      S3Region: 'ap-northeast-2',
    },
    validationSchema: Yup.object().shape({
      Type: Yup.string().strict(false).oneOf(['create', 'exist']),
      SelectedReport: Yup.string().when('Type', ([Type], schema) => {
        return Type === 'exist'
          ? schema
              .strict(false)
              .trim()
              .matches(/^[^ ][\w\W ]*[^ ]/, t('common.required.base'))
              .defined(t('common.required.base'))
          : schema;
      }),
      ReportName: Yup.string().when('Type', ([Type], schema) => {
        return Type === 'create'
          ? schema
              .strict(false)
              .trim()
              .matches(/^[a-zA-Z\d!\-_.*'()]+$/, t('onboard.aws.report.name'))
              .defined(t('common.required.base'))
          : schema;
      }),
      BucketName: Yup.string().when('Type', ([Type], schema) => {
        return Type === 'create'
          ? schema
              .strict(false)
              .trim()
              .min(3, t('onboard.aws.bucket.length'))
              .max(63, t('onboard.aws.bucket.length'))
              .matches(/^[a-z\d.-]*$/, t('onboard.aws.bucket.consist'))
              .matches(/^[a-z\d].*[a-z\d]$/, t('onboard.aws.bucket.beginEnd'))
              .matches(/^(?!xn--).*$/, t('onboard.aws.bucket.xn'))
              .matches(
                /^.*((?!-s3alias).{8}|^.{0,7})$/,
                t('onboard.aws.bucket.s3alias'),
              )
              .test('matches', t('onboard.aws.bucket.ip'), (value) => {
                if (value) {
                  return !value.match(
                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                  );
                }
                return true;
              })
              .defined(t('common.required.base'))
          : schema;
      }),
      S3Region: Yup.string().when('Type', ([Type], schema) => {
        return Type === 'create'
          ? schema.strict(false).trim().defined(t('common.required.base'))
          : schema;
      }),
    }),
  };
}
