import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { SuspenseBox, SuspensePaper } from '@components/suspense';
import { CustomerTable, OrgCountByStatus } from '@features/customer/list';

export default function CustomerPage() {
  const { t } = useTranslation('customer');
  return (
    <Paper sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
            {t('title.statusSummary')}
          </Typography>
          <Grid container spacing="24px">
            <Grid tablet={3} zero={12}>
              <SuspensePaper sx={{ p: '16px 24px' }}>
                <OrgCountByStatus status="in-use" />
              </SuspensePaper>
            </Grid>
            <Grid tablet={3} zero={12}>
              <SuspensePaper sx={{ p: '16px 24px' }}>
                <OrgCountByStatus status="suspended" />
              </SuspensePaper>
            </Grid>
            <Grid tablet={3} zero={12}>
              <SuspensePaper sx={{ p: '16px 24px' }}>
                <OrgCountByStatus status="poc" />
              </SuspensePaper>
            </Grid>
            <Grid tablet={3} zero={12}>
              <SuspensePaper sx={{ p: '16px 24px' }}>
                <OrgCountByStatus status="canceled" />
              </SuspensePaper>
            </Grid>
          </Grid>
        </Box>
        <SuspenseBox>
          <CustomerTable />
        </SuspenseBox>
      </Box>
    </Paper>
  );
}
