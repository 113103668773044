import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  DateRange,
  Download,
  FilterList,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  useDownloadInvoiceListAsCsv,
  useGetInvoiceListForCurrentCompany,
} from '@api/queries/invoice';
import SimpleTable from '@components/table/simple';
import { InvoiceChip } from '@components/styled/chip';
import { formatDate, formatKRW } from '@utils/formatter';
import { useGetCompany } from '@api/queries/company';
import { CSPDot, CSPDotContainer } from '@features/invoice/list/table/styled';

type InvoiceTableData = Omit<InvoiceList, 'AccountIdList' | 'Status'> & {
  AccountIdList: string;
  Status: string;
};

export default function InvoiceTable() {
  const { t } = useTranslation('table');
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetInvoiceListForCurrentCompany();
  const { data: company } = useGetCompany();
  const { mutateAsync, isPending } = useDownloadInvoiceListAsCsv();
  const downloadCsv = useCallback(async () => {
    if (company?.data) {
      try {
        const { data: blob, filename } = await mutateAsync(company.data.UID);
        const url = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        const link = document.createElement('a');
        link.id = 'heka_invoice_list_download';
        link.href = url;
        if (filename) {
          link.download = filename;
        }
        const child = document.body.appendChild(link);
        link.click();
        document.body.removeChild(child);
      } catch (e) {
        console.error(e);
      }
    }
  }, [company?.data, mutateAsync]);
  const tableData = useMemo(() => {
    if (response?.data) {
      return response?.data.map(
        (v) =>
          ({
            ...v,
            AccountIdList: v.AccountIdList.join(', '),
            Status: globalT(`chip.invoice.${v.Status}`),
          }) as InvoiceTableData,
      );
    }
    return [];
  }, [response?.data, globalT]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<InvoiceTableData>();
    return [
      columnHelper.accessor('InvoiceId', {
        id: 'InvoiceId',
        header: t('invoice.invoiceId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrganizationName', {
        id: 'OrganizationName',
        header: t('invoice.customerName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Date', {
        id: 'Date',
        header: t('invoice.billingPeriod'),
        cell: (v) => formatDate(v.getValue(), globalT('format.date.yearMonth')),
      }),
      columnHelper.accessor('CSP', {
        id: 'CSP',
        header: 'CSP',
        cell: (v) => {
          const csp = v.getValue();
          if (!csp.length) return null;
          return (
            <CSPDotContainer key={`${v.row.id}_csp`}>
              {csp.map((item) => (
                <CSPDot
                  key={`${v.row.id}_csp_${item}`}
                  csp={item}
                  title={globalT(`csp.${item}`)}
                />
              ))}
            </CSPDotContainer>
          );
        },
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('CloudCost.KRW', {
        id: 'CloudCost',
        header: t('invoice.cloudUsageAmount'),
        cell: (v) => formatKRW(v.getValue()),
        enableGlobalFilter: false,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('AdditionalCost.KRW', {
        id: 'AdditionalCost',
        header: t('invoice.additionalAmount'),
        cell: (v) => formatKRW(v.getValue()),
        enableGlobalFilter: false,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('DiscountCost.KRW', {
        id: 'DiscountCost',
        header: t('invoice.discountAmount'),
        cell: (v) => formatKRW(v.getValue()),
        enableGlobalFilter: false,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalCost.KRW', {
        id: 'TotalCost',
        header: t('invoice.totalBillingAmount'),
        cell: (v) => formatKRW(v.getValue()),
        enableGlobalFilter: false,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('Status', {
        id: 'Status',
        header: t('invoice.status'),
        cell: (v) => {
          switch (v.getValue()) {
            case globalT('chip.invoice.Invoiced'):
              return <InvoiceChip status="Invoiced" />;
            case globalT('chip.invoice.Paid'):
              return <InvoiceChip status="Paid" />;
            case globalT('chip.invoice.Overdue'):
              return <InvoiceChip status="Overdue" />;
            default:
              return <InvoiceChip status="Unissued" />;
          }
        },
      }),
      columnHelper.display({
        id: 'Details',
        header: t('general.details'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            href={`/invoice/${v.row.original.InvoiceId}`}
          >
            <KeyboardArrowRight />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t, globalT]);
  return (
    <>
      <SimpleTable
        size="comfort"
        columns={columns}
        data={tableData}
        enablePagination
        enableGlobalFilter
        enableRoute
      >
        {/*<Button color="sub" startIcon={<DateRange />}>*/}
        {/*  이번 달*/}
        {/*</Button>*/}
        {/*<Button color="sub" startIcon={<FilterList />}>*/}
        {/*  {globalT('button.filter')}*/}
        {/*</Button>*/}
        <LoadingButton
          onClick={downloadCsv}
          loading={isPending}
          startIcon={<Download />}
        >
          {globalT('button.download')}
        </LoadingButton>
      </SimpleTable>
    </>
  );
}
