import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { INVOICE_EXCLUDE_ZERO_STATE } from '@features/invoice/detail/atom';
import FormModal from '@components/modal/form';

type IProps = ModalProps;
export default function CloudUsageOptionModal({ open, onClose }: IProps) {
  const { t: globalT } = useTranslation('global');
  const [excludeZero, setExcludeZero] = useRecoilState(
    INVOICE_EXCLUDE_ZERO_STATE,
  );
  const { values, isValid, dirty, handleChange, handleBlur, handleSubmit } =
    useFormik<{ ExcludeZero: boolean }>({
      initialValues: {
        ExcludeZero: excludeZero,
      },
      validationSchema: Yup.object()
        .shape({
          ExcludeZero: Yup.bool(),
        })
        .defined(),
      onSubmit: (v) => {
        if (v.ExcludeZero !== excludeZero) {
          setExcludeZero(v.ExcludeZero);
        }
        onClose();
      },
      enableReinitialize: true,
    });
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="클라우드 서비스 보기 설정"
      maxWidth="320px"
      minHeight="320px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <Button type="submit" color="emphasis1" disabled={!isValid || !dirty}>
            {globalT('button.apply')}
          </Button>
        </>
      }
      sx={{ alignItems: 'flex-start' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box>
          <InputLabel>필터</InputLabel>
          <FormControlLabel
            name="ExcludeZero"
            value={values.ExcludeZero}
            onChange={handleChange}
            onBlur={handleBlur}
            control={
              <Checkbox
                size="small"
                color="emphasis"
                checked={values.ExcludeZero}
              />
            }
            label="클라우드 요금 없는 항목 제외"
          />
        </Box>
      </Box>
    </FormModal>
  );
}
