import { KeyboardEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, InputAdornment, List } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { MenuItem } from '@heka/theme';
import { useGetOrganizationList } from '@api/queries/organization';
import { FilterInput, PopperComponent } from '../styled';
import { useCurrentInfo } from '@hooks';

type IProps = Pick<
  ReturnType<typeof useFormik<CreateReport>>,
  'values' | 'setFieldValue'
>;
export default function ReportCustomerAutocomplete({
  values,
  setFieldValue,
}: IProps) {
  const { t } = useTranslation('report');
  const { hasRole } = useCurrentInfo(true);
  const { data: organizations } = useGetOrganizationList();
  const setValues = useCallback(
    (newValue: Array<string>) => {
      setFieldValue('Organizations', newValue);
    },
    [setFieldValue],
  );
  const value = useMemo(() => {
    return values.Organizations;
  }, [values.Organizations]);
  const optionsOrigin = useMemo(() => {
    return (organizations?.data ?? []).map((v) => v.UID);
  }, [organizations?.data]);
  const options = useMemo(() => {
    return _.concat(['HEKA_SELECT_ALL'], optionsOrigin);
  }, [optionsOrigin]);
  const isAllSelected = useMemo(() => {
    return optionsOrigin.every((e) => value.includes(e)) ?? false;
  }, [optionsOrigin, value]);
  const isPartialSelected = useMemo(() => {
    return optionsOrigin.some((e) => value.includes(e)) ?? false;
  }, [optionsOrigin, value]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else {
      setValues(optionsOrigin ?? []);
    }
  }, [isAllSelected, setValues, optionsOrigin]);
  const getChecked = useCallback(
    (option: string, selected: boolean) => {
      if (option === 'HEKA_SELECT_ALL') {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: string) => {
      if (option === 'HEKA_SELECT_ALL') {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  const getOptionLabel = useCallback(
    (option: string) => {
      if (option === 'HEKA_SELECT_ALL') {
        return `모든 고객사 (${optionsOrigin.length})`;
      }
      if (organizations?.data.length) {
        const name = organizations?.data.find((v) => v.UID === option)?.Name;
        return name ?? '';
      }
      return option;
    },
    [organizations?.data, optionsOrigin],
  );
  return (
    <Autocomplete
      open
      value={value}
      onChange={(e, v, r) => {
        if (
          e.type === 'keydown' &&
          (e as KeyboardEvent).key === 'Backspace' &&
          r === 'removeOption'
        ) {
          return;
        }
        if (v.find((v) => v === 'HEKA_SELECT_ALL')) {
          toggleAll();
          return;
        }
        setFieldValue('Organizations', v);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={({ className, ...props }, option, { selected }) => {
        return (
          <MenuItem
            {...props}
            key={`report-org-${_.get(props, 'key')}-${_.get(
              props,
              'data-option-index',
            )}`}
            selected={getChecked(option, selected)}
            indeterminate={getIndeterminate(option)}
            sx={{
              py: '12px',
            }}
            multi
          >
            {getOptionLabel(option)}
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <FilterInput
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          placeholder={t('holder.customer', { value: value.length })}
          fullWidth
        />
      )}
      PopperComponent={PopperComponent}
      PaperComponent={Box}
      ListboxComponent={List}
      ListboxProps={{
        sx: {
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        },
      }}
      disableCloseOnSelect
      disableClearable
      multiple
    />
  );
}
