import { useSuspenseQuery } from '@tanstack/react-query';
import { NCPSalesService } from '@api/services';
import { NCP_SALES_KEY } from '@constants/query';

export default function useGetNCPSalesAutoComplete() {
  return useSuspenseQuery({
    queryKey: NCP_SALES_KEY.GET_SALES_AUTOCOMPLETE,
    queryFn: () => NCPSalesService.getNCPSalesAutoComplete(),
  });
}
