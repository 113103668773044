import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Add, KeyboardArrowRight, MoreVert } from '@mui/icons-material';
import { useGetCurrentUser, useGetUsers } from '@api/queries/user';
import SimpleTable from '@components/table/simple';
import { useToggle, useUserHelper } from '@hooks';
import { formatDate } from '@utils/formatter';
import SettingsMemberCreateModal from '../../modal/member/create';
import SettingsMemberPermissionModal from '../../modal/member/permission';
import { UserStatusChip } from '@components/styled/chip';
import ChangeRoleModal from '@features/settings/permission/modal/role';
import ActivateUserModal from '@features/settings/permission/modal/activate';
import ChangePasswordModal from '@features/settings/permission/modal/password';
import DeleteUserModal from '@features/settings/permission/modal/delete';

interface IProps {
  isClient?: boolean;
}
export default function SettingsPermissionMemberTable({
  isClient = false,
}: IProps) {
  const { t } = useTranslation('table');
  const { t: settingsT } = useTranslation('settings');
  const { t: globalT } = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const [tmpUser, setTmpUser] = useState<User | undefined>(undefined);
  const [openRole, toggleRole] = useToggle();
  const [openActivate, toggleActivate] = useToggle();
  const [openPassword, togglePassword] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [toChange, setToChange] = useState<
    | {
        UID: string;
        ToAdmin: boolean;
      }
    | undefined
  >(undefined);
  const [openCreate, toggleCreate] = useToggle();
  const { data: response } = useGetUsers(isClient);
  const { data: current } = useGetCurrentUser();
  const { includesRole } = useUserHelper();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<User>();
    return [
      columnHelper.accessor('FirstName', {
        id: 'Name',
        header: t('general.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Email', {
        id: 'Email',
        header: t('general.email'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('LastLogin', {
        id: 'LastLogin',
        header: t('settings.lastLogin'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD HH:mm') ?? '-',
      }),
      columnHelper.accessor('Role', {
        id: 'Role',
        header: t('general.role'),
        cell: (v) => globalT(`role.${v.getValue()}`),
      }),
      columnHelper.accessor('Deactivate', {
        id: 'Deactivate',
        header: t('settings.memberStatus'),
        cell: (v) => <UserStatusChip deactivated={v.getValue()} />,
      }),
      columnHelper.accessor('Organizations', {
        id: 'Organizations',
        header: t('settings.organizations'),
        cell: (v) => {
          if (includesRole(v.row.original, 'ADMIN')) {
            return t('settings.organization', { context: 'all' });
          }
          return t('settings.organization', {
            context: 'some',
            value: v.getValue().length,
          });
        },
      }),
      columnHelper.display({
        id: 'QuickMenu',
        header: t('general.quickMenu'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={(e) => {
              setTmpUser(v.row.original);
              handleOpenMenu(e);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Details',
        header: t('general.details'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            component={Link}
            to={`/settings/${isClient ? 'customer' : 'permission'}/${
              v.row.original.UID
            }`}
          >
            <KeyboardArrowRight />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t, globalT, includesRole, handleOpenMenu, isClient]);
  return (
    <>
      <SimpleTable
        size="compact"
        columns={columns}
        data={response?.data}
        title={
          isClient
            ? t('title.settings.permission.customer')
            : t('title.settings.permission.member')
        }
        enablePagination
        enableGlobalFilter
      >
        <Button onClick={toggleCreate} startIcon={<Add />}>
          {settingsT('button.inviteMember')}
        </Button>
      </SimpleTable>
      <SettingsMemberCreateModal
        open={openCreate}
        onClose={toggleCreate}
        isClient={isClient}
      />
      <SettingsMemberPermissionModal
        open={typeof toChange !== 'undefined'}
        onClose={() => setToChange(undefined)}
        user={toChange}
      />
      <ChangeRoleModal
        open={openRole && typeof tmpUser !== 'undefined'}
        onClose={() => {
          toggleRole();
          setTmpUser(undefined);
        }}
        UID={tmpUser?.UID}
        currentRole={tmpUser?.Role}
      />
      <ActivateUserModal
        open={openActivate && typeof tmpUser !== 'undefined'}
        onClose={() => {
          toggleActivate();
          setTmpUser(undefined);
        }}
        Data={tmpUser}
      />
      <ChangePasswordModal
        open={openPassword && typeof tmpUser !== 'undefined'}
        onClose={() => {
          togglePassword();
          setTmpUser(undefined);
        }}
        UID={tmpUser?.UID}
      />
      <DeleteUserModal
        open={openDelete && typeof tmpUser !== 'undefined'}
        onClose={() => {
          toggleDelete();
          setTmpUser(undefined);
        }}
        UID={tmpUser?.UID}
      />
      <Menu
        open={menuOpen && typeof tmpUser !== 'undefined'}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              border: 'none',
              borderRadius: '16px',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
              backdropFilter: 'blur(3px)',
            },
          },
        }}
      >
        {!isClient && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              toggleRole();
            }}
          >
            {settingsT('button.changeRole')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            toggleActivate();
          }}
        >
          {settingsT('button.toggleActivation')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            togglePassword();
          }}
        >
          {settingsT('button.resetPassword')}
        </MenuItem>
        {tmpUser?.UID !== current?.data.UID && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              toggleDelete();
            }}
          >
            {settingsT('button.deleteUser')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
