import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ContractService } from '@api/services';
import { CONTRACT_KEY } from '@constants/query';

export default function useGetContracts(UID?: string) {
  const [Id, setId] = useState(UID ?? '');
  const query = useSuspenseQuery({
    queryKey: [...CONTRACT_KEY.GET_CONTRACTS, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) ? ContractService.getContracts(queryKey[2]) : null,
  });
  return { ...query, setId };
}
