import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateNHNAccount } from '@api/queries/nhn/account';
import FormModal from '@components/modal/form';

type Props = {
  item?: NHNAccount;
};
export default function EditNHNAccountModal({
  open,
  onClose,
  item,
}: ModalProps & Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.account.nhn.edit',
  });
  const { t: onboardT } = useTranslation('onboard', {
    keyPrefix: 'integrateNHN',
  });
  const { t: validationT } = useTranslation('validation');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateNHNAccount();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdateNHNAccount>({
    initialValues: {
      UID: '',
      APIPrefix: '',
      HmacSecretKey: '',
      AccessKey: '',
      SecretKey: '',
      AccountName: '',
    },
    validationSchema: Yup.object().shape({
      UID: Yup.string().defined(),
      APIPrefix: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+-[a-zA-Z0-9]{10}-[a-zA-Z0-9]+$/,
          validationT('onboard.nhn.apiPrefix'),
        )
        .defined(validationT('common.required.base')),
      HmacSecretKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{16}$/, validationT('onboard.nhn.hmacSecretKey'))
        .defined(validationT('common.required.base')),
      AccessKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{20}$/, validationT('onboard.nhn.accessKey'))
        .defined(validationT('common.required.base')),
      SecretKey: Yup.string()
        .matches(/^[a-zA-Z0-9]{16}$/, validationT('onboard.nhn.secretKey'))
        .defined(validationT('common.required.base')),
      AccountName: Yup.string(),
    }),
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: {
          UID: item.UID,
          APIPrefix: item.APIPrefix,
          HmacSecretKey: item.HmacSecretKey,
          AccessKey: item.AccessKey,
          SecretKey: item.SecretKey,
          AccountName: item.AccountName,
        },
      });
    }
  }, [item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!item || !isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="APIPrefix"
          value={values.APIPrefix}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.apiPrefix')}
          placeholder={onboardT('form.holder.apiPrefix')}
          error={Boolean(errors.APIPrefix && touched.APIPrefix)}
          helperText={
            Boolean(errors.APIPrefix && touched.APIPrefix) &&
            String(errors.APIPrefix)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="HmacSecretKey"
          value={values.HmacSecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.hmacSecretKey')}
          placeholder={onboardT('form.holder.hmacSecretKey')}
          error={Boolean(errors.HmacSecretKey && touched.HmacSecretKey)}
          helperText={
            Boolean(errors.HmacSecretKey && touched.HmacSecretKey) &&
            String(errors.HmacSecretKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="AccessKey"
          value={values.AccessKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.accessKey')}
          placeholder={onboardT('form.holder.accessKey')}
          error={Boolean(errors.AccessKey && touched.AccessKey)}
          helperText={
            Boolean(errors.AccessKey && touched.AccessKey) &&
            String(errors.AccessKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="SecretKey"
          value={values.SecretKey}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.secretKey')}
          placeholder={onboardT('form.holder.secretKey')}
          error={Boolean(errors.SecretKey && touched.SecretKey)}
          helperText={
            Boolean(errors.SecretKey && touched.SecretKey) &&
            String(errors.SecretKey)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="AccountName"
          value={values.AccountName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.accountName')}
          placeholder={onboardT('form.holder.accountName')}
          error={Boolean(errors.AccountName && touched.AccountName)}
          helperText={
            Boolean(errors.AccountName && touched.AccountName) &&
            String(errors.AccountName)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
      </Box>
    </FormModal>
  );
}
