import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import ReportSingleChart from '@components/chart/report/simple';
import ReportTable from '@components/table/report';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { ReportContentSection } from './styled';

const DUMMY = [
  {
    CSP: 'aws',
    '2323-01': 5,
    '2323-02': 5,
    '2323-03': 5,
    '2323-04': 5,
    '2323-05': 5,
    '2323-06': 5,
  },
];

export default function ReportCustomerTrendSection({
  Summary,
}: Pick<ReportContent, 'Summary'>) {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const pinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, string | number>>();
    return [
      columnHelper.display({
        id: 'TotalCustomers',
        header: '',
        cell: '고객사 수',
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, string | number>>();
    return Summary.map((item) =>
      columnHelper.accessor(formatDate(item.Date, 'YYYY-MM'), {
        id: formatDate(item.Date, 'YYYY-MM'),
        header: formatDate(item.Date, 'YYYY.MM'),
        cell: (v) => formatLocaleString(v.getValue()),
        size: 140,
        minSize: 140,
        meta: {
          align: 'right',
        },
      }),
    );
  }, [Summary]);
  const tableData = useMemo(() => {
    const obj: Record<string, string | number> = {};
    Summary.forEach((item) => {
      obj[formatDate(item.Date, 'YYYY-MM')] = item.Customer;
    });
    return [obj];
  }, [Summary]);
  return (
    <ReportContentSection title={reportT('title.customerTrend')}>
      <ReportSingleChart
        data={Summary}
        dateKey="Date"
        valueKey="Customer"
        color="url(#colorReportCustomerGradient)"
        Gradient={
          <defs>
            <linearGradient
              id="colorReportCustomerGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#7590EF" />
              <stop offset="1" stopColor="#78C1EB" />
            </linearGradient>
          </defs>
        }
      />
      <ReportTable
        data={tableData}
        pinnedColumns={pinnedColumns}
        columns={columns}
        disableHighlight
      />
    </ReportContentSection>
  );
}
