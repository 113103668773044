import { useCallback, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  List,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import {
  CloudProviderChip,
  CloudTypeChip,
  CustomerChip,
  InvoiceChip,
  ReportProviderChip,
} from '@components/styled/chip';
import { REPORT_FILTER_STATE } from '@atoms/page/filter';
import {
  ReportCustomerAutocomplete,
  ReportDateRangePicker,
} from './components';
import { AdditionalContentItem, ReportAccordion } from './styled';
import { useReportSettingsForm } from './form';
import {
  useCreateReport,
  useGetReportSettingsById,
  useUpdateReport,
} from '@api/queries/report';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_REPORT_STATE } from '@constants/filter/report';
import { useGetCompany } from '@api/queries/company';
import { useCurrentInfo } from '@hooks';

export default function ReportSettings({ UID }: { UID?: string }) {
  const { t } = useTranslation('report');
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const setReportSettings = useSetRecoilState(REPORT_FILTER_STATE);
  const { hasRole } = useCurrentInfo(true);
  const { data: company } = useGetCompany();
  const { data: response, setId } = useGetReportSettingsById(UID);
  const { mutateAsync: createReport, isPending: isCreating } =
    useCreateReport();
  const { mutateAsync: updateReport, isPending: isUpdating } =
    useUpdateReport();
  const { initialValues, validationSchema } = useReportSettingsForm();
  const {
    values,
    errors,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<CreateReport>({
    initialValues,
    validationSchema,
    onSubmit: async (v, helpers) => {
      try {
        const { data } = UID
          ? await updateReport({ ...v, UID })
          : await createReport(v);
        // helpers.resetForm({
        //   values: data,
        // });
        if (!UID) {
          navigate(`../${data.UID}`, { replace: true });
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  const toggleProvider = useCallback(
    (provider: CloudProvider | 'all') => () => {
      if (provider === 'all') {
        if (values.Providers.length === 3) {
          setFieldValue('Providers', []);
        } else {
          setFieldValue('Providers', ['aws', 'ncp', 'nhn']);
        }
        return;
      }
      const set = new Set(values.Providers);
      if (set.has(provider)) {
        set.delete(provider);
      } else {
        set.add(provider);
      }
      setFieldValue('Providers', Array.from(set));
    },
    [values.Providers, setFieldValue],
  );
  const toggleInvoiceStatus = useCallback(
    (status: InvoiceStatus | 'all') => () => {
      if (status === 'all') {
        const totalLength = hasRole('MANAGER') ? 4 : 3;
        if (values.InvoiceStatus.length === totalLength) {
          setFieldValue('InvoiceStatus', []);
        } else {
          const status = ['Invoiced', 'Paid', 'Overdue'];
          if (hasRole('MANAGER')) {
            status.unshift('Unissued');
          }
          setFieldValue('InvoiceStatus', status);
        }
        return;
      }
      const set = new Set(values.InvoiceStatus);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFieldValue('InvoiceStatus', Array.from(set));
    },
    [values.InvoiceStatus, setFieldValue, hasRole],
  );
  const toggleOrganizationStatus = useCallback(
    (status: OrganizationStatus | 'all') => () => {
      if (status === 'all') {
        if (values.OrganizationStatus.length === 4) {
          setFieldValue('OrganizationStatus', []);
        } else {
          setFieldValue('OrganizationStatus', [
            'in-use',
            'poc',
            'suspended',
            'canceled',
          ]);
        }
        return;
      }
      const set = new Set(values.OrganizationStatus);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFieldValue('OrganizationStatus', Array.from(set));
    },
    [values.OrganizationStatus, setFieldValue],
  );
  const toggleNCPCloudType = useCallback(
    (type: NCPAccountType | 'all') => () => {
      if (type === 'all') {
        if (values.NCPSettings.CloudType.length === 3) {
          setFieldValue('NCPSettings.CloudType', []);
        } else {
          setFieldValue('NCPSettings.CloudType', [
            'enterprise',
            'public',
            'finance',
          ]);
        }
        return;
      }
      const set = new Set(values.NCPSettings.CloudType);
      if (set.has(type)) {
        set.delete(type);
      } else {
        set.add(type);
      }
      setFieldValue('NCPSettings.CloudType', Array.from(set));
    },
    [values.NCPSettings.CloudType, setFieldValue],
  );
  const toggleNCPDisplayOption = useCallback(
    (status: ReportNCPDisplayOption) => () => {
      const set = new Set(values.NCPSettings.DisplayOptions);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFieldValue('NCPSettings.DisplayOptions', Array.from(set));
    },
    [values.NCPSettings.DisplayOptions, setFieldValue],
  );
  const toggleDisplayOption = useCallback(
    (status: ReportDisplayOption | 'all') => () => {
      if (status === 'all') {
        if (values.DisplayOptions.length === 3) {
          setFieldValue('DisplayOptions', []);
        } else {
          setFieldValue('DisplayOptions', [
            'CUSTOMER_TREND',
            'ACCOUNT_TREND',
            'INCLUDE_MARGIN',
            // 'BY_CSP',
            // 'BY_INVOICE_STATUS',
          ]);
        }
        return;
      }
      const set = new Set(values.DisplayOptions);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFieldValue('DisplayOptions', Array.from(set));
    },
    [values.DisplayOptions, setFieldValue],
  );
  const invoiceStatusChecked = useMemo(() => {
    const totalCount = hasRole('MANAGER') ? 4 : 3;
    return values.InvoiceStatus.length === totalCount;
  }, [hasRole, values.InvoiceStatus.length]);
  useEffect(() => {
    if (response?.data) {
      const newValues = {
        ChartType: response.data.ChartType,
        Name: response.data.Name,
        Memo: response.data.Memo,
        Providers: response.data.Providers,
        StartMonth: response.data.StartMonth,
        EndMonth: response.data.EndMonth,
        Organizations: response.data.Organizations,
        InvoiceStatus: response.data.InvoiceStatus,
        OrganizationStatus: response.data.OrganizationStatus,
        DisplayOptions: response.data.DisplayOptions,
        NCPSettings: response.data.NCPSettings,
      };
      setReportSettings(newValues);
      resetForm({
        values: newValues,
      });
    } else {
      setReportSettings(DEFAULT_REPORT_STATE);
      resetForm({
        values: DEFAULT_REPORT_STATE,
      });
    }
  }, [response?.data, setReportSettings, resetForm]);
  useEffect(() => {
    setId(UID ?? '');
  }, [UID, setId]);
  useEffect(() => {
    if (!UID && company?.data && company.data.AvailableCSP.length === 1) {
      setFieldValue('Providers', company.data.AvailableCSP);
    }
  }, [UID, company?.data, setFieldValue]);
  useEffect(() => {
    if (!UID && !hasRole('MANAGER')) {
      setFieldValue('InvoiceStatus', ['Invoiced', 'Paid', 'Overdue']);
      setFieldValue('OrganizationStatus', ['in-use']);
    }
  }, [UID, hasRole, setFieldValue]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Box
        sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '40px' }}
      >
        <ReportAccordion title={t('settings.reportInfo')}>
          <TextField
            name="Name"
            value={values.Name}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.reportName')}
            fullWidth
          />
          <TextField
            name="Memo"
            value={values.Memo}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('label.memo')}
            multiline
            minRows={3}
            fullWidth
          />
        </ReportAccordion>
        <ReportAccordion title={t('settings.commonSettings')}>
          {!!company?.data && company.data.AvailableCSP.length > 1 && (
            <Box>
              <InputLabel>{t('label.csp')}</InputLabel>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                  mt: '16px',
                  mb: '8px',
                }}
              >
                <FormControlLabel
                  name="Providers.all"
                  value="all"
                  onChange={toggleProvider('all')}
                  checked={values.Providers.length === 3}
                  control={
                    <Checkbox
                      indeterminate={
                        Boolean(values.Providers.length) &&
                        values.Providers.length < 3
                      }
                    />
                  }
                  label={t('text.selectAll')}
                />
                <FormControlLabel
                  name="Providers.aws"
                  onChange={toggleProvider('aws')}
                  checked={values.Providers.includes('aws')}
                  control={<Checkbox />}
                  label={<CloudProviderChip provider="aws" />}
                />
                <FormControlLabel
                  name="Providers.ncp"
                  onChange={toggleProvider('ncp')}
                  checked={values.Providers.includes('ncp')}
                  control={<Checkbox />}
                  label={<CloudProviderChip provider="ncp" />}
                />
                <FormControlLabel
                  name="Providers.nhn"
                  onChange={toggleProvider('nhn')}
                  checked={values.Providers.includes('nhn')}
                  control={<Checkbox />}
                  label={<CloudProviderChip provider="nhn" />}
                />
              </Box>
            </Box>
          )}
          <Box>
            <InputLabel>{t('label.period')}</InputLabel>
            <ReportDateRangePicker
              values={values}
              setFieldValue={setFieldValue}
            />
          </Box>
          <Box>
            <InputLabel>{t('label.invoiceStatus')}</InputLabel>
            <Grid container spacing="16px" mt="16px" mb="8px">
              <Grid xs={6}>
                <FormControlLabel
                  name="InvoiceStatus.all"
                  value="all"
                  onChange={toggleInvoiceStatus('all')}
                  checked={invoiceStatusChecked}
                  control={
                    <Checkbox
                      indeterminate={
                        !!values.InvoiceStatus.length && !invoiceStatusChecked
                      }
                    />
                  }
                  label={t('text.selectAll')}
                />
              </Grid>
              {hasRole('MANAGER') && (
                <Grid xs={6}>
                  <FormControlLabel
                    name="InvoiceStatus.Unissued"
                    onChange={toggleInvoiceStatus('Unissued')}
                    checked={values.InvoiceStatus.includes('Unissued')}
                    control={<Checkbox />}
                    label={<InvoiceChip status="Unissued" />}
                  />
                </Grid>
              )}
              <Grid xs={6}>
                <FormControlLabel
                  name="InvoiceStatus.Invoiced"
                  onChange={toggleInvoiceStatus('Invoiced')}
                  checked={values.InvoiceStatus.includes('Invoiced')}
                  control={<Checkbox />}
                  label={<InvoiceChip status="Invoiced" />}
                />
              </Grid>
              <Grid xs={6}>
                <FormControlLabel
                  name="InvoiceStatus.Paid"
                  onChange={toggleInvoiceStatus('Paid')}
                  checked={values.InvoiceStatus.includes('Paid')}
                  control={<Checkbox />}
                  label={<InvoiceChip status="Paid" />}
                />
              </Grid>
              <Grid xs={6}>
                <FormControlLabel
                  name="InvoiceStatus.Overdue"
                  onChange={toggleInvoiceStatus('Overdue')}
                  checked={values.InvoiceStatus.includes('Overdue')}
                  control={<Checkbox />}
                  label={<InvoiceChip status="Overdue" />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <InputLabel>{t('label.customer')}</InputLabel>
            <ReportCustomerAutocomplete
              values={values}
              setFieldValue={setFieldValue}
            />
          </Box>
          {hasRole('MANAGER') && (
            <Box>
              <InputLabel>{t('label.customerStatus')}</InputLabel>
              <Grid container spacing="16px" mt="16px" mb="8px">
                <Grid xs={6}>
                  <FormControlLabel
                    name="OrganizationStatus.all"
                    value="all"
                    onChange={toggleOrganizationStatus('all')}
                    checked={values.OrganizationStatus.length === 4}
                    control={
                      <Checkbox
                        indeterminate={
                          !!values.OrganizationStatus.length &&
                          values.OrganizationStatus.length != 4
                        }
                      />
                    }
                    label={t('text.selectAll')}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="OrganizationStatus.in-use"
                    onChange={toggleOrganizationStatus('in-use')}
                    checked={values.OrganizationStatus.includes('in-use')}
                    control={<Checkbox />}
                    label={<CustomerChip status="in-use" />}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="OrganizationStatus.poc"
                    onChange={toggleOrganizationStatus('poc')}
                    checked={values.OrganizationStatus.includes('poc')}
                    control={<Checkbox />}
                    label={<CustomerChip status="poc" />}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="OrganizationStatus.suspended"
                    onChange={toggleOrganizationStatus('suspended')}
                    checked={values.OrganizationStatus.includes('suspended')}
                    control={<Checkbox />}
                    label={<CustomerChip status="suspended" />}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="OrganizationStatus.canceled"
                    onChange={toggleOrganizationStatus('canceled')}
                    checked={values.OrganizationStatus.includes('canceled')}
                    control={<Checkbox />}
                    label={<CustomerChip status="canceled" />}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </ReportAccordion>
        {values.Providers.includes('ncp') && (
          <ReportAccordion title={t('settings.ncpSettings')}>
            <Box>
              <InputLabel>{t('label.ncpCloudType')}</InputLabel>
              <Grid container spacing="16px" mt="16px" mb="8px">
                <Grid xs={6}>
                  <FormControlLabel
                    name="NCPSettings.CloudType.all"
                    value="all"
                    onChange={toggleNCPCloudType('all')}
                    checked={values.NCPSettings.CloudType.length === 3}
                    control={
                      <Checkbox
                        indeterminate={
                          !!values.NCPSettings.CloudType.length &&
                          values.NCPSettings.CloudType.length != 3
                        }
                      />
                    }
                    label={t('text.selectAll')}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="NCPSettings.CloudType.enterprise"
                    onChange={toggleNCPCloudType('enterprise')}
                    checked={values.NCPSettings.CloudType.includes(
                      'enterprise',
                    )}
                    control={<Checkbox />}
                    label={<CloudTypeChip type="enterprise" disableCircle />}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="NCPSettings.CloudType.public"
                    onChange={toggleNCPCloudType('public')}
                    checked={values.NCPSettings.CloudType.includes('public')}
                    control={<Checkbox />}
                    label={<CloudTypeChip type="public" disableCircle />}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel
                    name="NCPSettings.CloudType.finance"
                    onChange={toggleNCPCloudType('finance')}
                    checked={values.NCPSettings.CloudType.includes('finance')}
                    control={<Checkbox />}
                    label={<CloudTypeChip type="finance" disableCircle />}
                  />
                </Grid>
              </Grid>
            </Box>
            {/*{hasRole('MANAGER') && (*/}
            {/*  <Box>*/}
            {/*    <InputLabel>{t('label.ncpOptions')}</InputLabel>*/}
            {/*    <Box*/}
            {/*      sx={{*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'column',*/}
            {/*        alignItems: 'flex-start',*/}
            {/*        gap: '16px',*/}
            {/*        mt: '16px',*/}
            {/*        mb: '8px',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <FormControlLabel*/}
            {/*        name="NCPSettings.DisplayOptions.INCLUDE_MARGIN"*/}
            {/*        onChange={toggleNCPDisplayOption('INCLUDE_MARGIN')}*/}
            {/*        checked={values.NCPSettings.DisplayOptions.includes(*/}
            {/*          'INCLUDE_MARGIN',*/}
            {/*        )}*/}
            {/*        control={<Checkbox />}*/}
            {/*        label={t('ncpDisplayOptions.INCLUDE_MARGIN')}*/}
            {/*      />*/}
            {/*      <FormControlLabel*/}
            {/*        name="NCPSettings.DisplayOptions.BY_CLOUD_TYPE"*/}
            {/*        onChange={toggleNCPDisplayOption('BY_CLOUD_TYPE')}*/}
            {/*        checked={values.NCPSettings.DisplayOptions.includes(*/}
            {/*          'BY_CLOUD_TYPE',*/}
            {/*        )}*/}
            {/*        control={<Checkbox />}*/}
            {/*        label={t('ncpDisplayOptions.BY_CLOUD_TYPE')}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*)}*/}
          </ReportAccordion>
        )}
        {hasRole('MANAGER') && (
          <ReportAccordion title={t('settings.options')}>
            <Box>
              <InputLabel>{t('label.displayOptions')}</InputLabel>
              <List
                disablePadding
                sx={{
                  border: '1px solid #E0E0E0',
                  borderRadius: '4px',
                }}
              >
                <AdditionalContentItem
                  value="all"
                  onClick={toggleDisplayOption('all')}
                  selected={values.DisplayOptions.length === 3}
                >
                  {t('text.selectAll')}
                </AdditionalContentItem>
                <AdditionalContentItem
                  value="CUSTOMER_TREND"
                  onClick={toggleDisplayOption('CUSTOMER_TREND')}
                  selected={values.DisplayOptions.includes('CUSTOMER_TREND')}
                >
                  {t('displayOptions.CUSTOMER_TREND')}
                </AdditionalContentItem>
                <AdditionalContentItem
                  value="ACCOUNT_TREND"
                  onClick={toggleDisplayOption('ACCOUNT_TREND')}
                  selected={values.DisplayOptions.includes('ACCOUNT_TREND')}
                >
                  {t('displayOptions.ACCOUNT_TREND')}
                </AdditionalContentItem>
                <AdditionalContentItem
                  value="INCLUDE_MARGIN"
                  onClick={toggleDisplayOption('INCLUDE_MARGIN')}
                  selected={values.DisplayOptions.includes('INCLUDE_MARGIN')}
                >
                  {t('displayOptions.INCLUDE_MARGIN')}
                </AdditionalContentItem>
                {/*<AdditionalContentItem*/}
                {/*  value="BY_CSP"*/}
                {/*  onClick={toggleDisplayOption('BY_CSP')}*/}
                {/*  selected={values.DisplayOptions.includes('BY_CSP')}*/}
                {/*>*/}
                {/*  {t('displayOptions.BY_CSP')}*/}
                {/*</AdditionalContentItem>*/}
                {/*<AdditionalContentItem*/}
                {/*  value="BY_INVOICE_STATUS"*/}
                {/*  onClick={toggleDisplayOption('BY_INVOICE_STATUS')}*/}
                {/*  selected={values.DisplayOptions.includes('BY_INVOICE_STATUS')}*/}
                {/*>*/}
                {/*  {t('displayOptions.BY_INVOICE_STATUS')}*/}
                {/*</AdditionalContentItem>*/}
                {/*<AdditionalContentItem*/}
                {/*  value="COMMISSION"*/}
                {/*  onClick={toggleDisplayOption('COMMISSION')}*/}
                {/*  selected={values.DisplayOptions.includes('COMMISSION')}*/}
                {/*>*/}
                {/*  {t('displayOptions.COMMISSION')}*/}
                {/*</AdditionalContentItem>*/}
              </List>
            </Box>
            {/*<Box>*/}
            {/*  <InputLabel>{t('label.graphType')}</InputLabel>*/}
            {/*  <RadioGroup*/}
            {/*    name="ChartType"*/}
            {/*    value={values.ChartType}*/}
            {/*    onChange={handleChange}*/}
            {/*    onBlur={handleBlur}*/}
            {/*    row*/}
            {/*    sx={{ gap: '16px', mt: '11px' }}*/}
            {/*  >*/}
            {/*    <Tile*/}
            {/*      value="bar"*/}
            {/*      label={<BarChart />}*/}
            {/*      description="Bar (막대)"*/}
            {/*      sx={{*/}
            {/*        flex: 1,*/}
            {/*        [`&:has(> .${radioClasses.root}:not(.Mui-checked))`]: {*/}
            {/*          '> .HekaTile-content': {*/}
            {/*            color: '#777B86',*/}
            {/*          },*/}
            {/*        },*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <Tile*/}
            {/*      value="line"*/}
            {/*      label={<ShowChart />}*/}
            {/*      description="Line (선)"*/}
            {/*      sx={{*/}
            {/*        flex: 1,*/}
            {/*        [`&:has(> .${radioClasses.root}:not(.Mui-checked))`]: {*/}
            {/*          '> .HekaTile-content': {*/}
            {/*            color: '#777B86',*/}
            {/*          },*/}
            {/*        },*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </RadioGroup>*/}
            {/*</Box>*/}
          </ReportAccordion>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Button color="cancel" sx={{ flex: 1 }}>
          {globalT('button.goBack')}
        </Button>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isUpdating || isCreating}
          disabled={!isValid || !dirty}
          sx={{ flex: 1 }}
        >
          {globalT('button.apply')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
