import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useCreateOrganization } from '@api/queries/organization';
import LargeModal from '@components/modal/large';
import { StepContainer } from './styled';
import { useCreateCustomerForm } from './form';
import {
  // AssignAWSAccount,
  // AssignNCPAccount,
  // ChooseProvider,
  CustomerComplete,
  CustomerInfo,
} from './steps';

type ModalStep = 'INFO' | 'INVITE' | 'CHOOSE' | 'AWS' | 'NCP' | 'COMPLETE';

export default function CreateCustomerModal({ open, onClose }: ModalProps) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.customer' });
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [currentStep, setCurrentStep] = useState<ModalStep>('INFO');
  const [selectedProviders, setSelectedProviders] = useState<
    Array<CloudProvider>
  >([]);
  const { mutateAsync: createCustomer, isPending } = useCreateOrganization();
  const { initialValues, validationSchema } = useCreateCustomerForm();
  const { isValid, dirty, handleSubmit, resetForm, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        const { data } = await createCustomer(v);
        if (data?.UID) {
          setUid(data.UID);
          setCurrentStep('COMPLETE');
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      setTimeout(() => {
        resetForm();
        setSelectedProviders([]);
        setCurrentStep('INFO');
      }, 500);
    }
  }, [onClose, resetForm]);
  const description = useMemo(() => {
    switch (currentStep) {
      case 'INFO':
        return t('steps.information.description');
      case 'INVITE':
        return t('steps.invitation.description');
      case 'CHOOSE':
        return t('steps.choose.description');
      case 'AWS':
        return t('steps.account.aws.description');
      case 'NCP':
        return t('steps.account.ncp.description');
      default:
        return '';
    }
  }, [currentStep, t]);
  const isSubmitStep = useMemo(() => {
    if (currentStep === 'INFO') {
      return true;
    }
    // if (currentStep === 'NCP') {
    //   return true;
    // } else if (currentStep === 'AWS' && !selectedProviders.includes('ncp')) {
    //   return true;
    // } else if (currentStep === 'CHOOSE' && !selectedProviders.length) {
    //   return true;
    // }
    return false;
  }, [currentStep, selectedProviders]);
  const secondaryText = useMemo(() => {
    switch (currentStep) {
      case 'INVITE':
        return globalT('button.skip');
      case 'COMPLETE':
        return t('button.finish');
      default:
        return globalT('button.goBack');
    }
  }, [currentStep, t, globalT]);
  const disabled = useMemo(() => {
    switch (currentStep) {
      case 'INFO':
      case 'AWS':
      case 'NCP':
        return !isValid || !dirty;
      default:
        return false;
    }
  }, [currentStep, isValid, dirty]);
  const handlePrimary = useCallback(() => {
    switch (currentStep) {
      case 'INFO':
        setCurrentStep('CHOOSE');
        break;
      case 'INVITE':
        setCurrentStep('CHOOSE');
        break;
      case 'CHOOSE':
        selectedProviders.includes('aws')
          ? setCurrentStep('AWS')
          : setCurrentStep('NCP');
        break;
      case 'AWS':
        setCurrentStep('NCP');
        break;
    }
    if (currentStep === 'COMPLETE' && uid) {
      navigate(`/customer/${uid}`);
      handleClose();
    }
  }, [currentStep, selectedProviders, uid, navigate, handleClose]);
  const handleSecondary = useCallback(() => {
    switch (currentStep) {
      case 'INFO':
      case 'COMPLETE':
        handleClose();
        break;
      case 'INVITE':
        setCurrentStep('CHOOSE');
        break;
      case 'CHOOSE':
        setCurrentStep('INFO');
        break;
      case 'AWS':
        setCurrentStep('CHOOSE');
        break;
      case 'NCP':
        selectedProviders.includes('aws')
          ? setCurrentStep('AWS')
          : setCurrentStep('CHOOSE');
        break;
    }
  }, [currentStep, selectedProviders, handleClose]);
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={t('title')}
      description={description}
      Actions={
        <>
          <Button
            type="button"
            color={
              currentStep === 'INVITE' || currentStep === 'COMPLETE'
                ? 'sub'
                : 'cancel'
            }
            onClick={handleSecondary}
          >
            {secondaryText}
          </Button>
          {isSubmitStep ? (
            <LoadingButton
              type="submit"
              loading={isPending}
              disabled={disabled}
            >
              {t('button.submit')}
            </LoadingButton>
          ) : (
            <Button
              type="button"
              color="emphasis1"
              onClick={handlePrimary}
              disabled={disabled}
            >
              {currentStep !== 'COMPLETE'
                ? globalT('button.next')
                : t('button.enterMore')}
            </Button>
          )}
        </>
      }
    >
      {currentStep === 'INFO' && (
        <StepContainer maxWidth="831px">
          <CustomerInfo {...formik} />
        </StepContainer>
      )}
      {/*{currentStep === 'CHOOSE' && (*/}
      {/*  <StepContainer maxWidth="628px">*/}
      {/*    <ChooseProvider*/}
      {/*      selectedProviders={selectedProviders}*/}
      {/*      setSelectedProviders={setSelectedProviders}*/}
      {/*    />*/}
      {/*  </StepContainer>*/}
      {/*)}*/}
      {/*{currentStep === 'AWS' && (*/}
      {/*  <StepContainer*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: '24px',*/}
      {/*      maxWidth: '748px',*/}
      {/*      px: 0,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <AssignAWSAccount {...formik} />*/}
      {/*  </StepContainer>*/}
      {/*)}*/}
      {/*{currentStep === 'NCP' && (*/}
      {/*  <StepContainer*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: '24px',*/}
      {/*      maxWidth: '748px',*/}
      {/*      px: 0,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <AssignNCPAccount {...formik} />*/}
      {/*  </StepContainer>*/}
      {/*)}*/}
      {currentStep === 'COMPLETE' && (
        <StepContainer maxWidth="748px">
          <CustomerComplete />
        </StepContainer>
      )}
    </LargeModal>
  );
}
