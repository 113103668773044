import { useSuspenseQuery } from '@tanstack/react-query';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useGetPayerAccounts() {
  return useSuspenseQuery({
    queryKey: AWS_ACCOUNT_KEY.GET_PAYER_ACCOUNTS,
    queryFn: () => AWSAccountService.getPayerAccounts(),
  });
}
