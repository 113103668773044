import { useMutation } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useDownloadInvoiceAsPdf() {
  return useMutation({
    mutationKey: INVOICE_KEY.DOWNLOAD_INVOICE_AS_PDF,
    mutationFn: ({
      InvoiceId,
      ExcludeZero,
    }: {
      InvoiceId: string;
      ExcludeZero: boolean;
    }) => InvoiceService.downloadInvoiceAsPdf(InvoiceId, ExcludeZero),
  });
}
