import { atom } from 'recoil';

export const INVOICE_EDIT_STATE = atom({
  key: 'INVOICE_EDIT_STATE',
  default: false,
});

export const INVOICE_PUBLISH_STATE = atom<
  Pick<Invoice, 'Status' | 'InvoiceId'> & {
    open: boolean;
    InvoicedDate?: Date;
    PaymentDate?: Date;
  }
>({
  key: 'INVOICE_PUBLISH_STATE',
  default: {
    open: false,
    Status: 'Unissued',
    InvoiceId: '',
  },
});

export const INVOICE_EXCLUDE_ZERO_STATE = atom({
  key: 'INVOICE_EXCLUDE_ZERO_STATE',
  default: false,
});

export const INVOICE_UPDATE_STATE = atom({
  key: 'INVOICE_UPDATE_STATE',
  default: false,
});
