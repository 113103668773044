import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Popper,
  InputAdornment,
  Button,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';
import { DateCalendar } from '@mui/x-date-pickers';
import DateRange from '@mui/icons-material/DateRange';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useToggle } from '@hooks';
import { formatDate } from '@utils/formatter';
import { getPickersCalendarHeaderUtilityClass } from '@mui/x-date-pickers/PickersCalendarHeader/pickersCalendarHeaderClasses';

type IProps = Pick<
  ReturnType<typeof useFormik<CreateReport>>,
  'values' | 'setFieldValue'
>;
export default function ReportDateRangePicker({
  values,
  setFieldValue,
}: IProps) {
  const [StartMonth, setStartMonth] = useState<dayjs.Dayjs>(
    dayjs(values.StartMonth),
  );
  const [EndMonth, setEndMonth] = useState<dayjs.Dayjs>(dayjs(values.EndMonth));
  const [isStart, setIsStart] = useState(true);
  const [openPicker, togglePicker] = useToggle();
  const ref = useRef();
  const handleStartMonth = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setStartMonth(newValue);
        if (
          EndMonth.isBefore(newValue, 'months') ||
          EndMonth.diff(newValue, 'months') > 5
        ) {
          const current = dayjs(newValue)
            .set('years', dayjs().year())
            .set('months', dayjs().month());
          const maximum = dayjs(newValue).add(5, 'months');
          setEndMonth(current.isBefore(maximum, 'months') ? current : maximum);
        }
      }
    },
    [EndMonth],
  );
  const handleEndMonth = useCallback((newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setEndMonth(newValue);
    }
  }, []);
  const handleAccept = useCallback(() => {
    setFieldValue('StartMonth', StartMonth.toDate());
    setFieldValue('EndMonth', EndMonth.toDate());
    setIsStart(true);
    togglePicker();
  }, [setFieldValue, StartMonth, EndMonth, togglePicker]);
  const handleClose = useCallback(() => {
    setIsStart(true);
    setStartMonth(dayjs(values.StartMonth));
    setEndMonth(dayjs(values.EndMonth));
    togglePicker();
  }, [values.StartMonth, values.EndMonth, togglePicker]);
  useEffect(() => {
    setStartMonth(dayjs(values.StartMonth));
  }, [values.StartMonth]);
  useEffect(() => {
    setEndMonth(dayjs(values.EndMonth));
  }, [values.EndMonth]);
  return (
    <>
      <TextField
        inputRef={ref}
        value={`${formatDate(StartMonth, 'YYYY.MM')} ~ ${formatDate(
          EndMonth,
          'YYYY.MM',
        )}`}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="calm"
                onClick={togglePicker}
                sx={{
                  p: '8px',
                  [`> .${getSvgIconUtilityClass('root')}`]: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                <DateRange sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          [`> .${getInputBaseUtilityClass('root')}`]: {
            paddingRight: 0,
          },
        }}
        fullWidth
      />
      <Popper
        open={openPicker}
        anchorEl={ref.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          {isStart ? (
            <Paper>
              <Typography color="text.main" fontWeight={500} letterSpacing={0}>
                시작월 설정
              </Typography>
              <DateCalendar
                value={StartMonth}
                onChange={handleStartMonth}
                views={['year', 'month']}
                openTo="month"
                disableFuture
                sx={{
                  [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                    px: 0,
                  },
                }}
              />
              <Button
                color="emphasis1"
                onClick={() => setIsStart(false)}
                fullWidth
              >
                종료월 설정
              </Button>
            </Paper>
          ) : (
            <Paper>
              <Typography color="text.main" fontWeight={500} letterSpacing={0}>
                종료월 설정
              </Typography>
              <DateCalendar
                value={EndMonth}
                onChange={handleEndMonth}
                views={['year', 'month']}
                openTo="month"
                shouldDisableMonth={(v) =>
                  v.isBefore(StartMonth, 'months') ||
                  v.diff(StartMonth, 'months') > 5
                }
                shouldDisableYear={(v) => v.isBefore(StartMonth, 'years')}
                disableFuture
                sx={{
                  [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                    px: 0,
                  },
                }}
              />
              <Button color="emphasis1" onClick={handleAccept} fullWidth>
                기간 적용
              </Button>
            </Paper>
          )}
        </ClickAwayListener>
      </Popper>
    </>
  );
}
