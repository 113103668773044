import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, IconButton, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import _ from 'lodash';
import SimpleTable from '@components/table/simple';
import { INVOICE_EDIT_STATE } from '@features/invoice/detail/atom';
import { formatKRW, formatLocaleString, formatUSD } from '@utils/formatter';
import AddAdditionalServiceModal from './AddAdditionalServiceModal';
import EditAdditionalServiceModal from './EditAdditionalServiceModal';
import DeleteAdditionalServiceModal from './DeleteAdditionalServiceModal';
import { ServiceAccordion } from '../styled';
import { InvoiceAccordion } from '../cloudUsage/styled';

type TableItem = {
  Name: string;
  Cost: number;
  PricingUnit: string;
  UsageAmount: number;
  CSP: CloudProvider;
  CompanyLevel: boolean;
  AccountId: string;
  AccountName?: string;
  Index: number;
};
type TableData = {
  CSP: CloudProvider;
  AccountId: string;
  AccountName?: string;
  Cost: number;
  Items: Array<TableItem>;
};

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceAdditionalService({ Invoices }: Props) {
  const { t } = useTranslation('table', { keyPrefix: 'invoice' });
  const { t: invoiceT } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const isEdit = useRecoilValue(INVOICE_EDIT_STATE);
  const [toAdd, setToAdd] = useState<BaseExtraModalProps['Data'] | undefined>(
    undefined,
  );
  const [toEdit, setToEdit] = useState<EditExtraModalProps['Data'] | undefined>(
    undefined,
  );
  const [toDelete, setToDelete] = useState<
    EditExtraModalProps['Data'] | undefined
  >(undefined);
  const handleAdd = useCallback(
    (
      CSP: CloudProvider,
      AccountId: string,
      CompanyLevel: boolean,
      AccountName: string | undefined = undefined,
    ) =>
      () => {
        setToAdd({
          CSP,
          OrganizationId: Invoices[0].OrganizationId,
          InvoiceId: Invoices[0].InvoiceId,
          AccountId,
          AccountName,
          CompanyLevel,
        });
      },
    [Invoices],
  );
  const handleEdit = useCallback(
    (
      CSP: CloudProvider,
      AccountId: string,
      CompanyLevel: boolean,
      index: number,
      AccountName: string | undefined = undefined,
    ) =>
      () => {
        setToEdit({
          CSP,
          OrganizationId: Invoices[0].OrganizationId,
          InvoiceId: Invoices[0].InvoiceId,
          AccountId,
          AccountName,
          CompanyLevel,
          TargetIndex: index,
        });
      },
    [Invoices],
  );
  const handleDelete = useCallback(
    (
      CSP: CloudProvider,
      AccountId: string,
      CompanyLevel: boolean,
      index: number,
    ) =>
      () => {
        setToDelete({
          CSP,
          OrganizationId: Invoices[0].OrganizationId,
          InvoiceId: Invoices[0].InvoiceId,
          AccountId,
          CompanyLevel,
          TargetIndex: index,
        });
      },
    [Invoices],
  );
  const awsServiceData = useMemo(() => {
    const commonItems: Array<TableItem> = [];
    const result: Array<TableData> = [];
    const awsInvoices = Invoices.filter((v) => v.CSP === 'aws');
    if (!awsInvoices.length) {
      return [];
    }
    awsInvoices.forEach((account) => {
      const services = _.get(
        account,
        'AdditionalData.AdditionalService.No region.AdditionalService.AdditionalService',
      );
      const Items: Array<TableItem> = Object.keys(services).map((key, i) => ({
        Name: key,
        Cost: services[key].Cost,
        PricingUnit: services[key].PricingUnit,
        UsageAmount: services[key].UsageAmount,
        CSP: 'aws',
        CompanyLevel: !!_.get(services, `${key}.CompanyLevel`),
        AccountId: account.AccountId,
        Index: i,
      }));
      commonItems.push(...Items.filter((v) => v.CompanyLevel));
      result.push({
        CSP: account.CSP,
        AccountId: account.AccountId,
        Cost: account.AdditionalCost.KRW,
        Items: Items.filter((v) => !v.CompanyLevel),
      });
    });
    result.unshift({
      CSP: 'aws',
      AccountId: 'COMMON',
      Cost: _.sumBy(commonItems, 'Cost'),
      Items: commonItems,
    });
    return result;
  }, [Invoices]);
  const ncpServiceData = useMemo(() => {
    const commonItems: Array<TableItem> = [];
    const result: Array<TableData> = [];
    const ncpInvoices = Invoices.filter((v) => v.CSP === 'ncp');
    if (!ncpInvoices.length) {
      return [];
    }
    ncpInvoices.forEach((account) => {
      const services = _.get(
        account,
        'AdditionalData.AdditionalService.No region.AdditionalService.AdditionalService',
      );
      const Items: Array<TableItem> = Object.keys(services).map((key, i) => ({
        Name: key,
        Cost: services[key].Cost,
        PricingUnit: services[key].PricingUnit,
        UsageAmount: services[key].UsageAmount,
        CSP: 'ncp',
        CompanyLevel: !!_.get(services, `${key}.CompanyLevel`),
        AccountId: account.AccountId,
        Index: i,
      }));
      commonItems.push(...Items.filter((v) => v.CompanyLevel));
      result.push({
        CSP: account.CSP,
        AccountId: account.AccountId,
        Cost: account.AdditionalCost.KRW,
        Items: Items.filter((v) => !v.CompanyLevel),
      });
    });
    result.unshift({
      CSP: 'ncp',
      AccountId: 'COMMON',
      Cost: _.sumBy(commonItems, 'Cost'),
      Items: commonItems,
    });
    return result;
  }, [Invoices]);
  const nhnServiceData = useMemo(() => {
    const commonItems: Array<TableItem> = [];
    const result: Array<TableData> = [];
    const nhnInvoices = Invoices.filter((v) => v.CSP === 'nhn');
    if (!nhnInvoices.length) {
      return [];
    }
    nhnInvoices.forEach((account) => {
      const services = _.get(
        account,
        'AdditionalData.AdditionalService.No region.AdditionalService.AdditionalService',
      );
      const Items: Array<TableItem> = Object.keys(services).map((key, i) => ({
        Name: key,
        Cost: services[key].Cost,
        PricingUnit: services[key].PricingUnit,
        UsageAmount: services[key].UsageAmount,
        CSP: 'nhn',
        CompanyLevel: !!_.get(services, `${key}.CompanyLevel`),
        AccountId: account.AccountId,
        AccountName: (account as NHNInvoice).OriginData.ProjectName,
        Index: i,
      }));
      commonItems.push(...Items.filter((v) => v.CompanyLevel));
      result.push({
        CSP: account.CSP,
        AccountId: account.AccountId,
        AccountName: (account as NHNInvoice).OriginData.ProjectName,
        Cost: account.AdditionalCost.KRW,
        Items: Items.filter((v) => !v.CompanyLevel),
      });
    });
    if (result.length) {
      result.unshift({
        CSP: 'nhn',
        AccountId: 'COMMON',
        AccountName: 'COMMON',
        Cost: _.sumBy(commonItems, 'Cost'),
        Items: commonItems,
      });
    }
    return result;
  }, [Invoices]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<TableItem>();
    const items: Array<any> = [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('additionalServiceName'),
        cell: (v) => v.getValue(),
        size: 411,
      }),
      columnHelper.accessor('UsageAmount', {
        id: 'UsageAmount',
        header: t('usageAmount'),
        cell: (v) => formatLocaleString(v.getValue()),
        size: 160,
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('Cost', {
        id: 'Cost',
        header: t('cost'),
        cell: (v) =>
          v.row.original.PricingUnit !== 'KRW'
            ? formatUSD(v.getValue())
            : formatKRW(v.getValue()),
        size: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
    if (isEdit) {
      items.push(
        columnHelper.display({
          id: 'Delete',
          header: globalT('button.delete'),
          cell: (v) => (
            <IconButton
              color="danger"
              size="small"
              onClick={handleDelete(
                v.row.original.CSP,
                v.row.original.AccountId,
                v.row.original.CompanyLevel,
                v.row.original.Index,
              )}
            >
              <Delete />
            </IconButton>
          ),
          size: 96,
          meta: {
            align: 'center',
          },
        }),
        columnHelper.display({
          id: 'Edit',
          header: globalT('button.edit'),
          cell: (v) => (
            <IconButton
              color="calm"
              size="small"
              onClick={handleEdit(
                v.row.original.CSP,
                v.row.original.AccountId,
                v.row.original.CompanyLevel,
                v.row.original.Index,
                v.row.original.AccountName,
              )}
            >
              <Edit />
            </IconButton>
          ),
          size: 96,
          meta: {
            align: 'center',
          },
        }),
      );
    }
    return items;
  }, [t, isEdit, globalT, handleDelete, handleEdit]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
      <Stack spacing="40px">
        {awsServiceData.map((account) => (
          <Box key={`invoice-aws-additional-${account.AccountId}`}>
            {isEdit ? (
              <ServiceAccordion
                key={`invoice-aws-additional-${account.AccountId}-edit`}
                csp={account.CSP}
                accountId={account.AccountId}
                cost={account.Cost}
                count={account.Items.length}
                onAdd={handleAdd(
                  account.CSP,
                  account.AccountId,
                  account.AccountId === 'COMMON',
                )}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </ServiceAccordion>
            ) : (
              <InvoiceAccordion
                key={`invoice-aws-additional-${account.AccountId}-plain`}
                csp={account.CSP}
                accountId={account.AccountId}
                cost={account.Cost}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </InvoiceAccordion>
            )}
          </Box>
        ))}
        {ncpServiceData.map((account) => (
          <Box key={`invoice-ncp-additional-${account.AccountId}`}>
            {isEdit ? (
              <ServiceAccordion
                key={`invoice-ncp-additional-${account.AccountId}-edit`}
                csp={account.CSP}
                accountId={account.AccountId}
                cost={account.Cost}
                count={account.Items.length}
                onAdd={handleAdd(
                  account.CSP,
                  account.AccountId,
                  account.AccountId === 'COMMON',
                )}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </ServiceAccordion>
            ) : (
              <InvoiceAccordion
                key={`invoice-ncp-additional-${account.AccountId}-plain`}
                csp={account.CSP}
                accountId={account.AccountId}
                cost={account.Cost}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </InvoiceAccordion>
            )}
          </Box>
        ))}
        {nhnServiceData.map((account) => (
          <Box key={`invoice-nhn-additional-${account.AccountId}`}>
            {isEdit ? (
              <ServiceAccordion
                key={`invoice-nhn-additional-${account.AccountId}-edit`}
                csp={account.CSP}
                accountId={account.AccountName ?? ''}
                cost={account.Cost}
                count={account.Items.length}
                onAdd={handleAdd(
                  account.CSP,
                  account.AccountId,
                  account.AccountId === 'COMMON',
                  account.AccountName,
                )}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </ServiceAccordion>
            ) : (
              <InvoiceAccordion
                key={`invoice-nhn-additional-${account.AccountId}-plain`}
                csp={account.CSP}
                accountId={account.AccountName ?? ''}
                cost={account.Cost}
              >
                {!!account.Items.length && (
                  <SimpleTable columns={columns} data={account.Items} />
                )}
              </InvoiceAccordion>
            )}
          </Box>
        ))}
      </Stack>
      <AddAdditionalServiceModal
        open={typeof toAdd !== 'undefined'}
        onClose={() => setToAdd(undefined)}
        Invoices={Invoices}
        Data={toAdd}
      />
      <EditAdditionalServiceModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        Invoices={Invoices}
        Data={toEdit}
      />
      <DeleteAdditionalServiceModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        Invoices={Invoices}
        Data={toDelete}
      />
    </Box>
  );
}
