import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { useGetInvoices } from '@api/queries/invoice';
import { CloudTypeChip, InvoiceChip } from '@components/styled/chip';
import { formatKRW } from '@utils/formatter';

export function StatusPaper({ status }: { status: InvoiceStatus }) {
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetInvoices();
  const numberOfCustomers = useMemo(() => {
    if (response?.data) {
      const filtered = response.data
        .filter((v) => v.Status === status)
        .map((v) => v.OrganizationId);
      return new Set(filtered).size;
    }
    return 0;
  }, [response?.data, status]);
  const totalCost = useMemo(() => {
    if (response?.data) {
      const filtered = response.data.filter((v) => v.Status === status);
      return sumBy(filtered, 'TotalCost.KRW');
    }
    return 0;
  }, [response?.data, status]);
  const background = useMemo(() => {
    switch (status) {
      case 'Invoiced':
        return 'rgba(225, 122, 0, 0.02)';
      case 'Paid':
        return 'rgba(0, 122, 209, 0.02)';
      case 'Overdue':
        return 'rgba(200, 0, 60, 0.02)';
      default:
        return 'rgba(206, 207, 222, 0.02)';
    }
  }, [status]);
  return (
    <Paper
      sx={{
        minHeight: '182px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '24px',
        background,
        backdropFilter: 'blur(2px)',
        px: '24px',
        py: '16px',
      }}
    >
      <Box sx={{ ml: '-4px' }}>
        <InvoiceChip variant="text" status={status} />
      </Box>
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {formatKRW(totalCost)}
      </Typography>
      <Typography color="textSecondary" letterSpacing="0.64px">
        {globalT('format.number.quantity', { value: numberOfCustomers })} 고객사
      </Typography>
    </Paper>
  );
}

export function NCPStatusPaper({ type }: { type: NCPAccountType }) {
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetInvoices();
  const numberOfCustomers = useMemo(() => {
    if (response?.data) {
      const filtered = response.data
        .filter((v) => v.CSP === 'ncp' && v.CloudType === type)
        .map((v) => v.OrganizationId);
      return new Set(filtered).size;
    }
    return 0;
  }, [response?.data, type]);
  const totalCost = useMemo(() => {
    if (response?.data) {
      const filtered = response.data.filter(
        (v) => v.CSP === 'ncp' && v.CloudType === type,
      );
      return sumBy(filtered, 'OriginData.totalDemandAmount');
    }
    return 0;
  }, [response?.data, type]);
  return (
    <Paper
      sx={{
        minHeight: '144px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '16px',
        backdropFilter: 'blur(2px)',
        px: '24px',
        py: '16px',
      }}
    >
      <Box sx={{ ml: '-4px' }}>
        <CloudTypeChip type={type} />
      </Box>
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {formatKRW(totalCost)}
      </Typography>
      <Typography color="textSecondary" letterSpacing="0.64px">
        {globalT('format.number.quantity', { value: numberOfCustomers })} 고객사
      </Typography>
    </Paper>
  );
}
