import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  calculateDataMax,
  formatChartAxis,
  formatChartLabel,
} from '@utils/chart';
import { formatDate } from '@utils/formatter';

type Props = {
  data?: Array<RevenueTrend>;
  dataKey?: keyof RevenueTrend;
};
export default function DashboardInvoiceChart({
  data = [],
  dataKey = 'TotalAmount',
}: Props) {
  const { t } = useTranslation('global', { keyPrefix: 'format' });
  const fill = useMemo(() => {
    switch (dataKey) {
      case 'AWSAmount':
        return 'url(#colorDashboardInvoiceAWSGradient)';
      case 'NCPAmount':
        return 'url(#colorDashboardInvoiceNCPGradient)';
      case 'NHNAmount':
        return 'url(#colorDashboardInvoiceNHNGradient)';
      default:
        return 'url(#colorDashboardInvoiceTotalGradient)';
    }
  }, [dataKey]);
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} barSize={32}>
        <XAxis
          dataKey="Month"
          stroke="none"
          strokeWidth={0}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={16}
          tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
          padding={{ left: 16, right: 16 }}
        />
        <YAxis
          type="number"
          scale="linear"
          stroke="none"
          strokeWidth={0}
          tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
          tickLine={false}
          tickSize={0}
          tickMargin={8}
          tickFormatter={formatChartAxis}
          domain={[0, calculateDataMax]}
          padding={{ top: 8, bottom: 8 }}
        />
        <defs>
          <linearGradient
            id="colorDashboardInvoiceTotalGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#E17A00" />
            <stop offset="1" stopColor="#EBC781" />
          </linearGradient>
          <linearGradient
            id="colorDashboardInvoiceAWSGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#B9DEAC" />
            <stop offset="1" stopColor="#DBE9A6" />
          </linearGradient>
          <linearGradient
            id="colorDashboardInvoiceNCPGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#2783A0" />
            <stop offset="1" stopColor="#7DB7BE" />
          </linearGradient>
          <linearGradient
            id="colorDashboardInvoiceNHNGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
          >
            <stop offset="0" stopColor="#1A3466" />
            <stop offset="1" stopColor="#A2D0EB" />
          </linearGradient>
        </defs>
        <Tooltip
          cursor={false}
          content={({ active, payload, label }) => {
            if (!active || !payload?.length) {
              return null;
            }
            return (
              <Box
                sx={{
                  borderRadius: '24px',
                  background: '#FFFFFF',
                  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                  backdropFilter: 'blur(3px)',
                  p: '24px',
                }}
              >
                <Typography color="textSecondary" mb="16px">
                  {formatDate(label, 'YYYY.MM')}
                </Typography>
                {typeof payload[0].value === 'number' && (
                  <Typography fontWeight={500}>
                    {t('currency.krw', { value: payload[0].value })}
                  </Typography>
                )}
              </Box>
            );
          }}
        />
        <Bar dataKey={dataKey} fill={fill} radius={8}>
          <LabelList
            dataKey={dataKey}
            position="top"
            fontSize={14}
            fill="#777B86"
            formatter={formatChartLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
