import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { OrganizationService } from '@api/services';
import { CUSTOMER_FILTER_STATE } from '@atoms/page/filter';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useGetOrganizationList() {
  const filter = useRecoilValue(CUSTOMER_FILTER_STATE);
  return useSuspenseQuery({
    queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_LIST, filter],
    queryFn: ({ queryKey }) =>
      OrganizationService.getOrganizationList(
        queryKey[2] as OrganizationFilter,
      ),
  });
}
