import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetUsers } from '@api/queries/user';
import SimpleTable from '@components/table/simple';
import { MemberLink } from './styled';
import { UserRoleChip } from '@components/styled/chip';

interface IProps {
  isClient?: boolean;
}
export default function SettingsPermissionMemberShortTable({
  isClient = false,
}: IProps) {
  const { t } = useTranslation('table');
  const navigate = useNavigate();
  const { data: response } = useGetUsers(isClient);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<User>();
    return [
      columnHelper.accessor('FirstName', {
        id: 'FirstName',
      }),
      columnHelper.accessor('Email', {
        id: 'Email',
      }),
      columnHelper.accessor('Role', {
        id: 'Role',
        header: t('general.role'),
        cell: (v) => <UserRoleChip role={v.getValue()} />,
      }),
      columnHelper.display({
        id: 'Link',
        header: t('general.name'),
        cell: (v) => (
          <MemberLink
            name={v.row.original.FirstName}
            email={v.row.original.Email}
            href={`/settings/${isClient ? 'customer' : 'permission'}/${
              v.row.original.UID
            }`}
            onClick={() =>
              navigate(
                `/settings/${isClient ? 'customer' : 'permission'}/${
                  v.row.original.UID
                }`,
              )
            }
          />
        ),
      }),
    ];
  }, [t, isClient, navigate]);
  return (
    <>
      <SimpleTable
        size="compact"
        columns={columns}
        data={response?.data}
        columnVisibility={{
          FirstName: false,
          Email: false,
          ...(isClient && {
            Role: false,
          }),
        }}
        enableGlobalFilter
      />
    </>
  );
}
