import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tab } from '@mui/material';
import { sumBy } from 'lodash';
import { Tabs } from '@heka/theme/src';
import { useGetRevenueTrend } from '@api/queries/dashboard';
import { useGetCompany } from '@api/queries/company';
import { DashboardNotFound } from '@features/dashboard/styled';
import DashboardInvoiceChart from '@components/chart/dashboard/invoice';

export default function InvoiceChart() {
  const { t } = useTranslation('dashboard');
  const [dataKey, setDataKey] = useState<keyof RevenueTrend>('TotalAmount');
  const { data: response } = useGetRevenueTrend();
  const { data: company } = useGetCompany();
  const isDataEmpty = useMemo(() => {
    return !sumBy(response?.data, dataKey);
  }, [response?.data, dataKey]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.invoiceTrend')}
      </Typography>
      <Tabs
        value={dataKey}
        onChange={(e, v) => setDataKey(v as keyof RevenueTrend)}
        sx={{ px: 0, mx: '-8px', mb: '16px' }}
      >
        <Tab value="TotalAmount" label="Total" />
        {!!company?.data?.AvailableCSP?.includes('aws') && (
          <Tab value="AWSAmount" label="AWS" />
        )}
        {!!company?.data?.AvailableCSP?.includes('ncp') && (
          <Tab value="NCPAmount" label="Naver Cloud" />
        )}
        {!!company?.data?.AvailableCSP?.includes('nhn') && (
          <Tab value="NHNAmount" label="NHN Cloud" />
        )}
      </Tabs>
      {!isDataEmpty ? (
        <DashboardInvoiceChart
          data={response?.data.map((v) => ({
            ...v,
            TotalAmount: v.TotalAmount,
            AWSAmount: v.AWSAmount,
            NCPAmount: v.NCPAmount,
            NHNAmount: v.NHNAmount,
          }))}
          dataKey={dataKey}
        />
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
