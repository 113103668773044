import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  ChipProps as MuiChipProps,
  getChipUtilityClass,
} from '@mui/material/Chip';
import { Cancel, CheckCircle, RemoveCircle } from '@mui/icons-material';

interface PermissionChipProps extends Pick<MuiChipProps, 'classes'> {
  status: 'Allow' | 'Forbidden' | 'Restricted';
}
const PermissionChip = styled(({ status, ...props }: PermissionChipProps) => {
  const label = useMemo(() => {
    switch (status) {
      case 'Allow':
        return '허용';
      case 'Forbidden':
        return '금지';
      case 'Restricted':
        return '제한';
    }
  }, [status]);
  const icon = useMemo(() => {
    switch (status) {
      case 'Allow':
        return <CheckCircle />;
      case 'Forbidden':
        return <Cancel />;
      case 'Restricted':
        return <RemoveCircle />;
      default:
        return undefined;
    }
  }, [status]);
  return <MuiChip {...props} size="selected" icon={icon} label={label} />;
})<PermissionChipProps>(({ status }) => ({
  ...(status === 'Allow' && {
    background: '#66BE2133',
  }),
  ...(status === 'Forbidden' && {
    background: '#C8003C1A',
  }),
  ...(status === 'Restricted' && {
    background: '#F7B83D33',
  }),
  [`> .${getChipUtilityClass('icon')}`]: {
    ...(status === 'Allow' && {
      color: '#66BE21',
    }),
    ...(status === 'Forbidden' && {
      color: '#C8003CCC',
    }),
    ...(status === 'Restricted' && {
      color: '#F8C35C',
    }),
  },
}));

export default PermissionChip;
