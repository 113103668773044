import { useSuspenseQuery } from '@tanstack/react-query';
import { NHNSalesService } from '@api/services';
import { NHN_SALES_KEY } from '@constants/query';

export default function useGetNHNSalesAutoComplete() {
  return useSuspenseQuery({
    queryKey: NHN_SALES_KEY.GET_SALES_AUTOCOMPLETE,
    queryFn: () => NHNSalesService.getNHNSalesAutoComplete(),
  });
}
