import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';

type Props = Pick<
  ReturnType<typeof useFormik>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
> & {
  gap?: string;
};

export default function CustomerInformationForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  gap,
}: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.information' });
  const openPostcode = useDaumPostcodePopup();
  const handlePostcodeComplete = useCallback(
    (fieldName: string) => (address: Address) => {
      let fullAddress = address.address;
      let extraAddress = '';
      if (address.addressType === 'R') {
        if (address.bname !== '') {
          extraAddress += address.bname;
        }
        if (address.buildingName !== '') {
          extraAddress +=
            extraAddress !== ''
              ? `, ${address.buildingName}`
              : address.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }
      setFieldValue(fieldName, fullAddress);
    },
    [setFieldValue],
  );
  const handlePostcodeOpen = useCallback(
    async (fieldName: string) => {
      try {
        await openPostcode({
          popupTitle: '주소 검색',
          onComplete: handlePostcodeComplete(fieldName),
        });
      } catch (e) {
        console.error(e);
      }
    },
    [openPostcode, handlePostcodeComplete],
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: gap ?? '48px' }}>
      <Box>
        <Typography letterSpacing="0.64px" mb="24px">
          {t('text.basic')}
        </Typography>
        <Grid container rowSpacing="16px" columnSpacing="24px">
          <Grid xs={6}>
            <TextField
              name="Name"
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.companyName')}
              placeholder={t('holder.companyName')}
              error={Boolean(errors.Name && touched.Name)}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="Representative"
              value={values.Representative}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.representative')}
              placeholder={t('holder.representative')}
              error={Boolean(errors.Representative && touched.Representative)}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="RegistrationNumber"
              value={values.RegistrationNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.registrationNumber')}
              placeholder={t('holder.registrationNumber')}
              error={Boolean(
                errors.RegistrationNumber && touched.RegistrationNumber,
              )}
              fullWidth
            />
          </Grid>
          <Grid
            xs={6}
            sx={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}
          >
            <TextField
              name="BusinessLocation"
              value={values.BusinessLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.businessAddress')}
              placeholder={t('holder.businessAddress')}
              error={Boolean(
                errors.BusinessLocation && touched.BusinessLocation,
              )}
              sx={{ flex: 1 }}
            />
            <Button
              color="sub"
              onClick={() => handlePostcodeOpen('BusinessLocation')}
            >
              {t('button.searchAddress')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography letterSpacing="0.64px" mb="24px">
          {t('text.additional')}
        </Typography>
        <Grid container rowSpacing="16px" columnSpacing="24px">
          <Grid xs={6}>
            <TextField
              name="Telephone"
              value={values.Telephone}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.phone')}
              placeholder={t('holder.phone')}
              error={Boolean(errors.Telephone && touched.Telephone)}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="TypeOfBusiness"
              value={values.TypeOfBusiness}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.businessType')}
              placeholder={t('holder.businessType')}
              error={Boolean(errors.TypeOfBusiness && touched.TypeOfBusiness)}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="Website"
              value={values.Website}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.website')}
              placeholder={t('holder.website')}
              error={Boolean(errors.Website && touched.Website)}
              fullWidth
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="ItemOfBusiness"
              value={values.ItemOfBusiness}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.businessItem')}
              placeholder={t('holder.businessItem')}
              error={Boolean(errors.ItemOfBusiness && touched.ItemOfBusiness)}
              fullWidth
            />
          </Grid>
          <Grid
            xs={6}
            sx={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}
          >
            <TextField
              name="HeadOfficeLocation"
              value={values.HeadOfficeLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('label.registeredAddress')}
              placeholder={t('holder.registeredAddress')}
              error={Boolean(
                errors.HeadOfficeLocation && touched.HeadOfficeLocation,
              )}
              sx={{ flex: 1 }}
            />
            <Button
              color="sub"
              onClick={() => handlePostcodeOpen('HeadOfficeLocation')}
            >
              {t('button.searchAddress')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
