import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import SettingsPermissionForm from '@features/settings/common/form/permission';

type Props = Pick<ReturnType<typeof useFormik>, 'values' | 'setFieldValue'>;

export default function UserPermission({ values, setFieldValue }: Props) {
  const { t } = useTranslation('settings');
  return (
    <Box sx={{ flex: 1 }}>
      <Typography
        color="text.main"
        fontSize="18px"
        fontWeight={500}
        letterSpacing="0.72px"
        mb="32px"
      >
        {t('text.userInformation')}
      </Typography>
      <SettingsPermissionForm
        values={values}
        setFieldValue={setFieldValue}
        valueKey="Organizations"
      />
    </Box>
  );
}
