import { useMutation } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { AUTH_KEY } from '@constants/query';
import { encryptLocalStorage } from '@utils/crypto';
import STORAGE_KEY from '@constants/storage';

export default function useSetInitialPassword() {
  return useMutation({
    mutationKey: AUTH_KEY.INITIAL_PASSWORD,
    mutationFn: (v: InitialPassword) => UserService.setInitialPassword(v),
    onSuccess: ({ data }) => {
      encryptLocalStorage(STORAGE_KEY.ACCESS_TOKEN, data.AccessToken);
    },
  });
}
