import { useCallback, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
  Typography,
  Paper,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { getPickersCalendarHeaderUtilityClass } from '@mui/x-date-pickers/PickersCalendarHeader/pickersCalendarHeaderClasses';
import { DateRange } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useToggle } from '@hooks';
import { AWS_COST_FILTER_STATE } from '@atoms/page/filter';
import { formatDate } from '@utils/formatter';

export default function DateRangeFilter() {
  const [filter, setFilter] = useRecoilState(AWS_COST_FILTER_STATE);
  const [isFrom, setIsFrom] = useState(true);
  const [FromDate, setFromDate] = useState<dayjs.Dayjs>(dayjs(filter.FromDate));
  const [ToDate, setToDate] = useState<dayjs.Dayjs>(dayjs(filter.ToDate));
  const [openPicker, togglePicker] = useToggle();
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleFromDate = useCallback((newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setFromDate(newValue);
    }
  }, []);
  const handleToDate = useCallback((newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setToDate(newValue);
    }
  }, []);
  const handleClose = useCallback(() => {
    setIsFrom(true);
    setFromDate(dayjs(filter.FromDate));
    setToDate(dayjs(filter.ToDate));
    togglePicker();
  }, [filter.FromDate, filter.ToDate, togglePicker]);
  const applyChange = useCallback(() => {
    setFilter((v) => ({
      ...v,
      FromDate: FromDate.toDate(),
      ToDate: ToDate.toDate(),
    }));
    setIsFrom(true);
    togglePicker();
  }, [FromDate, ToDate, setFilter, togglePicker]);
  return (
    <>
      <Button
        ref={ref}
        color="sub"
        onClick={togglePicker}
        startIcon={<DateRange />}
      >
        {formatDate(filter.FromDate)} ~ {formatDate(filter.ToDate)}
      </Button>
      <Popper
        open={openPicker}
        anchorEl={ref.current}
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          {isFrom ? (
            <Paper>
              <Typography color="text.main" fontWeight={500} letterSpacing={0}>
                시작일 설정
              </Typography>
              <DateCalendar
                value={FromDate}
                onChange={handleFromDate}
                showDaysOutsideCurrentMonth
                disableFuture
                sx={{
                  [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                    px: 0,
                  },
                }}
              />
              <Button
                color="emphasis1"
                onClick={() => setIsFrom(false)}
                fullWidth
              >
                종료일 설정
              </Button>
            </Paper>
          ) : (
            <Paper>
              <Typography color="text.main" fontWeight={500} letterSpacing={0}>
                종료일 설정
              </Typography>
              <DateCalendar
                value={ToDate}
                onChange={handleToDate}
                shouldDisableDate={(v) => v.isBefore(FromDate, 'days')}
                shouldDisableMonth={(v) => v.isBefore(FromDate, 'months')}
                shouldDisableYear={(v) => v.isBefore(FromDate, 'years')}
                showDaysOutsideCurrentMonth
                disableFuture
                sx={{
                  [`> .${getPickersCalendarHeaderUtilityClass('root')}`]: {
                    px: 0,
                  },
                }}
              />
              <Button color="emphasis1" onClick={applyChange} fullWidth>
                필터 적용
              </Button>
            </Paper>
          )}
        </ClickAwayListener>
      </Popper>
    </>
  );
}
