import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import QueryString from 'qs';
import { Link } from '@heka/theme';
import { useLogin } from '@api/queries/auth';

function useLoginForm(): UseFormValidation<LoginRequest> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Email: '',
      Password: '',
      Refresh: false,
    },
    validationSchema: Yup.object().shape({
      Email: Yup.string()
        .email(t('auth.email'))
        .defined(t('common.required.base')),
      Password: Yup.string()
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          t('auth.password.special'),
        )
        .min(8, t('auth.password.length'))
        .defined(t('common.required.base')),
      Refresh: Yup.boolean(),
    }),
  };
}

export default function LoginPage() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutateAsync, isPending } = useLogin();
  const { initialValues, validationSchema } = useLoginForm();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (v) => {
        try {
          await mutateAsync(v);
          const searchParams = QueryString.parse(search, {
            ignoreQueryPrefix: true,
          });
          const nextUrl = searchParams['nextUrl'];
          if (typeof nextUrl === 'string') {
            navigate(nextUrl);
          } else {
            navigate('/');
          }
        } catch (e) {
          console.error(e);
        }
      },
    });
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          pt: '24px',
          pb: '40px',
        }}
      >
        <Typography fontSize="20px" fontWeight="500" letterSpacing="1.6px">
          {t('login.title')}
        </Typography>
        <Typography fontWeight="500">{t('login.description')}</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ pb: '40px' }}>
        <Box sx={{ pb: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              py: '24px',
            }}
          >
            <TextField
              name="Email"
              value={values.Email}
              error={Boolean(errors.Email && touched.Email)}
              helperText={
                Boolean(errors.Email && touched.Email) && String(errors.Email)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('common.form.label.email')}
              placeholder={t('common.form.holder.email')}
              type="email"
              inputMode="email"
              autoComplete="username"
              autoCapitalize="off"
              fullWidth
            />
            <TextField
              name="Password"
              value={values.Password}
              error={Boolean(errors.Password && touched.Password)}
              helperText={
                Boolean(errors.Password && touched.Password) &&
                String(errors.Password)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('common.form.label.password')}
              placeholder={t('common.form.holder.password')}
              type="password"
              inputMode="text"
              autoComplete="current-password"
              autoCapitalize="off"
              fullWidth
            />
          </Box>
          <FormControlLabel
            name="Refresh"
            value={values.Refresh}
            onChange={handleChange}
            onBlur={handleBlur}
            control={<Checkbox color="emphasis" />}
            label={t('common.form.label.keepLoggedIn')}
          />
        </Box>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          fullWidth
        >
          {t('common.cta.login')}
        </LoadingButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          pt: '24px',
        }}
      >
        <Link size="comfort" color="calm" href="/register">
          {t('common.cta.register')}
        </Link>
        <Link size="comfort" color="calm" href="/login/recover">
          {t('common.cta.recovery')}
        </Link>
      </Box>
    </>
  );
}
