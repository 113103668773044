import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useUpdateInvoiceStatus() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: INVOICE_KEY.UPDATE_INVOICE_STATUS,
    mutationFn: ({
      InvoiceId,
      ...v
    }: UpdateInvoiceStatus & { InvoiceId: string }) =>
      InvoiceService.updateInvoiceStatus(InvoiceId, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['INVOICE'] });
    },
  });
}
