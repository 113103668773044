import { useMutation } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useCheckPassword() {
  return useMutation({
    mutationKey: USER_KEY.CHECK_PASSWORD,
    mutationFn: (v: CheckPassword) => UserService.checkPassword(v),
  });
}
