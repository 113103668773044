import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Typography, IconButton } from '@mui/material';
import { Add, Delete, KeyboardArrowRight } from '@mui/icons-material';
import { useGetOrganizationList } from '@api/queries/organization';
import CreateCustomerModal from '@features/customer/modal/customer/create';
import DeleteCustomerModal from '@features/customer/modal/customer/delete';
import { useToggle } from '@hooks';
import SimpleTable from '@components/table/simple';
import { ContractChip, CustomerChip } from '@components/styled/chip';
import { TableEmptyState } from '@components/styled/state';
import { formatDate, formatKRW, formatLocaleString } from '@utils/formatter';
import CustomerFilter from '../filter';

export default function CustomerTable() {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const { t: globalT } = useTranslation('global');
  const [openCreate, toggleCreate] = useToggle();
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: response } = useGetOrganizationList();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationList>();
    return [
      columnHelper.accessor('Status', {
        id: 'Status',
        header: t('customer.status'),
        cell: (v) => <CustomerChip status={v.getValue()} />,
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('ContractStatus', {
        id: 'ContractStatus',
        header: t('customer.contractStatus'),
        cell: (v) => {
          const status = v.getValue();
          switch (status) {
            case 'active':
            case 'scheduled':
            case 'expired':
              return <ContractChip status={status} />;
            default:
              return '';
          }
        },
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('customer.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('NumberOfAWSAccounts', {
        id: 'NumberOfAWSAccounts',
        header: t('customer.numberOfAWSAccounts'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('NumberOfNCPAccounts', {
        id: 'NumberOfNCPAccounts',
        header: t('customer.numberOfNCPAccounts'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('NumberOfNHNAccounts', {
        id: 'NumberOfNHNAccounts',
        header: t('customer.numberOfNHNAccounts'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('NumberOfContacts', {
        id: 'NumberOfContacts',
        header: t('customer.numberOfContacts'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('NumberOfAdditionalServices', {
        id: 'NumberOfAdditionalServices',
        header: t('customer.numberOfAdditionalServices'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('NumberOfDiscounts', {
        id: 'NumberOfDiscounts',
        header: t('customer.numberOfDiscounts'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('LastInvoicedAmount', {
        id: 'LastInvoicedAmount',
        header: t('customer.invoicedAmount'),
        cell: (v) => formatKRW(v.getValue()),
      }),
      columnHelper.accessor('LastInvoiced', {
        id: 'LastInvoiced',
        header: t('customer.lastInvoiced'),
        cell: (v) =>
          v.getValue()
            ? formatDate(v.getValue(), globalT('format.date.yearMonth'))
            : '-',
      }),
      columnHelper.accessor('InvoiceStatus', {
        id: 'InvoiceStatus',
        header: t('customer.invoiceStatus'),
        cell: (v) =>
          v.getValue()
            ? globalT(`chip.invoice.${v.getValue() as InvoiceStatus}`)
            : '-',
      }),
      columnHelper.display({
        id: 'Delete',
        header: globalT('button.delete'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Details',
        header: t('general.details'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            href={`/customer/${v.row.original.UID}`}
          >
            <KeyboardArrowRight />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t, globalT]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data}
        title={t('title.customer.list')}
        emptyState={
          <TableEmptyState>
            <Typography color="text.main">
              {customerT('table.empty.customer')}
            </Typography>
            <Button color="sub" startIcon={<Add />} onClick={toggleCreate}>
              {customerT('button.createFirst')}
            </Button>
          </TableEmptyState>
        }
        enableGlobalFilter
        enablePagination
        enableRoute
      >
        <CustomerFilter />
        <Button onClick={toggleCreate} startIcon={<Add />}>
          {customerT('button.create')}
        </Button>
      </SimpleTable>
      <CreateCustomerModal open={openCreate} onClose={toggleCreate} />
      <DeleteCustomerModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        UID={toDelete}
      />
    </>
  );
}
