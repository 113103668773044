import { useQuery } from '@tanstack/react-query';
import { decodeToken, isExpired } from 'react-jwt';
import { decryptLocalStorage } from '@utils/crypto';
import { TOKEN_KEY } from '@constants/query';
import STORAGE_KEY from '@constants/storage';

export default function useGetTokenData() {
  return useQuery({
    queryKey: TOKEN_KEY.GET_TOKEN_DATA,
    queryFn: async () => {
      return new Promise<TokenData | null>(function (resolve) {
        const token = decryptLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
        if (token !== null) {
          const decodedToken = decodeToken<TokenData>(token);
          const expired = isExpired(token);
          if (!expired && decodedToken) {
            resolve(decodedToken);
            return;
          }
        }
        localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
        localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        resolve(null);
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 30 * 1000,
    refetchIntervalInBackground: true,
  });
}
