import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import ReportMultipleChart from '@components/chart/report/multiple';
import ReportTable from '@components/table/report';
import { formatDate, formatKRW } from '@utils/formatter';
import { ReportContentSection } from './styled';

export default function ReportSalesSection({
  SalesPurchase,
  SalesSupply,
  SalesMargin,
}: Pick<ReportContent, 'SalesPurchase' | 'SalesSupply' | 'SalesMargin'>) {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const dateList = useMemo(() => {
    if (!_.isEmpty(SalesMargin)) {
      return Object.keys(Object.values(SalesMargin)[0].DateList);
    }
    return [];
  }, [SalesMargin]);
  const purchasePinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('report.customerName'),
        cell: (v) => {
          if (v.getValue() === 'HEKA_TABLE_TOTAL') {
            return t('report.totalCost');
          }
          return v.getValue();
        },
        size: 140,
        minSize: 140,
        maxSize: 140,
      }),
      columnHelper.accessor('Total', {
        id: 'Total',
        header: t('report.totalPurchase'),
        cell: (v) => formatKRW(v.getValue()),
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const supplyPinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('report.customerName'),
        cell: (v) => {
          if (v.getValue() === 'HEKA_TABLE_TOTAL') {
            return t('report.totalCost');
          }
          return v.getValue();
        },
        size: 140,
        minSize: 140,
        maxSize: 140,
      }),
      columnHelper.accessor('Total', {
        id: 'Total',
        header: t('report.totalSupply'),
        cell: (v) => formatKRW(v.getValue()),
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const marginPinnedColumns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('report.customerName'),
        cell: (v) => {
          if (v.getValue() === 'HEKA_TABLE_TOTAL') {
            return t('report.totalCost');
          }
          return v.getValue();
        },
        size: 140,
        minSize: 140,
        maxSize: 140,
      }),
      columnHelper.accessor('Total', {
        id: 'Total',
        header: t('report.totalMargin'),
        cell: (v) => formatKRW(v.getValue()),
        size: 160,
        minSize: 160,
        maxSize: 160,
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    return dateList.map((date) =>
      columnHelper.accessor(date, {
        id: date,
        header: formatDate(dayjs(date), 'YYYY.MM'),
        cell: (v) => formatKRW(v.getValue() ?? 0),
        meta: {
          align: 'right',
        },
      }),
    );
  }, [dateList]);
  const chartData = useMemo(() => {
    let obj: Record<
      string,
      { Date: Date; Purchase: number; Supply: number; Margin: number }
    > = {};
    Object.keys(SalesPurchase).forEach((org) => {
      Object.keys(SalesPurchase[org].DateList).forEach((date) => {
        if (_.has(obj, date)) {
          obj[date].Purchase += SalesPurchase[org].DateList[date].Total;
        } else {
          obj = _.assign(obj, {
            [date]: {
              Date: dayjs(date).toDate(),
              Purchase: SalesPurchase[org].DateList[date].Total,
              Supply: 0,
              Margin: 0,
            },
          });
        }
      });
    });
    Object.keys(SalesSupply).forEach((org) => {
      Object.keys(SalesSupply[org].DateList).forEach((date) => {
        if (_.has(obj, date)) {
          obj[date].Supply += SalesSupply[org].DateList[date].Total;
        } else {
          obj = _.assign(obj, {
            [date]: {
              Date: dayjs(date).toDate(),
              Purchase: 0,
              Supply: SalesSupply[org].DateList[date].Total,
              Margin: 0,
            },
          });
        }
      });
    });
    Object.keys(SalesMargin).forEach((org) => {
      Object.keys(SalesMargin[org].DateList).forEach((date) => {
        if (_.has(obj, date)) {
          obj[date].Margin += SalesMargin[org].DateList[date].Total;
        } else {
          obj = _.assign(obj, {
            [date]: {
              Date: dayjs(date).toDate(),
              Purchase: 0,
              Supply: 0,
              Margin: SalesMargin[org].DateList[date].Total,
            },
          });
        }
      });
    });
    return Object.values(obj);
  }, [SalesPurchase, SalesSupply, SalesMargin]);
  const purchaseTableData = useMemo(() => {
    let obj: Record<
      string,
      {
        Name: string;
        Total: number;
        Data: Record<string, number>;
      }
    > = {
      Total: {
        Name: 'HEKA_TABLE_TOTAL',
        Total: 0,
        Data: {},
      },
    };
    Object.keys(SalesPurchase).forEach((org) => {
      if (!_.has(obj, org)) {
        obj = _.assign(obj, {
          [org]: {
            Name: SalesPurchase[org].Name,
            Total: SalesPurchase[org].TotalCost,
            Data: {},
          },
        });
      }
      obj['Total'].Total += SalesPurchase[org].TotalCost;
      Object.keys(SalesPurchase[org].DateList).forEach((date) => {
        if (!_.has(obj['Total'].Data, date)) {
          obj['Total'].Data[date] = SalesPurchase[org].DateList[date].Total;
        } else {
          obj['Total'].Data[date] += SalesPurchase[org].DateList[date].Total;
        }
        if (!_.has(obj[org].Data, date)) {
          obj[org].Data[date] = SalesPurchase[org].DateList[date].Total;
        } else {
          obj[org].Data[date] += SalesPurchase[org].DateList[date].Total;
        }
      });
    });
    const result: Array<Record<string, string | number>> = [];
    Object.keys(obj).forEach((org) => {
      result.push({
        Name: obj[org].Name,
        Total: obj[org].Total,
        ...obj[org].Data,
      });
    });
    return result;
  }, [SalesPurchase]);
  const supplyTableData = useMemo(() => {
    let obj: Record<
      string,
      {
        Name: string;
        Total: number;
        Data: Record<string, number>;
      }
    > = {
      Total: {
        Name: 'HEKA_TABLE_TOTAL',
        Total: 0,
        Data: {},
      },
    };
    Object.keys(SalesSupply).forEach((org) => {
      if (!_.has(obj, org)) {
        obj = _.assign(obj, {
          [org]: {
            Name: SalesSupply[org].Name,
            Total: SalesSupply[org].TotalCost,
            Data: {},
          },
        });
      }
      obj['Total'].Total += SalesSupply[org].TotalCost;
      Object.keys(SalesSupply[org].DateList).forEach((date) => {
        if (!_.has(obj['Total'].Data, date)) {
          obj['Total'].Data[date] = SalesSupply[org].DateList[date].Total;
        } else {
          obj['Total'].Data[date] += SalesSupply[org].DateList[date].Total;
        }
        if (!_.has(obj[org].Data, date)) {
          obj[org].Data[date] = SalesSupply[org].DateList[date].Total;
        } else {
          obj[org].Data[date] += SalesSupply[org].DateList[date].Total;
        }
      });
    });
    const result: Array<Record<string, string | number>> = [];
    Object.keys(obj).forEach((org) => {
      result.push({
        Name: obj[org].Name,
        Total: obj[org].Total,
        ...obj[org].Data,
      });
    });
    return result;
  }, [SalesSupply]);
  const marginTableData = useMemo(() => {
    let obj: Record<
      string,
      {
        Name: string;
        Total: number;
        Data: Record<string, number>;
      }
    > = {
      Total: {
        Name: 'HEKA_TABLE_TOTAL',
        Total: 0,
        Data: {},
      },
    };
    Object.keys(SalesMargin).forEach((org) => {
      if (!_.has(obj, org)) {
        obj = _.assign(obj, {
          [org]: {
            Name: SalesMargin[org].Name,
            Total: SalesMargin[org].TotalCost,
            Data: {},
          },
        });
      }
      obj['Total'].Total += SalesMargin[org].TotalCost;
      Object.keys(SalesMargin[org].DateList).forEach((date) => {
        if (!_.has(obj['Total'].Data, date)) {
          obj['Total'].Data[date] = SalesMargin[org].DateList[date].Total;
        } else {
          obj['Total'].Data[date] += SalesMargin[org].DateList[date].Total;
        }
        if (!_.has(obj[org].Data, date)) {
          obj[org].Data[date] = SalesMargin[org].DateList[date].Total;
        } else {
          obj[org].Data[date] += SalesMargin[org].DateList[date].Total;
        }
      });
    });
    const result: Array<Record<string, string | number>> = [];
    Object.keys(obj).forEach((org) => {
      result.push({
        Name: obj[org].Name,
        Total: obj[org].Total,
        ...obj[org].Data,
      });
    });
    return result;
  }, [SalesMargin]);
  return (
    <ReportContentSection title={reportT('title.sales')}>
      <ReportMultipleChart
        data={chartData}
        dateKey="Date"
        labelList={[
          {
            label: reportT('label.salesPurchase'),
            valueKey: 'Purchase',
            color: 'url(#colorReportSalesPurchase)',
            gradient: 'linear-gradient(180deg, #7590EF 0%, #78C1EB 100%)',
          },
          {
            label: reportT('label.salesSupply'),
            valueKey: 'Supply',
            color: 'url(#colorReportSalesSupply)',
            gradient: 'linear-gradient(180deg, #F26B94 0%, #E9A2D9 100%)',
          },
          {
            label: reportT('label.salesMargin'),
            valueKey: 'Margin',
            color: 'url(#colorReportSalesMargin)',
            gradient: 'linear-gradient(180deg, #B9DEAC 0%, #DBE9A6 100%)',
          },
        ]}
        Gradient={
          <defs>
            <linearGradient
              id="colorReportSalesPurchase"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#7590EF" />
              <stop offset="1" stopColor="#78C1EB" />
            </linearGradient>
            <linearGradient
              id="colorReportSalesSupply"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#F26B94" />
              <stop offset="1" stopColor="#E9A2D9" />
            </linearGradient>
            <linearGradient
              id="colorReportSalesMargin"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
            >
              <stop offset="0" stopColor="#B9DEAC" />
              <stop offset="1" stopColor="#DBE9A6" />
            </linearGradient>
          </defs>
        }
      />
      <Typography letterSpacing="0.64px" mt="16px">
        {reportT('title.salesPurchase')}
      </Typography>
      <ReportTable
        data={purchaseTableData}
        pinnedColumns={purchasePinnedColumns}
        columns={columns}
      />
      <Typography letterSpacing="0.64px">
        {reportT('title.salesSupply')}
      </Typography>
      <ReportTable
        data={supplyTableData}
        pinnedColumns={supplyPinnedColumns}
        columns={columns}
      />
      <Typography letterSpacing="0.64px" mt="16px">
        {reportT('title.salesMargin')}
      </Typography>
      <ReportTable
        data={marginTableData}
        pinnedColumns={marginPinnedColumns}
        columns={columns}
      />
    </ReportContentSection>
  );
}
