import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { useGetNHNAssignedProjectList } from '@api/queries/nhn/account';
import SimpleTable from '@components/table/simple';
import { useRowSelect } from '@hooks';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

type Props = Pick<
  ReturnType<
    typeof useFormik<Omit<CreateContract, 'CSP'> & { CSP: CloudProvider | '' }>
  >,
  'values' | 'setFieldValue'
>;

type TableData = CloudAccount & {
  ProjectName: string;
  OrgName: string;
};

export default function NHNAccountTable({ values, setFieldValue }: Props) {
  const { t } = useTranslation('table');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { data: response } = useGetOrganizationForUI();
  const { data: projects } = useGetNHNAssignedProjectList();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<TableData>();
    return [
      columnHelper.display({
        id: 'Select',
        header: t('text.select'),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('ProjectName', {
        id: 'ProjectName',
        header: t('customer.projectName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OrgName', {
        id: 'OrgName',
        header: t('customer.projectOrgName'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t]);
  const tableData = useMemo<Array<TableData>>(() => {
    const projectList = projects?.data.filter((v) => v.OrgUID === UID) ?? [];
    return (
      response?.data.CloudAccounts.filter((v) => v.CSP === 'nhn').map((v) => {
        const projectInfo = projectList.find(
          (k) => k.ProjectInfo.ProjectId === v.AccountId,
        );
        if (projectInfo) {
          return {
            ...v,
            ProjectName: projectInfo.ProjectInfo.ProjectName,
            OrgName: projectInfo.ProjectInfo.OrgName,
          };
        }
        return {
          ...v,
          ProjectName: '',
          OrgName: '',
        };
      }) ?? []
    );
  }, [response?.data, projects?.data, UID]);
  const [selectedRows, onRowSelected, setSelectedRows] = useRowSelect(
    (value) => {
      const selected = tableData
        .filter((v, i) => value[i.toString()])
        .map((v) => v.AccountId);
      setFieldValue('AccountIdList', selected);
    },
  );
  useEffect(() => {
    setSelectedRows(
      tableData.reduce(
        (prev, current, idx) =>
          Object.assign(prev, {
            [idx]: values.AccountIdList.includes(current.AccountId),
          }),
        {},
      ) ?? {},
    );
  }, [setSelectedRows, tableData, values.AccountIdList]);
  return (
    <SimpleTable
      size="wide"
      columns={columns}
      data={tableData}
      rowSelection={{
        selectedRows,
        onRowSelected,
      }}
    />
  );
}
