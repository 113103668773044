import { useCallback, useMemo } from 'react';
import {
  useGetInitialData,
  useGetInitialDataSuspense,
} from '@api/queries/common';

export default function useCurrentInfo(suspense?: boolean) {
  const { company, user } = useGetInitialDataSuspense();
  const hasAWS = useMemo(() => {
    if (company?.data) {
      return company.data.AvailableCSP.includes('aws');
    }
    return false;
  }, [company?.data]);
  const hasNCP = useMemo(() => {
    if (company?.data) {
      return company.data.AvailableCSP.includes('ncp');
    }
    return false;
  }, [company?.data]);
  const hasNHN = useMemo(() => {
    if (company?.data) {
      return company.data.AvailableCSP.includes('nhn');
    }
    return false;
  }, [company?.data]);
  const hasRole = useCallback(
    (role: UserRole) => {
      if (user?.data) {
        switch (role) {
          case 'ROOT':
            return user.data.Role == 'ROOT';
          case 'ADMIN':
            return user.data.Role == 'ROOT' || user.data.Role == 'ADMIN';
          case 'MANAGER':
            return (
              user.data.Role == 'ROOT' ||
              user.data.Role == 'ADMIN' ||
              user.data.Role == 'MANAGER'
            );
          case 'CLIENT_ADMIN':
            return (
              user.data.Role == 'ROOT' ||
              user.data.Role == 'ADMIN' ||
              user.data.Role == 'MANAGER' ||
              user.data.Role == 'CLIENT_ADMIN'
            );
          case 'CLIENT_USER':
            return (
              user.data.Role == 'ROOT' ||
              user.data.Role == 'ADMIN' ||
              user.data.Role == 'MANAGER' ||
              user.data.Role == 'CLIENT_ADMIN' ||
              user.data.Role == 'CLIENT_USER'
            );
        }
      }
      return false;
    },
    [user?.data],
  );
  const isRoot = useMemo(() => {
    if (user?.data) {
      return user.data.Role === 'ROOT';
    }
    return false;
  }, [user?.data]);
  const isAdmin = useMemo(() => {
    if (user?.data) {
      return user.data.Role === 'ADMIN';
    }
    return false;
  }, [user?.data]);
  const isManager = useMemo(() => {
    if (user?.data) {
      return user.data.Role === 'MANAGER';
    }
    return false;
  }, [user?.data]);
  const isUser = useMemo(() => {
    if (user?.data) {
      return user.data.Role === 'CLIENT_USER';
    }
    return false;
  }, [user?.data]);
  return {
    hasAWS,
    hasNCP,
    hasNHN,
    hasRole,
    isRoot,
    isAdmin,
    isManager,
    isUser,
  };
}
