import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useConfirmVerification,
  useRegister,
  useSendVerification,
} from '@api/queries/auth';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

type RegisterForm = RegisterRequest & {
  Confirm: string;
  Code: string;
  AgreeTOC: boolean;
  AgreePP: boolean;
  AgreePIPA: boolean;
  AgreeAge: boolean;
};
function useRegisterForm(): UseFormValidation<RegisterForm> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      Email: '',
      Password: '',
      Confirm: '',
      FirstName: '',
      PhoneNumber: '',
      Name: '',
      Code: '',
      AgreeTOC: false,
      AgreePP: false,
      AgreePIPA: false,
      AgreeAge: false,
    },
    validationSchema: Yup.object().shape({
      Email: Yup.string()
        .email(t('auth.email'))
        .defined(t('common.required.base')),
      Password: Yup.string()
        .matches(/^.*[A-Z]+.*$/, t('auth.password.upper'))
        .matches(/^.*[a-z]+.*$/, t('auth.password.lower'))
        .matches(/^.*[0-9]+.*$/, t('auth.password.digit'))
        .matches(
          /^.*[~!@#$%^&*()_+=\-><?/`'".,|]+.*$/,
          t('auth.password.special'),
        )
        .min(8, t('auth.password.length'))
        .defined(t('common.required.base')),
      Confirm: Yup.string()
        .oneOf([Yup.ref('Password')], t('auth.confirm'))
        .defined(t('common.required.base')),
      FirstName: Yup.string().defined(t('common.required.base')),
      PhoneNumber: Yup.string().defined(t('common.required.base')),
      Name: Yup.string().defined(t('common.required.base')),
      Code: Yup.string().matches(/^[0-9]{6}$/, t('auth.code')),
      AgreeTOC: Yup.bool().oneOf([true]).defined(),
      AgreePP: Yup.bool().oneOf([true]).defined(),
      AgreePIPA: Yup.bool().oneOf([true]).defined(),
      AgreeAge: Yup.bool().oneOf([true]).defined(),
    }),
  };
}

export default function RegisterPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { t: globalT } = useTranslation('global');
  const [expandAgree, setExpandAgree] = useState(false);
  const [verifySent, setVerifySent] = useState(false);
  const { mutateAsync, isPending } = useRegister();
  const { mutateAsync: sendVerification } = useSendVerification();
  const { mutateAsync: confirmVerification } = useConfirmVerification();
  const { initialValues, validationSchema } = useRegisterForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        navigate('/register/done');
      } catch (e) {
        console.error(e);
      }
    },
  });
  const verifyEmail = useCallback(async () => {
    try {
      await sendVerification({ email: values.Email });
      setVerifySent(true);
    } catch (e) {
      console.error(e);
    }
  }, [sendVerification, values.Email]);
  const confirmCode = useCallback(async () => {
    try {
      await confirmVerification({ email: values.Email, code: values.Code });
    } catch (e) {
      console.error(e);
    }
  }, [sendVerification, values.Email, values.Code]);
  const checkedAll = useMemo(() => {
    return (
      values.AgreeTOC && values.AgreePP && values.AgreePIPA && values.AgreeAge
    );
  }, [values.AgreeTOC, values.AgreePP, values.AgreePIPA, values.AgreeAge]);
  const indeterminateAll = useMemo(() => {
    if (checkedAll) return false;
    return (
      values.AgreeTOC || values.AgreePP || values.AgreePIPA || values.AgreeAge
    );
  }, [
    checkedAll,
    values.AgreeTOC,
    values.AgreePP,
    values.AgreePIPA,
    values.AgreeAge,
  ]);
  const handleAllCheck = useCallback(async () => {
    const newValue = !checkedAll;
    await setFieldValue('AgreeTOC', newValue);
    await setFieldValue('AgreePP', newValue);
    await setFieldValue('AgreePIPA', newValue);
    await setFieldValue('AgreeAge', newValue);
  }, [checkedAll, setFieldValue]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel
          title={t('register.title')}
          description={t('register.description')}
        />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              py: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}
              >
                <TextField
                  name="Email"
                  value={values.Email}
                  error={Boolean(errors.Email && touched.Email)}
                  helperText={
                    Boolean(errors.Email && touched.Email) &&
                    String(errors.Email)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('common.form.label.email')}
                  placeholder={t('common.form.holder.email')}
                  type="email"
                  inputMode="email"
                  autoComplete="username"
                  autoCapitalize="off"
                  sx={{ flex: 1 }}
                />
                <Button
                  color="sub"
                  onClick={verifyEmail}
                  disabled={!touched.Email || Boolean(errors.Email)}
                  sx={{
                    minWidth: '140px',
                    ...(Boolean(errors.Email && touched.Email) && {
                      mb: '24.8px',
                    }),
                  }}
                >
                  {verifySent
                    ? t('register.cta.resend')
                    : t('register.cta.send')}
                </Button>
              </Box>
              {verifySent && (
                <Box
                  sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}
                >
                  <TextField
                    name="Code"
                    value={values.Code}
                    error={Boolean(errors.Code && touched.Code)}
                    helperText={
                      Boolean(errors.Code && touched.Code) &&
                      String(errors.Code)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('common.form.label.code')}
                    placeholder={t('common.form.holder.code')}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Check
                            sx={{
                              color: 'text.main',
                              width: '24px',
                              height: '24px',
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      flex: 1,
                      '> .MuiOutlinedInput-root': {
                        paddingRight: '8px',
                      },
                    }}
                  />
                  <Button
                    color="sub"
                    onClick={confirmCode}
                    sx={{
                      minWidth: '140px',
                      ...(Boolean(errors.Code && touched.Code) && {
                        mb: '24.8px',
                      }),
                    }}
                  >
                    {t('register.cta.verify')}
                  </Button>
                </Box>
              )}
              <TextField
                name="Password"
                value={values.Password}
                error={Boolean(errors.Password && touched.Password)}
                helperText={
                  Boolean(errors.Password && touched.Password) &&
                  String(errors.Password)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.password')}
                placeholder={t('common.form.holder.password')}
                type="password"
                inputMode="text"
                autoComplete="new-password"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="Confirm"
                value={values.Confirm}
                error={Boolean(errors.Confirm && touched.Confirm)}
                helperText={
                  Boolean(errors.Confirm && touched.Confirm) &&
                  String(errors.Confirm)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.confirm')}
                placeholder={t('common.form.holder.confirm')}
                type="password"
                inputMode="text"
                autoComplete="new-password"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="Name"
                value={values.Name}
                error={Boolean(errors.Name && touched.Name)}
                helperText={
                  Boolean(errors.Name && touched.Name) && String(errors.Name)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.company')}
                placeholder={t('common.form.holder.company')}
                type="text"
                inputMode="text"
                autoComplete="off"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="FirstName"
                value={values.FirstName}
                error={Boolean(errors.FirstName && touched.FirstName)}
                helperText={
                  Boolean(errors.FirstName && touched.FirstName) &&
                  String(errors.FirstName)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.name')}
                placeholder={t('common.form.holder.name')}
                type="text"
                inputMode="text"
                autoComplete="off"
                autoCapitalize="off"
                fullWidth
              />
              <TextField
                name="PhoneNumber"
                value={values.PhoneNumber}
                error={Boolean(errors.PhoneNumber && touched.PhoneNumber)}
                helperText={
                  Boolean(errors.PhoneNumber && touched.PhoneNumber) &&
                  String(errors.PhoneNumber)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('common.form.label.phone')}
                placeholder={t('common.form.holder.phone')}
                type="text"
                inputMode="text"
                autoComplete="off"
                autoCapitalize="off"
                fullWidth
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  p: '8px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="emphasis"
                      checked={checkedAll}
                      indeterminate={indeterminateAll}
                      onChange={handleAllCheck}
                    />
                  }
                  label="이용약관 전체 동의"
                />
                <SvgIcon
                  component={ExpandMore}
                  onClick={() => setExpandAgree((v) => !v)}
                  sx={{
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    ...(expandAgree && {
                      transform: 'rotate(180deg)',
                    }),
                  }}
                />
              </Box>
              <Collapse in={expandAgree}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    borderTop: '1px solid #EAEAEA',
                    p: '8px',
                  }}
                >
                  <FormControlLabel
                    name="AgreeTOC"
                    value={values.AgreeTOC}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    control={
                      <Checkbox color="emphasis" checked={values.AgreeTOC} />
                    }
                    label="[필수] 서비스 이용약관에 동의합니다"
                    componentsProps={{
                      typography: {
                        fontFamily: 'Noto Sans KR',
                      },
                    }}
                  />
                  <FormControlLabel
                    name="AgreePP"
                    value={values.AgreePP}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    control={
                      <Checkbox color="emphasis" checked={values.AgreePP} />
                    }
                    label="[필수] 개인정보 수집 및 이용에 동의합니다"
                    componentsProps={{
                      typography: {
                        fontFamily: 'Noto Sans KR',
                      },
                    }}
                  />
                  <FormControlLabel
                    name="AgreePIPA"
                    value={values.AgreePIPA}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    control={
                      <Checkbox color="emphasis" checked={values.AgreePIPA} />
                    }
                    label="[필수] 개인정보 처리자 의무 확인에 동의합니다"
                    componentsProps={{
                      typography: {
                        fontFamily: 'Noto Sans KR',
                      },
                    }}
                  />
                  <FormControlLabel
                    name="AgreeAge"
                    value={values.AgreeAge}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    control={
                      <Checkbox color="emphasis" checked={values.AgreeAge} />
                    }
                    label="[필수] 만 14세 이상입니다"
                    componentsProps={{
                      typography: {
                        fontFamily: 'Noto Sans KR',
                      },
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <Button color="cancel" onClick={() => navigate(-1)}>
          {globalT('button.goBack')}
        </Button>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {t('common.cta.register')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
