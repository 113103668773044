import { styled } from '@mui/material/styles';
import { Autocomplete, Box, InputAdornment } from '@mui/material';
import { getAutocompleteUtilityClass } from '@mui/material/Autocomplete';
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import type { InputBaseProps } from '@mui/material/InputBase';
import { Search } from '@mui/icons-material';
import { AutocompleteFilterInput } from '@components/styled/input';

const FilterPopperWrapper = styled('div')(({ theme }) => ({
  width: '100% !important',
  borderTop: '0.5px solid #E0E0E0',
  [`& .${getAutocompleteUtilityClass('paper')}`]: {
    margin: 0,
    color: 'inherit',
    // background: 'rgba(255, 255, 255, 0.98)',
    // borderBottomLeftRadius: '16px',
    // borderBottomRightRadius: '16px',
  },
  [`& .${getAutocompleteUtilityClass('listbox')}`]: {
    // background: 'rgba(255, 255, 255, 0.98)',
    padding: 0,
    width: '100%',
    // maxHeight: '400px',
    overflowY: 'auto',
    [`& .${getAutocompleteUtilityClass('option')}`]: {
      display: 'flex',
      minHeight: 'auto',
      alignItems: 'center',
      padding: '16px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.Mui-focused': {
        background: 'none',
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #f0f0f7',
      },
    },
  },
}));
function FilterAcPopperComponent({
  open,
  anchorEl = undefined,
  disablePortal = undefined,
  ...rest
}: {
  open: boolean;
  anchorEl?: any;
  disablePortal?: boolean;
}) {
  return <FilterPopperWrapper {...rest} />;
}

type FilterAutocompleteProps<Value> = Omit<
  AutocompleteProps<Value, true, true, false>,
  | 'renderInput'
  | 'PopperComponent'
  | 'PaperComponent'
  | 'disableCloseOnSelect'
  | 'disableClearable'
  | 'multiple'
  | 'freeSolo'
> &
  Pick<InputBaseProps, 'placeholder'>;
export function FilterAutocomplete<Value>({
  placeholder,
  ...props
}: FilterAutocompleteProps<Value>) {
  return (
    <Autocomplete
      renderInput={(params) => (
        <AutocompleteFilterInput
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          placeholder={placeholder}
          autoFocus
          fullWidth
        />
      )}
      renderTags={() => null}
      PopperComponent={FilterAcPopperComponent}
      PaperComponent={Box}
      disableCloseOnSelect
      disableClearable
      multiple
      {...props}
    />
  );
}
