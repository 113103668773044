import { useSuspenseQuery } from '@tanstack/react-query';
import { NHNAccountService } from '@api/services';
import { NHN_ACCOUNT_KEY } from '@constants/query';

export default function useGetNHNAccountHierarchy(unused = false) {
  return useSuspenseQuery({
    queryKey: [...NHN_ACCOUNT_KEY.GET_ACCOUNT_HIERARCHY, unused],
    queryFn: ({ queryKey }) =>
      NHNAccountService.getNHNAccountHierarchy(queryKey[2] as boolean),
  });
}
