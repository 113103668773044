import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Checkbox, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useGetOrganizationAccounts } from '@api/queries/aws/account';
import { useAWSHelper, useRowSelect } from '@hooks';
import SimpleTable from '@components/table/simple';

type Props = Pick<ReturnType<typeof useFormik>, 'values' | 'setFieldValue'>;

export default function CustomerAWSAccountForm({
  values,
  setFieldValue,
}: Props) {
  const { t } = useTranslation('table', { keyPrefix: 'customer' });
  const { t: tableT } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const { data: accounts } = useGetOrganizationAccounts();
  const { getAccountAlias } = useAWSHelper();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<
      AWSOrganizationAccount & { ManagedAlias?: string }
    >();
    return [
      columnHelper.display({
        id: 'Select',
        header: tableT('text.select'),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('accountAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ManagedAccount', {
        id: 'ManagedAccount',
        header: t('payerId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ManagedAlias', {
        id: 'ManagedAlias',
        header: t('payerAlias'),
        cell: (v) => v.getValue(),
      }),
    ];
  }, [t, tableT]);
  const tableData = useMemo(() => {
    return (
      accounts?.data.map((v) => ({
        ...v,
        ManagedAlias: getAccountAlias(v.ManagedAccount),
      })) ?? []
    );
  }, [getAccountAlias, accounts?.data]);
  const [selectedRows, onRowSelected, setSelectedRows] = useRowSelect(
    (value) => {
      const selected = tableData
        .filter((v, i) => value[i.toString()])
        .map((v) => v.AccountId);
      setFieldValue('AccountIdList', selected);
    },
  );
  useEffect(() => {
    setSelectedRows(
      tableData.reduce(
        (prev, current, idx) =>
          Object.assign(prev, {
            [idx]: values.AccountIdList.includes(current.AccountId),
          }),
        {},
      ) ?? {},
    );
  }, [setSelectedRows, tableData, values.AccountIdList]);
  return (
    <Box sx={{ width: '100%', flex: 1 }}>
      <Typography
        fontSize="18px"
        lineHeight="120%"
        fontWeight={500}
        letterSpacing="0.72px"
        mb="16px"
      >
        {customerT('text.awsAccountList')}
      </Typography>
      <SimpleTable
        size="wide"
        columns={columns}
        data={tableData}
        rowSelection={{
          selectedRows,
          onRowSelected,
        }}
        enableGlobalFilter
      />
    </Box>
  );
}
