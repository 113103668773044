import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  ChipProps as MuiChipProps,
  getChipUtilityClass,
} from '@mui/material/Chip';
import { CheckCircle, Dangerous, Warning } from '@mui/icons-material';

interface ContractChipProps extends Pick<MuiChipProps, 'classes'> {
  status?: ContractStatus;
}
const ContractChip = styled(
  ({ status = 'none', ...props }: ContractChipProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'chip.contract' });
    const label = useMemo(() => {
      return (
        <>
          {status === 'active' && (
            <CheckCircle sx={{ width: '12px', height: '12px' }} />
          )}
          {status === 'scheduled' && (
            <Warning sx={{ width: '12px', height: '12px' }} />
          )}
          {status === 'expired' && (
            <Dangerous sx={{ width: '12px', height: '12px' }} />
          )}
          {t(status)}
        </>
      );
    }, [t, status]);
    return <MuiChip {...props} label={label} />;
  },
)<ContractChipProps>(({ status = 'none' }) => ({
  color: '#3A4050',
  background: 'none',
  [`> .${getChipUtilityClass('label')}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    paddingLeft: 0,
    paddingRight: 0,
  },
  ...(status === 'active' && {
    color: '#66BE21',
  }),
  ...(status === 'scheduled' && {
    color: '#C86000',
  }),
  ...(status === 'expired' && {
    color: '#C8003C',
  }),
}));

export default ContractChip;
