import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReportService } from '@api/services';
import { REPORT_KEY } from '@constants/query';

export default function useUpdateReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: REPORT_KEY.UPDATE_REPORT,
    mutationFn: ({ UID, ...v }: CreateReport & { UID: string }) =>
      ReportService.updateReport(UID, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['REPORT'] });
    },
  });
}
