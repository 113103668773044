import { useSuspenseQuery } from '@tanstack/react-query';
import { NCPAccountService } from '@api/services';
import { NCP_ACCOUNT_KEY } from '@constants/query';

export default function useGetNCPMemberAccounts() {
  return useSuspenseQuery({
    queryKey: NCP_ACCOUNT_KEY.GET_MEMBER_ACCOUNTS,
    queryFn: () => NCPAccountService.getMemberAccounts(),
  });
}
