import {
  Bar,
  BarChart,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { calculateDataMax } from '@utils/chart';

export default function ReportMultipleChart({
  data = [],
  dateKey = 'Date',
  labelList,
  Gradient,
  barSize = 32,
}: ReportMultipleChartProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
      <ResponsiveContainer width="100%" height={328}>
        <BarChart data={data} barSize={barSize}>
          <XAxis
            dataKey={dateKey}
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(v) => formatDate(v, 'YYYY.MM')}
          />
          <YAxis
            type="number"
            scale="linear"
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={8}
            width={80}
            tickFormatter={(v) => formatLocaleString(v) ?? ''}
            domain={[0, calculateDataMax]}
          />
          {!!Gradient && <>{Gradient}</>}
          {labelList.map((item) => (
            <Bar
              key={`report_multiple_${item.valueKey}_${item.label}`}
              dataKey={item.valueKey}
              fill={item.color}
              radius={8}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {!!labelList.length && (
        <Box sx={{ display: 'flex', gap: '16px', pl: '88px' }}>
          {labelList.map((item) => (
            <Box
              key={`report_multiple_label_${item.valueKey}_${item.label}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  background: item.gradient ?? item.color,
                  borderRadius: '999px',
                }}
              />
              <Typography color="textSecondary" fontSize="12px">
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
