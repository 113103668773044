import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { InvoiceService, OrganizationService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

async function getInvoiceDetail(InvoiceId: string): Promise<{
  Invoices: Array<Invoice>;
  Company?: Organization;
  LastUpdated?: Date;
}> {
  try {
    const { data: invoices, extras } =
      await InvoiceService.getInvoicesByInvoiceId(InvoiceId);
    if (!invoices.length) {
      return {
        Invoices: [],
        Company: undefined,
        LastUpdated: undefined,
      };
    }
    const { data: company } = await OrganizationService.getOrganizationById(
      invoices[0].OrganizationId,
    );
    return {
      Invoices: invoices,
      Company: company,
      LastUpdated: extras.LastUpdated,
    };
  } catch (e) {
    return {
      Invoices: [],
      Company: undefined,
      LastUpdated: undefined,
    };
  }
}

export default function useGetInvoiceDetail(InvoiceId?: string) {
  const [Id, setId] = useState(InvoiceId ?? '');
  const query = useSuspenseQuery({
    queryKey: [...INVOICE_KEY.GET_INVOICE_DETAIL, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) ? getInvoiceDetail(queryKey[2]) : null,
  });
  return { ...query, setId };
}
