import AxiosClient from '@api/client';
import { formatDate } from '@utils/formatter';

const axios = AxiosClient.getInstance();
const PREFIX = '/organization' as const;

async function getContracts(OrganizationId: string) {
  const { data } = await axios.get<MultipleResponse<Contract>>(
    `${PREFIX}/${OrganizationId}/contract`,
  );
  return data;
}

async function getContractById(OrganizationId: string, ContractId: string) {
  const { data } = await axios.get<SingleResponse<Contract>>(
    `${PREFIX}/${OrganizationId}/contract/${ContractId}`,
  );
  return data;
}

async function createContract(OrganizationId: string, body: CreateContract) {
  const { data } = await axios.post<SingleResponse<Contract>>(
    `${PREFIX}/${OrganizationId}/contract`,
    {
      ...body,
      StartDate: formatDate(body.StartDate, 'YYYY-MM-DDT00:00:00'),
      EndDate: formatDate(body.EndDate, 'YYYY-MM-DDT00:00:00'),
    },
  );
  return data;
}

async function updateContract(
  OrganizationId: string,
  ContractId: string,
  body: UpdateContract,
) {
  const { data } = await axios.put<SingleResponse<Contract>>(
    `${PREFIX}/${OrganizationId}/contract/${ContractId}`,
    {
      ...body,
      StartDate: formatDate(body.StartDate, 'YYYY-MM-DDT00:00:00'),
      EndDate: formatDate(body.EndDate, 'YYYY-MM-DDT00:00:00'),
    },
  );
  return data;
}

async function deleteContract(OrganizationId: string, ContractId: string) {
  const { data } = await axios.delete(
    `${PREFIX}/${OrganizationId}/contract/${ContractId}`,
  );
  return data;
}

export default {
  getContracts,
  getContractById,
  createContract,
  updateContract,
  deleteContract,
};
