import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateCloudAccount } from '@api/queries/organization';
import FormModal from '@components/modal/form';
import { CUSTOMER_UID_STATE } from '@atoms/page/params';

export default function EditNCPAccountModal({
  open,
  onClose,
  item,
}: ModalProps & { item?: NCPAccount }) {
  const { t } = useTranslation('customer', {
    keyPrefix: 'form.account.ncp.edit',
  });
  const { t: globalT } = useTranslation('global');
  const UID = useRecoilValue(CUSTOMER_UID_STATE);
  const { mutateAsync, isPending } = useUpdateCloudAccount();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<CreateNCPAccount>({
    initialValues: {
      AccountId: '',
      Memo: '',
      Options: {
        CloudType: 'enterprise',
      },
    },
    validationSchema: Yup.object()
      .shape({
        AccountId: Yup.string().defined(),
        Memo: Yup.string(),
        Options: Yup.object()
          .shape({
            CloudType: Yup.string()
              .oneOf(['enterprise', 'public', 'finance'])
              .defined(),
          })
          .defined(),
      })
      .defined(),
    onSubmit: async (v) => {
      if (UID && item) {
        try {
          await mutateAsync({
            OrganizationId: UID,
            CSP: 'ncp',
            UID: item.UID,
            ...v,
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (item) {
      resetForm({
        values: {
          AccountId: item.AccountId,
          Memo: item.Memo,
          Options: {
            CloudType: item.Options.CloudType ?? 'enterprise',
          },
        },
      });
    }
  }, [item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="AccountId"
          value={values.AccountId}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.AccountId && touched.AccountId)}
          label="회원 번호"
        />
        <FormControl fullWidth>
          <InputLabel>클라우드 타입</InputLabel>
          <Select
            name="Options.CloudType"
            value={values.Options.CloudType}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <MenuItem value="enterprise">일반</MenuItem>
            <MenuItem value="public">공공</MenuItem>
            <MenuItem value="finance">금융</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="Memo"
          value={values.Memo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.Memo && touched.Memo)}
          label="메모"
        />
      </Box>
    </FormModal>
  );
}
