import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import HekaLink, { LinkProps as HekaLinkProps } from '../Link';

const Wrapper = styled('div', {
  name: 'HekaContextual',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root],
})({});

export type ContextualProps = {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  buttonProps?: Pick<MuiButtonProps, 'onClick'> & {
    label?: string | React.ReactNode;
  };
  linkProps?: Pick<HekaLinkProps, 'href' | 'hrefLang'> & {
    label?: string | React.ReactNode;
  };
};

const Contextual: StyledComponent<ContextualProps> = styled(
  ({ title, content, buttonProps, linkProps }: ContextualProps) => (
    <Wrapper>
      {Boolean(title) && <p className="HekaContextual-title">{title}</p>}
      {Boolean(content) && <p className="HekaContextual-content">{content}</p>}
      {Boolean(buttonProps || linkProps) && (
        <div className="HekaContextual-actions">
          {typeof linkProps !== 'undefined' && (
            <HekaLink className="HekaContextual-link" href={linkProps.href}>
              {linkProps.label}
            </HekaLink>
          )}
          {typeof buttonProps !== 'undefined' && (
            <MuiButton
              className="HekaContextual-button"
              size="small"
              onClick={buttonProps.onClick}
            >
              {buttonProps.label}
            </MuiButton>
          )}
        </div>
      )}
    </Wrapper>
  ),
  {
    skipVariantsResolver: true,
    skipSx: true,
  },
)<ContextualProps>();
Contextual.displayName = 'HekaContextual';

export default Contextual;
