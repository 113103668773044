import {
  Box,
  InputAdornment,
  InputBase,
  InputLabel,
  TextField,
} from '@mui/material';
import type { InputProps } from '@mui/material/Input';
import type { TextFieldProps } from '@mui/material/TextField';
import { getOutlinedInputUtilityClass } from '@mui/material/OutlinedInput';
import { getInputAdornmentUtilityClass } from '@mui/material/InputAdornment';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getInputBaseUtilityClass } from '@mui/material/InputBase';
import { getSvgIconUtilityClass } from '@mui/material/SvgIcon';

type AutocompleteIndicatorProps = Pick<
  TextFieldProps,
  'label' | 'placeholder'
> &
  Pick<InputProps, 'onClick'> & {
    open?: boolean;
  };
export function AutocompleteIndicator({
  onClick,
  label,
  placeholder,
  open = false,
}: AutocompleteIndicatorProps) {
  return (
    <Box>
      {!!label && <InputLabel>{label}</InputLabel>}
      <TextField
        placeholder={placeholder}
        InputProps={{
          onClick,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ '> svg': { color: '#323232 !important' } }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </InputAdornment>
          ),
        }}
        sx={{
          [`> .${getOutlinedInputUtilityClass('root')}`]: {
            cursor: 'pointer',
            pr: '8px',
            [`> .${getOutlinedInputUtilityClass('input')}`]: {
              cursor: 'pointer',
              userSelect: 'none',
            },
            [`> .${getInputAdornmentUtilityClass('root')} > svg`]: {
              width: '24px',
              height: '24px',
              color: 'text.secondary',
            },
          },
        }}
        fullWidth
      />
    </Box>
  );
}

export const AutocompleteFilterInput = styled(InputBase)(() => ({
  width: '100%',
  background: '#FFFFFF',
  [`> .${getInputBaseUtilityClass('input')}`]: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    paddingLeft: '8px !important',
    paddingRight: '16px !important',
  },
  [`> .${getInputAdornmentUtilityClass('root')}`]: {
    color: '#777B86',
    height: '16px',
    maxHeight: '16px',
    marginLeft: '16px',
    marginRight: 0,
    [`> .${getSvgIconUtilityClass('root')}`]: {
      width: '24px',
      height: '24px',
    },
  },
}));
