import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useGetUserById(UID?: string) {
  const [Id, setUID] = useState(UID ?? '');
  const query = useSuspenseQuery({
    queryKey: [...USER_KEY.GET_USER_BY_ID, Id],
    queryFn: ({ queryKey }) =>
      !isEmpty(queryKey[2]) ? UserService.getUserById(queryKey[2]) : null,
  });
  return { ...query, setUID };
}
