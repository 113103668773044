import { useMutation } from '@tanstack/react-query';
import { UserService } from '@api/services';
import { USER_KEY } from '@constants/query';

export default function useRequestChangePassword() {
  return useMutation({
    mutationKey: USER_KEY.REQUEST_CHANGE_PASSWORD,
    mutationFn: (UID: string) => UserService.requestChangePassword(UID),
  });
}
