import { useTranslation } from 'react-i18next';
import { Box, Typography, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Note } from '@heka/theme';
import { useCurrentInfo } from '@hooks';
import { RoleTile } from '../../../role/styled';

type Props = Pick<
  ReturnType<typeof useFormik>,
  'values' | 'handleChange' | 'handleBlur' | 'setFieldValue'
>;

export default function UserRole({ values, handleChange, handleBlur }: Props) {
  const { t } = useTranslation('settings');
  const { hasRole } = useCurrentInfo(true);
  return (
    <Box sx={{ width: '100%', maxWidth: '668px', minHeight: '264px' }}>
      <Typography
        color="text.main"
        fontSize="18px"
        fontWeight={500}
        letterSpacing="0.72px"
        my="8px"
      >
        {t('text.userRole')}
      </Typography>
      <RadioGroup
        name="Role"
        value={values.Role}
        onChange={handleChange}
        onBlur={handleBlur}
        row
        sx={{ minWidth: '100%', my: '24px' }}
      >
        <RoleTile value="ADMIN" disabled={!hasRole('ADMIN')} />
        <RoleTile value="MANAGER" disabled={!hasRole('MANAGER')} />
        <RoleTile value="CLIENT_USER" />
      </RadioGroup>
      {!_.isEmpty(values.Role) && (
        <Note
          title="권한 안내"
          content={t(`text.roleDescription.${values.Role}`)}
          defaultOpen
          disableCollapse
        />
      )}
    </Box>
  );
}
