import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Note } from '@heka/theme';
import { SupportSection } from '../styled';

type Props = {
  faq: Array<{ title: string; content?: string | TrustedHTML }>;
};

export default function SupportFAQ({ faq }: Props) {
  const { t } = useTranslation('global');
  return (
    <SupportSection>
      <Typography fontSize="14px" lineHeight="150%" color="text.main">
        {t('modal.support.faq.description')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mt: '24px',
          mb: '40px',
        }}
      >
        {faq.map((item, idx) => (
          <Note
            key={`${item.title}_${idx}`}
            variant="expand"
            title={item.title}
            content={item.content}
          />
        ))}
      </Box>
      <Typography fontSize="14px" lineHeight="150%" color="text.main">
        {t('modal.support.faq.footer')}
      </Typography>
    </SupportSection>
  );
}
