import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import { useGetOrganizationList } from '@api/queries/organization';
import { CustomerChip } from '@components/styled/chip';

export function StatusPaper({ status }: { status: OrganizationStatus }) {
  const { t: globalT } = useTranslation('global');
  const { data: response } = useGetOrganizationList();
  const numberOfCustomers = useMemo(() => {
    if (response?.data) {
      const filtered = response.data
        .filter((v) => v.Status === status)
        .map((v) => v.UID);
      return new Set(filtered).size;
    }
    return 0;
  }, [response?.data, status]);
  return (
    <Paper
      sx={{
        minHeight: '128px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '16px',
        backdropFilter: 'blur(2px)',
        px: '24px',
        py: '16px',
      }}
    >
      <Box sx={{ ml: '-4px' }}>
        <CustomerChip status={status} />
      </Box>
      <Typography fontSize="20px" fontWeight={500} letterSpacing="0.8px">
        {globalT('format.number.quantity', { value: numberOfCustomers })} 고객사
      </Typography>
    </Paper>
  );
}
