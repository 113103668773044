import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/ncp/account' as const;

async function getAccessAccounts() {
  const { data } = await axios.get<MultipleResponse<AccessAccount>>(PREFIX);
  return data;
}

async function getAccessAccountById(UID: string) {
  const { data } = await axios.get<SingleResponse<AccessAccount>>(
    `${PREFIX}/${UID}`,
  );
  return data;
}

async function getMemberAccounts() {
  const { data } = await axios.get<MultipleResponse<NCPMemberAccountList>>(
    `${PREFIX}/member/list`,
  );
  return data;
}

async function createAccessAccount({
  CloudType,
  AccessKey,
  SecretKey,
}: CreateAccessAccount) {
  const { data } = await axios.post<SingleResponse<AccessAccount>>(PREFIX, {
    CloudType,
    AccessKey,
    SecretKey,
  });
  return data;
}

async function updateAccessAccount({
  UID,
  AccountName,
  AccountId,
  CloudType,
  AccessKey,
  SecretKey,
}: UpdateAccessAccount) {
  const { data } = await axios.put<SingleResponse<AccessAccount>>(
    `${PREFIX}/${UID}`,
    {
      UID,
      AccountName,
      AccountId,
      CloudType,
      AccessKey,
      SecretKey,
    },
  );
  return data;
}

async function deleteAccessAccount(UID: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}`);
  return data;
}

export default {
  getAccessAccounts,
  getAccessAccountById,
  getMemberAccounts,
  createAccessAccount,
  updateAccessAccount,
  deleteAccessAccount,
};
