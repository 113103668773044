import { useSuspenseQuery } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useGetOrganizations() {
  return useSuspenseQuery({
    queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
    queryFn: () => OrganizationService.getOrganizations(),
  });
}
