import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import withToken from '@components/hoc/token';
import { SuspenseBox } from '@components/suspense';
import { Background } from '@components/ui';
import { BANNER_HEIGHT_STATE } from '@atoms/global/banner';
import GlobalHeader from './header';
import MainLeftPanel from './leftPanel/main';
import SettingsLeftPanel from './leftPanel/settings';

type Props = {
  settings?: boolean;
  disableLeftPanel?: boolean;
};
const MainLayout = withToken(function ({ settings, disableLeftPanel }: Props) {
  const bannerOffset = useRecoilValue(BANNER_HEIGHT_STATE);
  return (
    <>
      <Background />
      <SuspenseBox
        FallbackProps={{ size: 96, sx: { minHeight: '100vh' } }}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          minHeight: '100vh',
        }}
      >
        <GlobalHeader />
        {!disableLeftPanel &&
          (settings ? <SettingsLeftPanel /> : <MainLeftPanel />)}
        <SuspenseBox
          component="main"
          sx={{
            position: 'relative',
            flexGrow: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            p: '24px',
            mt: `${bannerOffset + 80}px`,
          }}
        >
          <Outlet />
        </SuspenseBox>
      </SuspenseBox>
    </>
  );
});

export default MainLayout;
