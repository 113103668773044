import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useCurrentInfo } from '@hooks';
import { BANNER_HEIGHT_STATE } from '@atoms/global/banner';
import { NavList, NavLink } from './styled';

export default function SettingsLeftPanel() {
  const { t } = useTranslation('global');
  const { hasRole } = useCurrentInfo();
  const bannerOffset = useRecoilValue(BANNER_HEIGHT_STATE);
  return (
    <Box
      component="nav"
      sx={(theme) => ({
        width: '100%',
        maxWidth: '170px',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        px: '20px',
        py: '24px',
        mt: `${Number(theme.mixins.toolbar.height) + bannerOffset}px`,
      })}
    >
      <Box>
        <Typography
          fontSize="14px"
          lineHeight="120%"
          color="textSecondary"
          mb="16px"
        >
          {t('sidebar.settings.general.root')}
        </Typography>
        <NavList>
          <NavLink
            label={t('sidebar.settings.general.profile')}
            href="/settings/profile"
          />
          {/*<NavLink*/}
          {/*  label={t('sidebar.settings.general.personal')}*/}
          {/*  href="/settings/personal"*/}
          {/*/>*/}
        </NavList>
      </Box>
      {hasRole('MANAGER') && (
        <Box>
          <Typography
            fontSize="14px"
            lineHeight="120%"
            color="textSecondary"
            mb="16px"
          >
            {t('sidebar.settings.admin.root')}
          </Typography>
          <NavList>
            <NavLink
              label={t('sidebar.settings.admin.permission')}
              href="/settings/permission"
              targetRole="ADMIN"
            />
            <NavLink
              label={t('sidebar.settings.admin.customer')}
              href="/settings/customer"
              targetRole="MANAGER"
            />
            <NavLink
              label={t('sidebar.settings.admin.account')}
              href="/settings/account"
              targetRole="ADMIN"
            />
            <NavLink
              label={t('sidebar.settings.admin.company')}
              href="/settings/company"
              targetRole="ADMIN"
            />
          </NavList>
        </Box>
      )}
    </Box>
  );
}
