import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { useUpdateInvoiceCustom } from '@api/queries/invoice';
import SimpleModal from '@components/modal/simple';

export default function DeleteDiscountModal({
  open,
  onClose,
  Invoices,
  Data,
}: EditExtraModalProps) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateInvoiceCustom();
  const handleDelete = useCallback(async () => {
    if (Data) {
      const items = _.flatten(
        Invoices.filter(
          (account) =>
            account.CSP === Data.CSP && account.AccountId === Data.AccountId,
        ).map((account) => {
          return _.get(
            account,
            'DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService',
          )
            ? Object.keys(
                _.get(
                  account,
                  'DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService',
                ),
              ).map((key) => ({
                AccountId: account.AccountId,
                ServiceName: key,
                UsageAmount: _.get(
                  account,
                  `DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.UsageAmount`,
                ),
                PricingUnit: _.get(
                  account,
                  `DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.PricingUnit`,
                ),
                Cost: _.get(
                  account,
                  `DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.Cost`,
                ),
                CompanyLevel: !!_.get(
                  account,
                  `DiscountDataOrigin.AdditionalService.No region.AdditionalService.AdditionalService.${key}.CompanyLevel`,
                ),
              }))
            : [];
        }),
      );
      items.splice(Data.TargetIndex, 1);
      try {
        await mutateAsync({
          CSP: Data.CSP,
          InvoiceId: Data.InvoiceId,
          AccountId: Data.AccountId,
          Discounts: items,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [Data, Invoices, mutateAsync, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('modal.discount.delete.title')}
      description={t('modal.discount.delete.description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="danger"
            loading={isPending}
            onClick={handleDelete}
          >
            {globalT('button.delete')}
          </LoadingButton>
        </>
      }
    />
  );
}
