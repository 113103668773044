import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateUser } from '@api/queries/user';
import SimpleModal from '@components/modal/simple';

export default function ActivateUserModal({
  open,
  onClose,
  Data,
}: ModalProps & { Data?: Pick<User, 'UID' | 'Deactivate'> }) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.permission',
  });
  const { t: globalT } = useTranslation('global');
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useUpdateUser();
  const handler = useCallback(async () => {
    if (Data) {
      try {
        await mutateAsync({
          UID: Data.UID,
          Deactivate: !Data.Deactivate,
        });
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [Data, mutateAsync, navigate, onClose]);
  if (!Data) {
    return null;
  }
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t(`${Data.Deactivate ? 'activate' : 'deactivate'}.title`)}
      description={t(
        `${Data.Deactivate ? 'activate' : 'deactivate'}.description`,
      )}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="emphasis1"
            onClick={handler}
            loading={isPending}
          >
            {t(`${Data.Deactivate ? 'activate' : 'deactivate'}.cta`)}
          </LoadingButton>
        </>
      }
    />
  );
}
