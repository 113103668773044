import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import { SuspenseBox } from '@components/suspense';
import {
  NCPSalesManagementChart,
  NCPSalesManagementTable,
} from '@features/sales/ncp';

export default function NCPSalesManagementPage() {
  const { t } = useTranslation('sales', { keyPrefix: 'ncp' });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Paper>
        <Typography
          fontSize="20px"
          fontWeight={500}
          letterSpacing="0.8px"
          mb="24px"
        >
          {t('title.root')}
        </Typography>
        <SuspenseBox FallbackProps={{ size: 72, sx: { minHeight: '537px' } }}>
          <NCPSalesManagementChart />
        </SuspenseBox>
      </Paper>
      <Paper>
        <Typography
          fontSize="18px"
          fontWeight={500}
          letterSpacing="0.72px"
          mb="16px"
        >
          {t('title.table')}
        </Typography>
        <SuspenseBox FallbackProps={{ size: 72, sx: { minHeight: '537px' } }}>
          <NCPSalesManagementTable />
        </SuspenseBox>
      </Paper>
    </Box>
  );
}
