import { Box } from '@mui/material';
import { SuspensePaper } from '@components/suspense';
import {
  CostManagementSummary,
  CostManagementChart,
  CostManagementTable,
  CostManagementFilter,
} from '@features/cost';

export default function CostManagementPage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <CostManagementSummary />
      <SuspensePaper FallbackProps={{ sx: { minHeight: '578px' } }}>
        <CostManagementChart />
      </SuspensePaper>
      <SuspensePaper FallbackProps={{ sx: { minHeight: '320px' } }}>
        <CostManagementTable />
      </SuspensePaper>
      <CostManagementFilter />
    </Box>
  );
}
