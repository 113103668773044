import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, RadioGroup, Typography } from '@mui/material';
import { Note, Tile } from '@heka/theme';
import _ from 'lodash';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function OnboardDonePage() {
  const { t } = useTranslation('onboard');
  const { t: globalT } = useTranslation('global');
  const [csp, setCsp] = useState<string>('');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PanelContainer>
        <LeftPanel title={t('done.title')} />
        <MainPanel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: t('done.content') }}
            />
            <RadioGroup
              value={csp}
              onChange={(e) => setCsp(e.target.value)}
              row
              sx={(theme) => ({
                gap: '24px',
                mt: '64px',
                [theme.breakpoints.down('desktop')]: {
                  flexDirection: 'column',
                },
              })}
            >
              <Tile
                value="aws"
                label={t('root.text.aws.label')}
                description={t('root.text.aws.description')}
                sx={{ flex: 1 }}
              />
              <Tile
                value="ncp"
                label={t('root.text.ncp.label')}
                description={t('root.text.ncp.description')}
                sx={{ flex: 1 }}
              />
              <Tile
                value="nhn"
                label={t('root.text.nhn.label')}
                description={t('root.text.nhn.description')}
                sx={{ flex: 1 }}
              />
            </RadioGroup>
            {csp === 'ncp' && (
              <Note
                color="emphasis"
                title={t('root.text.ncp.note.title')}
                content={t('root.text.ncp.note.content')}
                defaultOpen
                disableCollapse
                sx={{ mt: '24px' }}
              />
            )}
          </Box>
        </MainPanel>
      </PanelContainer>
      <BottomActions>
        <Button href="/" color="sub">
          {globalT('button.skip')}
        </Button>
        <Button
          href={`/onboard/integrate/${csp}`}
          color="emphasis1"
          disabled={_.isEmpty(csp)}
        >
          {t('done.cta')}
        </Button>
      </BottomActions>
    </Box>
  );
}
