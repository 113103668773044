import dayjs from 'dayjs';

export const DEFAULT_REPORT_STATE: CreateReport = {
  ChartType: 'bar',
  Name: '',
  Memo: '',
  Providers: [],
  StartMonth: dayjs().subtract(1, 'months').toDate(),
  EndMonth: dayjs().toDate(),
  Organizations: [],
  InvoiceStatus: ['Unissued', 'Invoiced', 'Paid', 'Overdue'],
  OrganizationStatus: ['in-use', 'poc', 'suspended', 'canceled'],
  DisplayOptions: [],
  NCPSettings: {
    CloudType: ['enterprise', 'public', 'finance'],
    DisplayOptions: [],
  },
};
