import type { KeyboardEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Autocomplete,
  Checkbox,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import _ from 'lodash';
import { useGetAWSCostTagAutocomplete } from '@api/queries/aws/cost';
import { FilterInput, PopperComponent } from '../styled';

type Props = {
  onClose: () => void;
  values: Array<CEUsageTagItemInternal>;
  setValues: (values: Array<CEUsageTagItemInternal>) => void;
};

export default function TagValue({ onClose, values, setValues }: Props) {
  const { t } = useTranslation('cost.filter');
  const { data: response } = useGetAWSCostTagAutocomplete();
  const options = useMemo(() => {
    const items: Array<CEUsageTagItemInternal> = [];
    if (response?.data) {
      items.push({ TagKey: '', Value: '' });
      Object.keys(response.data.TagValues).forEach((k) => {
        response.data.TagValues[k].forEach((v) => {
          items.push({ TagKey: k, Value: v });
        });
      });
    }
    return items;
  }, [response?.data]);
  const getOptionLabel = useCallback(
    (option: CEUsageTagItemInternal) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return t('filter.text.selectAll', {
          count: Math.max(options.length - 1, 0),
        });
      }
      if (_.isEmpty(option.Value)) {
        return `${t('filter.text.emptyTagKey')}: ${option.TagKey}`;
      }
      return `${option.TagKey}: ${option.Value}`;
    },
    [options.length, t],
  );
  const isAllSelected = useMemo(() => {
    if (options.length && values.length) {
      return options.length - values.length === 1;
    }
    return false;
  }, [options.length, values.length]);
  const isPartialSelected = useMemo(() => {
    if (options.length && values.length) {
      return options.length - 1 > -values.length;
    }
    return false;
  }, [options.length, values.length]);
  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      setValues([]);
    } else if (response?.data.TagValues) {
      setValues(
        _.flattenDeep(
          Object.keys(response.data.TagValues).map((k) =>
            response.data.TagValues[k].map((v) => ({ TagKey: k, Value: v })),
          ),
        ),
      );
    }
  }, [response?.data, isAllSelected, setValues]);
  const getChecked = useCallback(
    (option: CEUsageTagItemInternal, selected: boolean) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return isAllSelected;
      }
      return selected;
    },
    [isAllSelected],
  );
  const getIndeterminate = useCallback(
    (option: CEUsageTagItemInternal) => {
      if (_.isEmpty(option.TagKey) && _.isEmpty(option.Value)) {
        return isPartialSelected && !isAllSelected;
      }
      return undefined;
    },
    [isAllSelected, isPartialSelected],
  );
  return (
    <Box>
      <Autocomplete
        open
        onClose={(e, r) => {
          if (r === 'escape') {
            onClose();
          }
        }}
        value={values}
        onChange={(e, v, r) => {
          if (
            e.type === 'keydown' &&
            (e as KeyboardEvent).key === 'Backspace' &&
            r === 'removeOption'
          ) {
            return;
          }
          if (v.find((k) => _.isEmpty(k.TagKey) && _.isEmpty(k.Value))) {
            toggleAll();
            return;
          }
          setValues(v);
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) =>
          option.TagKey === value.TagKey && option.Value === value.Value
        }
        noOptionsText={t('filter.text.disabled', {
          name: t('filter.label.tag'),
        })}
        renderTags={() => null}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={getChecked(option, selected)}
              indeterminate={getIndeterminate(option)}
              sx={{ p: 0, pr: '8px' }}
            />
            <ListItemText sx={{ my: 0, wordWrap: 'break-word' }}>
              {getOptionLabel(option)}
            </ListItemText>
          </li>
        )}
        renderInput={(params) => (
          <FilterInput
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder={t('filter.text.filtering', {
              name: t('filter.label.tag'),
            })}
            autoFocus
            fullWidth
          />
        )}
        PopperComponent={PopperComponent}
        PaperComponent={Box}
        disableCloseOnSelect
        disableClearable
        multiple
      />
    </Box>
  );
}
