import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardService } from '@api/services';
import { DASHBOARD_KEY } from '@constants/query';

export default function useGetAdditionalServiceTrend() {
  return useSuspenseQuery({
    queryKey: DASHBOARD_KEY.GET_ADDITIONAL_SERVICE_TREND,
    queryFn: () => DashboardService.getAdditionalServiceTrend(),
  });
}
