import { Link, LinkProps } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { formatLocaleString } from '@utils/formatter';

type RankItemProps = {
  href: LinkProps['to'];
  index: number;
  name: string;
  amount: number;
};
export function RankItem({ href, name, amount, index }: RankItemProps) {
  return (
    <Box
      component={Link}
      to={href}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        gap: '16px',
        p: '8px',
        color: 'text.secondary',
        textDecoration: 'none',
        '&:hover': {
          color: 'text.main',
          background: '#F0D4A833',
          '> .NumberTag': {
            background: '#F0D4A866',
          },
        },
      }}
    >
      <Box
        className="NumberTag"
        sx={{
          borderRadius: '4px',
          p: '2px',
          background: '#CECFDE26',
          width: '16px',
          height: '16px',
        }}
      >
        <Typography letterSpacing="1.28px" lineHeight="12px" align="center">
          {index}
        </Typography>
      </Box>
      <Typography sx={{ flex: 1 }}>{name}</Typography>
      <Typography letterSpacing="0.64px">
        {formatLocaleString(amount)}원
      </Typography>
      <KeyboardArrowRight sx={{ width: '16px', height: '16px' }} />
    </Box>
  );
}
