import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const RoleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
});

interface RoleItemProps extends Pick<BoxProps, 'classes'> {
  role: UserRole;
  currentRole: UserRole;
}
export const RoleItem = styled(
  ({ role, currentRole, ...props }: RoleItemProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'role' });
    return (
      <Box {...props}>
        {role === currentRole && (
          <CheckCircle sx={{ width: '20px', height: '20px' }} />
        )}
        <Typography>{t(role)}</Typography>
      </Box>
    );
  },
)<RoleItemProps>(({ role, currentRole }) => ({
  color: '#B8B8B8',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  boxSizing: 'border-box',
  padding: '16px',
  border: '0.5px solid #E0E0E0',
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  ...(role !== currentRole && {
    '&:not(:last-of-type)': {
      borderRight: 'none',
    },
  }),
  ...(role === currentRole && {
    color: '#3A4050',
    background: '#3A40501A',
    borderRight: '0.5px solid #3A4050',
    borderColor: '#3A4050',
  }),
}));
