import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import LargeModal from '@components/modal/large';
import { useGetInvoiceHistory } from '@api/queries/invoice';
import SimpleTable from '@components/table/simple';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

function HistoryList({ InvoiceId }: { InvoiceId: string }) {
  const { data: response } = useGetInvoiceHistory(InvoiceId);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ActivityLog>();
    return [
      columnHelper.display({
        id: 'UpdatedAt',
      }),
      columnHelper.accessor('Category', {
        id: 'UsedDeposit',
      }),
      columnHelper.accessor('Action', {
        id: 'UsedDate',
      }),
      columnHelper.accessor('UserId', {
        id: 'UserId',
      }),
      columnHelper.display({
        id: '123123123',
      }),
    ];
  }, []);
  return <SimpleTable columns={columns} data={response?.data}></SimpleTable>;
}

export default function InvoiceHistoryModal({
  open,
  onClose,
  InvoiceId,
}: ModalProps & { InvoiceId: string }) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  return (
    <LargeModal
      open={open}
      onClose={onClose}
      title={t('modal.history.title')}
      titleProps={{ color: 'text.main' }}
      Actions={
        <Button color="emphasis1" onClick={onClose}>
          {globalT('button.goBack')}
        </Button>
      }
      maxWidth="1200px"
      minHeight="880px"
      pb="40px"
      sx={{
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ flex: 1 }}></Box>
    </LargeModal>
  );
}
