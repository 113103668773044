import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps as MuiChipProps } from '@mui/material/Chip';

interface UserRoleChipProps extends Pick<MuiChipProps, 'classes'> {
  role?: UserRole;
}
const UserRoleChip = styled(
  ({ role = 'MANAGER', ...props }: UserRoleChipProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'role' });
    return <MuiChip {...props} label={t(role)} />;
  },
)<UserRoleChipProps>(({ role = 'MANAGER' }) => ({
  color: '#777B86',
  background: '#CECFDE33',
  ...(role === 'MANAGER' && {
    color: '#777B86',
    background: '#CECFDE33',
  }),
  ...(role === 'ADMIN' && {
    color: '#1A1818',
    background: '#777B8666',
  }),
}));

export default UserRoleChip;
