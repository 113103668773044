import { useSuspenseQuery } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useGetInvoices() {
  return useSuspenseQuery({
    queryKey: INVOICE_KEY.GET_INVOICES,
    queryFn: () => InvoiceService.getInvoices(),
  });
}
