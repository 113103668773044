import * as Yup from 'yup';

export function useCustomerAWSAccountForm(): UseFormValidation<{
  AccountIdList: Array<string>;
}> {
  return {
    initialValues: {
      AccountIdList: [],
    },
    validationSchema: Yup.object()
      .shape({
        AccountIdList: Yup.array().of(Yup.string()),
      })
      .defined(),
  };
}
