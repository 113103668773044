import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Banner } from '@components/ui';

export default function CloudUsageBanner({ open, onClose }: ModalProps) {
  const { t } = useTranslation('global', { keyPrefix: 'banner.cloudUsage' });
  return (
    <Banner open={open} onClose={onClose} color="#FFFFFF" background="#94765B">
      <Typography fontSize="14px">{t('message')}</Typography>
      {/*<Link variant="inline" inverse>*/}
      {/*  {t('link')}*/}
      {/*</Link>*/}
    </Banner>
  );
}
