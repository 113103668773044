import { type MouseEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  InputLabel,
  Popper,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { SuspensePaper } from '@components/suspense';
import { CUSTOMER_FILTER_STATE } from '@atoms/page/filter';
import CustomerFilterAutocomplete from './CustomerFilterAutocomplete';
import {
  CloudProviderChip,
  ContractChip,
  CustomerChip,
} from '@components/styled/chip';

function CustomerFilterBody({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation('customer');
  const { t: filterT } = useTranslation('global', {
    keyPrefix: 'component.filter',
  });
  const [filter, setFilter] = useRecoilState(CUSTOMER_FILTER_STATE);
  const [filterRaw, setFilterRaw] = useState(filter);
  const applyChange = useCallback(() => {
    onClose();
    setTimeout(() => {
      setFilter(filterRaw);
    }, 500);
  }, [filterRaw, onClose, setFilter]);
  const allStatusChecked = useMemo(() => {
    return filterRaw.Status?.length === 4;
  }, [filterRaw.Status]);
  const toggleOrganizationStatus = useCallback(
    (status: OrganizationStatus | 'all') => () => {
      if (status === 'all') {
        if (allStatusChecked) {
          setFilterRaw((v) => ({
            ...v,
            Status: [],
          }));
        } else {
          setFilterRaw((v) => ({
            ...v,
            Status: ['in-use', 'poc', 'canceled', 'suspended'],
          }));
        }
        return;
      }
      const set = new Set(filterRaw.Status ?? []);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFilterRaw((v) => ({
        ...v,
        Status: Array.from(set),
      }));
    },
    [filterRaw.Status, allStatusChecked],
  );
  const allCSPChecked = useMemo(() => {
    return filterRaw.CSP?.length === 2;
  }, [filterRaw.CSP]);
  const toggleCSP = useCallback(
    (status: CloudProvider | 'all') => () => {
      if (status === 'all') {
        if (allCSPChecked) {
          setFilterRaw((v) => ({
            ...v,
            CSP: [],
          }));
        } else {
          setFilterRaw((v) => ({
            ...v,
            CSP: ['aws', 'ncp'],
          }));
        }
        return;
      }
      const set = new Set(filterRaw.CSP ?? []);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFilterRaw((v) => ({
        ...v,
        CSP: Array.from(set),
      }));
    },
    [filterRaw.CSP, allCSPChecked],
  );
  const allContractChecked = useMemo(() => {
    return filterRaw.ContractStatus?.length === 4;
  }, [filterRaw.ContractStatus]);
  const toggleContract = useCallback(
    (status: ContractStatus | 'all') => () => {
      if (status === 'all') {
        if (allContractChecked) {
          setFilterRaw((v) => ({
            ...v,
            ContractStatus: [],
          }));
        } else {
          setFilterRaw((v) => ({
            ...v,
            ContractStatus: ['none', 'active', 'scheduled', 'expired'],
          }));
        }
        return;
      }
      const set = new Set(filterRaw.ContractStatus ?? []);
      if (set.has(status)) {
        set.delete(status);
      } else {
        set.add(status);
      }
      setFilterRaw((v) => ({
        ...v,
        ContractStatus: Array.from(set),
      }));
    },
    [filterRaw.ContractStatus, allContractChecked],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <CustomerFilterAutocomplete
          basis="org"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        <Box>
          <InputLabel>{t('filter.label.status')}</InputLabel>
          <Grid container spacing="16px" py="8px">
            <Grid zero={4}>
              <FormControlLabel
                name="Status.all"
                onChange={toggleOrganizationStatus('all')}
                checked={allStatusChecked}
                control={
                  <Checkbox
                    indeterminate={
                      !!filterRaw.Status?.length && !allStatusChecked
                    }
                  />
                }
                label={filterT('label.selectAll')}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Status.in-use"
                onChange={toggleOrganizationStatus('in-use')}
                checked={!!filterRaw.Status?.includes('in-use')}
                control={<Checkbox />}
                label={<CustomerChip status="in-use" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Status.poc"
                onChange={toggleOrganizationStatus('poc')}
                checked={!!filterRaw.Status?.includes('poc')}
                control={<Checkbox />}
                label={<CustomerChip status="poc" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Status.suspended"
                onChange={toggleOrganizationStatus('suspended')}
                checked={!!filterRaw.Status?.includes('suspended')}
                control={<Checkbox />}
                label={<CustomerChip status="suspended" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Status.canceled"
                onChange={toggleOrganizationStatus('canceled')}
                checked={!!filterRaw.Status?.includes('canceled')}
                control={<Checkbox />}
                label={<CustomerChip status="canceled" />}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <InputLabel>{t('filter.label.csp')}</InputLabel>
          <Grid container spacing="16px" py="8px">
            <Grid zero={6}>
              <FormControlLabel
                name="CSP.all"
                onChange={toggleCSP('all')}
                checked={allCSPChecked}
                control={
                  <Checkbox
                    indeterminate={!!filterRaw.CSP?.length && !allCSPChecked}
                  />
                }
                label={filterT('label.selectAll')}
              />
            </Grid>
            <Grid zero={6}>
              <FormControlLabel
                name="CSP.aws"
                onChange={toggleCSP('aws')}
                checked={!!filterRaw.CSP?.includes('aws')}
                control={<Checkbox />}
                label={<CloudProviderChip provider="aws" />}
              />
            </Grid>
            <Grid zero={6}>
              <FormControlLabel
                name="CSP.ncp"
                onChange={toggleCSP('ncp')}
                checked={!!filterRaw.CSP?.includes('ncp')}
                control={<Checkbox />}
                label={<CloudProviderChip provider="ncp" />}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <InputLabel>{t('filter.label.contractStatus')}</InputLabel>
          <Grid container spacing="16px" py="8px">
            <Grid zero={4}>
              <FormControlLabel
                name="Contract.all"
                onChange={toggleContract('all')}
                checked={allContractChecked}
                control={
                  <Checkbox
                    indeterminate={
                      !!filterRaw.ContractStatus?.length && !allContractChecked
                    }
                  />
                }
                label={filterT('label.selectAll')}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Contract.active"
                onChange={toggleContract('active')}
                checked={!!filterRaw.ContractStatus?.includes('active')}
                control={<Checkbox />}
                label={<ContractChip status="active" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Contract.scheduled"
                onChange={toggleContract('scheduled')}
                checked={!!filterRaw.ContractStatus?.includes('scheduled')}
                control={<Checkbox />}
                label={<ContractChip status="scheduled" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Contract.expired"
                onChange={toggleContract('expired')}
                checked={!!filterRaw.ContractStatus?.includes('expired')}
                control={<Checkbox />}
                label={<ContractChip status="expired" />}
              />
            </Grid>
            <Grid zero={4}>
              <FormControlLabel
                name="Contract.none"
                onChange={toggleContract('none')}
                checked={!!filterRaw.ContractStatus?.includes('none')}
                control={<Checkbox />}
                label={<ContractChip status="none" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Button color="emphasis1" onClick={applyChange} fullWidth>
        {filterT('button.apply')}
      </Button>
    </Box>
  );
}

export default function CustomerFilter() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <Box>
      <Button color="sub" onClick={handleOpen} startIcon={<FilterList />}>
        필터
      </Button>
      <Popper
        id={open ? 'heka-customer-filter-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 2,
        })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={{ display: 'flex', width: '400px', maxWidth: '400px' }}>
            <SuspensePaper
              sx={{
                flex: 1,
                border: 'none',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <CustomerFilterBody onClose={handleClose} />
            </SuspensePaper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
