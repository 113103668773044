import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  Dialog,
  RadioGroup,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import { getDialogUtilityClass } from '@mui/material/Dialog';
import type { SelectChangeEvent } from '@mui/material/Select';
import { Refresh } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { Select, Tile } from '@heka/theme';
import { useGetAWSCostAutocomplete } from '@api/queries/aws/cost';
import { AWS_COST_FILTER_STATE } from '@atoms/page/filter';
import { SuspenseBox } from '@components/suspense';
import { DEFAULT_FILTER_STATE } from '@constants/filter/cost';
import { useCurrentInfo } from '@hooks';
import COST_FILTER_STATE from './atom';
import DateRangePicker from './DateRangePicker';
import Autocomplete from './Autocomplete';
import TagFilter from './tag';

function CostManagementFilterBody() {
  const { t } = useTranslation('cost');
  const { t: globalT } = useTranslation('global');
  const { hasRole } = useCurrentInfo();
  const setFilterState = useSetRecoilState(COST_FILTER_STATE);
  const [filter, setFilter] = useRecoilState(AWS_COST_FILTER_STATE);
  const [filterRaw, setFilterRaw] = useState(filter);
  const { data: response } = useGetAWSCostAutocomplete();
  const handleGroupByChange = useCallback((e: SelectChangeEvent<CEGroupBy>) => {
    setFilterRaw((v) => ({
      ...v,
      GroupBy: e.target.value as CEGroupBy,
      ...(e.target.value !== 'TAG' && {
        TagKey: ' ',
      }),
    }));
  }, []);
  const handleTagKeyChange = useCallback((e: SelectChangeEvent<string>) => {
    setFilterRaw((v) => ({
      ...v,
      TagKey: e.target.value,
    }));
  }, []);
  const handleGranularityChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilterRaw((v) => ({
        ...v,
        Granularity: e.target.value as CEGranularity,
      }));
    },
    [],
  );
  const resetFilter = useCallback(() => {
    setFilterRaw(DEFAULT_FILTER_STATE);
  }, []);
  const applyChange = useCallback(() => {
    setFilter(filterRaw);
    setFilterState(false);
  }, [filterRaw, setFilter, setFilterState]);
  return (
    <>
      <Typography fontSize="16px" fontWeight={500} letterSpacing="0.64px">
        {t('filter.title')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <FormControl>
          <InputLabel>{t('filter.label.date')}</InputLabel>
          <DateRangePicker filter={filterRaw} setFilter={setFilterRaw} />
        </FormControl>
        <FormControl>
          <InputLabel>{t('filter.label.granularity')}</InputLabel>
          <RadioGroup
            value={filterRaw.Granularity}
            onChange={handleGranularityChange}
            row
            sx={{ gap: '8px' }}
          >
            <Tile
              value="DAILY"
              description={t('filter.text.daily')}
              sx={{ flex: 1, p: '16px' }}
            />
            <Tile
              value="MONTHLY"
              description={t('filter.text.monthly')}
              sx={{ flex: 1, p: '16px' }}
            />
          </RadioGroup>
        </FormControl>
        {hasRole('MANAGER') && (
          <FormControl>
            <InputLabel>{t('filter.label.groupBy')}</InputLabel>
            <Select value={filterRaw.GroupBy} onChange={handleGroupByChange}>
              <MenuItem value="Cost">{t('filter.text.byNone')}</MenuItem>
              <MenuItem value="SERVICE">{t('filter.text.byService')}</MenuItem>
              {Boolean(response?.data.NumberOfTagKeys) && (
                <MenuItem value="TAG">{t('filter.text.byTag')}</MenuItem>
              )}
              {Boolean(response?.data.NumberOfAccounts) && (
                <MenuItem value="LINKED_ACCOUNT">
                  {t('filter.text.byLinkedAccount')}
                </MenuItem>
              )}
              <MenuItem value="PURCHASE_TYPE">
                {t('filter.text.byPurchaseType')}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {filterRaw.GroupBy === 'TAG' && (
          <FormControl>
            <InputLabel>{t('filter.label.tagKey')}</InputLabel>
            <Select value={filterRaw.TagKey} onChange={handleTagKeyChange}>
              <MenuItem value=" " disabled>
                {!isEmpty(response?.data.TagKeys)
                  ? '태그 키 선택'
                  : '태그 키 없음'}
              </MenuItem>
              {response?.data.TagKeys?.map((v: string) => (
                <MenuItem key={`tag_key_menu_${v}`} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Autocomplete
          basis="USAGE_TYPE"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        <Autocomplete
          basis="USAGE_TYPE_GROUP"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        <TagFilter filter={filterRaw} setFilter={setFilterRaw} />
        <Autocomplete
          basis="LINKED_ACCOUNT"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        <Autocomplete
          basis="SERVICE"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        {/*<Autocomplete label={t('filter.label.groupBy')} />*/}
        <Autocomplete
          basis="INSTANCE_TYPE"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        <Autocomplete
          basis="RECORD"
          filter={filterRaw}
          setFilter={setFilterRaw}
        />
        {/*<FormControl>*/}
        {/*  <InputLabel>{t('filter.label.sort')}</InputLabel>*/}
        {/*  <RadioGroup row sx={{ gap: '8px' }}>*/}
        {/*    <Tile*/}
        {/*      value="DAILY"*/}
        {/*      description="계정 기준"*/}
        {/*      sx={{ flex: 1, p: '16px' }}*/}
        {/*    />*/}
        {/*    <Tile*/}
        {/*      value="MONTHLY"*/}
        {/*      description="고객사 기준"*/}
        {/*      sx={{ flex: 1, p: '16px' }}*/}
        {/*    />*/}
        {/*  </RadioGroup>*/}
        {/*</FormControl>*/}
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}
      >
        <Button color="sub" onClick={resetFilter} startIcon={<Refresh />}>
          {globalT('button.reset')}
        </Button>
        <Button
          color="emphasis1"
          onClick={applyChange}
          disabled={
            filterRaw.GroupBy === 'TAG' && isEmpty(filterRaw.TagKey?.trim())
          }
        >
          {t('filter.button.apply')}
        </Button>
      </Box>
    </>
  );
}

export default function CostManagementFilter() {
  const [filterState, setFilterState] = useRecoilState(COST_FILTER_STATE);
  return (
    <Dialog
      open={filterState}
      onClose={() => setFilterState((v) => !v)}
      scroll="body"
      fullWidth
      sx={{
        [`> .${getDialogUtilityClass('container')} > .${getDialogUtilityClass(
          'paper',
        )}`]: {
          maxWidth: '560px',
          pt: '24px',
          pb: '56px',
        },
      }}
    >
      <SuspenseBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        <CostManagementFilterBody />
      </SuspenseBox>
    </Dialog>
  );
}
