import { styled } from '@mui/material/styles';
import { useGetLogo } from '@api/queries/company';
import HekaLogo from '@assets/logo/logo-120.svg?react';

const LogoImg = styled('img')({
  maxHeight: '40px',
});

export default function Logo() {
  const { data: response } = useGetLogo();
  if (!response?.data.Logo) {
    return <HekaLogo />;
  }
  return <LogoImg src={response.data.Logo} alt="Logo" />;
}
