import { RadioGroup } from '@mui/material';
import { Person, SupervisorAccount } from '@mui/icons-material';
import { useFormik } from 'formik';
import { Tile } from '@heka/theme';

type Props = Pick<
  ReturnType<typeof useFormik>,
  'values' | 'handleChange' | 'handleBlur'
>;

export default function ChooseType({
  values,
  handleChange,
  handleBlur,
}: Props) {
  return (
    <RadioGroup
      name="Type"
      value={values.Type}
      onChange={handleChange}
      onBlur={handleBlur}
      row
      sx={{ width: '100%', maxWidth: '507px', gap: '24px' }}
    >
      <Tile
        value="single"
        label={<Person sx={{ width: '40px', height: '40px' }} />}
        description="1명 초대"
        sx={{ flex: 1 }}
      />
      <Tile
        value="multiple"
        label={<SupervisorAccount sx={{ width: '40px', height: '40px' }} />}
        description="여러 명 초대"
        sx={{ flex: 1 }}
      />
    </RadioGroup>
  );
}
