import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ContractService } from '@api/services';
import { CONTRACT_KEY } from '@constants/query';

export default function useCreateContract() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: CONTRACT_KEY.CREATE_CONTRACT,
    mutationFn: ({
      OrganizationId,
      ...v
    }: CreateContract & { OrganizationId: string }) =>
      ContractService.createContract(OrganizationId, v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['CONTRACT'] });
    },
  });
}
