import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import hj from '@hotjar/browser';
import AxiosClient from '@api/client';
import '@utils/i18n';
import App from './App';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/noto-sans-kr/400.css';
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/noto-sans-kr/300.css';
import '@fontsource/noto-sans-kr/700.css';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.6,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.([A-Za-z]+\.)?heka\.so/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (import.meta.env.VITE_PROXY) {
  AxiosClient.initialize(import.meta.env.VITE_PROXY);
}
if (import.meta.env.VITE_GTM_ID) {
  TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });
}
if (import.meta.env.VITE_HJ_ID && import.meta.env.VITE_HJ_SV) {
  hj.init(
    Number(import.meta.env.VITE_HJ_ID),
    Number(import.meta.env.VITE_HJ_SV),
    { debug: import.meta.env.DEV },
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('heka_root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </RecoilRoot>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);
