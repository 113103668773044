import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { Add, Delete, KeyboardArrowRight } from '@mui/icons-material';
import { useGetReportList } from '@api/queries/report';
import SimpleTable from '@components/table/simple';
import { ReportProviderChip } from '@components/styled/chip';
import { formatDate } from '@utils/formatter';
import DeleteReportModal from './DeleteReportModal';

export default function ReportListTable() {
  const { t } = useTranslation('table');
  const { t: reportT } = useTranslation('report');
  const [toDelete, setToDelete] = useState<ReportSettings | undefined>(
    undefined,
  );
  const { data: response } = useGetReportList();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ReportSettings>();
    return [
      columnHelper.accessor('Name', {
        id: 'Name',
        header: t('report.name'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'Period',
        header: t('report.period'),
        cell: (v) =>
          `${formatDate(v.row.original.StartMonth, 'YYYY.MM')} ~ ${formatDate(
            v.row.original.EndMonth,
            'YYYY.MM',
          )}`,
      }),
      columnHelper.accessor('Providers', {
        id: 'Providers',
        header: t('report.cspOption'),
        cell: (v) => {
          if (
            v.row.original.Providers.length &&
            v.row.original.Providers.length < 3
          ) {
            return (
              <ReportProviderChip provider={v.row.original.Providers[0]} />
            );
          }
          return <ReportProviderChip provider="all" />;
        },
      }),
      columnHelper.accessor('UpdatedAt', {
        id: 'UpdatedAt',
        header: t('general.lastUpdated'),
        cell: (v) => formatDate(v.getValue(), 'YYYY-MM-DD HH:mm'),
        enableGlobalFilter: false,
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('general.delete'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Details',
        header: t('general.details'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            href={`/report/${v.row.original.UID}`}
          >
            <KeyboardArrowRight />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data}
        title={reportT('title.list')}
        enableGlobalFilter
      >
        <Button href="/report/create" startIcon={<Add />}>
          {reportT('button.create')}
        </Button>
      </SimpleTable>
      <DeleteReportModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        report={toDelete}
      />
    </>
  );
}
