import { Stack, Divider } from '@mui/material';
import { SuspensePaper } from '@components/suspense';
import {
  SettingsCompanyInformation,
  SettingsCompanyLogo,
  SettingsCompanyStamp,
} from '@features/settings/company';

export default function SettingsCompanyPage() {
  return (
    <SuspensePaper
      sx={{ flex: 1 }}
      FallbackProps={{ size: 72, sx: { flex: 1 } }}
    >
      <Stack spacing="40px" divider={<Divider />}>
        <SettingsCompanyInformation />
        <SettingsCompanyStamp />
        <SettingsCompanyLogo />
      </Stack>
      <div />
    </SuspensePaper>
  );
}
