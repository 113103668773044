import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { useAWSHelper, useToggle } from '@hooks';
import SimpleTable from '@components/table/simple';
import AddAWSAccountModal from '@features/customer/modal/account/aws/add';
import DeleteAWSAccountModal from '@features/customer/modal/account/aws/delete';

export default function CustomerAWSAccount() {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const [openAdd, toggleAdd] = useToggle();
  const [toDelete, setToDelete] = useState<string | undefined>(undefined);
  const { data: customer } = useGetOrganizationForUI();
  const { getAccountAlias } = useAWSHelper();
  const tableData = useMemo(() => {
    return (
      customer?.data.CloudAccounts.filter((v) => v.CSP === 'aws').map((v) => ({
        ...v,
        Alias: getAccountAlias(v.AccountId),
      })) ?? []
    );
  }, [getAccountAlias, customer?.data]);
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AWSAccount & { Alias?: string }>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AccountId',
        header: t('customer.accountId'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('Alias', {
        id: 'Alias',
        header: t('customer.accountAlias'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('general.delete'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.original.UID)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={tableData}
        title={customerT('title.awsAccount')}
        enableGlobalFilter
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addAccount')}
        </Button>
      </SimpleTable>
      <AddAWSAccountModal open={openAdd} onClose={toggleAdd} />
      <DeleteAWSAccountModal
        open={Boolean(toDelete)}
        onClose={() => setToDelete(undefined)}
        AccountId={toDelete}
      />
    </>
  );
}
