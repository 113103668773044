import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReportService } from '@api/services';
import { REPORT_KEY } from '@constants/query';

export default function useDeleteReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: REPORT_KEY.DELETE_REPORT,
    mutationFn: (v: string) => ReportService.deleteReport(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['REPORT'] });
    },
  });
}
