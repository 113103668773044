import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, TextField, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { formatLocaleString } from '@utils/formatter';
import { CreateUserForm } from '../form';

type Props = Pick<
  ReturnType<typeof useFormik<CreateUserForm>>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
>;

export default function UserInfo({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}: Props) {
  const { t } = useTranslation('settings');
  const addItem = useCallback(() => {
    const clone = _.cloneDeep(values.Users);
    clone.push({ Email: '', FirstName: '' });
    setFieldValue('Users', clone);
  }, [values, setFieldValue]);
  const removeItem = useCallback(
    (idx: number) => () => {
      const clone = _.cloneDeep(values.Users);
      clone.splice(idx, 1);
      setFieldValue('Users', clone);
    },
    [values, setFieldValue],
  );
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: values.Type === 'single' ? '668px' : '944px',
      }}
    >
      <Typography
        color="text.main"
        fontSize="18px"
        fontWeight={500}
        letterSpacing="0.72px"
        mb="32px"
      >
        {t('text.userInformation')}
      </Typography>
      {values.Type === 'multiple' ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {values.Users.map((user, idx) => (
            <Box
              key={`user-invite-list-${idx}`}
              sx={{ display: 'flex', borderBottom: '0.5px solid #CECFDE' }}
            >
              <Box
                sx={{
                  flex: '0 0 40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '8px 16px',
                }}
              >
                <Typography
                  color="textSecondary"
                  fontSize="14px"
                  letterSpacing="0.56px"
                >
                  {formatLocaleString(idx + 1)}
                </Typography>
              </Box>
              <Box
                sx={{ flex: 1, display: 'flex', gap: '24px', p: '24px 16px' }}
              >
                <TextField
                  name={`Users.${idx}.Email`}
                  value={user.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('form.label.userEmail')}
                  placeholder={t('form.holder.userEmail')}
                  error={Boolean(
                    _.get(errors, `Users.${idx}.Email`) &&
                      _.get(touched, `Users.${idx}.Email`),
                  )}
                  fullWidth
                />
                <TextField
                  name={`Users.${idx}.FirstName`}
                  value={user.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('form.label.name')}
                  placeholder={t('form.holder.name')}
                  error={Boolean(
                    _.get(errors, `Users.${idx}.FirstName`) &&
                      _.get(touched, `Users.${idx}.FirstName`),
                  )}
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  flex: '0 0 40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  py: '32px',
                }}
              >
                <IconButton
                  color="sub"
                  size="small"
                  onClick={removeItem(idx)}
                  disabled={values.Users.length === 1}
                >
                  <Remove />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center', py: '16px' }}>
            <Button
              size="small"
              color="sub"
              startIcon={<Add />}
              onClick={addItem}
            >
              {t('button.addUserInfo')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <TextField
            name={'Users.0.Email'}
            value={values.Users[0].Email}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('form.label.userEmail')}
            placeholder={t('form.holder.userEmail')}
            error={Boolean(
              _.get(errors, 'Users.0.Email') && _.get(touched, 'Users.0.Email'),
            )}
            fullWidth
            sx={{ mb: '16px' }}
          />
          <TextField
            name={'Users.0.FirstName'}
            value={values.Users[0].FirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('form.label.name')}
            placeholder={t('form.holder.name')}
            error={Boolean(
              _.get(errors, 'Users.0.FirstName') &&
                _.get(touched, 'Users.0.FirstName'),
            )}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
}
