import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AWSAccountService } from '@api/services';
import { AWS_ACCOUNT_KEY } from '@constants/query';

export default function useUpdatePayerAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: AWS_ACCOUNT_KEY.UPDATE_PAYER_ACCOUNT,
    mutationFn: (v: UpdatePayerAccount) =>
      AWSAccountService.updatePayerAccount(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['AWS_ACCOUNT'] });
    },
  });
}
