import { AxiosResponseHeaders } from 'axios';

export function getFilenameFromHeader(headers: Partial<AxiosResponseHeaders>) {
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    if (disposition.indexOf("attachment; filename*=utf-8''") !== -1) {
      return decodeURIComponent(
        disposition.replace("attachment; filename*=utf-8''", ''),
      );
    }
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return undefined;
}
