import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Button, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useGetAWSCostUsage } from '@api/queries/aws/cost';
import { AWS_COST_FILTER_STATE } from '@atoms/page/filter';
import CostSingleChart from '@components/chart/cost/single';
import CostMultipleChart from '@components/chart/cost/multiple';
import DateRangeFilter from '../filter/date';
import COST_FILTER_STATE from '../filter/atom';

export default function CostManagementChart() {
  const { t } = useTranslation('cost');
  const { t: globalT } = useTranslation('global');
  const setFilterState = useSetRecoilState(COST_FILTER_STATE);
  const filter = useRecoilValue(AWS_COST_FILTER_STATE);
  const { data: response } = useGetAWSCostUsage();
  if (!response) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '24px',
        }}
      >
        <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
          {t('chart.title')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <DateRangeFilter />
          <Button
            color="sub"
            startIcon={<FilterList />}
            onClick={() => setFilterState((v) => !v)}
          >
            {globalT('button.filter')}
          </Button>
        </Box>
      </Box>
      {response.data.GroupBy === 'Cost' ? (
        <CostSingleChart
          data={response.data.Graph}
          Granularity={filter.Granularity}
        />
      ) : (
        <CostMultipleChart
          data={response.data.Table}
          Granularity={filter.Granularity}
          GroupBy={filter.GroupBy}
          TotalCostByItems={response.data.TotalCostByItems}
        />
      )}
    </>
  );
}
