import { useNavigate } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useForgotPassword } from '@api/queries/auth';
import { Link } from '@heka/theme';

type PasswordRecoveryForm = {
  email: string;
};
function usePasswordRecoveryForm(): UseFormValidation<PasswordRecoveryForm> {
  const { t } = useTranslation('validation');
  return {
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('auth.email'))
        .defined(t('common.required.base')),
    }),
  };
}

export default function PasswordRecoveryPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { mutateAsync, isPending } = useForgotPassword();
  const { initialValues, validationSchema } = usePasswordRecoveryForm();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v.email);
        navigate('/login/recover/sent');
      } catch (e) {
        console.error(e);
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          pt: '24px',
          pb: '40px',
        }}
      >
        <Typography fontSize="20px" fontWeight="500" letterSpacing="1.6px">
          {t('recovery.title')}
        </Typography>
        <Typography fontWeight="500">{t('recovery.description')}</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            py: '24px',
          }}
        >
          <TextField
            name="email"
            value={values.email}
            error={Boolean(errors.email && touched.email)}
            helperText={
              Boolean(errors.email && touched.email) && String(errors.email)
            }
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('common.form.label.email')}
            placeholder={t('common.form.holder.email')}
            type="email"
            inputMode="email"
            autoComplete="username"
            autoCapitalize="off"
            fullWidth
          />
        </Box>
        <Box sx={{ py: '40px' }}>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid}
            fullWidth
          >
            {t('common.cta.recovery')}
          </LoadingButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          pt: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Typography>{t('common.text.login')}</Typography>
          <Link size="comfort" color="calm" href="/login">
            {t('common.cta.login')}
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Typography>{t('common.text.register')}</Typography>
          <Link size="comfort" color="calm" href="/register">
            {t('common.cta.register')}
          </Link>
        </Box>
      </Box>
    </>
  );
}
