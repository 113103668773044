import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { DataUsage } from '@mui/icons-material';
import { SuspensePaper } from '@components/suspense';

export function DashboardPaper({
  children,
  sx,
}: PropsWithChildren<{ sx?: SxProps<Theme> }>) {
  return (
    <SuspensePaper
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      FallbackProps={{ size: 64, sx: { minHeight: '100%', p: '64px' } }}
    >
      {children}
    </SuspensePaper>
  );
}

export function DashboardNotFound() {
  const { t } = useTranslation('dashboard');
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        p: '40px',
      }}
    >
      <DataUsage
        shapeRendering="geometricPrecision"
        sx={{ width: '24px', height: '24px' }}
      />
      <Typography align="center" mt="8px">
        {t('text.notFound.title')}
      </Typography>
      <Typography
        color="textSecondary"
        fontSize="14px"
        align="center"
        dangerouslySetInnerHTML={{ __html: t('text.notFound.description') }}
      />
    </Box>
  );
}

type DashboardLegendItemProps = {
  color: string;
  label: string;
  value: string | number;
};
export function DashboardLegendItem({
  color,
  label,
  value,
}: DashboardLegendItemProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            borderRadius: '999px',
            background: color,
          }}
        />
        <Typography color="#362F26" fontSize="14px">
          {label}
        </Typography>
      </Box>
      <Typography color="#362F26" fontSize="14px" align="right">
        {value}
      </Typography>
    </Box>
  );
}

export const DashboardChartCircle = styled(
  ({ children, ...props }: BoxProps) => (
    <Box {...props}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        {children}
      </Box>
    </Box>
  ),
)<BoxProps>({
  position: 'absolute',
  borderRadius: '999px',
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },
});
