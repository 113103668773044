import * as React from 'react';
import type { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material/styles';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface SelectProps<T = any>
  extends Omit<MuiSelectProps<T>, 'IconComponent'> {}

const Select: StyledComponent<SelectProps> = styled((props: SelectProps) => (
  <MuiSelect {...props} IconComponent={KeyboardArrowDown} />
))<SelectProps>();
Select.displayName = 'HekaSelect';

export default Select;
