import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { StatusPaper } from './styled';

export default function InvoiceStatus() {
  const { t } = useTranslation('dashboard');
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.invoiceStatus')}
      </Typography>
      <Grid container spacing="16px">
        <Grid xs={6}>
          <StatusPaper status="Unissued" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="Invoiced" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="Paid" />
        </Grid>
        <Grid xs={6}>
          <StatusPaper status="Overdue" />
        </Grid>
      </Grid>
    </>
  );
}
