import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  ChipProps as MuiChipProps,
  getChipUtilityClass,
} from '@mui/material/Chip';
import { ExpandMore } from '@mui/icons-material';

interface CustomerChipProps
  extends Pick<MuiChipProps, 'classes' | 'ref' | 'onClick'> {
  status?: OrganizationStatus;
}
const CustomerChip = styled(
  ({ onClick, status = 'in-use', ...props }: CustomerChipProps) => {
    const { t } = useTranslation('global', { keyPrefix: 'chip.customer' });
    const label = useMemo(() => {
      if (onClick) {
        return (
          <>
            {t(status)}
            <ExpandMore sx={{ width: '16px', height: '16px' }} />
          </>
        );
      }
      return t(status);
    }, [onClick, status, t]);
    return (
      <MuiChip {...props} size="selected" onClick={onClick} label={label} />
    );
  },
)<CustomerChipProps>(({ status = 'in-use' }) => ({
  color: '#66BE21',
  background: '#66BE211A',
  [`> .${getChipUtilityClass('label')}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  ...(status === 'canceled' && {
    color: '#777B86',
    background: '#777B861A',
  }),
  ...(status === 'suspended' && {
    color: '#C8003C',
    background: '#C8003C1A',
  }),
  ...(status === 'poc' && {
    color: '#007AD1',
    background: '#007AD11A',
  }),
}));

export default CustomerChip;
