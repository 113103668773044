import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useUpdateInvoiceCloudUsage } from '@api/queries/invoice';
import LargeModal from '@components/modal/large';
import { InvoiceAccountHeader } from '../../styled';
import {
  InvoiceAccordion,
  InvoiceCloudProduct,
  InvoiceCloudRegion,
  InvoiceCloudService,
} from '../styled';
import { formatKRW, formatLocaleString } from '@utils/formatter.ts';

const BodyTableCell = styled(TableCell)({
  padding: '8px',
});

type FormData = {
  Key: string;
  UsageAmount: number;
  UsageCost: number;
  PurchaseCost: number;
  Cost: number;
  DiscountRate: number;
  PricingUnit: string;
  Unit: number;
  UnitPrice: number;
  ResourceName: string;
  ResourceId: string;
  ParentResourceName: string;
  ParentResourceId: string;
  DisplayName: string;
};

export default function EditNHNCloudUsageModal({
  open,
  onClose,
  Invoices,
  Data,
}: NHNCloudUsageModalProps) {
  const { t } = useTranslation('invoice');
  const { t: tableT } = useTranslation('table', { keyPrefix: 'invoice' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateInvoiceCloudUsage();
  const calculateContractCost = useCallback((cost: number, rate: number) => {
    return Math.floor((cost * (100 - rate)) / 100);
  }, []);
  const handler = useCallback(
    async (v: Array<FormData>) => {
      if (Data) {
        try {
          await mutateAsync({
            InvoiceId: Data.InvoiceId,
            CSP: 'nhn',
            CloudUsage: [
              {
                AccountId: Data.AccountId,
                ServiceCode: Data.ServiceCode,
                Region: Data.Region,
                Product: Data.Product,
                Resource: Data.Resource,
                Data: _.reduce(
                  v,
                  (obj, param) => {
                    return _.assign(obj, {
                      [param.Key]: {
                        UsageAmount: param.UsageAmount,
                        UsageCost: param.UsageCost,
                        PurchaseCost: param.PurchaseCost,
                        Cost: calculateContractCost(
                          param.UsageCost,
                          param.DiscountRate,
                        ),
                        DiscountRate: param.DiscountRate,
                        PricingUnit: param.PricingUnit,
                        Unit: param.Unit,
                        UnitPrice: param.UnitPrice,
                        ResourceName: param.ResourceName,
                        ResourceId: param.ResourceId,
                        ParentResourceName: param.ParentResourceName,
                        ParentResourceId: param.ParentResourceId,
                        DisplayName: param.DisplayName,
                      },
                    });
                  },
                  {},
                ),
              },
            ],
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
    [Data, mutateAsync, onClose, calculateContractCost],
  );
  const invoice = useMemo(() => {
    if (Data?.AccountId) {
      return Invoices.find((v) => v.AccountId === Data.AccountId);
    }
    return undefined;
  }, [Invoices, Data]);
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik<Array<FormData>>({
    initialValues: [],
    validationSchema: Yup.array()
      .of(
        Yup.object()
          .shape({
            Key: Yup.string().defined(),
            Cost: Yup.number().defined(),
            DiscountRate: Yup.number().min(0).max(100).defined(),
            PricingUnit: Yup.string(),
            UsageAmount: Yup.number().defined(),
            DescriptionExtra: Yup.string(),
          })
          .defined(),
      )
      .defined(),
    onSubmit: handler,
  });
  const hasError = useCallback(
    (key: string) => {
      const error = _.get(errors, key);
      const touch = _.get(touched, key);
      return Boolean(error && touch);
    },
    [errors, touched],
  );
  useEffect(() => {
    if (invoice && Data) {
      const { ServiceCode, Region, Product, Resource } = Data;
      const items = _.get(
        invoice.Data,
        `${ServiceCode}.${Region}.${Product}.${Resource}`,
      );
      if (items) {
        const initialValue = Object.keys(items)
          .filter((v) => v !== 'ResourceTotalCost')
          .map((usage) => ({
            Key: usage,
            UsageAmount:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .UsageAmount,
            UsageCost:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .UsageCost,
            PurchaseCost:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .PurchaseCost,
            Cost: invoice.Data[ServiceCode][Region][Product][Resource][usage]
              .Cost,
            DiscountRate:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .DiscountRate,
            PricingUnit:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .PricingUnit,
            Unit: invoice.Data[ServiceCode][Region][Product][Resource][usage]
              .Unit,
            UnitPrice:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .UnitPrice,
            ResourceName:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .ResourceName,
            ResourceId:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .ResourceId,
            ParentResourceName:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .ParentResourceName,
            ParentResourceId:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .ParentResourceId,
            DisplayName:
              invoice.Data[ServiceCode][Region][Product][Resource][usage]
                .DisplayName,
          }));
        resetForm({
          values: initialValue,
        });
      }
    }
  }, [invoice, Data, resetForm]);
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="960px"
      minHeight="880px"
      title={t('modal.cloud.edit.title')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {globalT('button.save')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ flex: 1 }}>
        <InvoiceAccordion
          csp="nhn"
          accountId={(invoice as NHNInvoice)?.OriginData.ProjectName}
          cost={invoice?.TotalCost.KRW ?? 0}
          forceOpen
        >
          {/*<InvoiceAccountHeader*/}
          {/*  accountId={invoice?.AccountId ?? ''}*/}
          {/*  totalCost={invoice?.TotalCost.KRW ?? 0}*/}
          {/*/>*/}
          <InvoiceCloudService
            name={Data?.ServiceCode ?? ''}
            currency="KRW"
            hideCost
          >
            <InvoiceCloudRegion
              name={Data?.Region ?? ''}
              currency="KRW"
              hideCost
            >
              <InvoiceCloudProduct name={Data?.Product ?? ''}>
                <InvoiceCloudProduct name={Data?.ResourceName ?? ''} />
              </InvoiceCloudProduct>
            </InvoiceCloudRegion>
          </InvoiceCloudService>
          <Box sx={{ px: '40px', mb: '16px' }}>
            <Table size="condensed">
              <colgroup>
                <col width="160px" />
                <col width="180px" />
                <col width="140px" />
                <col width="96px" />
                <col width="140px" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>{tableT('aws.usageDetail')}</TableCell>
                  <TableCell>{tableT('aws.usageAmount')}</TableCell>
                  <TableCell align="right">{tableT('usageCost')}</TableCell>
                  <TableCell align="center">{tableT('discountRate')}</TableCell>
                  <TableCell align="right">{tableT('billingAmount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item, index) => (
                  <TableRow
                    key={`cloud-usage-nhn-edit-item-${index}`}
                    sx={{ border: 'none !important' }}
                  >
                    <TableCell>{item.DisplayName}</TableCell>
                    <TableCell>
                      {formatLocaleString(item.UsageAmount, 0, 2)}{' '}
                      {item.PricingUnit}
                    </TableCell>
                    <TableCell align="right">
                      {formatKRW(item.UsageCost)}
                    </TableCell>
                    <BodyTableCell align="center">
                      <TextField
                        name={`${index}.DiscountRate`}
                        value={item.DiscountRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={hasError(`${index}.DiscountRate`)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        type="number"
                        inputMode="decimal"
                        autoComplete="off"
                        fullWidth
                      />
                    </BodyTableCell>
                    <TableCell align="right">
                      {formatKRW(
                        calculateContractCost(
                          item.UsageCost,
                          item.DiscountRate,
                        ),
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </InvoiceAccordion>
      </Box>
    </LargeModal>
  );
}
