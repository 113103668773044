import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetCompany, useUpdateCompany } from '@api/queries/company';
import { useCurrentInfo } from '@hooks';

export default function SettingsCompanyInformation() {
  const { t } = useTranslation('settings');
  const { t: validationT } = useTranslation('validation', {
    keyPrefix: 'settings.company.registrationNumber',
  });
  const { data: company } = useGetCompany();
  const { hasRole } = useCurrentInfo();
  // const { data: response } = useGetUserProfile();
  const { mutateAsync, isPending } = useUpdateCompany();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdateCompany>({
    initialValues: {
      Name: '',
      Representative: '',
      RegistrationNumber: '',
      Website: '',
      Email: '',
      Address: '',
      DefaultPaymentDue: 0,
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string().defined(),
      Representative: Yup.string(),
      RegistrationNumber: Yup.string().matches(
        /^[1-9][0-9-]+[0-9]$/,
        validationT('length'),
      ),
      // .test('validation', validationT('invalid'), (value) => {
      //   if (value) {
      //     const numberMap = value
      //       .replace(/-/gi, '')
      //       .split('')
      //       .map((v) => parseInt(v, 10));
      //     if (numberMap.length === 10) {
      //       const validArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      //       let chk = 0;
      //       validArr.forEach((v, i) => {
      //         chk += v * numberMap[i];
      //       });
      //       chk += Math.floor((validArr[8] * numberMap[8]) / 10);
      //       return Math.floor(numberMap[9]) === 10 - (chk % 10);
      //     }
      //     return false;
      //   }
      //   return true;
      // }),
      Website: Yup.string(),
      Email: Yup.string().email(),
      Address: Yup.string(),
      DefaultPaymentDue: Yup.number().min(0).max(31),
    }),
    onSubmit: async (v, helpers) => {
      try {
        const { data } = await mutateAsync({
          ...v,
          Representative: !isEmpty(v.Representative)
            ? v.Representative
            : undefined,
          RegistrationNumber: !isEmpty(v.RegistrationNumber)
            ? v.RegistrationNumber
            : undefined,
          Website: !isEmpty(v.Website) ? v.Website : undefined,
          Email: !isEmpty(v.Email) ? v.Email : undefined,
          Address: !isEmpty(v.Address) ? v.Address : undefined,
        });
        helpers.resetForm({
          values: {
            Name: data.Name ?? '',
            Representative: data.Representative ?? '',
            RegistrationNumber: data.RegistrationNumber ?? '',
            Website: data.Website ?? '',
            Email: data.Email ?? '',
            Address: data.Address ?? '',
            DefaultPaymentDue: data.DefaultPaymentDue ?? 0,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (company?.data) {
      resetForm({
        values: {
          Name: company.data.Name ?? '',
          Representative: company.data.Representative ?? '',
          RegistrationNumber: company.data.RegistrationNumber ?? '',
          Website: company.data.Website ?? '',
          Email: company.data.Email ?? '',
          Address: company.data.Address ?? '',
          DefaultPaymentDue: company.data.DefaultPaymentDue ?? 0,
        },
      });
    }
  }, [company?.data, resetForm]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
        maxWidth: '608px',
      }}
    >
      <Typography color="textSecondary" fontSize="14px" letterSpacing="0.56px">
        {t('title.company.info')}
      </Typography>
      <Grid container spacing="24px">
        <Grid xs={6}>
          <TextField
            name="Name"
            value={values.Name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.Name && touched.Name)}
            label={t('form.label.companyName')}
            disabled={!hasRole('ADMIN')}
            fullWidth
          />
        </Grid>
        {/*<Grid xs={6}>*/}
        {/*  <TextField*/}
        {/*    name="Representative"*/}
        {/*    value={values.Representative}*/}
        {/*    onChange={handleChange}*/}
        {/*    onBlur={handleBlur}*/}
        {/*    error={Boolean(errors.Representative && touched.Representative)}*/}
        {/*    label={t('form.label.representative')}*/}
        {/*    disabled={!hasRole('ADMIN')}*/}
        {/*    type="text"*/}
        {/*    inputMode="text"*/}
        {/*    fullWidth*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid xs={6}>
          <TextField
            name="Website"
            value={values.Website}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.Website && touched.Website)}
            label={t('form.label.website')}
            disabled={!hasRole('ADMIN')}
            type="url"
            inputMode="url"
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="Email"
            value={values.Email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.Email && touched.Email)}
            label={t('form.label.companyEmail')}
            disabled={!hasRole('ADMIN')}
            type="email"
            inputMode="email"
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="Address"
            value={values.Address}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.Address && touched.Address)}
            label={t('form.label.location')}
            disabled={!hasRole('ADMIN')}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="RegistrationNumber"
            value={values.RegistrationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              errors.RegistrationNumber && touched.RegistrationNumber,
            )}
            helperText={
              Boolean(
                errors.RegistrationNumber && touched.RegistrationNumber,
              ) && String(errors.RegistrationNumber)
            }
            label={t('form.label.registrationNumber')}
            disabled={!hasRole('ADMIN')}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="Representative"
            value={values.Representative}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.Representative && touched.Representative)}
            helperText={
              Boolean(errors.Representative && touched.Representative) &&
              String(errors.Representative)
            }
            label={t('form.label.representative')}
            disabled={!hasRole('ADMIN')}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="DefaultPaymentDue"
            value={values.DefaultPaymentDue}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              errors.DefaultPaymentDue && touched.DefaultPaymentDue,
            )}
            label="기본 납입일"
            disabled={!hasRole('ADMIN')}
            select
            fullWidth
          >
            <MenuItem value={0}>설정 안 함</MenuItem>
            {[...Array(30)].map((_, i) => (
              <MenuItem key={`payment_due_item_${i}`} value={i + 1}>
                매월 {i + 1}일
              </MenuItem>
            ))}
            <MenuItem value={31}>매월 말일</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        color="emphasis1"
        loading={isPending}
        disabled={!hasRole('ADMIN') || !isValid || !dirty}
      >
        {t('button.saveChanges')}
      </LoadingButton>
    </Box>
  );
}
