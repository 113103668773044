import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { ORGANIZATION_KEY } from '@constants/query';

export default function useDeleteContact() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.DELETE_CONTACT,
    mutationFn: (v: { UID: string; OrganizationId: string }) =>
      OrganizationService.deleteContact(v.OrganizationId, v.UID),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI,
          variables.OrganizationId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID,
          variables.OrganizationId,
        ],
      });
    },
  });
}
