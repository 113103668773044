import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardService } from '@api/services';
import { DASHBOARD_KEY } from '@constants/query';

export default function useGetNCPAccountRatio() {
  return useSuspenseQuery({
    queryKey: DASHBOARD_KEY.GET_NCP_ACCOUNT_RATIO,
    queryFn: () => DashboardService.getNCPAccountRatio(),
  });
}
