import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { SettingsPermissionMemberTable } from '@features/settings/permission';
import { SuspensePaper } from '@components/suspense';

export default function SettingsPermissionPage() {
  const { t } = useTranslation('settings', { keyPrefix: 'title.permission' });
  return (
    <SuspensePaper
      sx={{ flex: 1 }}
      FallbackProps={{ size: 72, sx: { flex: 1 } }}
    >
      <Typography
        fontSize="20px"
        lineHeight="120%"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="24px"
      >
        {t('member')}
      </Typography>
      <SettingsPermissionMemberTable />
    </SuspensePaper>
  );
}
