import { Dialog as MuiDialog, styled } from '@mui/material';
import { getDialogUtilityClass } from '@mui/material/Dialog';
import { getBackdropUtilityClass } from '@mui/material/Backdrop';

export const SupportDialog = styled(MuiDialog)(({ theme }) => ({
  [`> .${getDialogUtilityClass('container')}`]: {
    position: 'relative',
    padding: '32px',
    [`> .${getDialogUtilityClass('paper')}`]: {
      width: '100%',
      height: '100%',
      maxWidth: '420px',
      maxHeight: '720px',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 32px 56px 32px',
      borderRadius: '16px',
      background: 'rgba(255, 255, 255, 0.95)',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
      backdropFilter: 'blur(3px)',
      margin: 0,
    },
  },
  [`> .${getBackdropUtilityClass('root')}`]: {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.up('md')]: {
    [`> .${getDialogUtilityClass('container')} > .${getDialogUtilityClass(
      'paper',
    )}`]: {
      position: 'absolute',
      left: '136px',
      bottom: '80px',
    },
  },
}));

export const SupportSection = styled('div')({
  flex: 1,
});
