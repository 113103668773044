import { useMemo, useState } from 'react';
import { enUS, koKR } from '@mui/x-date-pickers/locales';
import { overrideTheme } from '@heka/theme';
import LinkBehavior from './LinkBehavior';

export function useHekaTheme() {
  const [lang, setLang] = useState('ko');
  const theme = useMemo(() => {
    let localeSet = koKR;
    if (lang !== 'ko') {
      localeSet = enUS;
    }
    return overrideTheme(
      'light',
      {
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            },
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
        },
      },
      localeSet,
    );
  }, [lang]);
  return { theme, setThemeLang: setLang };
}
