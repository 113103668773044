import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Tab, Typography, useMediaQuery } from '@mui/material';
import { sumBy } from 'lodash';
import { Tabs } from '@heka/theme/src';
import { useGetSalesTrend } from '@api/queries/dashboard';
import { useGetCompany } from '@api/queries/company';
import SalesMonthlyChart from '@components/chart/sales/monthly';
import { DashboardNotFound } from '@features/dashboard/styled';

export default function SalesChart() {
  const { t } = useTranslation('dashboard');
  const [dataKey, setDataKey] =
    useState<Exclude<keyof SalesTrend, 'Month'>>('Total');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const { data: response } = useGetSalesTrend();
  const { data: company } = useGetCompany();
  const chartData = useMemo(() => {
    if (response?.data) {
      return response.data.map((v) => ({
        Month: v.Month,
        TotalPurchaseAmount: v[dataKey].PurchaseAmount,
        TotalSupplyAmount: v[dataKey].SupplyAmount,
        TotalMarginAmount: v[dataKey].MarginAmount,
      }));
    }
    return [];
  }, [response?.data, dataKey]);
  const isDataEmpty = useMemo(() => {
    return (
      !sumBy(response?.data, 'Total.PurchaseAmount') &&
      !sumBy(response?.data, 'Total.SupplyAmount') &&
      !sumBy(response?.data, 'Total.MarginAmount')
    );
  }, [response?.data]);
  return (
    <>
      <Typography
        color="textSecondary"
        fontSize="14px"
        letterSpacing="0.56px"
        mb="24px"
      >
        {t('title.sales')}
      </Typography>
      <Tabs
        value={dataKey}
        onChange={(e, v) => setDataKey(v as Exclude<keyof SalesTrend, 'Month'>)}
        sx={{ px: 0, mx: '-8px', mb: '16px' }}
      >
        <Tab value="Total" label="Total" />
        {!!company?.data?.AvailableCSP?.includes('ncp') && (
          <Tab value="NCP" label="Naver Cloud" />
        )}
        {!!company?.data?.AvailableCSP?.includes('nhn') && (
          <Tab value="NHN" label="NHN Cloud" />
        )}
      </Tabs>
      {!isDataEmpty ? (
        <SalesMonthlyChart
          csp={dataKey === 'NCP' ? 'ncp' : 'nhn'}
          data={chartData.slice(isTablet ? -6 : -3)}
          height={300}
          disableStroke
        />
      ) : (
        <DashboardNotFound />
      )}
    </>
  );
}
