import type { PropsWithChildren } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import { Refresh as RefreshIcon } from '@mui/icons-material';

export interface SuspenseFallbackProps {
  sx?: BoxProps['sx'];
  size?: CircularProgressProps['size'];
}

export function SuspenseFallback({ sx, size }: SuspenseFallbackProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '100%',
        ...sx,
      }}
    >
      <CircularProgress size={size ?? 64} sx={{ color: '#EED700' }} />
    </Box>
  );
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '100%',
        p: '32px',
      }}
    >
      <IconButton onClick={() => resetErrorBoundary()}>
        <RefreshIcon />
      </IconButton>
    </Box>
  );
}

export function ErrorBoundary({ children }: PropsWithChildren) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ReactErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          {children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
