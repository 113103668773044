import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetAWSCostAutocomplete } from '@api/queries/aws/cost';
import { formatLocaleString } from '@utils/formatter';

export default function ServiceCount() {
  const { t } = useTranslation('cost');
  const { data: response } = useGetAWSCostAutocomplete();
  return (
    <>
      <Typography fontSize="14px" letterSpacing="0.56px">
        {t('summary.serviceCount.title')}
      </Typography>
      <Typography fontSize="24px" fontWeight={500} color="#362F26">
        {formatLocaleString(response?.data.NumberOfServices)}
      </Typography>
    </>
  );
}
