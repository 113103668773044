import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
  MenuItem,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
} from '@mui/material';
import { getAutocompleteUtilityClass } from '@mui/material/Autocomplete';
import { getOutlinedInputUtilityClass } from '@mui/material/OutlinedInput';
import { LoadingButton } from '@mui/lab';
import { Add, Remove, KeyboardArrowDown } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useUpdateInvoiceStatus } from '@api/queries/invoice';
import { InvoiceChip } from '@components/styled/chip';
import LargeModal from '@components/modal/large';
import { formatDate, formatLocaleString } from '@utils/formatter';
import { INVOICE_PUBLISH_STATE } from '../atom';
import { InvoiceEmailAccordion } from '../cloudUsage/styled';
import dayjs from 'dayjs';

type ContactListProps = {
  selectedContacts: Array<Contact>;
  setSelectedContacts: Dispatch<SetStateAction<Array<Contact>>>;
  Contacts: Array<Contact>;
};
function ContactList({
  selectedContacts,
  setSelectedContacts,
  Contacts,
}: ContactListProps) {
  const { t } = useTranslation('invoice');
  return (
    <Autocomplete
      value={selectedContacts}
      options={Contacts ?? []}
      onChange={(e, v) => {
        setSelectedContacts(v);
      }}
      getOptionLabel={(option) => `${option.Name}(${option.Email})`}
      renderTags={() => null}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem {...props} className={undefined} selected={selected}>
            <Checkbox checked={selected} sx={{ p: 0, pr: '8px' }} />
            {option.Name} ({option.Email})
          </MenuItem>
        );
      }}
      popupIcon={<KeyboardArrowDown />}
      slotProps={{
        paper: {
          sx: { p: 0, mt: '8px' },
        },
        popupIndicator: {
          sx: {
            p: '0 !important',
            '> svg': {
              color: '#323232',
              width: '24px !important',
              height: '24px !important',
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            selectedContacts.length
              ? t('text.selectedRecipient', { count: selectedContacts.length })
              : t('form.holder.customerRecipient')
          }
          InputProps={{ ...params.InputProps, readOnly: true }}
          sx={{
            [`> .${getOutlinedInputUtilityClass('root')}`]: {
              p: 0,
              cursor: 'pointer',
              userSelect: 'none',
              [`> .${getOutlinedInputUtilityClass('input')}`]: {
                p: '12px 16px',
                cursor: 'pointer',
                userSelect: 'none',
              },
            },
          }}
        />
      )}
      ListboxProps={{
        sx: {
          py: 0,
        },
      }}
      noOptionsText={t('form.option.customer')}
      disableCloseOnSelect
      disableClearable
      multiple
      sx={{
        [`& .${getAutocompleteUtilityClass('endAdornment')}`]: {
          right: '8px',
        },
      }}
    />
  );
}

export default function PublishInvoiceModal({
  type,
  Contacts,
  open,
  onClose,
}: ModalProps & { type: 'initial' | 'correction'; Contacts: Array<Contact> }) {
  const { t } = useTranslation('invoice');
  const { t: globalT } = useTranslation('global');
  const [selectedContacts, setSelectedContacts] = useState<Array<Contact>>([]);
  const [publishState, setPublishState] = useRecoilState(INVOICE_PUBLISH_STATE);
  const { mutateAsync, isPending } = useUpdateInvoiceStatus();
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik<{
    EmailList: Array<{ Email: string }>;
    AttachmentType: 'all' | 'pdf' | 'excel';
    IncludeTransaction: boolean;
    ApplyInvoicedDate: boolean;
  }>({
    initialValues: {
      EmailList: [],
      AttachmentType: 'pdf',
      IncludeTransaction: false,
      ApplyInvoicedDate: false,
    },
    validationSchema: Yup.object()
      .shape({
        EmailList: Yup.array().of(
          Yup.object().shape({
            Email: Yup.string().email().defined(),
          }),
        ),
        AttachmentType: Yup.mixed().oneOf(['all', 'pdf', 'excel']).defined(),
        IncludeTransaction: Yup.boolean().defined(),
        ApplyInvoicedDate: Yup.boolean().defined(),
      })
      .defined(),
    onSubmit: async (v, helpers) => {
      const EmailList = [
        ...selectedContacts.map((v) => v.Email),
        ...v.EmailList.map((v) => v.Email),
      ];
      const Attached =
        values.AttachmentType === 'all'
          ? ['pdf', 'excel']
          : [values.AttachmentType];
      if (values.IncludeTransaction) {
        Attached.push('transaction');
      }
      try {
        await mutateAsync({
          InvoiceId: publishState.InvoiceId,
          Status: publishState.Status,
          InvoicedDate: formatDate(
            v.ApplyInvoicedDate ? dayjs() : publishState.InvoicedDate,
            'YYYY-MM-DDT00:00:00',
          ),
          PaymentDate: formatDate(
            publishState.PaymentDate,
            'YYYY-MM-DDT00:00:00',
          ),
          EmailList,
          Attached,
        });
        setPublishState((k) => ({ ...k, open: false }));
        setTimeout(() => {
          helpers.resetForm();
        }, 500);
      } catch (e) {
        console.error(e);
      }
    },
  });
  const addItem = useCallback(() => {
    const clone = _.cloneDeep(values.EmailList);
    clone.push({ Email: '' });
    setFieldValue('EmailList', clone);
  }, [values.EmailList, setFieldValue]);
  const removeItem = useCallback(
    (idx: number) => () => {
      const clone = _.cloneDeep(values.EmailList);
      clone.splice(idx, 1);
      setFieldValue('EmailList', clone);
    },
    [values.EmailList, setFieldValue],
  );
  return (
    <LargeModal
      type="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t(`modal.publish.${type}.title`)}
      titleProps={{ color: 'text.main' }}
      descriptionProps={{
        color: 'text.main',
        fontSize: '16px',
        lineHeight: '150%',
      }}
      description={
        <Trans
          i18nKey={`invoice:modal.publish.${type}.description`}
          components={{ chip: <InvoiceChip status="Invoiced" /> }}
        />
      }
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton type="submit" loading={isPending} disabled={!isValid}>
            {t(`modal.publish.${type}.cta`)}
          </LoadingButton>
        </>
      }
      maxWidth="640px"
      minHeight="880px"
      pb="40px"
      sx={{
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography color="text.main" mb="24px">
          {t('text.publishInvoiceToCustomer')}
        </Typography>
        <ContactList
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
          Contacts={Contacts}
        />
        <InvoiceEmailAccordion title={t('text.addRecipient')}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {values.EmailList.map((contact, idx) => (
              <Box
                key={`manual-contact-list-${idx}`}
                sx={{
                  display: 'flex',
                  borderBottom: '0.5px solid #CECFDE',
                  mx: '16px',
                }}
              >
                <Box
                  sx={{
                    flex: '0 0 40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '8px 16px',
                  }}
                >
                  <Typography
                    color="textSecondary"
                    fontSize="14px"
                    letterSpacing="0.56px"
                  >
                    {formatLocaleString(idx + 1)}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, p: '16px' }}>
                  <TextField
                    name={`EmailList.${idx}.Email`}
                    value={contact.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('form.label.recipientEmail')}
                    placeholder={t('form.holder.recipientEmail')}
                    error={Boolean(
                      _.get(errors, `${idx}.Email`) &&
                        _.get(touched, `${idx}.Email`),
                    )}
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    flex: '0 0 40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    py: '24px',
                  }}
                >
                  <IconButton
                    color="sub"
                    size="small"
                    onClick={removeItem(idx)}
                  >
                    <Remove />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: '16px',
                background: '#CECFDE0D',
              }}
            >
              <Button
                size="small"
                color="sub"
                startIcon={<Add />}
                onClick={addItem}
              >
                {t('button.addNewRecipient')}
              </Button>
            </Box>
          </Box>
        </InvoiceEmailAccordion>
        <Typography color="text.main" mt="40px" mb="24px">
          {t('text.setAttachment')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box>
            <InputLabel sx={{ mb: '16px' }}>
              {t('form.label.invoiceAttachment')}
            </InputLabel>
            <RadioGroup
              name="AttachmentType"
              value={values.AttachmentType}
              onChange={(e, v) => setFieldValue('AttachmentType', v)}
              sx={{ gap: '8px' }}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={t('form.label.selectAll')}
                sx={{ ml: '8px' }}
              />
              <FormControlLabel
                value="pdf"
                control={<Radio />}
                label="PDF"
                sx={{ ml: '8px' }}
              />
              <FormControlLabel
                value="excel"
                control={<Radio />}
                label="Excel"
                sx={{ ml: '8px' }}
              />
            </RadioGroup>
          </Box>
          <Box>
            <InputLabel sx={{ mb: '16px' }}>
              {t('form.label.additionalAttachment')}
            </InputLabel>
            <FormControlLabel
              name="IncludeTransaction"
              value={values.IncludeTransaction}
              onChange={(e, v) => setFieldValue('IncludeTransaction', v)}
              control={<Checkbox size="small" />}
              label={t('form.label.transaction')}
              sx={{ ml: '12px !important' }}
            />
          </Box>
        </Box>
        {type === 'initial' && (
          <>
            <Typography color="text.main" mt="40px" mb="24px">
              청구서 설정
            </Typography>
            <Box>
              <InputLabel sx={{ mb: '16px' }}>발행일</InputLabel>
              <FormControlLabel
                name="ApplyInvoicedDate"
                value={values.ApplyInvoicedDate}
                onChange={(e, v) => setFieldValue('ApplyInvoicedDate', v)}
                control={<Checkbox size="small" />}
                label="현재 일자로 변경"
                sx={{ ml: '12px !important' }}
              />
            </Box>
          </>
        )}
      </Box>
    </LargeModal>
  );
}
