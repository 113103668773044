import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Pagination,
} from '@mui/material';
import _ from 'lodash';
import { Search } from '@heka/theme/src';

export default function SimpleTable({
  size,
  columns,
  data,
  title,
  children,
  columnVisibility,
  maxHeight,
  emptyState,
  rowSelection,
  enablePagination,
  enableRoute,
  enableGlobalFilter,
}: PropsWithChildren<SimpleTableProps>) {
  const { t } = useTranslation('table');
  const [filterValue, setFilterValue] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  useEffect(() => {
    const timeout = setTimeout(() => {
      setGlobalFilter(filterValue);
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);
  const fuzzyFilter: FilterFn<any> = useCallback(
    (row, columnId, filterValue, addMeta) => {
      const itemRank = rankItem(row.getValue(columnId), filterValue);
      addMeta({ itemRank });
      return itemRank.passed;
    },
    [],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const handlePagination = useCallback(
    (updater: Updater<PaginationState>) => {
      if (typeof updater === 'function') {
        const updated = updater(pagination);
        if (enableRoute) {
          searchParams.set('page', (updated.pageIndex + 1).toString());
          setSearchParams(searchParams);
        } else {
          setPagination(updated);
        }
      }
    },
    [enableRoute, pagination, searchParams, setSearchParams],
  );
  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      ...(!!rowSelection && {
        rowSelection: rowSelection.selectedRows,
      }),
      ...(!!enableGlobalFilter && {
        globalFilter,
      }),
      ...(!!enablePagination && {
        pagination,
      }),
    },
    ...(!!rowSelection && {
      onRowSelectionChange: rowSelection.onRowSelected,
    }),
    ...(enablePagination && {
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: handlePagination,
    }),
    ...(!!enableGlobalFilter && {
      getFilteredRowModel: getFilteredRowModel(),
    }),
    ...(!!enableGlobalFilter && {
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: fuzzyFilter,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
    }),
  });
  useEffect(() => {
    if (enableRoute) {
      const page = searchParams.get('page');
      if (!page || _.isNaN(Number(page))) {
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
        return;
      }
      const pageIndex = _.toInteger(page) - 1;
      if (pageIndex < 0) {
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
        return;
      }
      if (pageIndex >= table.getPageCount()) {
        searchParams.set('page', table.getPageCount().toString());
        setSearchParams(searchParams, { replace: true });
        return;
      }
      if (pagination.pageIndex !== pageIndex) {
        setPagination((v) => ({
          ...v,
          pageIndex: _.toInteger(page) - 1,
        }));
      }
    } else {
      if (pagination.pageIndex < 0) {
        table.setPageIndex(0);
      } else if (pagination.pageIndex >= table.getPageCount()) {
        table.setPageIndex(table.getPageCount() - 1);
      }
    }
  }, [enableRoute, pagination.pageIndex, searchParams, setSearchParams, table]);
  const showHeader = useMemo(() => {
    return Boolean(title || children || enableGlobalFilter);
  }, [title, children, enableGlobalFilter]);
  return (
    <Box>
      {showHeader && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
            pb: '16px',
          }}
        >
          {!!title && (
            <Typography
              fontSize="18px"
              lineHeight="120%"
              fontWeight={500}
              letterSpacing="0.72px"
              flex={1}
            >
              {title}
            </Typography>
          )}
          {Boolean(children || enableGlobalFilter) && (
            <>
              {!!enableGlobalFilter && (
                <Search
                  value={filterValue ?? ''}
                  onChange={(e) => setFilterValue(e.target.value)}
                  placeholder={t('holder.search')}
                  type="search"
                  inputMode="text"
                  autoComplete="off"
                />
              )}
              {Boolean(children) && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {children}
                </Box>
              )}
            </>
          )}
        </Box>
      )}
      {(!!data?.length || !emptyState) && (
        <>
          <TableContainer sx={{ maxHeight }}>
            <Table size={size}>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        align={header.column.columnDef.meta?.align}
                        sx={{
                          width: header.column.columnDef.size,
                          minWidth: header.column.columnDef.minSize,
                          maxWidth: header.column.columnDef.maxSize,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} hover selected={row.getIsSelected()}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        align={cell.column.columnDef.meta?.align}
                        sx={{
                          width: cell.column.columnDef.size,
                          minWidth: cell.column.columnDef.minSize,
                          maxWidth: cell.column.columnDef.maxSize,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!!enablePagination && table.getPageCount() > 1 && (
            <Pagination
              page={table.getState().pagination.pageIndex + 1}
              count={table.getPageCount()}
              onChange={(e, v) => table.setPageIndex(v - 1)}
              hidePrevButton={table.getState().pagination.pageIndex === 0}
              hideNextButton={
                table.getState().pagination.pageIndex ===
                table.getPageCount() - 1
              }
              sx={{ mt: '16px' }}
            />
          )}
        </>
      )}
      {!data?.length && !!emptyState && (
        <Box sx={{ mt: '16px' }}>{emptyState}</Box>
      )}
    </Box>
  );
}
