import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

type Props = {
  href: LinkProps['to'] | -1;
  label?: string;
};
export default function BackToListButton({ href, label }: Props) {
  const { t } = useTranslation('global');
  return (
    <Box
      component={Link}
      // @ts-expect-error: Type error
      to={href}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        gap: '8px',
        mb: '24px',
        textDecoration: 'none',
      }}
    >
      <IconButton size="small" color="sub">
        <KeyboardArrowLeft />
      </IconButton>
      <Typography color="textPrimary" letterSpacing="0.64px">
        {label ?? t('button.backToList')}
      </Typography>
    </Box>
  );
}
