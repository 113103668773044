import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { formatDate, formatLocaleString } from '@utils/formatter';

type PathRadius = 'NONE' | 'BOTH' | 'TOP' | 'BOTTOM';

const COLORS = [
  '#CFB4A1',
  '#B5A288',
  '#CCAA67',
  '#94705B',
  '#B38A5B',
  '#EEBB9F',
  '#FAE9AF',
  '#FFF8DF',
  '#E1F0A5',
  '#C7E4BC',
  '#8BD0BF',
  '#66BEC3',
  '#529CB3',
  '#43507D',
  '#385FAA',
  '#3B80B0',
];

type CostMultipleChartProps = {
  data?: Array<{
    Date: Date;
    Cost: number;
    Items: Array<{ Name: string; Cost: number }>;
  }>;
  Granularity: CEGranularity;
  GroupBy?: CEGroupBy;
} & Pick<AWSCEUsageByOther, 'TotalCostByItems'>;

export default function CostMultipleChart({
  data = [],
  Granularity,
  GroupBy,
  TotalCostByItems,
}: CostMultipleChartProps) {
  const { t } = useTranslation('cost');
  const labels = useMemo(() => {
    const items = Object.keys(
      _(TotalCostByItems).toPairs().orderBy(1, 'desc').fromPairs().value(),
    ).filter((v) => v !== 'TotalCost');
    if (items.length > 10) {
      return [...items.slice(0, 8), 'Others'];
    }
    return items;
  }, [TotalCostByItems]);
  const chartData = useMemo(() => {
    return data.map((v) => {
      const items: Record<string, number> = {};
      labels.forEach((k) => {
        Object.assign(items, { [k]: null });
      });
      v.Items.forEach((k) => {
        if (_.isNumber(k.Cost)) {
          if (labels.includes(k.Name)) {
            items[k.Name] = Number(items[k.Name]) + k.Cost;
          } else if (_.has(items, 'Others')) {
            items.Others = Number(items.Others) + k.Cost;
          }
        }
      });
      return {
        Date: v.Date,
        Cost: v.Cost,
        ...items,
      };
    });
  }, [data, labels]);
  const formatName = useCallback(
    (name: string) => {
      if (GroupBy === 'TAG' && _.isEmpty(name)) {
        return t('chart.text.emptyTag');
      }
      if (name === 'Others') {
        return t('chart.text.others');
      }
      return name;
    },
    [GroupBy],
  );
  const makePath = useCallback(
    (type: PathRadius, x: number, y: number, width: number, height: number) => {
      switch (type) {
        case 'BOTH':
          return `M${x},${y + 8}A 8,8,0,0,1,${x + 8},${y}L ${
            x + width - 8
          },${y}A 8,8,0,0,1,${x + width},${y + 8}L ${x + width},${
            y + height - 8
          }A 8,8,0,0,1,${x + width - 8},${y + height}L ${x + 8},${
            y + height
          }A 8,8,0,0,1,${x},${y + height - 8}Z`;
        case 'TOP':
          return `M${x},${y + 8}A 8,8,0,0,1,${x + 8},${y}L ${
            x + width - 8
          },${y}A 8,8,0,0,1,${x + width},${y + 8}L ${x + width},${
            y + height
          }L ${x},${y + height}Z`;
        case 'BOTTOM':
          return `M${x},${y}L ${x + width},${y}L ${x + width},${
            y + height - 8
          }A 8,8,0,0,1,${x + width - 8},${y + height}L ${x + 8},${
            y + height
          }A 8,8,0,0,1,${x},${y + height - 8}Z`;
        default:
          return `M ${x},${y} h ${width} v ${height} h -${width} Z`;
      }
    },
    [],
  );
  return (
    <Box>
      <ResponsiveContainer width="100%" height={460}>
        <BarChart data={chartData} barSize={32}>
          <XAxis
            dataKey="Date"
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(v) =>
              formatDate(
                v,
                Granularity === 'MONTHLY' ? 'YYYY.MM' : 'YYYY.MM.DD',
              )
            }
            padding={{ left: 16, right: 16 }}
          />
          <YAxis
            scale="linear"
            stroke="none"
            tick={{ fill: '#777B86', fontSize: '12px', fontWeight: 400 }}
            tickLine={false}
            tickSize={0}
            tickMargin={8}
            tickFormatter={(v) => formatLocaleString(v) ?? ''}
            padding={{ top: 8, bottom: 8 }}
          />
          <CartesianGrid vertical={false} stroke="#E0E0E0" strokeWidth={0.5} />
          {labels.map((label, idx) => (
            <Bar
              key={`cost_multiple_chart_bar_${label}`}
              dataKey={label}
              stackId="stack"
              fill={COLORS[idx % COLORS.length]}
              shape={({ name, ...props }) => {
                const { height = 0, payload } = props as any;
                const origin = _.omit(payload, ['Date', 'Cost']);
                const firstIdx = _.findIndex(labels, (v) => origin[v] !== null);
                const lastIdx = _.findLastIndex(
                  labels,
                  (v) => origin[v] !== null,
                );
                let radius: number | [number, number, number, number] = 0;
                if (height > 2) {
                  if (idx === lastIdx) {
                    radius = [8, 8, 0, 0];
                  } else if (idx === firstIdx) {
                    radius = [0, 0, 8, 8];
                  }
                }
                return <Rectangle {...props} radius={radius} />;
              }}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '16px',
          rowGap: '8px',
          mt: '40px',
          ml: '64px',
        }}
      >
        {labels.map((label, idx) => (
          <Box
            key={`cost_multiple_chart_legend_${label}`}
            sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          >
            <Box
              sx={{
                display: 'block',
                width: '12px',
                height: '12px',
                borderRadius: '999px',
                background: COLORS[idx % COLORS.length],
              }}
            />
            <Typography color="textSecondary" fontSize="12px" lineHeight="12px">
              {formatName(label)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
