import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useUpdateContract } from '@api/queries/contract';
import CustomerContractForm, {
  useCustomerContractForm,
} from '@features/customer/form/contract';
import FormModal from '@components/modal/form';

type IProps = {
  UID: string;
  Item?: Contract;
};
export default function EditContractModal({
  open,
  onClose,
  UID,
  Item,
}: ModalProps & IProps) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.contract.edit' });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateContract();
  const { initialValues, validationSchema } = useCustomerContractForm();
  const { handleSubmit, resetForm, isValid, dirty, ...formik } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      if (Item) {
        try {
          await mutateAsync({
            ...v,
            OrganizationId: UID,
            ContractId: Item.UID,
            CSP: v.CSP as CloudProvider,
          });
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    },
  });
  useEffect(() => {
    if (Item) {
      resetForm({
        values: {
          CSP: Item.CSP,
          StartDate: Item.StartDate,
          EndDate: Item.EndDate,
          AccountIdList: Item.AccountIdList,
          DiscountRate: Item.DiscountRate,
          Memo: Item.Memo,
          EnableNotification: Item.EnableNotification,
          EnableEmail: Item.EnableEmail,
          ReminderDays: Item.ReminderDays,
        },
      });
    }
  }, [Item, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      onSubmit={handleSubmit}
      maxWidth="480px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      {!!Item && <CustomerContractForm {...formik} />}
    </FormModal>
  );
}
