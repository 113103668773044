import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateNHNAccountUser } from '@api/queries/nhn/account';
import FormModal from '@components/modal/form';

type Props = {
  UID?: string;
};
export default function EditNHNAccountUserModal({
  open,
  onClose,
  UID,
}: ModalProps & Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.account.nhn.editUser',
  });
  const { t: onboardT } = useTranslation('onboard', {
    keyPrefix: 'integrateNHN',
  });
  const { t: validationT } = useTranslation('validation');
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useUpdateNHNAccountUser();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<UpdateNHNAccountUser>({
    initialValues: {
      UID: '',
      TenantId: '',
      Username: '',
      Password: '',
    },
    validationSchema: Yup.object().shape({
      UID: Yup.string().defined(),
      TenantId: Yup.string()
        .matches(/^[a-zA-Z0-9]{32}$/, validationT('onboard.nhn.tenantId'))
        .defined(validationT('common.required.base')),
      Username: Yup.string()
        .email(validationT('auth.email'))
        .defined(validationT('common.required.base')),
      Password: Yup.string().defined(validationT('common.required.base')),
    }),
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (UID) {
      resetForm({
        values: {
          UID,
          TenantId: '',
          Username: '',
          Password: '',
        },
      });
    }
  }, [UID, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isPending}
            disabled={!UID || !isValid || !dirty}
          >
            {t('cta')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <TextField
          name="TenantId"
          value={values.TenantId}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.tenantId')}
          placeholder={onboardT('form.holder.tenantId')}
          error={Boolean(errors.TenantId && touched.TenantId)}
          helperText={
            Boolean(errors.TenantId && touched.TenantId) &&
            String(errors.TenantId)
          }
          type="text"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="Username"
          value={values.Username}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.loginEmail')}
          placeholder={onboardT('form.holder.loginEmail')}
          error={Boolean(errors.Username && touched.Username)}
          helperText={
            Boolean(errors.Username && touched.Username) &&
            String(errors.Username)
          }
          type="email"
          inputMode="email"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
        <TextField
          name="Password"
          value={values.Password}
          onChange={handleChange}
          onBlur={handleBlur}
          label={onboardT('form.label.apiPassword')}
          placeholder={onboardT('form.holder.apiPassword')}
          error={Boolean(errors.Password && touched.Password)}
          helperText={
            Boolean(errors.Password && touched.Password) &&
            String(errors.Password)
          }
          type="password"
          inputMode="text"
          autoCorrect="off"
          autoComplete="one-time-code"
          fullWidth
        />
      </Box>
    </FormModal>
  );
}
