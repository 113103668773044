import { useSuspenseQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { NHNSalesService } from '@api/services';
import { NHN_SALES_FILTER_STATE } from '@atoms/page/filter';
import { NHN_SALES_KEY } from '@constants/query';

export default function useGetNHNSalesGraph() {
  const filter = useRecoilValue(NHN_SALES_FILTER_STATE);
  return useSuspenseQuery({
    queryKey: [...NHN_SALES_KEY.GET_SALES_GRAPH, filter],
    queryFn: ({ queryKey }) =>
      NHNSalesService.getNHNSalesGraph(queryKey[2] as NHNSalesFilter),
  });
}
