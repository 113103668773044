import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { NHN_ACCOUNT_KEY, ORGANIZATION_KEY } from '@constants/query';

export default function useBulkAddCloudAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.BULK_ADD_CLOUD_ACCOUNT,
    mutationFn: ({
      OrganizationId,
      CSP,
      Accounts,
      DiscountRate,
    }: {
      OrganizationId: string;
      CSP: CloudProvider;
      Accounts: Array<CreateCloudAccount>;
      DiscountRate?: number;
    }) =>
      OrganizationService.bulkAddCloudAccount(
        OrganizationId,
        CSP,
        Accounts,
        DiscountRate,
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: [...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID, res.data.UID],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
      queryClient.invalidateQueries({
        queryKey: NHN_ACCOUNT_KEY.GET_ACCOUNT_HIERARCHY,
      });
      queryClient.invalidateQueries({
        queryKey: NHN_ACCOUNT_KEY.GET_ASSIGNED_PROJECT_LIST,
      });
    },
  });
}
