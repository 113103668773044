import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InvoiceService } from '@api/services';
import { INVOICE_KEY } from '@constants/query';

export default function useRestoreInvoiceCloudUsage() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: INVOICE_KEY.RESTORE_INVOICE_CLOUD_USAGE,
    mutationFn: (v: string) => InvoiceService.restoreInvoiceCloudUsage(v),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['INVOICE'] });
    },
  });
}
