import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeletePayerAccount } from '@api/queries/aws/account';
import SimpleModal from '@components/modal/simple';

type Props = {
  UID?: string;
};
export default function DeletePayerAccountModal({
  open,
  onClose,
  UID,
}: ModalProps & Props) {
  const { t } = useTranslation('settings', {
    keyPrefix: 'modal.account.aws.delete',
  });
  const { t: globalT } = useTranslation('global');
  const { mutateAsync, isPending } = useDeletePayerAccount();
  const handler = useCallback(async () => {
    if (UID) {
      try {
        await mutateAsync(UID);
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }, [UID, mutateAsync, onClose]);
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={t('title')}
      description={t('description')}
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            color="danger"
            onClick={handler}
            loading={isPending}
            disabled={!UID}
          >
            {globalT('button.delete')}
          </LoadingButton>
        </>
      }
    />
  );
}
