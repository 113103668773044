import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/users' as const;

async function getUsers(isClient: boolean = false) {
  const URL = isClient ? `${PREFIX}/client` : PREFIX;
  const { data } = await axios.get<MultipleResponse<User>>(URL);
  return data;
}

async function getClientUsers() {
  const { data } = await axios.get<MultipleResponse<User>>(`${PREFIX}/client`);
  return data;
}

async function getUserById(UID: string) {
  const { data } = await axios.get<SingleResponse<User>>(`${PREFIX}/${UID}`);
  return data;
}

async function getCurrentUser() {
  const { data } = await axios.get<SingleResponse<UserProfile>>(`${PREFIX}/me`);
  return data;
}

async function createUser(body: CreateUser) {
  const { data } = await axios.post<SingleResponse<User>>(PREFIX, body);
  return data;
}

async function createBulkUsers(body: CreateBulkUsers) {
  const { data } = await axios.post<MultipleResponse<User>>(
    `${PREFIX}/bulk`,
    body,
  );
  return data;
}

async function updateCurrentUser(body: UpdateUser) {
  const { data } = await axios.put<SingleResponse<User>>(PREFIX, body);
  return data;
}

async function updateUser(UID: string, body: UpdateUser) {
  const { data } = await axios.put<SingleResponse<User>>(
    `${PREFIX}/${UID}`,
    body,
  );
  return data;
}

async function deleteUser(UID: string) {
  const { data } = await axios.delete(`${PREFIX}/${UID}`);
  return data;
}

async function changePassword(body: ChangePassword, UID?: string) {
  const { data } = await axios.post<SingleResponse<User>>(
    `${PREFIX}/password/change${UID ? `/${UID}` : ''}`,
    body,
  );
  return data;
}

async function requestChangePassword(UID: string) {
  const { data } = await axios.post(`${PREFIX}/password/change/request/${UID}`);
  return data;
}

async function requestResetPassword(email: string) {
  const { data } = await axios.post(`${PREFIX}/password/reset/${email}`);
  return data;
}

async function confirmResetPassword({ Token, Email, Password }: ResetPassword) {
  const { data } = await axios.post<SingleResponse<User>>(
    `${PREFIX}/password/reset/confirm/${Token}`,
    { Email, Password },
  );
  return data;
}

async function checkPassword(body: CheckPassword) {
  const { data } = await axios.post<SingleResponse<User>>(
    `${PREFIX}/password/check`,
    body,
  );
  return data;
}

async function setInitialPassword(body: InitialPassword) {
  const { data } = await axios.post<SingleResponse<TokenResponse>>(
    `${PREFIX}/password/initial`,
    body,
  );
  return data;
}

export default {
  getUsers,
  getClientUsers,
  getUserById,
  getCurrentUser,
  createUser,
  createBulkUsers,
  updateCurrentUser,
  updateUser,
  deleteUser,
  changePassword,
  requestChangePassword,
  requestResetPassword,
  confirmResetPassword,
  checkPassword,
  setInitialPassword,
};
