import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useGetCompany(suspense?: boolean) {
  const useQueryFn = suspense ? useSuspenseQuery : useQuery;
  return useQueryFn({
    queryKey: COMPANY_KEY.GET_COMPANY,
    queryFn: () => CompanyService.getCompany(),
    staleTime: 5000,
  });
}
