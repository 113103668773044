import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dummy from '@assets/dummy.svg?react';
import { SupportSection } from '../styled';

export default function SupportForm() {
  const { t } = useTranslation('global');
  return (
    <SupportSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        '> img': {
          width: '100%',
          height: 'auto',
        },
      }}
    >
      <Dummy />
      <Typography fontSize="14px" lineHeight="150%" color="text.main">
        {t('modal.support.done.description')}
      </Typography>
    </SupportSection>
  );
}
