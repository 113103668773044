import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import {
  useCreateNHNAccount,
  useGetVerifiedNHNUser,
} from '@api/queries/nhn/account';
import {
  NHNOnboardResellerInfo,
  useNHNAccountForm,
} from '@features/onboard/nhn';
import {
  BottomActions,
  LeftPanel,
  MainPanel,
  PanelContainer,
} from '@components/layout/auth';

export default function OnboardIntegrateNHNPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation('onboard', { keyPrefix: 'integrateNHN' });
  const { t: globalT } = useTranslation('global');
  const {
    data: response,
    isFetched,
    isError,
  } = useGetVerifiedNHNUser(params.uid);
  const { mutateAsync, isPending } = useCreateNHNAccount();
  const { initialValues, validationSchema } = useNHNAccountForm();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      try {
        await mutateAsync(v);
        navigate('/onboard/done');
      } catch (e) {
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (response?.data) {
      setFieldValue('TempUID', response.data.TempUID);
    }
  }, [response?.data, setFieldValue]);
  useEffect(() => {
    if (isFetched && isError) {
      navigate(-1);
    }
  }, [isFetched, isError, navigate]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <PanelContainer disableOverflow>
        <LeftPanel title={t('title')} description={t('description')} />
        <MainPanel>
          <NHNOnboardResellerInfo
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </MainPanel>
      </PanelContainer>
      <BottomActions enableSupport>
        <Button type="button" color="cancel" onClick={() => navigate(-1)}>
          {globalT('button.prev')}
        </Button>
        <LoadingButton
          type="submit"
          color="emphasis1"
          loading={isPending}
          disabled={!isValid || !dirty}
        >
          {t('cta')}
        </LoadingButton>
      </BottomActions>
    </Box>
  );
}
