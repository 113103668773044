import { Box } from '@mui/material';
import CustomerInformationForm from '@features/customer/form/info';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

type Props = Pick<
  ReturnType<typeof useFormik<CreateOrganization>>,
  | 'values'
  | 'errors'
  | 'touched'
  | 'handleChange'
  | 'handleBlur'
  | 'setFieldValue'
>;
export default function CustomerInfo(props: Props) {
  const { t } = useTranslation('customer', { keyPrefix: 'form.customer' });
  return (
    <>
      <Typography
        fontSize="20px"
        fontWeight={500}
        letterSpacing="0.8px"
        mb="48px"
      >
        {t('steps.information.title')}
      </Typography>
      <CustomerInformationForm {...props} />
    </>
  );
}
