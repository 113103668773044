import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useDownloadReportAsExcel } from '@api/queries/report';
import FormModal from '@components/modal/form';
import { useRecoilValue } from 'recoil';
import { REPORT_FILTER_STATE } from '@atoms/page/filter';

type FileType = 'PDF' | 'CSV' | 'EXCEL';

export default function DownloadReportModal({
  open,
  onClose,
  UID,
}: ModalProps & { UID: string }) {
  const { t } = useTranslation('report');
  const { t: globalT } = useTranslation('global');
  const reportSettings = useRecoilValue(REPORT_FILTER_STATE);
  const { mutateAsync: downloadExcel, isPending: isExcelDownloading } =
    useDownloadReportAsExcel();
  const handlePdf = useCallback(async () => {
    try {
      // const { data: blob, filename } = await downloadPdf(InvoiceId);
      // const url = window.URL.createObjectURL(
      //   new Blob([blob], { type: 'application/pdf' }),
      // );
      // const link = document.createElement('a');
      // link.id = 'heka_invoice_pdf_download';
      // link.href = url;
      // if (filename) {
      //   link.download = filename;
      // }
      // const child = document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(child);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [UID, onClose]);
  const handleExcel = useCallback(
    async (Options: Array<ReportDownloadOption>) => {
      try {
        const { data: blob, filename } = await downloadExcel({ UID, Options });
        const url = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        const link = document.createElement('a');
        link.id = 'heka_report_excel_download';
        link.href = url;
        if (filename) {
          link.download = filename;
        }
        const child = document.body.appendChild(link);
        link.click();
        document.body.removeChild(child);
      } catch (e) {
        console.error(e);
      }
    },
    [UID, downloadExcel],
  );
  const handleTransaction = useCallback(async () => {
    try {
      // const { data: blob, filename } = await downloadTransaction(InvoiceId);
      // const url = window.URL.createObjectURL(
      //   new Blob([blob], {
      //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   }),
      // );
      // const link = document.createElement('a');
      // link.id = 'heka_invoice_transaction';
      // link.href = url;
      // if (filename) {
      //   link.download = filename;
      // }
      // const child = document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(child);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [UID, onClose]);
  const { values, isValid, handleSubmit, setFieldValue, resetForm } =
    useFormik<{
      Type: FileType | '';
      Options: Array<ReportDownloadOption>;
    }>({
      initialValues: {
        Type: 'EXCEL',
        Options: [],
      },
      validationSchema: Yup.object()
        .shape({
          Type: Yup.mixed().oneOf(['PDF', 'CSV', 'EXCEL']).defined(),
          Options: Yup.array(
            Yup.mixed<ReportDownloadOption>().defined(),
          ).defined(),
        })
        .defined(),
      onSubmit: async (v, helpers) => {
        switch (v.Type) {
          case 'PDF':
            await handlePdf();
            break;
          case 'CSV':
            await handleTransaction();
            break;
          case 'EXCEL':
            await handleExcel(v.Options);
            break;
        }
        onClose();
        helpers.resetForm();
      },
    });
  const toggleOption = useCallback(
    (option: ReportDownloadOption) => () => {
      const set = new Set(values.Options);
      if (set.has(option)) {
        set.delete(option);
      } else {
        set.add(option);
      }
      setFieldValue('Options', Array.from(set));
    },
    [values.Options, setFieldValue],
  );
  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetForm();
    });
  }, [onClose, resetForm]);
  return (
    <FormModal
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="리포트 다운로드"
      minHeight="300px"
      Actions={
        <>
          <Button color="cancel" onClick={onClose}>
            {globalT('button.goBack')}
          </Button>
          <LoadingButton
            type="submit"
            color="emphasis1"
            loading={isExcelDownloading}
            disabled={!isValid || _.isEmpty(values.Type)}
          >
            {globalT('button.download')}
          </LoadingButton>
        </>
      }
    >
      <Typography color="text.main" fontSize="14px" lineHeight="150%" mb="8px">
        다운로드 항목 상세 설정
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          py: '8px',
          mb: '24px',
        }}
      >
        <FormControlLabel
          name="Options.Summary"
          checked={values.Options.includes('Summary')}
          onChange={toggleOption('Summary')}
          control={<Checkbox />}
          label="리포트 요약"
        />
        <FormControlLabel
          name="Options.Amount"
          checked={values.Options.includes('Amount')}
          onChange={toggleOption('Amount')}
          control={<Checkbox />}
          label="청구 금액"
        />
        {reportSettings.DisplayOptions.includes('CUSTOMER_TREND') && (
          <FormControlLabel
            name="Options.CustomerTrend"
            checked={values.Options.includes('CustomerTrend')}
            onChange={toggleOption('CustomerTrend')}
            control={<Checkbox />}
            label="고객사 추이"
          />
        )}
        {reportSettings.DisplayOptions.includes('ACCOUNT_TREND') && (
          <FormControlLabel
            name="Options.AccountTrend"
            checked={values.Options.includes('AccountTrend')}
            onChange={toggleOption('AccountTrend')}
            control={<Checkbox />}
            label="고객사 계정 추이"
          />
        )}
        {reportSettings.DisplayOptions.includes('INCLUDE_MARGIN') && (
          <FormControlLabel
            name="Options.Sales"
            checked={values.Options.includes('Sales')}
            onChange={toggleOption('Sales')}
            control={<Checkbox />}
            label="마진 정보 포함"
          />
        )}
      </Box>
      {/*{reportSettings.Providers.includes('ncp') &&*/}
      {/*  reportSettings.DisplayOptions.includes('INCLUDE_MARGIN') && (*/}
      {/*    <>*/}
      {/*      <Typography*/}
      {/*        color="text.main"*/}
      {/*        fontSize="14px"*/}
      {/*        lineHeight="150%"*/}
      {/*        mb="8px"*/}
      {/*      >*/}
      {/*        Naver Cloud 설정*/}
      {/*      </Typography>*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          display: 'flex',*/}
      {/*          flexDirection: 'column',*/}
      {/*          alignItems: 'flex-start',*/}
      {/*          gap: '16px',*/}
      {/*          py: '8px',*/}
      {/*          mb: '24px',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <FormControlLabel*/}
      {/*          name="Options.NCPMargin"*/}
      {/*          checked={values.Options.includes('NCPMargin')}*/}
      {/*          onChange={toggleOption('NCPMargin')}*/}
      {/*          control={<Checkbox />}*/}
      {/*          label="마진 정보 포함"*/}
      {/*        />*/}
      {/*      </Box>*/}
      {/*    </>*/}
      {/*  )}*/}
    </FormModal>
  );
}
