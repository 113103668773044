import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  List,
  ListItem as MuiListItem,
} from '@mui/material';
import { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import { useHeadObserver } from './hooks';

interface ListItemProps extends MuiListItemProps {
  sectionId?: string;
  activeId?: string;
}
const ListItem = styled(MuiListItem, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'sectionId' && propName !== 'activeId',
})<ListItemProps>(({ theme, sectionId, activeId }) => ({
  color: theme.palette.text.secondary,
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '8px',
  paddingRight: '8px',
  '> a': {
    color: 'inherit !important',
    textDecoration: 'none',
  },
  ...(sectionId === activeId && {
    color: '#098FB9',
    fontWeight: 500,
    borderLeft: '1px solid #098FB9',
  }),
}));

const SECTIONS = [
  {
    id: 'summary',
    key: 'summary',
  },
  {
    id: 'detail',
    key: 'detail',
  },
  {
    id: 'cloud',
    key: 'cloudUsage',
  },
  {
    id: 'service',
    key: 'additionalService',
  },
  {
    id: 'discount',
    key: 'discount',
  },
  {
    id: 'deposit',
    key: 'deposit',
  },
] as const;

export default function InvoiceQuickMenu() {
  const { t } = useTranslation('invoice');
  const { activeId } = useHeadObserver();
  const handleNavigate = useCallback(
    (id: string) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const elem = document.querySelector(`#${id}`);
      if (elem) {
        const box = elem.getBoundingClientRect();
        const { body } = document;
        const docEl = document.documentElement;
        const scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
        const clientTop = docEl.clientTop || body.clientTop || 0;
        const top = box.top + scrollTop - clientTop;
        window.scrollTo({
          left: 0,
          top: top - 120,
          behavior: 'smooth',
        });
      }
    },
    [],
  );
  return (
    <Paper>
      <Typography fontSize="18px" fontWeight={500} letterSpacing="0.72px">
        {t('title.quickMenu')}
      </Typography>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          mt: '24px',
          p: 0,
        }}
      >
        {SECTIONS.map((section) => (
          <ListItem
            key={`invoice-quick-menu-${section.id}`}
            sectionId={section.id}
            activeId={activeId}
          >
            <Link to={`#${section.id}`} onClick={handleNavigate(section.id)}>
              {t(`title.${section.key}`)}
            </Link>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
