import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';
import {
  ChipProps as MuiChipProps,
  getChipUtilityClass,
} from '@mui/material/Chip';
import ChipCircleIcon from '@assets/vector/ChipCircleIcon.svg?react';

interface CloudTypeChipProps extends Pick<MuiChipProps, 'classes'> {
  type: NCPAccountType;
  disableCircle?: boolean;
}
const CloudTypeChip = styled(
  ({ type, disableCircle, ...props }: CloudTypeChipProps) => {
    const label = useMemo(() => {
      switch (type) {
        case 'public':
          return '공공';
        case 'enterprise':
          return '일반';
        case 'finance':
          return '금융';
      }
    }, [type]);
    return (
      <MuiChip
        {...props}
        variant="text"
        size="selected"
        icon={!disableCircle ? <ChipCircleIcon /> : undefined}
        label={label}
      />
    );
  },
)<CloudTypeChipProps>(({ type, disableCircle }) => {
  if (disableCircle) {
    switch (type) {
      case 'enterprise':
        return {
          background: '#CECFDE4D',
        };
      case 'public':
        return {
          background: '#58A3CE4D',
        };
      case 'finance':
        return {
          background: '#E3755D4D',
        };
    }
  }
  switch (type) {
    case 'enterprise':
      return {
        [`> .${getChipUtilityClass('icon')}`]: {
          color: '#CECFDE',
        },
      };
    case 'public':
      return {
        [`> .${getChipUtilityClass('icon')}`]: {
          color: '#58A3CE',
        },
      };
    case 'finance':
      return {
        [`> .${getChipUtilityClass('icon')}`]: {
          color: '#E3755D',
        },
      };
  }
});

export default CloudTypeChip;
