import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationService } from '@api/services';
import { NHN_ACCOUNT_KEY, ORGANIZATION_KEY } from '@constants/query';

export default function useDeleteCloudAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ORGANIZATION_KEY.DELETE_CLOUD_ACCOUNT,
    mutationFn: ({
      OrganizationId,
      CSP,
      AccountUID,
    }: {
      OrganizationId: string;
      CSP: CloudProvider;
      AccountUID: string;
    }) =>
      OrganizationService.deleteCloudAccount(OrganizationId, CSP, AccountUID),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...ORGANIZATION_KEY.GET_ORGANIZATION_FOR_UI,
          variables.OrganizationId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...ORGANIZATION_KEY.GET_ORGANIZATION_BY_ID,
          variables.OrganizationId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATION_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: ORGANIZATION_KEY.GET_ORGANIZATIONS,
      });
      queryClient.invalidateQueries({
        queryKey: NHN_ACCOUNT_KEY.GET_ACCOUNT_HIERARCHY,
      });
    },
  });
}
