import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyService } from '@api/services';
import { COMPANY_KEY } from '@constants/query';

export default function useDeleteLogo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: COMPANY_KEY.DELETE_LOGO,
    mutationFn: () => CompanyService.deleteLogo(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['COMPANY'] });
    },
  });
}
