import AxiosClient from '@api/client';

const axios = AxiosClient.getInstance();
const PREFIX = '/exchange' as const;

async function getExchangeRateFromLastMonth() {
  const { data } = await axios.get<MultipleResponse<ExchangeRate>>(`${PREFIX}`);
  return data;
}

async function getExchangeRateByInvoiceId(InvoiceId: string) {
  const { data } = await axios.get<MultipleResponse<ExchangeRate>>(
    `${PREFIX}/invoice/${InvoiceId}`,
  );
  return data;
}

async function getExchangeRateById(UID: string) {
  const { data } = await axios.get<SingleResponse<ExchangeRate>>(
    `${PREFIX}/${UID}`,
  );
  return data;
}

export default {
  getExchangeRateFromLastMonth,
  getExchangeRateByInvoiceId,
  getExchangeRateById,
};
